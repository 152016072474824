/* Import Skins */
@import url('../dark-skin/skin.css');
@import url('../light-skin/skin.css');
@import url('../parade-skin/skin.css');
@import url('../metro-black-skin/skin.css');
@import url('../metro-white-skin/skin.css');
@import url('../mac-skin/skin.css');
@import url('../smooth-skin/skin.css');

/* Reset Styles */
.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder, .ilightbox-holder .ilightbox-container, .ilightbox-holder .ilightbox-container img.ilightbox-image, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-toolbar *, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social, .ilightbox-holder .ilightbox-container .ilightbox-social * {
    float:none;
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    line-height:100%;
    vertical-align:baseline;
    background:transparent;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	   -moz-user-select: -moz-none;
	 -khtml-user-select: none;
	     -o-user-select: none;
	        user-select: none;
}
.ilightbox-overlay, .ilightbox-loader, .ilightbox-loader *, .ilightbox-holder .ilightbox-container .ilightbox-caption, .ilightbox-toolbar, .ilightbox-thumbnails, .ilightbox-thumbnails *, .ilightbox-holder .ilightbox-container .ilightbox-social {
	-webkit-transform: translateZ(0);
	   -moz-transform: translateZ(0);
}

/* Disable & Hide Scrollbar */
.ilightbox-noscroll {
	overflow: hidden;
}
.ilightbox-closedhand * {
	cursor: url(closedhand.cur),default !important;
}

.ilightbox-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;
}

.ilightbox-loader {
	position: fixed;
	z-index: 100005;
	top: 45%;
	left: -192px;
	padding-left: 30px;
	opacity: 0.9;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=90);
			
	-webkit-border-radius: 0 100px 100px 0;
	        border-radius: 0 100px 100px 0;
}

.ilightbox-loader div {
	width: 72px;
	height: 72px;
	
	-webkit-border-radius: 0 100px 100px 0;
	        border-radius: 0 100px 100px 0;
}

.ilightbox-loader.horizontal {
	left: 45%;
	top: -192px;
	padding: 0;
	padding-top: 30px;
			
	-webkit-border-radius: 0 0 100px 100px;
	        border-radius: 0 0 100px 100px;
}

.ilightbox-loader.horizontal  div {
	-webkit-border-radius: 0 0 100px 100px;
	        border-radius: 0 0 100px 100px;
}

.ilightbox-toolbar {
	display: none;
	position: fixed;
	z-index: 100010;
}

.ilightbox-toolbar a {
	float: left;
	cursor: pointer;
}

.ilightbox-toolbar .ilightbox-prev-button,
.ilightbox-toolbar .ilightbox-next-button {
	display: none;
}

.ilightbox-thumbnails {
	display: block;
	position: fixed;
	z-index: 100009;
}

.ilightbox-thumbnails.ilightbox-horizontal {
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical {
	top: 0;
	right: 0;
	width: 140px;
	height: 100%;
	overflow: hidden;
}

.ilightbox-thumbnails .ilightbox-thumbnails-container {
	display: block;
	position: relative;
}

.ilightbox-thumbnails.ilightbox-horizontal .ilightbox-thumbnails-container {
	width: 100%;
	height: 100px;
}

.ilightbox-thumbnails.ilightbox-vertical .ilightbox-thumbnails-container {
	width: 140px;
	height: 100%;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid {
	display: block;
	position: absolute;

	-webkit-transform: translateZ(0);
	   -moz-transform: translateZ(0);
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail {
	display: block;
	cursor: pointer;
	padding: 10px;
	position: relative;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail img {
	width: 100%;
	height: 100%;
	
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	
	-ms-interpolation-mode: bicubic;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: .7;
}

.ilightbox-thumbnails .ilightbox-thumbnails-grid .ilightbox-thumbnail .ilightbox-thumbnail-icon:hover {
	opacity: 1;
}

.ilightbox-holder {
	display: none;
	position: fixed;
	z-index: 100003;

	-webkit-transform: none;
	   -moz-transform: none;
}

.ilightbox-holder.ilightbox-next, .ilightbox-holder.ilightbox-prev {
	cursor: pointer;
}

.ilightbox-holder div.ilightbox-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.ilightbox-holder.supportTouch div.ilightbox-container {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

.ilightbox-holder img.ilightbox-image {
	width: 100%;
	height: 100%;
}

.ilightbox-holder .ilightbox-container .ilightbox-caption {
	display: none;
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 0;
	max-width: 100%;
	padding: 5px 10px;
	margin: 0 auto;
	font-size: 12px;
    line-height: 150%;
	word-wrap: break-word;
	z-index: 20003;
	
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	
	-webkit-border-radius: 3px 3px 0 0;
	        border-radius: 3px 3px 0 0;
}

.ilightbox-holder .ilightbox-container .ilightbox-social {
	display: none;
	position: absolute;
	left: 10px;
	top: 10px;
	padding: 5px;
	padding-left: 0;
	z-index: 20003;
	height: 16px;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul {
	float: left;
	list-style: none;
	height: 16px;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li {
	display: inline;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li a {
	float: left;
	margin-left: 5px;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	background-position: 50%;
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.facebook a {
	background-image: url('../social_icons/facebook_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.digg a {
	background-image: url('../social_icons/digg_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.twitter a {
	background-image: url('../social_icons/twitter_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.delicious a {
	background-image: url('../social_icons/delicious_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.reddit a {
	background-image: url('../social_icons/reddit_16.png');
}

.ilightbox-holder .ilightbox-container .ilightbox-social ul li.googleplus a {
	background-image: url('../social_icons/google_plus_16.png');
}

.ilightbox-holder .ilightbox-alert {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	padding-top: 100px;
	margin: auto;
	width: 300px;
	height: 50px;
}

.ilightbox-holder .ilightbox-wrapper {
	width: 100%;
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.ilightbox-holder .ilightbox-inner-toolbar {
	position: relative;
	z-index: 100;
}

.ilightbox-holder .ilightbox-inner-toolbar .ilightbox-toolbar {
	position: absolute;
}

/* Style arrow buttons */
.ilightbox-button {
	position: fixed;
	z-index: 100008;
	cursor: pointer;
}

/* Style mobile devices */
.isMobile .ilightbox-button,
.isMobile .ilightbox-thumbnails {
	display: none !important;
}

.isMobile .ilightbox-toolbar .ilightbox-prev-button,
.isMobile .ilightbox-toolbar .ilightbox-next-button {
	display: block;
}