/*********************************
COMPONENTS 
*********************************/
/***
General reset
***/
/* Set default body */
body {
  color: #5c6873;
  font-family: "Roboto Condensed", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-weight: 300;
  font-size: 17px; }

a {
  outline: none !important;
  -webkit-transition: back 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out; }

a:focus,
a:hover,
a:active {
  outline: none !important;
  text-decoration: none; }

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
 properly apply the media queries in Bootstrap's CSS. To address this, 
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@-o-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, 
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied 
*/
@-ms-viewport {
  width: auto !important; }

h6 {
  color: #3f444a;
  font-size: 12px;
  margin: 5px 0; }

h5 {
  color: #3f444a;
  font-size: 14px;
  margin: 7px 0; }

h4 {
  color: #3f444a;
  font-size: 16px;
  margin: 8px 0; }

h3 {
  color: #3f444a;
  font-size: 18px;
  margin: 10px 0; }

h2 {
  color: #3f444a;
  font-size: 20px;
  margin: 10px 0; }

h1 {
  color: #3f444a;
  font-size: 22px;
  margin: 10px 0; }

.c-reset {
  padding: 0;
  margin: 0; }

.c-hide {
  display: none; }

.c-overflow-hide {
  overflow: hidden; }

/* linkify buttons */
.c-link {
  background: 0;
  border: 0;
  outline: none; }

.c-border {
  border: 1px solid #eeeeee; }

.c-border-thick {
  border: 2px solid #eeeeee; }

.c-border-top {
  border-top: 1px solid #eeeeee; }

.c-border-right {
  border-right: 1px solid #eeeeee; }

.c-border-bottom {
  border-bottom: 1px solid #eeeeee; }

.c-border-left {
  border-left: 1px solid #eeeeee; }

.c-no-border {
  border: 0 !important; }

.c-shadow {
  box-shadow: 0px 3px 18px -5px rgba(0, 0, 0, 0.3); }

.c-square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.c-rounded {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px; }

.c-bs-grid-reset-space .row,
.c-bs-grid-reset-space div[class^="col-"],
.c-bs-grid-reset-space div[class*=" col-"] {
  margin: 0;
  padding: 0; }

.c-bs-grid-small-space .row {
  margin-left: -5px;
  margin-right: -5px; }
  .c-bs-grid-small-space .row div[class^="col-"],
  .c-bs-grid-small-space .row div[class*=" col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.c-margin-t-5 {
  margin-top: 5px; }

.c-margin-t-8 {
  margin-top: 8px; }

.c-margin-t-10 {
  margin-top: 10px !important; }

.c-margin-t-15 {
  margin-top: 15px !important; }

.c-margin-t-20 {
  margin-top: 20px; }

.c-margin-t-25 {
  margin-top: 25px; }

.c-margin-t-30 {
  margin-top: 30px; }

.c-margin-t-40 {
  margin-top: 40px; }

.c-margin-t-50 {
  margin-top: 50px; }

.c-margin-t-60 {
  margin-top: 60px; }

.c-margin-t-80 {
  margin-top: 80px; }

.c-margin-b-5 {
  margin-bottom: 5px !important; }

.c-margin-b-10 {
  margin-bottom: 10px; }

.c-margin-b-15 {
  margin-bottom: 15px !important; }

.c-margin-b-20 {
  margin-bottom: 20px; }

.c-margin-b-25 {
  margin-bottom: 25px; }

.c-margin-b-30 {
  margin-bottom: 30px; }

.c-margin-b-40 {
  margin-bottom: 40px; }

.c-margin-b-50 {
  margin-bottom: 50px; }

.c-margin-b-60 {
  margin-bottom: 60px; }

.c-margin-b-80 {
  margin-bottom: 80px; }

.c-margin-b-100 {
  margin-bottom: 100px; }

.c-margin-r-40 {
  margin-right: 40px; }

.c-padding-10 {
  padding: 10px; }

.c-padding-20 {
  padding: 20px; }

.c-left,
.c-font-left {
  text-align: left; }

.c-center,
.c-font-center {
  text-align: center; }

.c-right,
.c-font-right {
  text-align: right; }

.c-font-thin {
  font-weight: 300 !important; }

.c-font-slim {
  font-weight: 400 !important; }

.c-font-sbold {
  font-weight: 500 !important; }

.c-font-bold {
  font-weight: 600 !important; }

.c-font-uppercase {
  text-transform: uppercase; }

.c-font-lowercase {
  text-transform: initial !important; }

.c-font-title {
  color: #3f444a !important; }

.c-font-subtitle {
  color: #5c6873 !important; }

.c-font-line-through {
  text-decoration: line-through; }

.c-font-10 {
  font-size: 10px; }

.c-font-11 {
  font-size: 11px; }

.c-font-12 {
  font-size: 12px; }

.c-font-13 {
  font-size: 13px; }

.c-font-14 {
  font-size: 14px; }

.c-font-15 {
  font-size: 15px; }

.c-font-16 {
  font-size: 16px; }

.c-font-17 {
  font-size: 17px; }

.c-font-18 {
  font-size: 18px; }

.c-font-19 {
  font-size: 19px; }

.c-font-20 {
  font-size: 20px; }

.c-font-21 {
  font-size: 21px; }

.c-font-22 {
  font-size: 22px; }

.c-font-23 {
  font-size: 23px; }

.c-font-24 {
  font-size: 24px; }

.c-font-25 {
  font-size: 25px; }

.c-font-26 {
  font-size: 26px; }

.c-font-27 {
  font-size: 27px; }

.c-font-28 {
  font-size: 28px; }

.c-font-29 {
  font-size: 29px; }

.c-font-30 {
  font-size: 30px; }

.c-font-31 {
  font-size: 31px; }

.c-font-32 {
  font-size: 32px; }

.c-font-33 {
  font-size: 33px; }

.c-font-34 {
  font-size: 34px; }

.c-font-35 {
  font-size: 35px; }

.c-font-36 {
  font-size: 36px; }

.c-font-37 {
  font-size: 37px; }

.c-font-38 {
  font-size: 38px; }

.c-font-39 {
  font-size: 39px; }

.c-font-40 {
  font-size: 40px; }

.c-font-41 {
  font-size: 41px; }

.c-font-42 {
  font-size: 42px; }

.c-font-43 {
  font-size: 43px; }

.c-font-44 {
  font-size: 44px; }

.c-font-45 {
  font-size: 45px; }

.c-font-46 {
  font-size: 46px; }

.c-font-47 {
  font-size: 47px; }

.c-font-48 {
  font-size: 48px; }

.c-font-49 {
  font-size: 49px; }

.c-font-50 {
  font-size: 50px; }

.c-font-51 {
  font-size: 51px; }

.c-font-52 {
  font-size: 52px; }

.c-font-53 {
  font-size: 53px; }

.c-font-54 {
  font-size: 54px; }

.c-font-55 {
  font-size: 55px; }

.c-font-56 {
  font-size: 56px; }

.c-font-57 {
  font-size: 57px; }

.c-font-58 {
  font-size: 58px; }

.c-font-59 {
  font-size: 59px; }

.c-font-60 {
  font-size: 60px; }

.c-font-61 {
  font-size: 61px; }

.c-font-62 {
  font-size: 62px; }

.c-font-63 {
  font-size: 63px; }

.c-font-64 {
  font-size: 64px; }

.c-font-65 {
  font-size: 65px; }

.c-font-66 {
  font-size: 66px; }

.c-font-67 {
  font-size: 67px; }

.c-font-68 {
  font-size: 68px; }

.c-font-69 {
  font-size: 69px; }

.c-font-70 {
  font-size: 70px; }

.c-font-71 {
  font-size: 71px; }

.c-font-72 {
  font-size: 72px; }

.c-font-73 {
  font-size: 73px; }

.c-font-74 {
  font-size: 74px; }

.c-font-75 {
  font-size: 75px; }

.c-font-76 {
  font-size: 76px; }

.c-font-77 {
  font-size: 77px; }

.c-font-78 {
  font-size: 78px; }

.c-font-79 {
  font-size: 79px; }

.c-font-80 {
  font-size: 80px; }

.c-font-81 {
  font-size: 81px; }

.c-font-82 {
  font-size: 82px; }

.c-font-83 {
  font-size: 83px; }

.c-font-84 {
  font-size: 84px; }

.c-font-85 {
  font-size: 85px; }

.c-font-86 {
  font-size: 86px; }

.c-font-87 {
  font-size: 87px; }

.c-font-88 {
  font-size: 88px; }

.c-font-89 {
  font-size: 89px; }

.c-font-90 {
  font-size: 90px; }

.c-line-height-10 {
  line-height: 10px; }

.c-line-height-11 {
  line-height: 11px; }

.c-line-height-12 {
  line-height: 12px; }

.c-line-height-13 {
  line-height: 13px; }

.c-line-height-14 {
  line-height: 14px; }

.c-line-height-15 {
  line-height: 15px; }

.c-line-height-16 {
  line-height: 16px; }

.c-line-height-17 {
  line-height: 17px; }

.c-line-height-18 {
  line-height: 18px; }

.c-line-height-19 {
  line-height: 19px; }

.c-line-height-20 {
  line-height: 20px; }

.c-line-height-21 {
  line-height: 21px; }

.c-line-height-22 {
  line-height: 22px; }

.c-line-height-23 {
  line-height: 23px; }

.c-line-height-24 {
  line-height: 24px; }

.c-line-height-25 {
  line-height: 25px; }

.c-line-height-26 {
  line-height: 26px; }

.c-line-height-27 {
  line-height: 27px; }

.c-line-height-28 {
  line-height: 28px; }

.c-line-height-29 {
  line-height: 29px; }

.c-line-height-30 {
  line-height: 30px; }

.c-line-height-31 {
  line-height: 31px; }

.c-line-height-32 {
  line-height: 32px; }

.c-line-height-33 {
  line-height: 33px; }

.c-line-height-34 {
  line-height: 34px; }

.c-line-height-35 {
  line-height: 35px; }

.c-line-height-36 {
  line-height: 36px; }

.c-line-height-37 {
  line-height: 37px; }

.c-line-height-38 {
  line-height: 38px; }

.c-line-height-39 {
  line-height: 39px; }

.c-line-height-40 {
  line-height: 40px; }

/* Font Colors */
.c-font-white {
  color: #FFFFFF !important; }
  .c-font-white small {
    color: #FFFFFF !important; }

.c-font-white-hover:hover {
  color: #FFFFFF !important; }

.c-font-black {
  color: #000000 !important; }
  .c-font-black small {
    color: #000000 !important; }

.c-font-black-hover:hover {
  color: #000000 !important; }

.c-font-regular {
  color: #7f8c97 !important; }
  .c-font-regular small {
    color: #7f8c97 !important; }

.c-font-regular-hover:hover {
  color: #7f8c97 !important; }

.c-font-green {
  color: #32c5d2 !important; }
  .c-font-green small {
    color: #32c5d2 !important; }

.c-font-green-hover:hover {
  color: #32c5d2 !important; }

.c-font-green-1 {
  color: #52c9ba !important; }
  .c-font-green-1 small {
    color: #52c9ba !important; }

.c-font-green-1-hover:hover {
  color: #52c9ba !important; }

.c-font-green-2 {
  color: #5dc09c !important; }
  .c-font-green-2 small {
    color: #5dc09c !important; }

.c-font-green-2-hover:hover {
  color: #5dc09c !important; }

.c-font-green-3 {
  color: #84c68f !important; }
  .c-font-green-3 small {
    color: #84c68f !important; }

.c-font-green-3-hover:hover {
  color: #84c68f !important; }

.c-font-red {
  color: #eb5d68 !important; }
  .c-font-red small {
    color: #eb5d68 !important; }

.c-font-red-hover:hover {
  color: #eb5d68 !important; }

.c-font-red-1 {
  color: #cd6262 !important; }
  .c-font-red-1 small {
    color: #cd6262 !important; }

.c-font-red-1-hover:hover {
  color: #cd6262 !important; }

.c-font-red-2 {
  color: #e7505a !important; }
  .c-font-red-2 small {
    color: #e7505a !important; }

.c-font-red-2-hover:hover {
  color: #e7505a !important; }

.c-font-red-3 {
  color: #d05163 !important; }
  .c-font-red-3 small {
    color: #d05163 !important; }

.c-font-red-3-hover:hover {
  color: #d05163 !important; }

.c-font-yellow {
  color: #FF6B57 !important; }
  .c-font-yellow small {
    color: #FF6B57 !important; }

.c-font-yellow-hover:hover {
  color: #FF6B57 !important; }

.c-font-yellow-1 {
  color: #c8d046 !important; }
  .c-font-yellow-1 small {
    color: #c8d046 !important; }

.c-font-yellow-1-hover:hover {
  color: #c8d046 !important; }

.c-font-yellow-2 {
  color: #c5bf66 !important; }
  .c-font-yellow-2 small {
    color: #c5bf66 !important; }

.c-font-yellow-2-hover:hover {
  color: #c5bf66 !important; }

.c-font-yellow-3 {
  color: #c5b96b !important; }
  .c-font-yellow-3 small {
    color: #c5b96b !important; }

.c-font-yellow-3-hover:hover {
  color: #c5b96b !important; }

.c-font-blue {
  color: #3498DB !important; }
  .c-font-blue small {
    color: #3498DB !important; }

.c-font-blue-hover:hover {
  color: #3498DB !important; }

.c-font-blue-1 {
  color: #5e9cd1 !important; }
  .c-font-blue-1 small {
    color: #5e9cd1 !important; }

.c-font-blue-1-hover:hover {
  color: #5e9cd1 !important; }

.c-font-blue-2 {
  color: #5893dd !important; }
  .c-font-blue-2 small {
    color: #5893dd !important; }

.c-font-blue-2-hover:hover {
  color: #5893dd !important; }

.c-font-blue-3 {
  color: #57bfe1 !important; }
  .c-font-blue-3 small {
    color: #57bfe1 !important; }

.c-font-blue-3-hover:hover {
  color: #57bfe1 !important; }

.c-font-purple {
  color: #b771b0 !important; }
  .c-font-purple small {
    color: #b771b0 !important; }

.c-font-purple-hover:hover {
  color: #b771b0 !important; }

.c-font-purple-1 {
  color: #a962bb !important; }
  .c-font-purple-1 small {
    color: #a962bb !important; }

.c-font-purple-1-hover:hover {
  color: #a962bb !important; }

.c-font-purple-2 {
  color: #aa67a3 !important; }
  .c-font-purple-2 small {
    color: #aa67a3 !important; }

.c-font-purple-2-hover:hover {
  color: #aa67a3 !important; }

.c-font-purple-3 {
  color: #ac3773 !important; }
  .c-font-purple-3 small {
    color: #ac3773 !important; }

.c-font-purple-3-hover:hover {
  color: #ac3773 !important; }

.c-font-brown {
  color: #8a7f68 !important; }
  .c-font-brown small {
    color: #8a7f68 !important; }

.c-font-brown-hover:hover {
  color: #8a7f68 !important; }

.c-font-brown-1 {
  color: #685e47 !important; }
  .c-font-brown-1 small {
    color: #685e47 !important; }

.c-font-brown-1-hover:hover {
  color: #685e47 !important; }

.c-font-brown-2 {
  color: #7a6a61 !important; }
  .c-font-brown-2 small {
    color: #7a6a61 !important; }

.c-font-brown-2-hover:hover {
  color: #7a6a61 !important; }

.c-font-brown-3 {
  color: #9d8b81 !important; }
  .c-font-brown-3 small {
    color: #9d8b81 !important; }

.c-font-brown-3-hover:hover {
  color: #9d8b81 !important; }

.c-font-dark {
  color: #2f353b !important; }
  .c-font-dark small {
    color: #2f353b !important; }

.c-font-dark-hover:hover {
  color: #2f353b !important; }

.c-font-dark-1 {
  color: #525e64 !important; }
  .c-font-dark-1 small {
    color: #525e64 !important; }

.c-font-dark-1-hover:hover {
  color: #525e64 !important; }

.c-font-dark-2 {
  color: #31383c !important; }
  .c-font-dark-2 small {
    color: #31383c !important; }

.c-font-dark-2-hover:hover {
  color: #31383c !important; }

.c-font-dark-3 {
  color: #41515b !important; }
  .c-font-dark-3 small {
    color: #41515b !important; }

.c-font-dark-3-hover:hover {
  color: #41515b !important; }

.c-font-grey {
  color: #eeeeee !important; }
  .c-font-grey small {
    color: #eeeeee !important; }

.c-font-grey-hover:hover {
  color: #eeeeee !important; }

.c-font-grey-1 {
  color: #f7f7f7 !important; }
  .c-font-grey-1 small {
    color: #f7f7f7 !important; }

.c-font-grey-1-hover:hover {
  color: #f7f7f7 !important; }

.c-font-grey-2 {
  color: #677083 !important; }
  .c-font-grey-2 small {
    color: #677083 !important; }

.c-font-grey-2-hover:hover {
  color: #677083 !important; }

.c-font-grey-3 {
  color: #7f8c97 !important; }
  .c-font-grey-3 small {
    color: #7f8c97 !important; }

.c-font-grey-3-hover:hover {
  color: #7f8c97 !important; }

/* Background Colors */
.c-bg-white {
  background-color: #FFFFFF !important; }
  .c-bg-white.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(255, 255, 255, 0.04) !important; }
  .c-bg-white.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(255, 255, 255, 0.4) !important; }
  .c-bg-white.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(255, 255, 255, 0.6) !important; }
  .c-bg-white.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(255, 255, 255, 0.8) !important; }
  .c-bg-white.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(255, 255, 255, 0.9) !important; }
  .c-bg-white.c-bg-white-font {
    color: #2f353b !important; }

.c-bg-white-hover:hover {
  background-color: #FFFFFF !important; }

.c-border-white-hover:hover {
  border-color: #FFFFFF !important; }

.c-bg-after-white:after {
  background-color: #FFFFFF !important; }

.c-bg-before-white:before {
  background-color: #FFFFFF !important; }

.c-bg-black {
  background-color: #000000 !important; }
  .c-bg-black.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(0, 0, 0, 0.04) !important; }
  .c-bg-black.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(0, 0, 0, 0.4) !important; }
  .c-bg-black.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(0, 0, 0, 0.6) !important; }
  .c-bg-black.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(0, 0, 0, 0.8) !important; }
  .c-bg-black.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(0, 0, 0, 0.9) !important; }
  .c-bg-black.c-bg-black-font {
    color: #ffffff !important; }

.c-bg-black-hover:hover {
  background-color: #000000 !important; }

.c-border-black-hover:hover {
  border-color: #000000 !important; }

.c-bg-after-black:after {
  background-color: #000000 !important; }

.c-bg-before-black:before {
  background-color: #000000 !important; }

.c-bg-regular {
  background-color: #7f8c97 !important; }
  .c-bg-regular.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(127, 140, 151, 0.04) !important; }
  .c-bg-regular.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(127, 140, 151, 0.4) !important; }
  .c-bg-regular.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(127, 140, 151, 0.6) !important; }
  .c-bg-regular.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(127, 140, 151, 0.8) !important; }
  .c-bg-regular.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(127, 140, 151, 0.9) !important; }
  .c-bg-regular.c-bg-regular-font {
    color: #ffffff !important; }

.c-bg-regular-hover:hover {
  background-color: #7f8c97 !important; }

.c-border-regular-hover:hover {
  border-color: #7f8c97 !important; }

.c-bg-after-regular:after {
  background-color: #7f8c97 !important; }

.c-bg-before-regular:before {
  background-color: #7f8c97 !important; }

.c-bg-green {
  background-color: #32c5d2 !important; }
  .c-bg-green.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(50, 197, 210, 0.04) !important; }
  .c-bg-green.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(50, 197, 210, 0.4) !important; }
  .c-bg-green.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(50, 197, 210, 0.6) !important; }
  .c-bg-green.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(50, 197, 210, 0.8) !important; }
  .c-bg-green.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(50, 197, 210, 0.9) !important; }
  .c-bg-green.c-bg-green-font {
    color: #ffffff !important; }

.c-bg-green-hover:hover {
  background-color: #32c5d2 !important; }

.c-border-green-hover:hover {
  border-color: #32c5d2 !important; }

.c-bg-after-green:after {
  background-color: #32c5d2 !important; }

.c-bg-before-green:before {
  background-color: #32c5d2 !important; }

.c-bg-green-1 {
  background-color: #52c9ba !important; }
  .c-bg-green-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(82, 201, 186, 0.04) !important; }
  .c-bg-green-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(82, 201, 186, 0.4) !important; }
  .c-bg-green-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(82, 201, 186, 0.6) !important; }
  .c-bg-green-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(82, 201, 186, 0.8) !important; }
  .c-bg-green-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(82, 201, 186, 0.9) !important; }
  .c-bg-green-1.c-bg-green-1-font {
    color: #ffffff !important; }

.c-bg-green-1-hover:hover {
  background-color: #52c9ba !important; }

.c-border-green-1-hover:hover {
  border-color: #52c9ba !important; }

.c-bg-after-green-1:after {
  background-color: #52c9ba !important; }

.c-bg-before-green-1:before {
  background-color: #52c9ba !important; }

.c-bg-green-2 {
  background-color: #5dc09c !important; }
  .c-bg-green-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(93, 192, 156, 0.04) !important; }
  .c-bg-green-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(93, 192, 156, 0.4) !important; }
  .c-bg-green-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(93, 192, 156, 0.6) !important; }
  .c-bg-green-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(93, 192, 156, 0.8) !important; }
  .c-bg-green-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(93, 192, 156, 0.9) !important; }
  .c-bg-green-2.c-bg-green-2-font {
    color: #ffffff !important; }

.c-bg-green-2-hover:hover {
  background-color: #5dc09c !important; }

.c-border-green-2-hover:hover {
  border-color: #5dc09c !important; }

.c-bg-after-green-2:after {
  background-color: #5dc09c !important; }

.c-bg-before-green-2:before {
  background-color: #5dc09c !important; }

.c-bg-green-3 {
  background-color: #84c68f !important; }
  .c-bg-green-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(132, 198, 143, 0.04) !important; }
  .c-bg-green-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(132, 198, 143, 0.4) !important; }
  .c-bg-green-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(132, 198, 143, 0.6) !important; }
  .c-bg-green-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(132, 198, 143, 0.8) !important; }
  .c-bg-green-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(132, 198, 143, 0.9) !important; }
  .c-bg-green-3.c-bg-green-3-font {
    color: #ffffff !important; }

.c-bg-green-3-hover:hover {
  background-color: #84c68f !important; }

.c-border-green-3-hover:hover {
  border-color: #84c68f !important; }

.c-bg-after-green-3:after {
  background-color: #84c68f !important; }

.c-bg-before-green-3:before {
  background-color: #84c68f !important; }

.c-bg-red {
  background-color: #eb5d68 !important; }
  .c-bg-red.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(235, 93, 104, 0.04) !important; }
  .c-bg-red.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(235, 93, 104, 0.4) !important; }
  .c-bg-red.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(235, 93, 104, 0.6) !important; }
  .c-bg-red.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(235, 93, 104, 0.8) !important; }
  .c-bg-red.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(235, 93, 104, 0.9) !important; }
  .c-bg-red.c-bg-red-font {
    color: #ffffff !important; }

.c-bg-red-hover:hover {
  background-color: #eb5d68 !important; }

.c-border-red-hover:hover {
  border-color: #eb5d68 !important; }

.c-bg-after-red:after {
  background-color: #eb5d68 !important; }

.c-bg-before-red:before {
  background-color: #eb5d68 !important; }

.c-bg-red-1 {
  background-color: #cd6262 !important; }
  .c-bg-red-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(205, 98, 98, 0.04) !important; }
  .c-bg-red-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(205, 98, 98, 0.4) !important; }
  .c-bg-red-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(205, 98, 98, 0.6) !important; }
  .c-bg-red-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(205, 98, 98, 0.8) !important; }
  .c-bg-red-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(205, 98, 98, 0.9) !important; }
  .c-bg-red-1.c-bg-red-1-font {
    color: #ffffff !important; }

.c-bg-red-1-hover:hover {
  background-color: #cd6262 !important; }

.c-border-red-1-hover:hover {
  border-color: #cd6262 !important; }

.c-bg-after-red-1:after {
  background-color: #cd6262 !important; }

.c-bg-before-red-1:before {
  background-color: #cd6262 !important; }

.c-bg-red-2 {
  background-color: #e7505a !important; }
  .c-bg-red-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(231, 80, 90, 0.04) !important; }
  .c-bg-red-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(231, 80, 90, 0.4) !important; }
  .c-bg-red-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(231, 80, 90, 0.6) !important; }
  .c-bg-red-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(231, 80, 90, 0.8) !important; }
  .c-bg-red-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(231, 80, 90, 0.9) !important; }
  .c-bg-red-2.c-bg-red-2-font {
    color: #ffffff !important; }

.c-bg-red-2-hover:hover {
  background-color: #e7505a !important; }

.c-border-red-2-hover:hover {
  border-color: #e7505a !important; }

.c-bg-after-red-2:after {
  background-color: #e7505a !important; }

.c-bg-before-red-2:before {
  background-color: #e7505a !important; }

.c-bg-red-3 {
  background-color: #d05163 !important; }
  .c-bg-red-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(208, 81, 99, 0.04) !important; }
  .c-bg-red-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(208, 81, 99, 0.4) !important; }
  .c-bg-red-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(208, 81, 99, 0.6) !important; }
  .c-bg-red-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(208, 81, 99, 0.8) !important; }
  .c-bg-red-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(208, 81, 99, 0.9) !important; }
  .c-bg-red-3.c-bg-red-3-font {
    color: #ffffff !important; }

.c-bg-red-3-hover:hover {
  background-color: #d05163 !important; }

.c-border-red-3-hover:hover {
  border-color: #d05163 !important; }

.c-bg-after-red-3:after {
  background-color: #d05163 !important; }

.c-bg-before-red-3:before {
  background-color: #d05163 !important; }

.c-bg-yellow {
  background-color: #FF6B57 !important; }
  .c-bg-yellow.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(255, 107, 87, 0.04) !important; }
  .c-bg-yellow.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(255, 107, 87, 0.4) !important; }
  .c-bg-yellow.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(255, 107, 87, 0.6) !important; }
  .c-bg-yellow.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(255, 107, 87, 0.8) !important; }
  .c-bg-yellow.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(255, 107, 87, 0.9) !important; }
  .c-bg-yellow.c-bg-yellow-font {
    color: #ffffff !important; }

.c-bg-yellow-hover:hover {
  background-color: #FF6B57 !important; }

.c-border-yellow-hover:hover {
  border-color: #FF6B57 !important; }

.c-bg-after-yellow:after {
  background-color: #FF6B57 !important; }

.c-bg-before-yellow:before {
  background-color: #FF6B57 !important; }

.c-bg-yellow-1 {
  background-color: #c8d046 !important; }
  .c-bg-yellow-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(200, 208, 70, 0.04) !important; }
  .c-bg-yellow-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(200, 208, 70, 0.4) !important; }
  .c-bg-yellow-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(200, 208, 70, 0.6) !important; }
  .c-bg-yellow-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(200, 208, 70, 0.8) !important; }
  .c-bg-yellow-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(200, 208, 70, 0.9) !important; }
  .c-bg-yellow-1.c-bg-yellow-1-font {
    color: #ffffff !important; }

.c-bg-yellow-1-hover:hover {
  background-color: #c8d046 !important; }

.c-border-yellow-1-hover:hover {
  border-color: #c8d046 !important; }

.c-bg-after-yellow-1:after {
  background-color: #c8d046 !important; }

.c-bg-before-yellow-1:before {
  background-color: #c8d046 !important; }

.c-bg-yellow-2 {
  background-color: #c5bf66 !important; }
  .c-bg-yellow-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(197, 191, 102, 0.04) !important; }
  .c-bg-yellow-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(197, 191, 102, 0.4) !important; }
  .c-bg-yellow-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(197, 191, 102, 0.6) !important; }
  .c-bg-yellow-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(197, 191, 102, 0.8) !important; }
  .c-bg-yellow-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(197, 191, 102, 0.9) !important; }
  .c-bg-yellow-2.c-bg-yellow-2-font {
    color: #ffffff !important; }

.c-bg-yellow-2-hover:hover {
  background-color: #c5bf66 !important; }

.c-border-yellow-2-hover:hover {
  border-color: #c5bf66 !important; }

.c-bg-after-yellow-2:after {
  background-color: #c5bf66 !important; }

.c-bg-before-yellow-2:before {
  background-color: #c5bf66 !important; }

.c-bg-yellow-3 {
  background-color: #c5b96b !important; }
  .c-bg-yellow-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(197, 185, 107, 0.04) !important; }
  .c-bg-yellow-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(197, 185, 107, 0.4) !important; }
  .c-bg-yellow-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(197, 185, 107, 0.6) !important; }
  .c-bg-yellow-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(197, 185, 107, 0.8) !important; }
  .c-bg-yellow-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(197, 185, 107, 0.9) !important; }
  .c-bg-yellow-3.c-bg-yellow-3-font {
    color: #ffffff !important; }

.c-bg-yellow-3-hover:hover {
  background-color: #c5b96b !important; }

.c-border-yellow-3-hover:hover {
  border-color: #c5b96b !important; }

.c-bg-after-yellow-3:after {
  background-color: #c5b96b !important; }

.c-bg-before-yellow-3:before {
  background-color: #c5b96b !important; }

.c-bg-blue {
  background-color: #3498DB !important; }
  .c-bg-blue.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(52, 152, 219, 0.04) !important; }
  .c-bg-blue.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(52, 152, 219, 0.4) !important; }
  .c-bg-blue.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(52, 152, 219, 0.6) !important; }
  .c-bg-blue.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(52, 152, 219, 0.8) !important; }
  .c-bg-blue.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(52, 152, 219, 0.9) !important; }
  .c-bg-blue.c-bg-blue-font {
    color: #ffffff !important; }

.c-bg-blue-hover:hover {
  background-color: #3498DB !important; }

.c-border-blue-hover:hover {
  border-color: #3498DB !important; }

.c-bg-after-blue:after {
  background-color: #3498DB !important; }

.c-bg-before-blue:before {
  background-color: #3498DB !important; }

.c-bg-blue-1 {
  background-color: #5e9cd1 !important; }
  .c-bg-blue-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(94, 156, 209, 0.04) !important; }
  .c-bg-blue-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(94, 156, 209, 0.4) !important; }
  .c-bg-blue-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(94, 156, 209, 0.6) !important; }
  .c-bg-blue-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(94, 156, 209, 0.8) !important; }
  .c-bg-blue-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(94, 156, 209, 0.9) !important; }
  .c-bg-blue-1.c-bg-blue-1-font {
    color: #ffffff !important; }

.c-bg-blue-1-hover:hover {
  background-color: #5e9cd1 !important; }

.c-border-blue-1-hover:hover {
  border-color: #5e9cd1 !important; }

.c-bg-after-blue-1:after {
  background-color: #5e9cd1 !important; }

.c-bg-before-blue-1:before {
  background-color: #5e9cd1 !important; }

.c-bg-blue-2 {
  background-color: #5893dd !important; }
  .c-bg-blue-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(88, 147, 221, 0.04) !important; }
  .c-bg-blue-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(88, 147, 221, 0.4) !important; }
  .c-bg-blue-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(88, 147, 221, 0.6) !important; }
  .c-bg-blue-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(88, 147, 221, 0.8) !important; }
  .c-bg-blue-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(88, 147, 221, 0.9) !important; }
  .c-bg-blue-2.c-bg-blue-2-font {
    color: #ffffff !important; }

.c-bg-blue-2-hover:hover {
  background-color: #5893dd !important; }

.c-border-blue-2-hover:hover {
  border-color: #5893dd !important; }

.c-bg-after-blue-2:after {
  background-color: #5893dd !important; }

.c-bg-before-blue-2:before {
  background-color: #5893dd !important; }

.c-bg-blue-3 {
  background-color: #57bfe1 !important; }
  .c-bg-blue-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(87, 191, 225, 0.04) !important; }
  .c-bg-blue-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(87, 191, 225, 0.4) !important; }
  .c-bg-blue-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(87, 191, 225, 0.6) !important; }
  .c-bg-blue-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(87, 191, 225, 0.8) !important; }
  .c-bg-blue-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(87, 191, 225, 0.9) !important; }
  .c-bg-blue-3.c-bg-blue-3-font {
    color: #ffffff !important; }

.c-bg-blue-3-hover:hover {
  background-color: #57bfe1 !important; }

.c-border-blue-3-hover:hover {
  border-color: #57bfe1 !important; }

.c-bg-after-blue-3:after {
  background-color: #57bfe1 !important; }

.c-bg-before-blue-3:before {
  background-color: #57bfe1 !important; }

.c-bg-purple {
  background-color: #b771b0 !important; }
  .c-bg-purple.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(183, 113, 176, 0.04) !important; }
  .c-bg-purple.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(183, 113, 176, 0.4) !important; }
  .c-bg-purple.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(183, 113, 176, 0.6) !important; }
  .c-bg-purple.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(183, 113, 176, 0.8) !important; }
  .c-bg-purple.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(183, 113, 176, 0.9) !important; }
  .c-bg-purple.c-bg-purple-font {
    color: #ffffff !important; }

.c-bg-purple-hover:hover {
  background-color: #b771b0 !important; }

.c-border-purple-hover:hover {
  border-color: #b771b0 !important; }

.c-bg-after-purple:after {
  background-color: #b771b0 !important; }

.c-bg-before-purple:before {
  background-color: #b771b0 !important; }

.c-bg-purple-1 {
  background-color: #a962bb !important; }
  .c-bg-purple-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(169, 98, 187, 0.04) !important; }
  .c-bg-purple-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(169, 98, 187, 0.4) !important; }
  .c-bg-purple-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(169, 98, 187, 0.6) !important; }
  .c-bg-purple-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(169, 98, 187, 0.8) !important; }
  .c-bg-purple-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(169, 98, 187, 0.9) !important; }
  .c-bg-purple-1.c-bg-purple-1-font {
    color: #ffffff !important; }

.c-bg-purple-1-hover:hover {
  background-color: #a962bb !important; }

.c-border-purple-1-hover:hover {
  border-color: #a962bb !important; }

.c-bg-after-purple-1:after {
  background-color: #a962bb !important; }

.c-bg-before-purple-1:before {
  background-color: #a962bb !important; }

.c-bg-purple-2 {
  background-color: #aa67a3 !important; }
  .c-bg-purple-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(170, 103, 163, 0.04) !important; }
  .c-bg-purple-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(170, 103, 163, 0.4) !important; }
  .c-bg-purple-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(170, 103, 163, 0.6) !important; }
  .c-bg-purple-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(170, 103, 163, 0.8) !important; }
  .c-bg-purple-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(170, 103, 163, 0.9) !important; }
  .c-bg-purple-2.c-bg-purple-2-font {
    color: #ffffff !important; }

.c-bg-purple-2-hover:hover {
  background-color: #aa67a3 !important; }

.c-border-purple-2-hover:hover {
  border-color: #aa67a3 !important; }

.c-bg-after-purple-2:after {
  background-color: #aa67a3 !important; }

.c-bg-before-purple-2:before {
  background-color: #aa67a3 !important; }

.c-bg-purple-3 {
  background-color: #ac3773 !important; }
  .c-bg-purple-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(172, 55, 115, 0.04) !important; }
  .c-bg-purple-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(172, 55, 115, 0.4) !important; }
  .c-bg-purple-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(172, 55, 115, 0.6) !important; }
  .c-bg-purple-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(172, 55, 115, 0.8) !important; }
  .c-bg-purple-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(172, 55, 115, 0.9) !important; }
  .c-bg-purple-3.c-bg-purple-3-font {
    color: #ffffff !important; }

.c-bg-purple-3-hover:hover {
  background-color: #ac3773 !important; }

.c-border-purple-3-hover:hover {
  border-color: #ac3773 !important; }

.c-bg-after-purple-3:after {
  background-color: #ac3773 !important; }

.c-bg-before-purple-3:before {
  background-color: #ac3773 !important; }

.c-bg-brown {
  background-color: #8a7f68 !important; }
  .c-bg-brown.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(138, 127, 104, 0.04) !important; }
  .c-bg-brown.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(138, 127, 104, 0.4) !important; }
  .c-bg-brown.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(138, 127, 104, 0.6) !important; }
  .c-bg-brown.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(138, 127, 104, 0.8) !important; }
  .c-bg-brown.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(138, 127, 104, 0.9) !important; }
  .c-bg-brown.c-bg-brown-font {
    color: #ffffff !important; }

.c-bg-brown-hover:hover {
  background-color: #8a7f68 !important; }

.c-border-brown-hover:hover {
  border-color: #8a7f68 !important; }

.c-bg-after-brown:after {
  background-color: #8a7f68 !important; }

.c-bg-before-brown:before {
  background-color: #8a7f68 !important; }

.c-bg-brown-1 {
  background-color: #685e47 !important; }
  .c-bg-brown-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(104, 94, 71, 0.04) !important; }
  .c-bg-brown-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(104, 94, 71, 0.4) !important; }
  .c-bg-brown-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(104, 94, 71, 0.6) !important; }
  .c-bg-brown-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(104, 94, 71, 0.8) !important; }
  .c-bg-brown-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(104, 94, 71, 0.9) !important; }
  .c-bg-brown-1.c-bg-brown-1-font {
    color: #ffffff !important; }

.c-bg-brown-1-hover:hover {
  background-color: #685e47 !important; }

.c-border-brown-1-hover:hover {
  border-color: #685e47 !important; }

.c-bg-after-brown-1:after {
  background-color: #685e47 !important; }

.c-bg-before-brown-1:before {
  background-color: #685e47 !important; }

.c-bg-brown-2 {
  background-color: #7a6a61 !important; }
  .c-bg-brown-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(122, 106, 97, 0.04) !important; }
  .c-bg-brown-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(122, 106, 97, 0.4) !important; }
  .c-bg-brown-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(122, 106, 97, 0.6) !important; }
  .c-bg-brown-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(122, 106, 97, 0.8) !important; }
  .c-bg-brown-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(122, 106, 97, 0.9) !important; }
  .c-bg-brown-2.c-bg-brown-2-font {
    color: #ffffff !important; }

.c-bg-brown-2-hover:hover {
  background-color: #7a6a61 !important; }

.c-border-brown-2-hover:hover {
  border-color: #7a6a61 !important; }

.c-bg-after-brown-2:after {
  background-color: #7a6a61 !important; }

.c-bg-before-brown-2:before {
  background-color: #7a6a61 !important; }

.c-bg-brown-3 {
  background-color: #9d8b81 !important; }
  .c-bg-brown-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(157, 139, 129, 0.04) !important; }
  .c-bg-brown-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(157, 139, 129, 0.4) !important; }
  .c-bg-brown-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(157, 139, 129, 0.6) !important; }
  .c-bg-brown-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(157, 139, 129, 0.8) !important; }
  .c-bg-brown-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(157, 139, 129, 0.9) !important; }
  .c-bg-brown-3.c-bg-brown-3-font {
    color: #ffffff !important; }

.c-bg-brown-3-hover:hover {
  background-color: #9d8b81 !important; }

.c-border-brown-3-hover:hover {
  border-color: #9d8b81 !important; }

.c-bg-after-brown-3:after {
  background-color: #9d8b81 !important; }

.c-bg-before-brown-3:before {
  background-color: #9d8b81 !important; }

.c-bg-dark {
  background-color: #2f353b !important; }
  .c-bg-dark.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(47, 53, 59, 0.04) !important; }
  .c-bg-dark.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(47, 53, 59, 0.4) !important; }
  .c-bg-dark.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(47, 53, 59, 0.6) !important; }
  .c-bg-dark.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(47, 53, 59, 0.8) !important; }
  .c-bg-dark.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(47, 53, 59, 0.9) !important; }
  .c-bg-dark.c-bg-dark-font {
    color: #ffffff !important; }

.c-bg-dark-hover:hover {
  background-color: #2f353b !important; }

.c-border-dark-hover:hover {
  border-color: #2f353b !important; }

.c-bg-after-dark:after {
  background-color: #2f353b !important; }

.c-bg-before-dark:before {
  background-color: #2f353b !important; }

.c-bg-dark-1 {
  background-color: #525e64 !important; }
  .c-bg-dark-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(82, 94, 100, 0.04) !important; }
  .c-bg-dark-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(82, 94, 100, 0.4) !important; }
  .c-bg-dark-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(82, 94, 100, 0.6) !important; }
  .c-bg-dark-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(82, 94, 100, 0.8) !important; }
  .c-bg-dark-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(82, 94, 100, 0.9) !important; }
  .c-bg-dark-1.c-bg-dark-1-font {
    color: #ffffff !important; }

.c-bg-dark-1-hover:hover {
  background-color: #525e64 !important; }

.c-border-dark-1-hover:hover {
  border-color: #525e64 !important; }

.c-bg-after-dark-1:after {
  background-color: #525e64 !important; }

.c-bg-before-dark-1:before {
  background-color: #525e64 !important; }

.c-bg-dark-2 {
  background-color: #31383c !important; }
  .c-bg-dark-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(49, 56, 60, 0.04) !important; }
  .c-bg-dark-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(49, 56, 60, 0.4) !important; }
  .c-bg-dark-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(49, 56, 60, 0.6) !important; }
  .c-bg-dark-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(49, 56, 60, 0.8) !important; }
  .c-bg-dark-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(49, 56, 60, 0.9) !important; }
  .c-bg-dark-2.c-bg-dark-2-font {
    color: #ffffff !important; }

.c-bg-dark-2-hover:hover {
  background-color: #31383c !important; }

.c-border-dark-2-hover:hover {
  border-color: #31383c !important; }

.c-bg-after-dark-2:after {
  background-color: #31383c !important; }

.c-bg-before-dark-2:before {
  background-color: #31383c !important; }

.c-bg-dark-3 {
  background-color: #41515b !important; }
  .c-bg-dark-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(65, 81, 91, 0.04) !important; }
  .c-bg-dark-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(65, 81, 91, 0.4) !important; }
  .c-bg-dark-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(65, 81, 91, 0.6) !important; }
  .c-bg-dark-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(65, 81, 91, 0.8) !important; }
  .c-bg-dark-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(65, 81, 91, 0.9) !important; }
  .c-bg-dark-3.c-bg-dark-3-font {
    color: #ffffff !important; }

.c-bg-dark-3-hover:hover {
  background-color: #41515b !important; }

.c-border-dark-3-hover:hover {
  border-color: #41515b !important; }

.c-bg-after-dark-3:after {
  background-color: #41515b !important; }

.c-bg-before-dark-3:before {
  background-color: #41515b !important; }

.c-bg-grey {
  background-color: #eeeeee !important; }
  .c-bg-grey.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(238, 238, 238, 0.04) !important; }
  .c-bg-grey.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(238, 238, 238, 0.4) !important; }
  .c-bg-grey.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(238, 238, 238, 0.6) !important; }
  .c-bg-grey.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(238, 238, 238, 0.8) !important; }
  .c-bg-grey.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(238, 238, 238, 0.9) !important; }
  .c-bg-grey.c-bg-grey-font {
    color: #6e6e6e !important; }

.c-bg-grey-hover:hover {
  background-color: #eeeeee !important; }

.c-border-grey-hover:hover {
  border-color: #eeeeee !important; }

.c-bg-after-grey:after {
  background-color: #eeeeee !important; }

.c-bg-before-grey:before {
  background-color: #eeeeee !important; }

.c-bg-grey-1 {
  background-color: #f7f7f7 !important; }
  .c-bg-grey-1.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(247, 247, 247, 0.04) !important; }
  .c-bg-grey-1.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(247, 247, 247, 0.4) !important; }
  .c-bg-grey-1.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(247, 247, 247, 0.6) !important; }
  .c-bg-grey-1.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(247, 247, 247, 0.8) !important; }
  .c-bg-grey-1.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(247, 247, 247, 0.9) !important; }
  .c-bg-grey-1.c-bg-grey-1-font {
    color: #777777 !important; }

.c-bg-grey-1-hover:hover {
  background-color: #f7f7f7 !important; }

.c-border-grey-1-hover:hover {
  border-color: #f7f7f7 !important; }

.c-bg-after-grey-1:after {
  background-color: #f7f7f7 !important; }

.c-bg-before-grey-1:before {
  background-color: #f7f7f7 !important; }

.c-bg-grey-2 {
  background-color: #677083 !important; }
  .c-bg-grey-2.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(103, 112, 131, 0.04) !important; }
  .c-bg-grey-2.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(103, 112, 131, 0.4) !important; }
  .c-bg-grey-2.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(103, 112, 131, 0.6) !important; }
  .c-bg-grey-2.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(103, 112, 131, 0.8) !important; }
  .c-bg-grey-2.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(103, 112, 131, 0.9) !important; }
  .c-bg-grey-2.c-bg-grey-2-font {
    color: #ffffff !important; }

.c-bg-grey-2-hover:hover {
  background-color: #677083 !important; }

.c-border-grey-2-hover:hover {
  border-color: #677083 !important; }

.c-bg-after-grey-2:after {
  background-color: #677083 !important; }

.c-bg-before-grey-2:before {
  background-color: #677083 !important; }

.c-bg-grey-3 {
  background-color: #7f8c97 !important; }
  .c-bg-grey-3.c-bg-opacity {
    /* RGBa with 0.04 opacity */
    background-color: rgba(127, 140, 151, 0.04) !important; }
  .c-bg-grey-3.c-bg-opacity-2 {
    /* RGBa with 0.4 opacity */
    background-color: rgba(127, 140, 151, 0.4) !important; }
  .c-bg-grey-3.c-bg-opacity-3 {
    /* RGBa with 0.6 opacity */
    background-color: rgba(127, 140, 151, 0.6) !important; }
  .c-bg-grey-3.c-bg-opacity-4 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(127, 140, 151, 0.8) !important; }
  .c-bg-grey-3.c-bg-opacity-5 {
    /* RGBa with 0.8 opacity */
    background-color: rgba(127, 140, 151, 0.9) !important; }
  .c-bg-grey-3.c-bg-grey-3-font {
    color: #ffffff !important; }

.c-bg-grey-3-hover:hover {
  background-color: #7f8c97 !important; }

.c-border-grey-3-hover:hover {
  border-color: #7f8c97 !important; }

.c-bg-after-grey-3:after {
  background-color: #7f8c97 !important; }

.c-bg-before-grey-3:before {
  background-color: #7f8c97 !important; }

/* Border Colors */
.c-border-white {
  border-color: #FFFFFF !important; }

.c-border-left-white {
  border-left-color: #FFFFFF !important; }

.c-border-right-white {
  border-right-color: #FFFFFF !important; }

.c-border-top-white {
  border-top-color: #FFFFFF !important; }

.c-border-bottom-white {
  border-bottom-color: #FFFFFF !important; }

.c-border-after-white:after,
.c-border-before-white:before {
  border-color: #FFFFFF !important; }

.c-border-black {
  border-color: #000000 !important; }

.c-border-left-black {
  border-left-color: #000000 !important; }

.c-border-right-black {
  border-right-color: #000000 !important; }

.c-border-top-black {
  border-top-color: #000000 !important; }

.c-border-bottom-black {
  border-bottom-color: #000000 !important; }

.c-border-after-black:after,
.c-border-before-black:before {
  border-color: #000000 !important; }

.c-border-regular {
  border-color: #7f8c97 !important; }

.c-border-left-regular {
  border-left-color: #7f8c97 !important; }

.c-border-right-regular {
  border-right-color: #7f8c97 !important; }

.c-border-top-regular {
  border-top-color: #7f8c97 !important; }

.c-border-bottom-regular {
  border-bottom-color: #7f8c97 !important; }

.c-border-after-regular:after,
.c-border-before-regular:before {
  border-color: #7f8c97 !important; }

.c-border-green {
  border-color: #32c5d2 !important; }

.c-border-left-green {
  border-left-color: #32c5d2 !important; }

.c-border-right-green {
  border-right-color: #32c5d2 !important; }

.c-border-top-green {
  border-top-color: #32c5d2 !important; }

.c-border-bottom-green {
  border-bottom-color: #32c5d2 !important; }

.c-border-after-green:after,
.c-border-before-green:before {
  border-color: #32c5d2 !important; }

.c-border-green-1 {
  border-color: #52c9ba !important; }

.c-border-left-green-1 {
  border-left-color: #52c9ba !important; }

.c-border-right-green-1 {
  border-right-color: #52c9ba !important; }

.c-border-top-green-1 {
  border-top-color: #52c9ba !important; }

.c-border-bottom-green-1 {
  border-bottom-color: #52c9ba !important; }

.c-border-after-green-1:after,
.c-border-before-green-1:before {
  border-color: #52c9ba !important; }

.c-border-green-2 {
  border-color: #5dc09c !important; }

.c-border-left-green-2 {
  border-left-color: #5dc09c !important; }

.c-border-right-green-2 {
  border-right-color: #5dc09c !important; }

.c-border-top-green-2 {
  border-top-color: #5dc09c !important; }

.c-border-bottom-green-2 {
  border-bottom-color: #5dc09c !important; }

.c-border-after-green-2:after,
.c-border-before-green-2:before {
  border-color: #5dc09c !important; }

.c-border-green-3 {
  border-color: #84c68f !important; }

.c-border-left-green-3 {
  border-left-color: #84c68f !important; }

.c-border-right-green-3 {
  border-right-color: #84c68f !important; }

.c-border-top-green-3 {
  border-top-color: #84c68f !important; }

.c-border-bottom-green-3 {
  border-bottom-color: #84c68f !important; }

.c-border-after-green-3:after,
.c-border-before-green-3:before {
  border-color: #84c68f !important; }

.c-border-red {
  border-color: #eb5d68 !important; }

.c-border-left-red {
  border-left-color: #eb5d68 !important; }

.c-border-right-red {
  border-right-color: #eb5d68 !important; }

.c-border-top-red {
  border-top-color: #eb5d68 !important; }

.c-border-bottom-red {
  border-bottom-color: #eb5d68 !important; }

.c-border-after-red:after,
.c-border-before-red:before {
  border-color: #eb5d68 !important; }

.c-border-red-1 {
  border-color: #cd6262 !important; }

.c-border-left-red-1 {
  border-left-color: #cd6262 !important; }

.c-border-right-red-1 {
  border-right-color: #cd6262 !important; }

.c-border-top-red-1 {
  border-top-color: #cd6262 !important; }

.c-border-bottom-red-1 {
  border-bottom-color: #cd6262 !important; }

.c-border-after-red-1:after,
.c-border-before-red-1:before {
  border-color: #cd6262 !important; }

.c-border-red-2 {
  border-color: #e7505a !important; }

.c-border-left-red-2 {
  border-left-color: #e7505a !important; }

.c-border-right-red-2 {
  border-right-color: #e7505a !important; }

.c-border-top-red-2 {
  border-top-color: #e7505a !important; }

.c-border-bottom-red-2 {
  border-bottom-color: #e7505a !important; }

.c-border-after-red-2:after,
.c-border-before-red-2:before {
  border-color: #e7505a !important; }

.c-border-red-3 {
  border-color: #d05163 !important; }

.c-border-left-red-3 {
  border-left-color: #d05163 !important; }

.c-border-right-red-3 {
  border-right-color: #d05163 !important; }

.c-border-top-red-3 {
  border-top-color: #d05163 !important; }

.c-border-bottom-red-3 {
  border-bottom-color: #d05163 !important; }

.c-border-after-red-3:after,
.c-border-before-red-3:before {
  border-color: #d05163 !important; }

.c-border-yellow {
  border-color: #FF6B57 !important; }

.c-border-left-yellow {
  border-left-color: #FF6B57 !important; }

.c-border-right-yellow {
  border-right-color: #FF6B57 !important; }

.c-border-top-yellow {
  border-top-color: #FF6B57 !important; }

.c-border-bottom-yellow {
  border-bottom-color: #FF6B57 !important; }

.c-border-after-yellow:after,
.c-border-before-yellow:before {
  border-color: #FF6B57 !important; }

.c-border-yellow-1 {
  border-color: #c8d046 !important; }

.c-border-left-yellow-1 {
  border-left-color: #c8d046 !important; }

.c-border-right-yellow-1 {
  border-right-color: #c8d046 !important; }

.c-border-top-yellow-1 {
  border-top-color: #c8d046 !important; }

.c-border-bottom-yellow-1 {
  border-bottom-color: #c8d046 !important; }

.c-border-after-yellow-1:after,
.c-border-before-yellow-1:before {
  border-color: #c8d046 !important; }

.c-border-yellow-2 {
  border-color: #c5bf66 !important; }

.c-border-left-yellow-2 {
  border-left-color: #c5bf66 !important; }

.c-border-right-yellow-2 {
  border-right-color: #c5bf66 !important; }

.c-border-top-yellow-2 {
  border-top-color: #c5bf66 !important; }

.c-border-bottom-yellow-2 {
  border-bottom-color: #c5bf66 !important; }

.c-border-after-yellow-2:after,
.c-border-before-yellow-2:before {
  border-color: #c5bf66 !important; }

.c-border-yellow-3 {
  border-color: #c5b96b !important; }

.c-border-left-yellow-3 {
  border-left-color: #c5b96b !important; }

.c-border-right-yellow-3 {
  border-right-color: #c5b96b !important; }

.c-border-top-yellow-3 {
  border-top-color: #c5b96b !important; }

.c-border-bottom-yellow-3 {
  border-bottom-color: #c5b96b !important; }

.c-border-after-yellow-3:after,
.c-border-before-yellow-3:before {
  border-color: #c5b96b !important; }

.c-border-blue {
  border-color: #3498DB !important; }

.c-border-left-blue {
  border-left-color: #3498DB !important; }

.c-border-right-blue {
  border-right-color: #3498DB !important; }

.c-border-top-blue {
  border-top-color: #3498DB !important; }

.c-border-bottom-blue {
  border-bottom-color: #3498DB !important; }

.c-border-after-blue:after,
.c-border-before-blue:before {
  border-color: #3498DB !important; }

.c-border-blue-1 {
  border-color: #5e9cd1 !important; }

.c-border-left-blue-1 {
  border-left-color: #5e9cd1 !important; }

.c-border-right-blue-1 {
  border-right-color: #5e9cd1 !important; }

.c-border-top-blue-1 {
  border-top-color: #5e9cd1 !important; }

.c-border-bottom-blue-1 {
  border-bottom-color: #5e9cd1 !important; }

.c-border-after-blue-1:after,
.c-border-before-blue-1:before {
  border-color: #5e9cd1 !important; }

.c-border-blue-2 {
  border-color: #5893dd !important; }

.c-border-left-blue-2 {
  border-left-color: #5893dd !important; }

.c-border-right-blue-2 {
  border-right-color: #5893dd !important; }

.c-border-top-blue-2 {
  border-top-color: #5893dd !important; }

.c-border-bottom-blue-2 {
  border-bottom-color: #5893dd !important; }

.c-border-after-blue-2:after,
.c-border-before-blue-2:before {
  border-color: #5893dd !important; }

.c-border-blue-3 {
  border-color: #57bfe1 !important; }

.c-border-left-blue-3 {
  border-left-color: #57bfe1 !important; }

.c-border-right-blue-3 {
  border-right-color: #57bfe1 !important; }

.c-border-top-blue-3 {
  border-top-color: #57bfe1 !important; }

.c-border-bottom-blue-3 {
  border-bottom-color: #57bfe1 !important; }

.c-border-after-blue-3:after,
.c-border-before-blue-3:before {
  border-color: #57bfe1 !important; }

.c-border-purple {
  border-color: #b771b0 !important; }

.c-border-left-purple {
  border-left-color: #b771b0 !important; }

.c-border-right-purple {
  border-right-color: #b771b0 !important; }

.c-border-top-purple {
  border-top-color: #b771b0 !important; }

.c-border-bottom-purple {
  border-bottom-color: #b771b0 !important; }

.c-border-after-purple:after,
.c-border-before-purple:before {
  border-color: #b771b0 !important; }

.c-border-purple-1 {
  border-color: #a962bb !important; }

.c-border-left-purple-1 {
  border-left-color: #a962bb !important; }

.c-border-right-purple-1 {
  border-right-color: #a962bb !important; }

.c-border-top-purple-1 {
  border-top-color: #a962bb !important; }

.c-border-bottom-purple-1 {
  border-bottom-color: #a962bb !important; }

.c-border-after-purple-1:after,
.c-border-before-purple-1:before {
  border-color: #a962bb !important; }

.c-border-purple-2 {
  border-color: #aa67a3 !important; }

.c-border-left-purple-2 {
  border-left-color: #aa67a3 !important; }

.c-border-right-purple-2 {
  border-right-color: #aa67a3 !important; }

.c-border-top-purple-2 {
  border-top-color: #aa67a3 !important; }

.c-border-bottom-purple-2 {
  border-bottom-color: #aa67a3 !important; }

.c-border-after-purple-2:after,
.c-border-before-purple-2:before {
  border-color: #aa67a3 !important; }

.c-border-purple-3 {
  border-color: #ac3773 !important; }

.c-border-left-purple-3 {
  border-left-color: #ac3773 !important; }

.c-border-right-purple-3 {
  border-right-color: #ac3773 !important; }

.c-border-top-purple-3 {
  border-top-color: #ac3773 !important; }

.c-border-bottom-purple-3 {
  border-bottom-color: #ac3773 !important; }

.c-border-after-purple-3:after,
.c-border-before-purple-3:before {
  border-color: #ac3773 !important; }

.c-border-brown {
  border-color: #8a7f68 !important; }

.c-border-left-brown {
  border-left-color: #8a7f68 !important; }

.c-border-right-brown {
  border-right-color: #8a7f68 !important; }

.c-border-top-brown {
  border-top-color: #8a7f68 !important; }

.c-border-bottom-brown {
  border-bottom-color: #8a7f68 !important; }

.c-border-after-brown:after,
.c-border-before-brown:before {
  border-color: #8a7f68 !important; }

.c-border-brown-1 {
  border-color: #685e47 !important; }

.c-border-left-brown-1 {
  border-left-color: #685e47 !important; }

.c-border-right-brown-1 {
  border-right-color: #685e47 !important; }

.c-border-top-brown-1 {
  border-top-color: #685e47 !important; }

.c-border-bottom-brown-1 {
  border-bottom-color: #685e47 !important; }

.c-border-after-brown-1:after,
.c-border-before-brown-1:before {
  border-color: #685e47 !important; }

.c-border-brown-2 {
  border-color: #7a6a61 !important; }

.c-border-left-brown-2 {
  border-left-color: #7a6a61 !important; }

.c-border-right-brown-2 {
  border-right-color: #7a6a61 !important; }

.c-border-top-brown-2 {
  border-top-color: #7a6a61 !important; }

.c-border-bottom-brown-2 {
  border-bottom-color: #7a6a61 !important; }

.c-border-after-brown-2:after,
.c-border-before-brown-2:before {
  border-color: #7a6a61 !important; }

.c-border-brown-3 {
  border-color: #9d8b81 !important; }

.c-border-left-brown-3 {
  border-left-color: #9d8b81 !important; }

.c-border-right-brown-3 {
  border-right-color: #9d8b81 !important; }

.c-border-top-brown-3 {
  border-top-color: #9d8b81 !important; }

.c-border-bottom-brown-3 {
  border-bottom-color: #9d8b81 !important; }

.c-border-after-brown-3:after,
.c-border-before-brown-3:before {
  border-color: #9d8b81 !important; }

.c-border-dark {
  border-color: #2f353b !important; }

.c-border-left-dark {
  border-left-color: #2f353b !important; }

.c-border-right-dark {
  border-right-color: #2f353b !important; }

.c-border-top-dark {
  border-top-color: #2f353b !important; }

.c-border-bottom-dark {
  border-bottom-color: #2f353b !important; }

.c-border-after-dark:after,
.c-border-before-dark:before {
  border-color: #2f353b !important; }

.c-border-dark-1 {
  border-color: #525e64 !important; }

.c-border-left-dark-1 {
  border-left-color: #525e64 !important; }

.c-border-right-dark-1 {
  border-right-color: #525e64 !important; }

.c-border-top-dark-1 {
  border-top-color: #525e64 !important; }

.c-border-bottom-dark-1 {
  border-bottom-color: #525e64 !important; }

.c-border-after-dark-1:after,
.c-border-before-dark-1:before {
  border-color: #525e64 !important; }

.c-border-dark-2 {
  border-color: #31383c !important; }

.c-border-left-dark-2 {
  border-left-color: #31383c !important; }

.c-border-right-dark-2 {
  border-right-color: #31383c !important; }

.c-border-top-dark-2 {
  border-top-color: #31383c !important; }

.c-border-bottom-dark-2 {
  border-bottom-color: #31383c !important; }

.c-border-after-dark-2:after,
.c-border-before-dark-2:before {
  border-color: #31383c !important; }

.c-border-dark-3 {
  border-color: #41515b !important; }

.c-border-left-dark-3 {
  border-left-color: #41515b !important; }

.c-border-right-dark-3 {
  border-right-color: #41515b !important; }

.c-border-top-dark-3 {
  border-top-color: #41515b !important; }

.c-border-bottom-dark-3 {
  border-bottom-color: #41515b !important; }

.c-border-after-dark-3:after,
.c-border-before-dark-3:before {
  border-color: #41515b !important; }

.c-border-grey {
  border-color: #eeeeee !important; }

.c-border-left-grey {
  border-left-color: #eeeeee !important; }

.c-border-right-grey {
  border-right-color: #eeeeee !important; }

.c-border-top-grey {
  border-top-color: #eeeeee !important; }

.c-border-bottom-grey {
  border-bottom-color: #eeeeee !important; }

.c-border-after-grey:after,
.c-border-before-grey:before {
  border-color: #eeeeee !important; }

.c-border-grey-1 {
  border-color: #f7f7f7 !important; }

.c-border-left-grey-1 {
  border-left-color: #f7f7f7 !important; }

.c-border-right-grey-1 {
  border-right-color: #f7f7f7 !important; }

.c-border-top-grey-1 {
  border-top-color: #f7f7f7 !important; }

.c-border-bottom-grey-1 {
  border-bottom-color: #f7f7f7 !important; }

.c-border-after-grey-1:after,
.c-border-before-grey-1:before {
  border-color: #f7f7f7 !important; }

.c-border-grey-2 {
  border-color: #677083 !important; }

.c-border-left-grey-2 {
  border-left-color: #677083 !important; }

.c-border-right-grey-2 {
  border-right-color: #677083 !important; }

.c-border-top-grey-2 {
  border-top-color: #677083 !important; }

.c-border-bottom-grey-2 {
  border-bottom-color: #677083 !important; }

.c-border-after-grey-2:after,
.c-border-before-grey-2:before {
  border-color: #677083 !important; }

.c-border-grey-3 {
  border-color: #7f8c97 !important; }

.c-border-left-grey-3 {
  border-left-color: #7f8c97 !important; }

.c-border-right-grey-3 {
  border-right-color: #7f8c97 !important; }

.c-border-top-grey-3 {
  border-top-color: #7f8c97 !important; }

.c-border-bottom-grey-3 {
  border-bottom-color: #7f8c97 !important; }

.c-border-after-grey-3:after,
.c-border-before-grey-3:before {
  border-color: #7f8c97 !important; }

.c-bg-img-center-contain {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.c-bg-img-center {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.c-bg-img-top {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover; }

.c-bg-img-bottom-right {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover; }

.c-bg-img-top-center {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.c-bg-none,
.c-bg-transparent {
  background: none; }

.c-pos-relative {
  position: relative; }

.c-bg-parallax {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  min-height: 100%; }

/* ios safari hacks */
@supports (-webkit-text-size-adjust: none) and (-ms-accelerator: true) {
  .c-bg-parallax {
    background-attachment: scroll !important; } }

@media (max-width: 991px) {
  .c-bg-parallax {
    background-attachment: scroll !important; } }

.c-opacity-0 {
  opacity: 0;
  filter: alphaopacity=0; }

.c-opacity-01 {
  opacity: 0.1;
  filter: alphaopacity=10; }

.c-opacity-02 {
  opacity: 0.2;
  filter: alphaopacity=20; }

.c-opacity-03 {
  opacity: 0.3;
  filter: alphaopacity=30; }

.c-opacity-04 {
  opacity: 0.4;
  filter: alphaopacity=40; }

.c-opacity-05 {
  opacity: 0.5;
  filter: alphaopacity=50; }

.c-opacity-06 {
  opacity: 0.6;
  filter: alphaopacity=60; }

.c-opacity-07 {
  opacity: 0.7;
  filter: alphaopacity=70; }

.c-opacity-08 {
  opacity: 0.8;
  filter: alphaopacity=80; }

.c-opacity-1 {
  opacity: 1;
  filter: alphaopacity=100; }

.c-layout-page:before,
.c-layout-page:after {
  content: " ";
  display: table; }

.c-layout-page:after {
  clear: both; }

.c-layout-page-loading * {
  visibility: hidden; }

.c-layout-page-loading .c-content-loader {
  visibility: visible;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%; }

@media (min-width: 992px) {
  .container-fluid {
    padding: 0 100px; } }

.c-layout-header:before,
.c-layout-header:after {
  content: " ";
  display: table; }

.c-layout-header:after {
  clear: both; }

.c-layout-header .c-navbar:before,
.c-layout-header .c-navbar:after {
  content: " ";
  display: table; }

.c-layout-header .c-navbar:after {
  clear: both; }

.c-layout-header .c-brand {
  transition: all 0.2s;
  display: inline-block; }
  .c-layout-header .c-brand.c-pull-left {
    float: left; }
  .c-layout-header .c-brand.c-pull-right {
    float: right; }
  .c-layout-header .c-brand > .c-hor-nav-toggler {
    display: none; }
  .c-layout-header .c-brand > .c-topbar-toggler,
  .c-layout-header .c-brand > .c-search-toggler,
  .c-layout-header .c-brand > .c-cart-toggler {
    display: none; }
  .c-layout-quick-search-shown .c-layout-header .c-brand {
    display: none; }
  .c-layout-header .c-brand .c-desktop-logo {
    display: block; }
  .c-layout-header .c-brand .c-desktop-logo-inverse {
    display: none; }
  .c-layout-header .c-brand > .c-btn-login {
    display: none; }

.c-layout-header .c-quick-search {
  display: none;
  padding: 0;
  margin: 0;
  transition: all 0.2s;
  position: relative; }
  .c-layout-header .c-quick-search:before,
  .c-layout-header .c-quick-search:after {
    content: " ";
    display: table; }
  .c-layout-header .c-quick-search:after {
    clear: both; }
  .c-layout-quick-search-shown .c-layout-header .c-quick-search {
    display: block;
    transition: all 0.2s; }
  .c-layout-header .c-quick-search > .form-control {
    display: block;
    font-size: 22px;
    font-weight: 400;
    border: 0;
    background: transparent;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .c-layout-header .c-quick-search > .form-control::-ms-clear {
      display: none; }
    .c-layout-header .c-quick-search > .form-control:focus,
    .c-layout-header .c-quick-search > .form-control:active {
      box-shadow: none; }
  .c-layout-header .c-quick-search > span {
    display: inline-block;
    position: absolute;
    font-size: 36px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }
    .c-layout-header .c-quick-search > span:hover {
      cursor: pointer; }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-no-border {
    border-bottom: 0 !important; }
  .c-layout-header .c-navbar {
    line-height: 0px; }
    .c-layout-header .c-navbar > .container {
      position: relative; }
    .c-layout-header .c-navbar > .container-fluid {
      position: relative;
      padding: 0 100px; }
  .c-layout-header .c-brand {
    margin: 40px 0 37px 0; }
    .c-layout-header .c-brand .c-desktop-logo-inverse {
      display: none; }
    .c-layout-header .c-brand .c-desktop-logo {
      display: inline-block; }
    .c-layout-header .c-brand .c-mobile-logo {
      display: none; }
  .c-layout-header .c-quick-search > .form-control {
    padding: 10px 0;
    height: 100px; }
  .c-layout-header .c-quick-search > span {
    top: 50px;
    right: -2px; }
  .c-layout-header-fullscreen.c-layout-header-static .c-layout-header,
  .c-layout-header-fixed .c-layout-header {
    top: 0;
    position: fixed;
    z-index: 9995;
    width: 100%; }
  .c-layout-header-fixed .c-layout-page {
    margin-top: 100px; }
  .c-layout-header-fixed.c-layout-header-topbar .c-layout-page {
    margin-top: 145px; }
  .c-layout-header-fullscreen .c-layout-page,
  .c-layout-header-fullscreen.c-layout-header-fixed.c-layout-header-topbar .c-layout-page {
    margin-top: 0; }
  .c-page-on-scroll.c-layout-header-static .c-layout-header {
    display: none; }
  .c-page-on-scroll.c-layout-header-fixed .c-layout-header {
    height: 65px;
    line-height: 0px; }
    .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-brand {
      transition: margin 0.2s;
      margin: 22px 0 19px 0; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-brand .c-desktop-logo {
        display: none; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-brand .c-desktop-logo-inverse {
        display: block; }
    .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-mega-menu {
      line-height: 0px; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-mega-menu .nav.navbar-nav > li > .c-link {
        transition: all 0.2s;
        padding: 23px 15px 21px 15px;
        min-height: 65px; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-mega-menu .nav.navbar-nav > li > .c-quick-sidebar-toggler {
        transition: all 0.2s;
        padding: 26px 0 24px 15px; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-mega-menu .nav.navbar-nav > li > .c-btn {
        transition: all 0.2s;
        margin: 15px 15px 16px 15px; }
      .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-mega-menu .nav.navbar-nav > li > .c-btn-icon {
        transition: all 0.2s;
        margin: 6px 5px 7px 5px; }
    .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-quick-search > .form-control {
      font-size: 22px;
      padding: 10px 0;
      height: 65px; }
    .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-quick-search > span {
      font-size: 36px;
      top: 33px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header {
    margin: 0;
    transition: all 0.2s; }
    .c-layout-header .container-fluid {
      padding-left: 0;
      padding-right: 0; }
    .c-layout-header .c-navbar {
      height: 65px; }
      .c-layout-header .c-navbar > .container {
        position: relative;
        padding: 0; }
    .c-layout-header .c-brand {
      float: none !important;
      display: block;
      margin: 20px 15px 21px 15px; }
      .c-layout-header .c-brand .c-desktop-logo-inverse {
        display: none; }
      .c-layout-header .c-brand > .c-hor-nav-toggler {
        display: inline-block;
        background: none;
        border: 0;
        margin: -1px 0 0 0;
        padding: 0;
        float: right;
        outline: none; }
        .c-layout-header .c-brand > .c-hor-nav-toggler > .c-line {
          width: 15px;
          display: block;
          height: 2px;
          padding: 0px;
          margin: 3px 0; }
          .c-layout-header .c-brand > .c-hor-nav-toggler > .c-line:first-child {
            margin-top: 4px; }
      .c-layout-header .c-brand > .c-topbar-toggler,
      .c-layout-header .c-brand > .c-search-toggler,
      .c-layout-header .c-brand > .c-cart-toggler {
        background: none;
        border: 0;
        margin: 0px 25px 0 0;
        padding: 0;
        float: right;
        outline: none;
        height: 20px;
        width: 20px; }
        .c-layout-header .c-brand > .c-topbar-toggler > i,
        .c-layout-header .c-brand > .c-search-toggler > i,
        .c-layout-header .c-brand > .c-cart-toggler > i {
          font-size: 14px; }
      .c-layout-header .c-brand > .c-search-toggler,
      .c-layout-header .c-brand > .c-cart-toggler {
        display: inline-block; }
      .c-layout-header .c-brand > .c-btn-login {
        float: right;
        display: inline-block;
        margin-right: 20px;
        margin-top: -3px; }
      .c-layout-header .c-brand .c-desktop-logo {
        display: none; }
    .c-layout-header .c-mega-menu {
      line-height: 0px; }
    .c-layout-header .c-quick-search {
      position: relative; }
      .c-layout-header .c-quick-search > .form-control {
        font-size: 20px;
        padding: 10px 15px;
        height: 65px; }
      .c-layout-header .c-quick-search > span {
        font-size: 36px;
        right: 20px;
        top: 9px; }
  .c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-static .c-layout-header,
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header {
    top: 0;
    position: fixed;
    z-index: 9995;
    width: 100%;
    transition: none; }
    .c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-static .c-layout-header.c-mega-menu-shown .c-mega-menu,
    .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header.c-mega-menu-shown .c-mega-menu {
      position: absolute;
      overflow-y: auto; }
  .c-layout-header-topbar-collapse .c-layout-header .c-brand > .c-topbar-toggler {
    display: inline-block; }
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-page {
    margin-top: 65px; }
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header-topbar .c-layout-page {
    margin-top: 110px; }
  .c-layout-header-fullscreen.c-layout-header-mobile-fixed .c-layout-page,
  .c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-fixed.c-layout-header-topbar .c-layout-page {
    margin-top: 0; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header .c-navbar .c-mega-menu {
    line-height: 0px;
    display: inline-block;
    padding: 0;
    margin: 0;
    position: static; }
    .c-layout-header .c-navbar .c-mega-menu.c-pull-left {
      float: left; }
    .c-layout-header .c-navbar .c-mega-menu.c-pull-right {
      float: right; }
    .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav {
      line-height: 0px;
      background: none;
      position: static;
      margin: 0;
      padding: 0; }
      .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li {
        padding: 0;
        margin: 0;
        float: left;
        position: static; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-menu-type-classic {
          position: relative; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
          letter-spacing: 1px;
          font-style: normal;
          transition: all 0.2s;
          padding: 41px 15px 39px 15px;
          min-height: 100px;
          font-size: 17px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
            background: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
          transition: all 0.2s;
          display: inline-block;
          background: none !important;
          padding: 44px 0 40px 15px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
            width: 15px;
            display: block;
            height: 2px;
            padding: 0px;
            margin: 3px 0; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line:first-child {
              margin-top: 0px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn {
          transition: margin 0.2s;
          margin: 34px 15px 33px 15px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn > i {
            position: relative;
            top: 1px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon {
          background: none;
          outline: none;
          transition: margin 0.2s;
          margin: 24px 5px 20px 5px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover {
            background: none; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon > i {
            position: relative;
            top: 0px;
            font-size: 14px;
            height: 14px; }
            .ie .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon > i {
              top: 2px; }
            .ie9 .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon > i {
              top: 0px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-quick-sidebar-toggler,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-btn-link,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-link,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-quick-sidebar-toggler,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-btn-link,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-link {
          background: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu {
          width: auto;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .container-fluid .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu {
            width: 1170px !important; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu,
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-pull-right {
            right: 0;
            left: auto; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-pull-left {
            left: 0;
            right: auto; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu {
          box-shadow: none; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > h3 {
            margin: 0;
            letter-spacing: 1px;
            padding: 10px 25px 10px 25px;
            font-size: 16px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > a {
            padding: 8px 25px;
            white-space: normal;
            letter-spacing: 1px;
            font-size: 14px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li.dropdown-submenu > .dropdown-menu {
            margin-top: -10px;
            margin-left: 0; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-fullwidth {
          width: 100%;
          left: 0;
          right: 0; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic {
          left: auto;
          right: auto;
          min-width: 235px;
          width: 235px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic.c-pull-right {
            right: 0;
            left: auto; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic .dropdown-menu {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0;
            min-width: 235px;
            width: 235px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega {
          padding: 0; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu {
            float: none;
            box-shadow: none !important;
            background: none;
            position: static;
            display: block; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu:before,
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu:after {
              content: " ";
              display: table; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu:after {
              clear: both; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .row {
            margin: 0 0; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .row > div {
              padding: 0; }
              .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .row > div:last-child {
                border-right: 0; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .c-menu-type-inline {
            margin: 10px 0; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs {
            padding: 20px 40px; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li {
              margin: 0 15px; }
              .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li:first-child {
                margin-left: 0; }
              .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li > a {
                margin: 0;
                padding: 7px 0;
                border: 0;
                background: none;
                font-size: 16px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .tab-content {
            padding: 0;
            margin: 0 15px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > li {
            display: table-cell;
            width: 1%; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > li .dropdown-menu.c-menu-type-inline {
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0;
              margin: 0;
              padding: 15px 0; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > li > .dropdown-menu {
              float: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .dropdown-menu {
          display: block; }
      .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav .c-visible-mobile {
        display: none !important; }
    .c-layout-quick-search-shown .c-layout-header .c-navbar .c-mega-menu {
      display: none; }
  .c-layout-header .c-navbar .container-fluid > .c-navbar-wrapper > .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega {
    max-width: 1170px; }
  .c-layout-header .c-navbar .c-mega-menu {
    line-height: 0px; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li > .c-link {
      font-size: 15px;
      text-transform: uppercase; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      font-size: 14px;
      text-transform: uppercase; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li .dropdown-menu > li > a {
      font-size: 12px;
      text-transform: uppercase; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li > a {
      font-size: 14px;
      text-transform: uppercase; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li > .c-link {
      font-weight: 600; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      font-weight: 500; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li .dropdown-menu > li > a {
      font-weight: 500; }
    .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li > a {
      font-weight: 600; } }

@media (min-width: 1170px) {
  .c-layout-header .c-navbar .container-fluid > .c-navbar-wrapper > .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega {
    left: auto;
    right: 20px; }
    .c-layout-header .c-navbar .container-fluid > .c-navbar-wrapper > .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega.c-pull-left {
      left: 20px;
      right: auto; } }

.c-mega-menu-onepage-dots {
  width: 12px;
  position: fixed;
  list-style: none;
  top: 50%;
  right: 20px; }
  .c-mega-menu-onepage-dots .c-onepage-dots-nav {
    position: relative;
    padding: 0;
    margin: 0; }
    .c-mega-menu-onepage-dots .c-onepage-dots-nav > li.c-onepage-link {
      margin: 7px 0;
      display: block;
      float: none;
      padding: 0; }
      .c-mega-menu-onepage-dots .c-onepage-dots-nav > li.c-onepage-link > a {
        padding: 0;
        margin: 0;
        display: block;
        line-height: 0px;
        height: 12px;
        width: 12px;
        background: #e3e7ec;
        border-radius: 12px; }
      .c-mega-menu-onepage-dots .c-onepage-dots-nav > li.c-onepage-link.c-active > a,
      .c-mega-menu-onepage-dots .c-onepage-dots-nav > li.c-onepage-link.active > a {
        background: #b5bfce; }
      .c-mega-menu-onepage-dots .c-onepage-dots-nav > li.c-onepage-link .tooltip .tooltip-inner {
        border-radius: 0;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px 20px; }

@media (max-width: 991px) {
  /* 991px */
  .c-mega-menu-onepage-dots {
    right: 20px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-navbar .c-mega-menu {
    display: inline-block;
    padding: 0;
    margin: 0;
    padding: 5px 10px;
    display: none;
    width: 100%;
    float: none !important;
    overflow-x: hidden;
    transition: all 0.2s; }
    .c-layout-header .c-navbar .c-mega-menu.c-pull-left {
      float: left; }
    .c-layout-header .c-navbar .c-mega-menu.c-pull-right {
      float: right; }
    .c-layout-header .c-navbar .c-mega-menu.c-shown {
      display: block;
      position: absolute;
      z-index: 9996; }
    .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav {
      margin: 0 -15px !important;
      float: none; }
      .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li {
        display: block;
        float: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
          padding: 10px 20px 10px 20px;
          font-size: 15px;
          letter-spacing: 1px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus,
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover {
            background: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn {
          margin: 15px 15px 5px 15px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-search-toggler {
          display: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
          padding: 10px 20px 10px 20px;
          background: 0;
          border: 0;
          outline: none;
          padding-right: 0; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
            width: 15px;
            display: block;
            height: 2px;
            padding: 0px;
            margin: 3px 0; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line:first-child {
              margin-top: 3px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-open > .dropdown-menu {
          display: block; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-open > .dropdown-menu .dropdown-submenu.c-open > .dropdown-menu {
            display: block; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-submenu > a > .c-arrow,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li a.dropdown-toggle > .c-arrow {
          position: absolute;
          right: 15px;
          padding: 3px 0;
          display: inline-block;
          font-size: 13px;
          font-family: FontAwesome;
          height: auto;
          margin-top: -3px;
          text-align: center;
          width: 30px;
          font-weight: 300; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-submenu > a > .c-arrow:before,
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li a.dropdown-toggle > .c-arrow:before {
            content: "\f104"; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-open.dropdown-submenu > a > .c-arrow:before,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-open > a.dropdown-toggle > .c-arrow:before,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .c-open.dropdown-submenu > a > .c-arrow:before,
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .c-open > a.dropdown-toggle > .c-arrow:before {
          content: "\f107"; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu {
          padding-bottom: 0;
          float: none;
          display: none;
          position: static;
          background: none;
          box-shadow: none;
          min-width: none; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > h3 {
            margin: 0;
            font-size: 15px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > a {
            font-size: 14px;
            white-space: normal; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > a:focus,
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li .dropdown-menu > li > a:hover {
              background: none; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic {
          padding: 0; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic > li > a,
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic > .dropdown-submenu > a {
            padding: 10px 20px 10px 40px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic > .dropdown-submenu > .dropdown-menu > li > a {
            padding: 10px 20px 10px 60px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > a {
            padding: 10px 20px 10px 80px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > a {
            padding: 10px 20px 10px 100px; }
        .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega {
          padding-top: 0;
          min-width: 0 !important; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu {
            display: block; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu.c-menu-type-inline {
              margin-left: 0; }
              .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu.c-menu-type-inline > li > h3 {
                padding: 10px 20px 10px 40px; }
              .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .dropdown-menu.c-menu-type-inline > li > a {
                padding: 10px 20px 10px 60px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .dropdown-submenu > a {
            padding: 10px 20px 10px 40px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .dropdown-submenu > .dropdown-menu {
            display: none; }
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .dropdown-submenu > .dropdown-menu .dropdown-menu > li > h3,
            .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .dropdown-submenu > .dropdown-menu .dropdown-menu > li > a {
              padding: 10px 20px 10px 60px; }
          .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .dropdown-submenu.c-open > .dropdown-menu {
            display: block; }
      .c-layout-header .c-navbar .c-mega-menu > .nav.navbar-nav .c-visible-desktop {
        display: none !important; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li > .c-link {
    font-size: 13px;
    text-transform: uppercase; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li .dropdown-menu > li > h3 {
    font-size: 13px;
    text-transform: uppercase; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-uppercase > .nav.navbar-nav > li .dropdown-menu > li > a {
    font-size: 13px;
    text-transform: uppercase; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li > .c-link {
    font-weight: 400; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li .dropdown-menu > li > h3 {
    font-weight: 400; }
  .c-layout-header .c-navbar .c-mega-menu.c-fonts-bold > .nav.navbar-nav > li .dropdown-menu > li > a {
    font-weight: 400; } }

.c-layout-header:before,
.c-layout-header:after {
  content: " ";
  display: table; }

.c-layout-header:after {
  clear: both; }

.c-layout-header .c-topbar:before,
.c-layout-header .c-topbar:after {
  content: " ";
  display: table; }

.c-layout-header .c-topbar:after {
  clear: both; }

.c-layout-header .c-topbar .c-top-menu {
  display: inline-block;
  padding: 0;
  margin: 0; }
  .c-layout-header .c-topbar .c-top-menu.c-pull-left {
    float: left; }
  .c-layout-header .c-topbar .c-top-menu.c-pull-right {
    float: right; }
  .c-layout-header .c-topbar .c-top-menu > .c-icons,
  .c-layout-header .c-topbar .c-top-menu > .c-links,
  .c-layout-header .c-topbar .c-top-menu > .c-ext {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li,
    .c-layout-header .c-topbar .c-top-menu > .c-links > li,
    .c-layout-header .c-topbar .c-top-menu > .c-ext > li {
      display: inline-block; }
  .c-layout-header .c-topbar .c-top-menu > .c-icons > li {
    padding: 12px 5px 8px 5px; }
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li > a {
      font-size: 14px; }
      .c-layout-header .c-topbar .c-top-menu > .c-icons > li > a > i {
        font-size: 15px; }
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li > span {
      font-size: 15px; }
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li > a:active,
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li > a:hover,
    .c-layout-header .c-topbar .c-top-menu > .c-icons > li > a:focus {
      cursor: pointer;
      text-decoration: none; }
  .c-layout-header .c-topbar .c-top-menu > .c-links > li {
    padding: 0 3px 5px 3px; }
    .c-layout-header .c-topbar .c-top-menu > .c-links > li > a:not(.btn),
    .c-layout-header .c-topbar .c-top-menu > .c-links > li > span {
      font-size: 15px; }
      .c-layout-header .c-topbar .c-top-menu > .c-links > li > a:not(.btn) > i,
      .c-layout-header .c-topbar .c-top-menu > .c-links > li > span > i {
        font-size: 16px; }
    .c-layout-header .c-topbar .c-top-menu > .c-links > li > a:not(.btn):active,
    .c-layout-header .c-topbar .c-top-menu > .c-links > li > a:not(.btn):hover,
    .c-layout-header .c-topbar .c-top-menu > .c-links > li > a:not(.btn):focus {
      cursor: pointer;
      text-decoration: none; }
    .c-layout-header .c-topbar .c-top-menu > .c-links > li.c-divider {
      position: relative;
      font-size: 13px;
      top: -1px; }
  .c-layout-header .c-topbar .c-top-menu > .c-ext > li {
    margin: 0;
    padding: 0; }
    .c-layout-header .c-topbar .c-top-menu > .c-ext > li > a > i {
      font-size: 15px;
      position: relative;
      top: 3px; }
    .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang {
      position: relative;
      top: 8px;
      margin: 0 10px 0 15px; }
      .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang.c-last {
        margin-right: 0; }
      .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang > a {
        display: inline-block;
        float: left;
        padding: 7px 7px 5px 7px;
        height: 28px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px; }
      .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang > .dropdown-menu {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        min-width: 155px;
        padding: 0;
        margin: 0; }
        .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li {
          padding: 0; }
          .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li > a {
            letter-spacing: 1px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 8px 12px; }
      .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-lang:hover > .dropdown-menu {
        display: block; }
    .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-search {
      margin-top: 8px; }
      .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-search > form {
        float: left;
        padding: 0;
        margin: 0;
        position: relative; }
        .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-search > form > .form-control {
          font-weight: 400;
          border: 0;
          border-radius: 0;
          padding: 4px 14px 2px 6px;
          height: 28px;
          font-size: 12px;
          text-transform: uppercase; }
        .c-layout-header .c-topbar .c-top-menu > .c-ext > li.c-search > form > i {
          cursor: pointer;
          font-size: 13px;
          position: absolute;
          top: 8px;
          right: 8px; }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header .c-topbar {
    height: 45px; }
    .c-layout-header .c-topbar > .container-fluid {
      padding: 0 100px; }
  .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-topbar {
    transition: all 0.2s;
    display: none; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-topbar {
    margin: 0;
    min-height: 45px; }
    .c-layout-header .c-topbar .container-fluid {
      padding-left: 15px;
      padding-right: 15px; }
  .c-layout-header-topbar-collapse .c-layout-header .c-topbar {
    display: none; }
  .c-layout-header-topbar-collapse.c-topbar-expanded .c-layout-header .c-topbar {
    display: block; }
  .c-layout-header-topbar-collapse.c-topbar-expanded.c-layout-header-fixed.c-layout-header-mobile-fixed:not(.c-layout-header-fullscreen) .c-layout-page {
    margin-top: 110px; } }

.c-page-on-scroll .c-layout-header .c-cart-toggler-wrapper .c-btn-icon {
  margin-bottom: 3px !important; }

.c-layout-header .c-cart-toggler-wrapper .c-btn-icon i {
  font-size: 20px !important; }

.c-layout-header .c-cart-toggler-wrapper .c-cart-number {
  margin-left: 5px;
  position: relative;
  top: -3px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  padding: 3px 0;
  height: 25px;
  font-size: 15px;
  width: 25px;
  color: #ffffff; }

.c-layout-header .c-cart-menu {
  border: 1px solid #f3f3f3;
  display: none;
  width: 390px;
  background: #ffffff;
  position: absolute;
  top: 100%;
  right: 0; }
  .c-header-cart-shown .c-layout-header .c-cart-menu {
    display: block; }
  .c-layout-header .c-cart-menu .c-cart-menu-title {
    padding: 20px; }
    .c-layout-header .c-cart-menu .c-cart-menu-title > .c-cart-menu-float-l {
      float: left;
      display: inline-block; }
    .c-layout-header .c-cart-menu .c-cart-menu-title > .c-cart-menu-float-r {
      float: right;
      display: inline-block; }
  .c-layout-header .c-cart-menu .c-cart-menu-items {
    padding: 0;
    max-height: 500px;
    overflow-y: auto;
    width: 100%; }
    .c-layout-header .c-cart-menu .c-cart-menu-items > li {
      border-top: 1px solid #f3f3f3;
      padding: 20px;
      width: 100%;
      list-style: none;
      overflow: auto; }
      .c-layout-header .c-cart-menu .c-cart-menu-items > li > img {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 20px; }
      .c-layout-header .c-cart-menu .c-cart-menu-items > li > .c-cart-menu-content {
        padding-left: 100px; }
        .c-layout-header .c-cart-menu .c-cart-menu-items > li > .c-cart-menu-content .c-item-name,
        .c-layout-header .c-cart-menu .c-cart-menu-items > li > .c-cart-menu-content .c-item-price {
          line-height: 1em; }
      .c-layout-header .c-cart-menu .c-cart-menu-items > li > .c-cart-menu-close {
        float: right;
        margin-top: 10px; }
        .c-layout-header .c-cart-menu .c-cart-menu-items > li > .c-cart-menu-close > a {
          font-size: 28px;
          font-family: arial;
          font-weight: 100;
          color: #ddd; }
  .c-layout-header .c-cart-menu .c-cart-menu-footer {
    border-top: 1px solid #f3f3f3;
    padding: 20px;
    text-align: center; }

.c-layout-header .container-fluid .c-cart-menu {
  right: 20px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-cart-toggler-wrapper {
    display: none !important; }
  .c-layout-header .c-brand > .c-cart-toggler {
    width: auto;
    height: auto; }
    .c-layout-header .c-brand > .c-cart-toggler i {
      display: inline-block;
      font-size: 18px !important;
      position: relative;
      top: 3px; }
    .c-layout-header .c-brand > .c-cart-toggler .c-cart-number {
      margin-left: 3px;
      position: relative;
      top: 0px;
      display: inline-block;
      border-radius: 20px;
      text-align: center;
      padding: 2px 0;
      height: 22px;
      font-size: 13px;
      width: 22px;
      color: #ffffff; } }

@media (max-width: 767px) {
  .c-layout-header .c-cart-menu {
    width: 100%;
    right: 0; }
    .c-layout-header .c-cart-menu .c-cart-menu-items {
      max-height: 300px; }
  .c-layout-header .container-fluid .c-cart-menu {
    right: 0; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-default {
    background: none;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-default .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #ffffff; }
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #ffffff; }
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #ffffff; }
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #ffffff; }
      .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #ffffff; }
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #ffffff; }
        .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #ffffff; }
    .c-layout-header.c-layout-header-default .c-quick-search > .form-control {
      color: #ffffff; }
      .c-layout-header.c-layout-header-default .c-quick-search > .form-control::-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-default .c-quick-search > .form-control:-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-default .c-quick-search > .form-control:-ms-input-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-default .c-quick-search > .form-control::-webkit-input-placeholder {
        color: white; }
    .c-layout-header.c-layout-header-default .c-quick-search > span {
      color: white; }
  .c-page-on-scroll .c-layout-header.c-layout-header-default {
    background: rgba(0, 0, 0, 0.7);
    border-bottom: 0;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #ffffff; }
    .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: white; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) {
    position: relative;
    color: #ffffff !important; }
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before {
      transition: 0.2s;
      position: absolute;
      top: 32px;
      content: '';
      width: 15px;
      height: 2px;
      display: block;
      background: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before {
        top: 14px;
        transition: 0.2s; }
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i {
      color: #ffffff !important; }
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a.c-btn-icon,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a.c-btn-icon,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a.c-btn-icon,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a.c-btn-icon {
    color: #ffffff; }
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > .c-search-toggler > i,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > .c-search-toggler > i,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-search-toggler > i,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-search-toggler > i {
    color: #ffffff !important; }
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-default .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-quick-sidebar-toggler > .c-line {
    background: #ffffff !important; }
  .c-layout-header.c-layout-header-default.c-bordered {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-default-mobile {
    background: #ffffff;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-hor-nav-toggler {
      position: relative;
      top: -1px;
      display: inline-block;
      background: #f5f6f8;
      padding: 5px 8px 6px 8px; }
      .c-layout-header.c-layout-header-default-mobile .c-brand > .c-hor-nav-toggler > .c-line {
        background: #bac3cd; }
      .c-layout-header.c-layout-header-default-mobile .c-brand > .c-hor-nav-toggler:hover {
        background: #e6eaed; }
        .c-layout-header.c-layout-header-default-mobile .c-brand > .c-hor-nav-toggler:hover > .c-line {
          background: #abb7c3; }
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-topbar-toggler > i,
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-search-toggler > i,
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-cart-toggler > i {
      color: #9facba; }
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-topbar-toggler:hover > i,
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-search-toggler:hover > i,
    .c-layout-header.c-layout-header-default-mobile .c-brand > .c-cart-toggler:hover > i {
      color: #bdc6cf; }
    .c-layout-header.c-layout-header-default-mobile .c-quick-search > .form-control {
      color: #677581; }
      .c-layout-header.c-layout-header-default-mobile .c-quick-search > .form-control::-moz-placeholder {
        color: #818e9a; }
      .c-layout-header.c-layout-header-default-mobile .c-quick-search > .form-control:-moz-placeholder {
        color: #818e9a; }
      .c-layout-header.c-layout-header-default-mobile .c-quick-search > .form-control:-ms-input-placeholder {
        color: #818e9a; }
      .c-layout-header.c-layout-header-default-mobile .c-quick-search > .form-control::-webkit-input-placeholder {
        color: #818e9a; }
    .c-layout-header.c-layout-header-default-mobile .c-quick-search > span {
      color: #818e9a; }
  .c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-static .c-layout-header,
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header {
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.25); } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-default-mobile.c-header-transparent-dark {
    background: #ffffff; }
    .c-layout-header.c-layout-header-default-mobile.c-header-transparent-dark.c-mega-menu-shown {
      background: #ffffff; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-dark-mobile {
    background: #394048;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-hor-nav-toggler {
      position: relative;
      top: -1px;
      display: inline-block;
      background: #2e333a;
      padding: 5px 8px 6px 8px; }
      .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-hor-nav-toggler > .c-line {
        background: #8e99a6; }
      .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-hor-nav-toggler:hover {
        background: #22272c; }
        .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-hor-nav-toggler:hover > .c-line {
          background: #808c9a; }
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-topbar-toggler > i,
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-search-toggler > i,
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-cart-toggler > i {
      color: #e6e6e6; }
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-topbar-toggler:hover > i,
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-search-toggler:hover > i,
    .c-layout-header.c-layout-header-dark-mobile .c-brand > .c-cart-toggler:hover > i {
      color: white; }
    .c-layout-header.c-layout-header-dark-mobile .c-quick-search > .form-control {
      color: #fff; }
      .c-layout-header.c-layout-header-dark-mobile .c-quick-search > .form-control::-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-dark-mobile .c-quick-search > .form-control:-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-dark-mobile .c-quick-search > .form-control:-ms-input-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-dark-mobile .c-quick-search > .form-control::-webkit-input-placeholder {
        color: white; }
    .c-layout-header.c-layout-header-dark-mobile .c-quick-search > span {
      color: white; }
  .c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-static .c-layout-header,
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header {
    border-bottom: 0;
    box-shadow: 0; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-dark-mobile.c-header-transparent-dark {
    background: rgba(30, 36, 42, 0.9); }
    .c-layout-header.c-layout-header-dark-mobile.c-header-transparent-dark.c-mega-menu-shown {
      background: #394048; }
    .c-layout-header.c-layout-header-dark-mobile.c-header-transparent-dark .c-topbar {
      background: none; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-2 {
    background: rgba(30, 36, 42, 0.25);
    border-bottom: 0; }
    .c-layout-header.c-layout-header-2 .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #ffffff; }
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #ffffff; }
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #ffffff; }
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #ffffff; }
      .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #ffffff; }
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #ffffff; }
        .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #ffffff; }
    .c-layout-header.c-layout-header-2 .c-quick-search > .form-control {
      color: #ffffff; }
      .c-layout-header.c-layout-header-2 .c-quick-search > .form-control::-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-2 .c-quick-search > .form-control:-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-2 .c-quick-search > .form-control:-ms-input-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-2 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: white; }
    .c-layout-header.c-layout-header-2 .c-quick-search > span {
      color: white; }
  .c-page-on-scroll .c-layout-header.c-layout-header-2 {
    background: rgba(30, 36, 42, 0.82);
    border-bottom: 0;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #ffffff; }
    .c-page-on-scroll .c-layout-header.c-layout-header-2 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: white; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-3 {
    background: #2f353b;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-3 .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #ffffff; }
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #ffffff; }
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #ffffff; }
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #ffffff; }
      .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #ffffff; }
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #ffffff; }
        .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #ffffff; }
    .c-layout-header.c-layout-header-3 .c-quick-search > .form-control {
      color: #ffffff; }
      .c-layout-header.c-layout-header-3 .c-quick-search > .form-control::-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-3 .c-quick-search > .form-control:-moz-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-3 .c-quick-search > .form-control:-ms-input-placeholder {
        color: white; }
      .c-layout-header.c-layout-header-3 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: white; }
    .c-layout-header.c-layout-header-3 .c-quick-search > span {
      color: white; }
  .c-page-on-scroll .c-layout-header.c-layout-header-3 {
    background: #2f353b;
    border-bottom: 0;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #ffffff; }
    .c-page-on-scroll .c-layout-header.c-layout-header-3 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: white; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler),
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) {
    position: relative;
    color: #ffffff !important; }
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before {
      transition: 0.2s;
      position: absolute;
      top: 32px;
      content: '';
      width: 15px;
      height: 2px;
      display: block;
      background: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before,
      .c-page-on-scroll .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler):before {
        top: 14px;
        transition: 0.2s; }
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i,
    .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn):not(.c-btn-icon):not(.c-quick-sidebar-toggler) > i {
      color: #ffffff !important; }
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a.c-btn-icon,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a.c-btn-icon,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a.c-btn-icon,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a.c-btn-icon {
    color: #ffffff !important; }
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > .c-search-toggler > i,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > .c-search-toggler > i,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-search-toggler > i,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-search-toggler > i {
    color: #ffffff !important; }
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > .c-quick-sidebar-toggler > .c-line,
  .c-layout-header.c-layout-header-3.c-layout-header-3-custom-menu .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > .c-quick-sidebar-toggler > .c-line {
    background: #ffffff !important; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-4 {
    background: none;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-4 .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #3a3f45; }
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #3a3f45; }
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: #464c53; }
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #3a3f45; }
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #3a3f45; }
      .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #3a3f45; }
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #3a3f45; }
        .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #3a3f45; }
    .c-layout-header.c-layout-header-4 .c-quick-search > .form-control {
      color: #69727c; }
      .c-layout-header.c-layout-header-4 .c-quick-search > .form-control::-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-4 .c-quick-search > .form-control:-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-4 .c-quick-search > .form-control:-ms-input-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-4 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: #828b96; }
    .c-layout-header.c-layout-header-4 .c-quick-search > span {
      color: #828b96; }
  .c-page-on-scroll .c-layout-header.c-layout-header-4 {
    background: #ffffff;
    border-bottom: 1px solid #e6eff3;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #69727c; }
      .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #69727c; }
    .c-page-on-scroll .c-layout-header.c-layout-header-4 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: #757f8a; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-4.c-bordered {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-5 {
    background: #2f353b;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-5 .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #ffffff; }
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #ffffff; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #ffffff; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #ffffff; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #ffffff; }
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #ffffff; }
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #ffffff; }
    .c-layout-header.c-layout-header-5 .c-quick-search > .form-control {
      color: #69727c; }
      .c-layout-header.c-layout-header-5 .c-quick-search > .form-control::-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-5 .c-quick-search > .form-control:-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-5 .c-quick-search > .form-control:-ms-input-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-5 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: #828b96; }
    .c-layout-header.c-layout-header-5 .c-quick-search > span {
      color: #828b96; }
  .c-page-on-scroll .c-layout-header.c-layout-header-5 {
    background: #2f353b;
    border-bottom: 0;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #ffffff; }
    .c-page-on-scroll .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: white; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-5 {
    background: none !important;
    margin: 20px 0; }
    .c-layout-header.c-layout-header-5 .c-navbar .container-fluid {
      padding: 0 20px; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-brand {
      margin: 0; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-navbar-wrapper {
      background: #2f353b; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li {
      border-right: 1px solid #363d44; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        padding: 19px 25px 16px 25px;
        min-height: 57px; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
        padding: 22px 25px 14px 25px; }
        .ie9 .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
          margin-bottom: -6px; }
        .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
          width: 15px;
          display: block;
          height: 2px;
          padding: 0px;
          margin: 3px 0; }
          .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line:first-child {
            margin-top: 0px; }
          .ie .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line:first-child {
            margin-top: 2px; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn {
        margin: 12px 25px 7px 25px; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon {
        margin: 1px 10px 0px 10px; }
      .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu > .nav.navbar-nav > li:last-child {
        border-right: 0; }
    .c-layout-header.c-layout-header-5 .c-quick-search > .form-control {
      padding: 10px 20px;
      height: 57px; }
    .c-layout-header.c-layout-header-5 .c-quick-search > span {
      top: 28px;
      right: 22px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-5 .container-fluid {
    padding: 0; }
  .c-layout-header.c-layout-header-5 .c-navbar .c-brand {
    margin: 0 0 0 0; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-brand > .c-hor-nav-toggler {
      margin-top: 14px;
      margin-right: 15px; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-brand > .c-cart-toggler {
      margin-top: 14px;
      margin-right: 15px; }
    .c-layout-header.c-layout-header-5 .c-navbar .c-brand > .c-search-toggler {
      margin-top: 15px;
      margin-right: 15px; }
  .c-layout-header.c-layout-header-5 .c-navbar .c-mega-menu {
    padding: 30px 0 0 0; }
  .c-layout-header.c-layout-header-5 {
    box-shadow: none;
    height: 57px; }
    .c-layout-header.c-layout-header-5 .c-quick-search .form-control {
      height: 57px; }
    .c-layout-header.c-layout-header-5 .c-quick-search > span {
      top: 29px; }
    .c-layout-header.c-layout-header-5.c-mega-menu-shown .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li {
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-6 {
    background: #2f353b;
    border-bottom: 0; }
    .c-layout-header.c-layout-header-6 .c-navbar {
      background: none; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
        color: #ffffff; }
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
          color: #ffffff; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > a:not(.btn),
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > a:not(.btn),
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > a:not(.btn) {
        color: #ffffff; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:focus > button > .c-line,
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:active > button > .c-line,
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:hover > button > .c-line {
        background: #ffffff; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active {
        color: #ffffff; }
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > a:not(.btn) {
          color: #ffffff; }
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-active > button > .c-line {
          background: #ffffff; }
    .c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
      color: #69727c; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control::-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control:-moz-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control:-ms-input-placeholder {
        color: #828b96; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: #828b96; }
    .c-layout-header.c-layout-header-6 .c-quick-search > span {
      color: #828b96; }
  .c-page-on-scroll .c-layout-header.c-layout-header-6 {
    background: #2f353b;
    border-bottom: 0;
    box-shadow: none; }
    .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon,
    .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
      color: #ffffff; }
      .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon:focus,
      .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:hover,
      .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link:focus {
        color: #ffffff; }
    .c-page-on-scroll .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
      background-color: white; } }

.c-layout-header.c-layout-header-6 .c-topbar {
  padding: 24px 0;
  background: #fff;
  height: auto; }
  .c-layout-header.c-layout-header-6 .c-topbar .c-brand {
    float: left;
    margin: 0px;
    padding: 8px 0 0 0; }
    .c-layout-quick-search-shown .c-layout-header.c-layout-header-6 .c-topbar .c-brand {
      display: block; }
    .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-logo {
      display: inline-block;
      position: relative; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-logo:after {
        top: -2px;
        position: absolute;
        right: -22px;
        content: "";
        width: 1px;
        background: #dfe4e8;
        height: 32px;
        display: inline-block; }
    .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-icons {
      position: relative;
      display: inline-block;
      padding: 0;
      top: 1px;
      margin: 0 0 0 35px;
      list-style: none; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-icons > li {
        display: inline-block;
        padding: 3px 3px 5px 3px; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-icons > li > a {
          font-size: 16px; }
          .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-icons > li > a > i {
            color: #aeb8c2;
            font-size: 16px;
            padding: 4px; }
  .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu {
    margin: 12px 0 10px 0;
    float: right; }
    .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu > .c-links > li {
      padding: 0 7px; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu > .c-links > li > a:not(.btn) {
        color: #aeb8c2;
        font-weight: 500; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu > .c-links > li > .btn {
        margin-top: -4px; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu > .c-links > li.c-divider {
        width: 1px;
        display: inline-block; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu > .c-links > li.c-divider:after {
          top: -20px;
          position: absolute;
          right: 7px;
          content: "";
          width: 1px;
          background: #dfe4e8;
          height: 32px;
          display: inline-block; }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header.c-layout-header-6 .c-navbar {
    line-height: 0px; }
    .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu {
      float: none;
      display: block;
      text-align: center;
      line-height: 0px; }
      .c-layout-quick-search-shown .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu {
        display: none; }
      .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav {
        line-height: 0px;
        float: none;
        display: inline-block; }
        .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li {
          margin: 0 !important;
          padding: 0 !important;
          /*
						.c-menu-type-mega {
							margin-top: -6px;
						}
						*/ }
          .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-link {
            padding: 25px 25px 22px 25px;
            min-height: 67px; }
          .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li:first-child > .c-link {
            padding-left: 5px; }
          .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
            padding: 28px 25px 15px 25px; }
          .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn {
            margin: 18px 25px 7px 25px; }
          .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-btn-icon {
            margin: 8px 10px 0px 10px; }
  .c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
    padding: 20px 20px 20px 5px;
    height: 67px; }
  .c-layout-header.c-layout-header-6 .c-quick-search > span {
    top: 35px;
    right: 8px; }
  .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar {
    width: 100%;
    float: none;
    display: block; }
    .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu {
      float: none;
      width: 100%;
      display: block; }
      .c-layout-quick-search-shown .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu {
        display: none; }
      .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu > .nav.navbar-nav {
        width: 100%;
        display: inline-block; }
        .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
          padding-right: 10px; }
        .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu > .nav.navbar-nav > li > .c-search-toggler {
          padding-right: 5px; }
        .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-search-toggler-wrapper,
        .c-layout-header.c-layout-header-6.c-navbar-fluid .c-navbar .c-mega-menu > .nav.navbar-nav > li.c-quick-sidebar-toggler-wrapper {
          float: right; }
  .c-layout-header-fixed.c-layout-header-6-topbar .c-layout-page {
    margin-top: 167px; }
  .c-page-on-scroll.c-layout-header-fixed .c-layout-header.c-layout-header-6 {
    height: 67px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header.c-layout-header-6 .c-topbar {
    padding: 0; }
    .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu {
      float: none;
      display: block;
      padding: 0;
      margin: 10px 0;
      text-align: right; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu:before,
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu:after {
        content: " ";
        display: table; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu:after {
        clear: both; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu .c-links {
        float: right; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-top-menu .c-links > li:last-child {
          padding-right: 0; }
    .c-layout-header.c-layout-header-6 .c-topbar .c-brand {
      width: 100%;
      display: block;
      float: none;
      margin: 10px 0 20px 0; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand:before,
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand:after {
        content: " ";
        display: table; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand:after {
        clear: both; }
      .c-layout-quick-search-shown .c-layout-header.c-layout-header-6 .c-topbar .c-brand {
        display: none; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-logo {
        float: left; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-icons {
        float: left; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler {
        float: right;
        background: #f8f9fa;
        padding: 5px 7px;
        position: relative;
        top: 2px; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler > .c-line {
          background-color: #9facba; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:active,
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:focus,
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:hover {
          background: #e9ecef; }
          .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:active > .c-line,
          .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:focus > .c-line,
          .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-hor-nav-toggler:hover > .c-line {
            background-color: #90a0b0; }
      .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-search-toggler {
        float: right;
        position: relative;
        top: 2px; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-search-toggler > i {
          color: #aeb8c2; }
        .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-search-toggler:hover > i {
          color: #748596; }
  .c-layout-header.c-layout-header-6 .c-navbar {
    height: auto; }
    .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu {
      margin-bottom: 0px; }
      .c-layout-quick-search-shown .c-layout-header.c-layout-header-6 .c-navbar .c-mega-menu {
        display: none; }
  .c-layout-header.c-layout-header-6 .c-quick-search {
    margin-top: 0; }
    .c-layout-header.c-layout-header-6 .c-quick-search > .form-control {
      background: none;
      color: #101113;
      padding: 25px 10px 35px 10px;
      height: 55px; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control::-moz-placeholder {
        color: #040505; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control:-moz-placeholder {
        color: #040505; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control:-ms-input-placeholder {
        color: #040505; }
      .c-layout-header.c-layout-header-6 .c-quick-search > .form-control::-webkit-input-placeholder {
        color: #040505; }
    .c-layout-header.c-layout-header-6 .c-quick-search > span {
      top: 0px;
      color: #101113; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu {
    background: #2c3137;
    box-shadow: none;
    border: 0; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu {
    border-radius: 0; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      color: #cbd2d9; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li > a {
      color: #7d8691; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li > a:hover,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li > a:focus {
        background: none;
        color: #7d8691; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li.c-active > a,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li .dropdown-menu > li:hover > a {
      background: #292e33;
      color: #ff6b57; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic .dropdown-menu {
    background: #2c3137; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .row > div {
    border-right: 1px solid #32373d; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs {
    border-bottom: 1px solid #434b53; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li > a {
      color: #cbd2d9; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li:hover > a {
      color: #ffffff; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li.active {
      border-bottom: 1px solid #ffffff; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li.active > a {
        color: #ffffff; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > li .dropdown-menu.c-menu-type-inline {
    border-right: 1px solid #32373d; } }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu {
    background: #f7f8f9;
    box-shadow: 0px 3px 9px -5px rgba(0, 0, 0, 0.25);
    border: 0; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu {
    border-radius: 0; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      color: #000000; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li > a {
      color: #383e45; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li > a:hover,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li > a:focus {
        background: none;
        color: #383e45; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li.c-active > a,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li .dropdown-menu > li:hover > a {
      background: #f2f2f2;
      color: #000000; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-classic .dropdown-menu {
    background: #f7f8f9; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > .row > div {
    border-right: 1px solid #f2f2f2; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs {
    border-bottom: 1px solid #f2f2f2; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li > a {
      color: #434b53; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li:hover > a {
      color: #000000; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li.active {
      border-bottom: 1px solid #000000; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega .nav.nav-tabs > li.active > a {
        color: #000000; }
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light > .nav.navbar-nav > li > .dropdown-menu.c-menu-type-mega > li .dropdown-menu.c-menu-type-inline {
    border-right: 1px solid #f2f2f2; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile {
    background: #394048; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-link {
      border-bottom: 1px solid #404851;
      color: #ebedf2; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-link:focus,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-link:hover {
        background: none;
        color: #ebedf2; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
      background: none; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: white; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler:hover > .c-line {
        background-color: #ff6b57; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li.c-active > .c-link,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li:hover > .c-link {
      color: #ff6b57;
      background: none; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      color: #ccd1de; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li > a {
      border-bottom: 1px solid #404851;
      color: #bdc3d4; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li > a:focus,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li > a:hover {
        color: #bdc3d4; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li.c-active > a,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li .dropdown-menu > li:hover > a {
      background: none;
      color: #ff6b57; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn {
    border-color: #ccd1de;
    color: #ccd1de; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn:focus,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn:active,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn.active,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-dark-mobile > .nav.navbar-nav > li > .c-btn:hover {
      color: #394048;
      background: #ffffff; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile {
    background: #ffffff; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-link {
      border-bottom: 1px solid #f7f7f7;
      color: #3f444a; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-link:focus,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-link:hover {
        background: none;
        color: #3f444a; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler {
      background: none; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler > .c-line {
        background-color: #626a73; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li > .c-quick-sidebar-toggler:hover > .c-line {
        background-color: #ff6b57; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li.c-active > .c-link,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li:hover > .c-link {
      color: #ff6b57;
      background: none; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li > h3 {
      color: #3f444a; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li > a {
      border-bottom: 1px solid #f7f7f7;
      color: #4b5158; }
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li > a:focus,
      .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li > a:hover {
        color: #4b5158; }
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li.c-active > a,
    .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile > .nav.navbar-nav > li .dropdown-menu > li:hover > a {
      background: none;
      color: #ff6b57; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header .c-navbar .c-mega-menu.c-mega-menu-light-mobile {
    box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.25); } }

.c-layout-header:before,
.c-layout-header:after {
  content: " ";
  display: table; }

.c-layout-header:after {
  clear: both; }

.c-layout-header .c-topbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background: none; }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn),
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > span,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn),
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > span,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn),
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > span {
    color: #ffffff;
    font-weight: 500; }
    .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > span > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > span > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > span > i {
      color: #f7f7f7; }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):active,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):focus,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):active,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):focus,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):active,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):focus {
    color: #cccccc; }
    .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li > a:not(.btn):focus > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li > a:not(.btn):focus > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li > a:not(.btn):focus > i {
      color: #c4c4c4; }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-icons > li.c-divider,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-links > li.c-divider,
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-divider {
    color: #ffffff; }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > a {
    background: rgba(255, 255, 255, 0.2);
    color: #f2f2f2; }
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > a:active,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > a:hover,
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > a:focus {
      color: #d9d9d9;
      background: rgba(255, 255, 255, 0.2); }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > .dropdown-menu {
    background: #f6f8fa; }
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li.active > a {
      background: #e9eef3; }
    .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li > a {
      color: #324559; }
      .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li > a:hover {
        background: #e9eef3; }
  .c-layout-header .c-topbar-dark .c-top-menu > .c-ext > li.c-lang:hover > .dropdown-menu {
    display: block; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header-topbar-collapse .c-layout-header .c-topbar-dark {
    background: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }

.c-layout-header:before,
.c-layout-header:after {
  content: " ";
  display: table; }

.c-layout-header:after {
  clear: both; }

.c-layout-header .c-topbar-light {
  border-bottom: 1px solid rgba(135, 151, 174, 0.15);
  background: none; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn),
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > span,
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn),
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > span,
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn),
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > span {
    color: #8797ae;
    font-weight: 500; }
    .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > span > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > span > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn) > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > span > i {
      color: #8797ae; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):active,
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):focus,
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):active,
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):focus,
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):active,
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):hover,
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):focus {
    color: #53647c; }
    .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li > a:not(.btn):focus > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-links > li > a:not(.btn):focus > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):active > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):hover > i,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li > a:not(.btn):focus > i {
      color: #53647c; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-icons > li.c-divider,
  .c-layout-header .c-topbar-light .c-top-menu > .c-links > li.c-divider,
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-divider {
    color: #8797ae; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > a {
    background: #f6f8fa;
    color: #697d99; }
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > a:active,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > a:hover,
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > a:focus {
      color: #49586c;
      background: #f6f8fa; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > .dropdown-menu {
    background: #f6f8fa; }
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li.active > a {
      background: #e9eef3; }
    .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li > a {
      color: #6285a8; }
      .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang > .dropdown-menu > li > a:hover {
        background: #e9eef3; }
  .c-layout-header .c-topbar-light .c-top-menu > .c-ext > li.c-lang:hover > .dropdown-menu {
    display: block; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-header-topbar-collapse .c-layout-header .c-topbar-light {
    background: #ffffff;
    border-bottom: 1px solid rgba(135, 151, 174, 0.15); } }

.c-layout-header .c-topbar-light.c-solid-bg {
  background: #ffffff; }

.c-layout-revo-slider {
  overflow-x: hidden; }
  .c-layout-revo-slider:before,
  .c-layout-revo-slider:after {
    content: " ";
    display: table; }
  .c-layout-revo-slider:after {
    clear: both; }
  .c-layout-revo-slider .c-video-hint > i {
    position: relative;
    top: 2px;
    font-size: 14px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-1 .c-main-title {
    font-size: 22px;
    line-height: 28px; }
  .c-layout-revo-slider-1 .c-action-btn.btn,
  .c-layout-revo-slider-1 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    margin-top: 20px;
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

.c-layout-revo-slider-3 .c-main-title-1 {
  line-height: 34px;
  display: inline-block;
  padding: 40px; }

.c-layout-revo-slider-3 .c-main-title-2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 50px;
  letter-spacing: 1px;
  line-height: 68px; }

.c-layout-revo-slider-3 .c-action-btn.c-btn-border-2x {
  padding: 9px 24px 7px 24px;
  font-size: 16px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-3 .c-main-title-1 {
    padding: 20px;
    font-size: 18px;
    line-height: 25px; }
  .c-layout-revo-slider-3 .c-main-title-2 {
    padding: 20px 30px;
    line-height: 25px;
    font-size: 22px; }
  .c-layout-revo-slider-3 .c-action-btn {
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

@media (max-width: 767px) {
  /* 767px */
  .c-layout-revo-slider-3 .c-main-title-1 {
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    margin-left: 70px; }
  .c-layout-revo-slider-3 .c-action-btn {
    margin-top: 50px; } }

.c-layout-revo-slider-4 .c-action-btn.c-btn-border-2x {
  padding: 9px 24px 7px 24px;
  font-size: 16px; }

.c-layout-revo-slider-4 .c-block-text {
  display: inline-block;
  padding: 40px; }

.c-layout-revo-slider-4 .c-main-title-circle {
  background: rgba(0, 0, 0, 0.05);
  padding: 380px 260px 380px 260px;
  letter-spacing: 1px;
  line-height: 68px;
  border-radius: 600px; }
  .c-layout-revo-slider-4 .c-main-title-circle.c-light-bg {
    background: rgba(255, 255, 255, 0.3); }

.c-layout-revo-slider-4 .c-main-title-square {
  background: rgba(0, 0, 0, 0.11);
  padding: 300px 300px;
  letter-spacing: 1px;
  line-height: 68px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-4 .c-main-title-circle {
    padding: 180px 160px 180px 160px;
    line-height: 25px;
    font-size: 22px;
    border-radius: 300px; }
  .c-layout-revo-slider-4 .c-main-title-square {
    background: rgba(0, 0, 0, 0.11);
    padding: 300px 300px;
    line-height: 25px;
    font-size: 22px; }
  .c-layout-revo-slider-4 .c-action-btn {
    margin-top: 20px;
    padding: 8px 22px 7px 22px;
    font-size: 14px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-6 .c-main-title {
    margin-top: -80px;
    padding: 20px;
    font-size: 20px;
    line-height: 24px; }
  .c-layout-revo-slider-6 .c-sub-title {
    padding: 15px 20px;
    line-height: 22px;
    font-size: 13px; }
  .c-layout-revo-slider-6 .c-action-btn.btn,
  .c-layout-revo-slider-6 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    padding: 7px 22px 5px 22px;
    font-size: 14px;
    margin-bottom: -60px; } }

.c-layout-revo-slider-7 .c-singup-form {
  display: none;
  text-align: center;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 50%;
  margin-top: -100px; }
  .c-layout-revo-slider-7 .c-singup-form .c-subtitle {
    display: inline-block;
    padding: 12px 0 8px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .c-layout-revo-slider-7 .c-singup-form .form-inline {
    margin-top: 20px; }
    .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group {
      margin-right: 5px; }
      .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group .form-control {
        border-color: #fff;
        color: #fff; }
        .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group .form-control::-moz-placeholder {
          color: #f2f2f2; }
        .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group .form-control:-moz-placeholder {
          color: #f2f2f2; }
        .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group .form-control:-ms-input-placeholder {
          color: #f2f2f2; }
        .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group .form-control::-webkit-input-placeholder {
          color: #f2f2f2; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-7 .c-singup-form {
    top: auto;
    margin-top: 200px; }
    .c-layout-header-mobile-fixed .c-layout-revo-slider-7 .c-singup-form {
      margin-top: 300px; }
    .c-layout-revo-slider-7 .c-singup-form h3 {
      font-size: 26px;
      font-weight: bold; }
    .c-layout-revo-slider-7 .c-singup-form h5 {
      font-size: 16px;
      font-weight: bold; } }

@media (max-width: 767px) {
  /* 768px */
  .c-layout-revo-slider-7 .c-singup-form {
    margin-top: 50px; }
    .c-layout-revo-slider-7 .c-singup-form h3 {
      font-size: 17px;
      font-weight: bold; }
    .c-layout-revo-slider-7 .c-singup-form h5 {
      font-size: 14px;
      font-weight: bold; }
    .c-layout-header-mobile-fixed .c-layout-revo-slider-7 .c-singup-form {
      margin-top: 150px; }
    .c-layout-revo-slider-7 .c-singup-form .form-inline .form-group {
      margin: 10px 10px; } }

.c-layout-revo-slider-8 .c-main-title {
  padding: 40px 50px 40px 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  letter-spacing: 1px;
  line-height: 76px; }

.c-layout-revo-slider-8 .c-action-bar {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  background: rgba(0, 0, 0, 0.2); }
  .c-layout-revo-slider-8 .c-action-bar .c-content {
    display: inline-block;
    float: left;
    margin-right: 150px; }
  .c-layout-revo-slider-8 .c-action-bar .c-btn-container {
    padding-top: 25px;
    display: inline-block;
    float: right; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-8 .c-main-title {
    padding: 20px 20px 20px 20px;
    line-height: 32px;
    font-size: 26px;
    margin-top: -40px; }
  .c-layout-revo-slider-8 .c-action-bar {
    padding: 5px; }
    .c-layout-revo-slider-8 .c-action-bar .c-content {
      display: block;
      float: none;
      margin: 0 0 10px 0;
      text-align: center; }
      .c-layout-revo-slider-8 .c-action-bar .c-content > h3 {
        font-size: 16px; }
      .c-layout-revo-slider-8 .c-action-bar .c-content > p {
        font-size: 12px; }
    .c-layout-revo-slider-8 .c-action-bar .c-btn-container {
      padding-top: 15px;
      display: block;
      float: none;
      text-align: center; } }

@media (max-width: 767px) {
  /* 767px */
  .c-layout-revo-slider-8 .c-main-title {
    padding: 15px 15px 15px 15px;
    line-height: 26px;
    font-size: 22px;
    margin-top: -30px; }
  .c-layout-revo-slider-8 .c-action-bar {
    padding: 5px; }
    .c-layout-revo-slider-8 .c-action-bar .c-content {
      margin: 0 0 10px 0; }
      .c-layout-revo-slider-8 .c-action-bar .c-content > h3 {
        font-size: 14px;
        white-space: normal;
        line-height: 18px; }
      .c-layout-revo-slider-8 .c-action-bar .c-content > p {
        font-size: 12px;
        white-space: normal;
        line-height: 16px; }
    .c-layout-revo-slider-8 .c-action-bar .c-btn-container {
      padding-top: 10px; }
    .c-layout-revo-slider-8 .c-action-bar .c-action-btn-2.btn,
    .c-layout-revo-slider-8 .c-action-bar .c-action-btn.btn {
      padding: 6px 12px; } }

.c-layout-revo-slider-11 .c-main-title {
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 50px;
  letter-spacing: 1px;
  line-height: 68px;
  display: inline-block; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-11 .c-main-title {
    font-size: 22px;
    line-height: 28px; }
  .c-layout-revo-slider-11 .c-action-btn.btn,
  .c-layout-revo-slider-11 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    margin-top: 20px;
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

.c-layout-revo-slider-12 .c-main-title {
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 50px;
  letter-spacing: 1px;
  line-height: 68px;
  display: inline-block; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-12 .c-main-title {
    font-size: 22px;
    line-height: 28px; }
  .c-layout-revo-slider-12 .c-action-btn.btn,
  .c-layout-revo-slider-12 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    margin-top: 20px;
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

.c-layout-revo-slider-13 .c-main-title {
  font-size: 20px;
  line-height: 28px;
  padding: 15px;
  border: 1px solid #ffffff; }

.c-layout-revo-slider-13 .c-highlight-title {
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 20px !important; }

.c-layout-revo-slider-13 .c-slider-line {
  height: 1px;
  width: 80%;
  display: block;
  margin: 0 auto 30px auto !important; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-13 .c-main-title {
    line-height: 28px; }
  .c-layout-revo-slider-13 .c-highlight-title {
    margin-bottom: 15px !important; }
    .c-layout-revo-slider-13 .c-highlight-title > span {
      font-size: 80px; }
  .c-layout-revo-slider-13 .c-action-btn.btn,
  .c-layout-revo-slider-13 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    margin-top: 20px;
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

.c-layout-revo-slider-14 .c-slider-14-title {
  border: 1px solid;
  padding: 40px 45px;
  border-color: rgba(255, 255, 255, 0.12); }
  .c-layout-revo-slider-14 .c-slider-14-title h3 {
    font-size: 71px; }
  .c-layout-revo-slider-14 .c-slider-14-title p {
    margin-bottom: 25px; }

.c-layout-revo-slider-14 .c-main-title {
  line-height: 28px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-revo-slider-14 .c-slider-14-title {
    border: 1px solid;
    padding: 40px 45px;
    border-color: rgba(255, 255, 255, 0.12); }
    .c-layout-revo-slider-14 .c-slider-14-title span {
      font-size: 71px; }
  .c-layout-revo-slider-14 .c-main-title {
    line-height: 28px; }
  .c-layout-revo-slider-14 .c-action-btn.btn,
  .c-layout-revo-slider-14 .c-action-btn.btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
    margin-top: 20px;
    padding: 7px 22px 5px 22px;
    font-size: 14px; } }

.c-layout-quick-sidebar {
  ddisplay: block;
  width: 275px;
  background: #2f353b;
  z-index: 9996;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -275px;
  transition: right 0.2s;
  overflow-x: auto; }
  .c-layout-quick-sidebar-shown .c-layout-quick-sidebar {
    right: 0;
    transition: right 0.2s; }
  .c-layout-quick-sidebar > .c-header {
    padding: 15px;
    text-align: right; }
    .c-layout-quick-sidebar > .c-header > .c-close > i {
      color: #acb4bd; }
    .c-layout-quick-sidebar > .c-header > .c-close:hover > i {
      color: #bac1c8; }
  .c-layout-quick-sidebar > .c-content > .c-section {
    margin: 30px; }
    .c-layout-quick-sidebar > .c-content > .c-section:first-child {
      margin-top: 10px; }
    .c-layout-quick-sidebar > .c-content > .c-section > h3 {
      font-size: 13px;
      color: #acb4bd;
      margin: 5px 0;
      font-weight: 600;
      text-transform: uppercase; }
    .c-layout-quick-sidebar > .c-content > .c-section > .c-settings {
      margin: 10px 0; }
      .c-layout-quick-sidebar > .c-content > .c-section > .c-settings:before,
      .c-layout-quick-sidebar > .c-content > .c-section > .c-settings:after {
        content: " ";
        display: table; }
      .c-layout-quick-sidebar > .c-content > .c-section > .c-settings:after {
        clear: both; }
      .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .btn {
        padding-left: 9px;
        padding-right: 9px;
        min-width: 91px;
        text-align: center; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .btn:first-child {
          margin-right: 8px; }
      .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color {
        display: inline-block;
        height: 29px;
        width: 29px;
        margin: 0 0px 0px 0;
        border: 3px solid #2f353b;
        border-radius: 20px !important; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-active {
          border: 3px solid #515b66; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color:hover {
          cursor: pointer; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-default {
          background: #32c5d2; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-green1 {
          background: #52c9ba; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-green2 {
          background: #5dc09c; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-green3 {
          background: #84c68f; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-yellow1 {
          background: #c8d046; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-yellow2 {
          background: #c5bf66; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-yellow3 {
          background: #c5b96b; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-red1 {
          background: #cd6262; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-red2 {
          background: #e7505a; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-red3 {
          background: #d05163; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-purple1 {
          background: #b771b0; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-purple2 {
          background: #e7505a; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-purple3 {
          background: #d05163; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-blue1 {
          background: #6e8cd7; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-blue2 {
          background: #5893dd; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-blue3 {
          background: #57bfe1; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-brown1 {
          background: #8a7f68; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-brown2 {
          background: #685e47; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-brown3 {
          background: #7a6a61; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-dark1 {
          background: #525e64; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-dark2 {
          background: #31383c; }
        .c-layout-quick-sidebar > .c-content > .c-section > .c-settings > .c-color.c-dark3 {
          background: #41515b; }

.c-layout-sidebar-menu {
  float: left;
  width: 270px;
  border-top: 0;
  margin: 30px 0 40px 0; }
  .c-layout-sidebar-menu .c-sidebar-menu-toggler {
    display: none; }
  .c-layout-sidebar-menu .c-sidebar-menu {
    height: auto;
    border: 1px solid #eaeef1;
    list-style: none;
    padding: 0 0; }
    .c-layout-sidebar-menu .c-sidebar-menu.collapse {
      display: block; }
    .c-layout-sidebar-menu .c-sidebar-menu > li {
      position: relative;
      padding: 0;
      margin: 0; }
      .c-layout-sidebar-menu .c-sidebar-menu > li > a {
        display: block;
        color: #626a73;
        padding: 12px 30px 12px 25px;
        font-weight: 500; }
      .c-layout-sidebar-menu .c-sidebar-menu > li.c-active > a {
        transition: all 0.2s;
        background-color: #f9fbfc; }
      .c-layout-sidebar-menu .c-sidebar-menu > li:hover > a,
      .c-layout-sidebar-menu .c-sidebar-menu > li.c-open > a {
        transition: all 0.2s;
        background-color: #f9fbfc; }
    .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu {
      list-style: none;
      padding: 0;
      display: none;
      transition: all 0.3s; }
      .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li {
        padding: 0;
        margin: 0; }
        .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li > a {
          display: block;
          padding: 5px 20px 5px 40px;
          color: #3f444a; }
          .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li > a > i {
            font-size: 14px;
            margin-right: 5px;
            margin-top: 1px;
            opacity: 0.6;
            filter: alphaopacity=60; }
        .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li:last-child > a {
          border-top: 0; }
        .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li > .c-dropdown-menu > li > a {
          padding-left: 60px; }
        .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li > .c-dropdown-menu > li > .c-dropdown-menu > li > a {
          padding-left: 80px; }
        .c-layout-sidebar-menu .c-sidebar-menu li .c-dropdown-menu li > .c-dropdown-menu > li > .c-dropdown-menu > li > .c-dropdown-menu > li > a {
          padding-left: 100px; }
    .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown {
      position: relative; }
      .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown > a .c-arrow {
        transition: all 0.2s;
        font-family: fontawesome;
        position: absolute;
        right: 10px;
        display: inline-block;
        font-weight: 300;
        margin-top: -1px;
        color: #a7adb3;
        width: 30px;
        text-align: center; }
        .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown > a .c-arrow:before {
          content: "\f105"; }
      .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown.c-expanded > a .c-arrow:before,
      .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown.c-open > a .c-arrow:before {
        content: "\f107"; }
      .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown.c-expanded > .c-dropdown-menu,
      .c-layout-sidebar-menu .c-sidebar-menu li.c-dropdown.c-open > .c-dropdown-menu {
        display: block;
        transition: all 0.3s; }
    .c-layout-sidebar-menu .c-sidebar-menu li:last-child > a {
      border-top: 0; }
  .c-layout-sidebar-menu .c-sidebar-menu.c-option-2 {
    background-color: #f8f9fa;
    border: 0; }
    .c-layout-sidebar-menu .c-sidebar-menu.c-option-2 > li:hover > a,
    .c-layout-sidebar-menu .c-sidebar-menu.c-option-2 > li.c-open > a {
      transition: all 0.2s;
      background-color: #eff1f4; }

.c-layout-sidebar-content {
  overflow: hidden;
  padding: 30px 0 30px 30px; }

.c-layout-sidebar-menu.c-align-right {
  float: right; }

.c-layout-sidebar-content.c-align-left {
  padding: 30px 40px 30px 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-sidebar-menu {
    width: 100%;
    float: none;
    display: block;
    margin: 0; }
    .c-layout-sidebar-menu .c-sidebar-menu-toggler {
      display: block;
      margin: 30px 0 20px 0; }
      .c-layout-sidebar-menu .c-sidebar-menu-toggler .c-title {
        display: inline-block;
        font-size: 18px;
        margin: 8px 0 5px 0;
        color: #3f444a; }
      .c-layout-sidebar-menu .c-sidebar-menu-toggler .c-content-toggler {
        float: right;
        clear: both; }
    .c-layout-sidebar-menu .c-sidebar-menu {
      margin-top: 10px; }
      .c-layout-sidebar-menu .c-sidebar-menu.collapse {
        display: none; }
      .c-layout-sidebar-menu .c-sidebar-menu.collapse.in {
        display: block; }
  .c-layout-sidebar-content {
    overflow: visible;
    padding: 10px 0 30px 0; } }

.c-layout-breadcrumbs-1 {
  padding: 25px 0;
  background: #f7fafb; }
  .c-layout-breadcrumbs-1:before,
  .c-layout-breadcrumbs-1:after {
    content: " ";
    display: table; }
  .c-layout-breadcrumbs-1:after {
    clear: both; }
  .c-layout-breadcrumbs-1 .c-page-title {
    display: inline-block; }
    .c-layout-breadcrumbs-1 .c-page-title.c-pull-left {
      float: left; }
    .c-layout-breadcrumbs-1 .c-page-title.c-pull-right {
      float: right; }
    .c-layout-breadcrumbs-1 .c-page-title h3 {
      color: #000000;
      margin: 10px 0 6px 0;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 1px; }
    .c-layout-breadcrumbs-1 .c-page-title h4 {
      color: #7f8c97;
      margin: 5px 0 5px 0;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 1px; }
  .c-layout-breadcrumbs-1 .c-page-breadcrumbs {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs.c-pull-left {
      float: left; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs.c-pull-right {
      float: right; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs > li {
      display: inline-block;
      margin: 0;
      padding: 8px 4px 8px 4px; }
      .c-layout-breadcrumbs-1 .c-page-breadcrumbs > li,
      .c-layout-breadcrumbs-1 .c-page-breadcrumbs > li > a {
        color: #7f8c97;
        font-size: 16px;
        font-weight: 400; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs.c-pull-right > li:last-child {
      padding-right: 0; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs.c-pull-left > li:first-child {
      padding-left: 0; }
  .c-layout-breadcrumbs-1.c-bordered {
    border-bottom: 1px solid #f4f8f9; }
  .c-layout-breadcrumbs-1.c-bordered-both {
    border-top: 1px solid #f4f8f9;
    border-bottom: 1px solid #f4f8f9; }
  .c-layout-breadcrumbs-1.c-subtitle {
    padding: 30px 0; }
    .c-layout-breadcrumbs-1.c-subtitle .c-page-breadcrumbs {
      margin-top: 10px; }
  .c-layout-breadcrumbs-1.c-bgimage {
    padding: 80px 0; }
    .c-layout-breadcrumbs-1.c-bgimage .c-page-breadcrumbs {
      margin-top: 10px; }
  .c-layout-breadcrumbs-1.c-bgimage-full {
    padding: 200px 0 100px; }
    .c-layout-breadcrumbs-1.c-bgimage-full .c-breadcrumbs-wrapper {
      background: rgba(0, 0, 0, 0.2);
      padding: 10px 0;
      margin-bottom: 30px; }
    .c-layout-breadcrumbs-1.c-bgimage-full .c-page-breadcrumbs {
      margin-top: 10px; }
    .c-layout-breadcrumbs-1.c-bgimage-full.c-centered {
      text-align: center; }
      .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-breadcrumbs-wrapper {
        background: rgba(0, 0, 0, 0.2);
        padding: 20px 0; }
      .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-breadcrumbs {
        display: block;
        float: none; }
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-breadcrumbs:before,
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-breadcrumbs:after {
          content: " ";
          display: table; }
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-breadcrumbs:after {
          clear: both; }
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-breadcrumbs > li:first-child {
          padding-left: 0; }
      .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-title {
        display: block;
        float: none;
        margin-bottom: 10px; }
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-title:before,
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-title:after {
          content: " ";
          display: table; }
        .c-layout-breadcrumbs-1.c-bgimage-full.c-centered .c-page-title:after {
          clear: both; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-breadcrumbs-1 {
    padding: 10px 0; }
    .c-layout-breadcrumbs-1 .c-page-title > h3 {
      margin: 6px 15px 6px 0; }
    .c-layout-breadcrumbs-1 .c-page-breadcrumbs {
      clear: both;
      float: left !important;
      text-align: left;
      clear: both; }
      .c-layout-breadcrumbs-1 .c-page-breadcrumbs > li {
        padding: 6px 4px 6px 4px; }
        .c-layout-breadcrumbs-1 .c-page-breadcrumbs > li:first-child {
          padding-left: 0; }
    .c-layout-breadcrumbs-1.c-bgimage-full {
      padding: 40px 0; }
      .c-layout-header-fullscreen .c-layout-breadcrumbs-1.c-bgimage-full {
        padding-top: 105px; }
      .c-layout-breadcrumbs-1.c-bgimage-full .c-breadcrumbs-wrapper {
        background: rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        margin-bottom: 10px; }
      .c-layout-breadcrumbs-1.c-bgimage-full .c-page-breadcrumbs {
        margin-top: 10px; } }

@media (max-width: 767px) {
  /* 767px */
  .c-layout-breadcrumbs-1 .c-page-title {
    display: block;
    float: left;
    text-align: left; }
    .c-layout-breadcrumbs-1 .c-page-title:before,
    .c-layout-breadcrumbs-1 .c-page-title:after {
      content: " ";
      display: table; }
    .c-layout-breadcrumbs-1 .c-page-title:after {
      clear: both; }
  .c-layout-breadcrumbs-1 .c-page-breadcrumbs {
    display: block; } }

.c-layout-footer.c-layout-footer-1 {
  padding: 0;
  margin: 0; }
  .c-layout-footer.c-layout-footer-1 .c-prefooter {
    padding: 60px 0;
    background: #394048; }
    .c-layout-footer.c-layout-footer-1 .c-prefooter .c-logo {
      padding-bottom: 20px; }
    .c-layout-footer.c-layout-footer-1 .c-prefooter .c-about {
      color: #818e9a;
      font-size: 17px;
      font-weight: 500;
      padding: 20px 0 10px 0; }
    .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links {
      padding: 0 0 10px 0; }
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links:before,
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links:after {
        content: " ";
        display: table; }
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links:after {
        clear: both; }
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links .c-nav {
        padding: 0;
        margin: 0 40px 0 0;
        display: inline-block;
        float: left;
        list-style: none; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links .c-nav > li {
          padding: 4px 0; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links .c-nav > li > a {
            color: #c0c9d3;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase; }
            .c-layout-footer.c-layout-footer-1 .c-prefooter .c-links .c-nav > li > a:hover {
              color: #edf0f3; }
    .c-layout-footer.c-layout-footer-1 .c-prefooter .c-contact {
      color: #818e9a;
      font-size: 17px;
      font-weight: 500;
      padding: 20px 0; }
    .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback {
      margin-left: 20px; }
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback h3 {
        color: #ffffff;
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 10px; }
      .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form {
        padding-top: 10px; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control {
          color: #7c8895;
          font-size: 17px;
          font-weight: 300;
          border-radius: 0;
          box-shadow: none;
          border-color: #474f59;
          background: transparent;
          padding: 8px 16px; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control::-moz-placeholder {
            color: #6f7b89; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control:-moz-placeholder {
            color: #6f7b89; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control:-ms-input-placeholder {
            color: #6f7b89; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control::-webkit-input-placeholder {
            color: #6f7b89; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .form-control:focus {
            box-shadow: none;
            border-color: #474f59; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap {
          width: 100%;
          height: 100%;
          display: table;
          vertical-align: middle; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group {
          height: 100%;
          display: table-cell;
          vertical-align: middle; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group .form-control {
            height: 48px; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group .form-control:first-child {
            border-right-color: transparent; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group .form-control:last-child {
            border-top-color: transparent;
            border-right-color: transparent; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group:last-child .form-control:first-child {
            border-right-color: #474f59; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .c-form-wrap-group:last-child .form-control:last-child {
            border-right-color: #474f59; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form textarea.form-control {
          border-top-color: transparent;
          height: 180px; }
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form textarea.form-control:active,
          .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form textarea.form-control:focus {
            border-color: #474f59;
            border-top-color: transparent; }
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .btn:active,
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .btn:hover,
        .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback form .btn:focus {
          background: #525c67;
          border-color: #525c67; }
  .c-layout-footer.c-layout-footer-1 .c-postfooter {
    padding: 40px 0;
    background: #2f353b; }
    .c-layout-footer.c-layout-footer-1 .c-postfooter .c-copyright {
      color: #a4aeb8;
      padding: 0;
      margin-top: 10px; }
    .c-layout-footer.c-layout-footer-1 .c-postfooter .c-socials {
      float: right;
      list-style: none;
      padding: 0;
      margin: 0; }
      .c-layout-footer.c-layout-footer-1 .c-postfooter .c-socials > li {
        display: inline-block;
        padding: 0 6px; }
        .c-layout-footer.c-layout-footer-1 .c-postfooter .c-socials > li > a > i {
          display: inline-block;
          padding: 10px;
          background: #2b3036;
          color: #64707e;
          font-size: 18px;
          font-weight: bold; }
        .c-layout-footer.c-layout-footer-1 .c-postfooter .c-socials > li > a:hover > i {
          background: #202328;
          color: #6f7d8c; }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-footer.c-layout-footer-1 .c-prefooter > .container-fluid {
    padding: 0 100px; }
  .c-layout-footer.c-layout-footer-1 .c-postfooter > .container-fluid {
    padding: 0 100px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer.c-layout-footer-1 .c-prefooter .c-feedback {
    margin: 0; } }

.c-layout-footer.c-layout-footer-2 {
  padding: 100px 0;
  margin: 0;
  background-image: url(../img/content/backgrounds/bg_footer_1.jpg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover; }
  .c-layout-footer.c-layout-footer-2.c-opt-2 {
    padding: 80px 0; }
  .c-layout-footer.c-layout-footer-2 .c-container {
    margin-bottom: 80px;
    overflow: auto; }
    .c-layout-footer.c-layout-footer-2 .c-container.c-first {
      padding-right: 50px; }
    .c-layout-footer.c-layout-footer-2 .c-container.c-last {
      padding-left: 50px; }
    .c-layout-footer.c-layout-footer-2 .c-container > .c-content-title-1 > h3 {
      font-size: 24px;
      color: #ffffff; }
    .c-layout-footer.c-layout-footer-2 .c-container > .c-content-title-1 > .c-line-left {
      margin-bottom: 30px; }
    .c-layout-footer.c-layout-footer-2 .c-container > .c-content-title-1 > p {
      margin-bottom: 30px;
      text-transform: none; }
      .c-layout-footer.c-layout-footer-2 .c-container > .c-content-title-1 > p.c-ask {
        color: #616a73; }
    .c-layout-footer.c-layout-footer-2 .c-container > .c-links {
      padding: 0 0 10px 0; }
      .c-layout-footer.c-layout-footer-2 .c-container > .c-links:before,
      .c-layout-footer.c-layout-footer-2 .c-container > .c-links:after {
        content: " ";
        display: table; }
      .c-layout-footer.c-layout-footer-2 .c-container > .c-links:after {
        clear: both; }
      .c-layout-footer.c-layout-footer-2 .c-container > .c-links .c-nav {
        padding: 0;
        margin: 0 40px 0 0;
        display: inline-block;
        float: left;
        list-style: none; }
        .c-layout-footer.c-layout-footer-2 .c-container > .c-links .c-nav > li {
          padding: 4px 0 10px 0; }
          .c-layout-footer.c-layout-footer-2 .c-container > .c-links .c-nav > li > a {
            color: #b1bcc9;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase; }
            .c-layout-footer.c-layout-footer-2 .c-container > .c-links .c-nav > li > a:hover {
              color: #edf0f3; }
    .c-layout-footer.c-layout-footer-2 .c-container > .c-socials {
      float: left;
      list-style: none;
      padding: 0;
      margin: 0; }
      .c-layout-footer.c-layout-footer-2 .c-container > .c-socials > li {
        display: inline-block;
        padding: 0 6px; }
        .c-layout-footer.c-layout-footer-2 .c-container > .c-socials > li > a > i {
          display: inline-block;
          padding: 10px;
          background: #11111c;
          color: #555555;
          font-size: 18px;
          font-weight: bold; }
        .c-layout-footer.c-layout-footer-2 .c-container > .c-socials > li > a:hover > i {
          background: #202328;
          color: #6f7d8c; }
        .c-layout-footer.c-layout-footer-2 .c-container > .c-socials > li:first-child {
          padding-left: 0; }
    .c-layout-footer.c-layout-footer-2 .c-container form {
      margin-bottom: 20px; }
      .c-layout-footer.c-layout-footer-2 .c-container form .input-group {
        width: 350px; }
        .c-layout-footer.c-layout-footer-2 .c-container form .input-group .form-control {
          background: none;
          color: #eee; }
    .c-layout-footer.c-layout-footer-2 .c-container:last-child {
      margin-bottom: 0; }
  .c-layout-footer.c-layout-footer-2 .c-copyright {
    color: #a4aeb8;
    padding: 0;
    margin: 140px 0 -60px 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer.c-layout-footer-2 {
    background-image: url(../img/content/backgrounds/bg_footer_1-1.jpg); }
    .c-layout-footer.c-layout-footer-2 .row > div {
      margin-bottom: 80px; }
      .c-layout-footer.c-layout-footer-2 .row > div:last-child {
        margin-bottom: 0; }
    .c-layout-footer.c-layout-footer-2 .c-container.c-left {
      padding: 0; }
    .c-layout-footer.c-layout-footer-2 .c-container.c-right {
      padding: 0; }
    .c-layout-footer.c-layout-footer-2 .c-container.c-last {
      padding-left: 0; }
    .c-layout-footer.c-layout-footer-2 .c-container form .form-control {
      width: 250px; }
    .c-layout-footer.c-layout-footer-2 .c-copyright {
      margin-top: 60px; } }

.c-layout-footer.c-layout-footer-3 {
  margin: 0; }
  .c-layout-footer.c-layout-footer-3 .c-prefooter {
    padding: 80px 0 20px 0; }
    .c-layout-footer.c-layout-footer-3 .c-prefooter .c-content-title-1 > h3 {
      font-size: 22px; }
    .c-layout-footer.c-layout-footer-3 .c-prefooter .c-content-title-1 > .c-line-left {
      margin-bottom: 30px; }
    .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container {
      padding: 0 10px;
      margin-bottom: 40px; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container.c-first {
        padding-left: 0; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container.c-last {
        padding-right: 0; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-links {
        padding: 0; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-links > li {
          list-style: none;
          padding: 5px 0;
          border-bottom: 1px solid #394047; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-links > li > a {
            color: #5c6873; }
            .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-links > li > a:hover {
              color: #32c5d2; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-links > li:last-child {
            border-bottom: none; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post {
        border-bottom: 1px solid #394047;
        padding: 10px 0; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post:first-child {
          padding-top: 0; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post.c-last,
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post:last-child {
          border-bottom: 0 !important; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-img {
          width: 40px;
          height: 40px;
          position: relative;
          float: left;
          margin-top: 10px; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-img img {
            width: 100%;
            border-radius: 40px; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-content {
          padding-left: 50px; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-content > .c-post-title {
            text-transform: uppercase;
            font-weight: 600; }
            .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-content > .c-post-title a {
              color: #5c6873; }
            .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-content > .c-post-title a:hover {
              color: #32c5d2; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-blog > .c-post > .c-post-content > .c-text {
            font-size: 16px; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works {
        padding: 0;
        overflow: auto;
        margin: 0; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li {
          list-style: none;
          display: inline-block;
          padding: 0;
          margin: 0 0 5px 0; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li.c-first {
            margin-right: 5px; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li.c-last {
            margin-left: 5px; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li img {
            opacity: 0.8;
            filter: alphaopacity=80;
            margin: 0;
            width: 74px; }
            .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li img:hover {
              opacity: 1;
              filter: alphaopacity=100; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-socials {
        list-style: none;
        padding: 0;
        margin: 0; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-socials > li {
          display: inline-block;
          padding: 0 5px; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-socials > li > a > i {
            position: relative;
            display: inline-block;
            padding: 10px;
            background: #1e2226;
            color: #54606b;
            font-size: 18px;
            font-weight: bold; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-socials > li > a:hover > i {
            background-color: #272d32;
            color: white; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-socials > li:first-child {
            padding-left: 0; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-address {
        clear: both;
        padding: 0;
        margin-top: 20px; }
        .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-address > li {
          list-style: none;
          padding: 5px 0; }
          .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-address > li > i {
            margin-right: 10px;
            position: relative;
            top: 2px; }
      .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-read-more {
        display: block;
        padding: 10px 0;
        font-size: 14px;
        margin-top: 20px; }
  .c-layout-footer.c-layout-footer-3 .c-postfooter {
    padding: 30px 0; }
    .c-layout-footer.c-layout-footer-3 .c-postfooter .c-copyright {
      font-size: 15px;
      margin: 0; }
    .c-layout-footer.c-layout-footer-3 .c-postfooter .c-line {
      width: 100%;
      height: 1px;
      background-color: #393f42;
      margin: 40px auto; }
    .c-layout-footer.c-layout-footer-3 .c-postfooter .c-payments {
      margin-right: -5px; }
    .c-layout-footer.c-layout-footer-3 .c-postfooter .c-col {
      height: 38px;
      line-height: 38px; }

@media (max-width: 1199px) {
  /* 1199px */
  .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li {
    width: 30%; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container {
    padding: 0; }
    .c-layout-footer.c-layout-footer-3 .c-prefooter .c-container .c-works > li {
      width: auto; } }

.c-layout-footer.c-layout-footer-4 {
  padding: 0;
  margin: 0; }
  .c-layout-footer.c-layout-footer-4.c-bg-footer-8 {
    background: url(../img/content/backgrounds/bg-97.jpg) center center no-repeat; }
  .c-layout-footer.c-layout-footer-4.c-bg-footer-8-rtl {
    background: url(../img/content/backgrounds/bg-97-rtl.jpg) center center no-repeat; }
  .c-layout-footer.c-layout-footer-4.c-bg-footer-9 {
    background: url(../img/content/backgrounds/bg-98.jpg) center center no-repeat; }
  .c-layout-footer.c-layout-footer-4.c-bg-footer-9-rtl {
    background: url(../img/content/backgrounds/bg-98-rtl.jpg) center center no-repeat; }
  .c-layout-footer.c-layout-footer-4 .c-footer {
    padding: 60px 0; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-logo {
      padding-bottom: 20px; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-footer-4-p-right {
      padding-right: 60px; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-footer-4-p-left {
      padding-left: 60px; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-content-title-1 {
      display: inline-block; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-content-title-1 h3 {
        padding: 10px; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-content-title-1 .c-border {
        border: 2px solid #ffffff; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-about {
      color: #a7a9ac;
      font-size: 15px;
      padding: 10px 0; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-links {
      padding: 20px 0 30px 0; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-links:before,
      .c-layout-footer.c-layout-footer-4 .c-footer .c-links:after {
        content: " ";
        display: table; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-links:after {
        clear: both; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-links .c-nav {
        padding: 0;
        margin: 0 40px 0 0;
        display: inline-block;
        float: left;
        list-style: none; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-links .c-nav > li {
          padding: 4px 0;
          display: inline-block; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-links .c-nav > li > a {
            color: #ffffff;
            font-size: 15px;
            text-transform: uppercase;
            padding: 10px 20px;
            margin: 0 1px;
            line-height: 3em; }
            .c-layout-footer.c-layout-footer-4 .c-footer .c-links .c-nav > li > a.c-active {
              border: 1px solid;
              margin: 0; }
            .c-layout-footer.c-layout-footer-4 .c-footer .c-links .c-nav > li > a:hover {
              color: #edf0f3;
              border: 1px solid #ffffff;
              margin: 0; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-contact {
      color: #a7a9ac;
      font-size: 15px;
      padding: 20px 0; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-socials {
      list-style: none;
      padding: 0;
      margin: 0; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-socials > li {
        display: inline-block;
        padding-right: 5px; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-socials > li > a > i {
          display: inline-block;
          padding: 10px;
          color: #a7a9ac;
          font-size: 18px; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-socials > li > a:hover > i {
          background: #202328;
          color: #6f7d8c; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback {
      margin-left: 20px; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback h3 {
        color: #ffffff;
        font-size: 27px;
        padding-bottom: 10px; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form {
        padding-top: 10px; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control {
          color: #8cdde4;
          font-size: 17px;
          font-weight: 300;
          border-radius: 0;
          box-shadow: none;
          border-color: #8cdde4;
          background: transparent;
          padding: 8px 16px;
          margin-bottom: 20px; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control::-moz-placeholder {
            color: #b5e9ee; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control:-moz-placeholder {
            color: #b5e9ee; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control:-ms-input-placeholder {
            color: #b5e9ee; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control::-webkit-input-placeholder {
            color: #b5e9ee; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control:focus {
            box-shadow: none;
            border-color: #474f59; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form textarea.form-control {
          height: 180px; }
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form textarea.form-control:active,
          .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form textarea.form-control:focus {
            border-color: #474f59; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-twitter h3 {
      font-size: 27px;
      padding-bottom: 10px; }

@media (min-width: 992px) {
  /* 992px */
  .c-layout-footer.c-layout-footer-4 .c-footer > .container-fluid {
    padding: 0 100px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer.c-layout-footer-4.c-bg-footer-8 {
    background: url(../img/content/backgrounds/bg-83.jpg) center center no-repeat;
    background-size: cover; }
  .c-layout-footer.c-layout-footer-4.c-bg-footer-9 {
    background: url(../img/content/backgrounds/bg-83.jpg) center center no-repeat;
    background-size: cover; }
    .c-layout-footer.c-layout-footer-4.c-bg-footer-9 .c-footer-4-p-left {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px 15px;
      margin-top: 40px; }
  .c-layout-footer.c-layout-footer-4 .c-footer .c-footer-4-p-right {
    padding-right: 15px; }
  .c-layout-footer.c-layout-footer-4 .c-footer .c-footer-4-p-left {
    padding-left: 15px; }
  .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback {
    margin: 40px 0 0 0; }
    .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form {
      padding-top: 10px; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control {
        color: #666666;
        border-color: #666666; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control::-moz-placeholder {
          color: gray; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control:-moz-placeholder {
          color: gray; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control:-ms-input-placeholder {
          color: gray; }
        .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form .form-control::-webkit-input-placeholder {
          color: gray; }
      .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form textarea.form-control:active,
      .c-layout-footer.c-layout-footer-4 .c-footer .c-feedback form textarea.form-control:focus {
        border-color: #474f59; } }

.c-layout-footer-6 {
  margin: 0;
  padding-top: 40px; }
  .c-layout-footer-6 .c-prefooter {
    padding: 35px 20px 40px;
    margin-bottom: 40px; }
  .c-layout-footer-6 .c-content-title-1 h3 {
    margin-bottom: 25px; }
  .c-layout-footer-6 .c-head {
    margin: 5px auto; }
    .c-layout-footer-6 .c-head .c-left .socicon {
      margin-top: 3px; }
      .c-layout-footer-6 .c-head .c-left .socicon .socicon-btn {
        width: auto;
        height: auto;
        padding: 13px; }
        .c-layout-footer-6 .c-head .c-left .socicon .socicon-btn + .socicon-btn {
          margin-left: 5px; }
    .c-layout-footer-6 .c-head .c-right {
      float: right; }
      .c-layout-footer-6 .c-head .c-right .socicon-btn {
        margin: 0; }
      .c-layout-footer-6 .c-head .c-right .c-title {
        float: left;
        margin: 20px 30px 0; }
      .c-layout-footer-6 .c-head .c-right .c-icons {
        float: left; }
        .c-layout-footer-6 .c-head .c-right .c-icons > .socicon-btn {
          width: auto;
          height: auto;
          padding: 13px;
          float: left;
          margin-left: -1px; }
  .c-layout-footer-6 .c-body .c-twitter {
    width: 340px; }
  .c-layout-footer-6 .c-body .c-links {
    float: left;
    padding: 0;
    width: 50%; }
    .c-layout-footer-6 .c-body .c-links > li {
      list-style-type: none;
      line-height: 30px; }
  .c-layout-footer-6 .c-body .c-address {
    margin: 0; }
  .c-layout-footer-6 .c-foot {
    padding-bottom: 5px; }
    .c-layout-footer-6 .c-foot .c-text {
      margin: 0; }
  .c-layout-footer-6 .c-line {
    width: 100%;
    height: 1px;
    background-color: #e8f0f5;
    margin: 40px auto; }
  .c-layout-footer-6 .c-postfooter {
    padding: 30px 0; }
    .c-layout-footer-6 .c-postfooter .c-copyright {
      font-size: 15px;
      margin: 0; }
    .c-layout-footer-6 .c-postfooter .c-payments {
      margin-right: -5px; }
    .c-layout-footer-6 .c-postfooter .c-col {
      height: 38px;
      line-height: 38px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer-6 .c-head .c-right {
    float: none;
    margin-top: 30px; }
    .c-layout-footer-6 .c-head .c-right > .c-title {
      display: none; }
  .c-layout-footer-6 .c-body .c-links, .c-layout-footer-6 .c-body .c-twitter {
    margin-bottom: 40px; }
  .c-layout-footer-6 .c-body .c-twitter {
    width: 100%; }
  .c-layout-footer-6 .c-foot .c-text {
    margin-bottom: 40px; } }

.c-layout-footer-7 {
  margin: 0;
  background-color: #2e3336; }
  .c-layout-footer-7 .c-input {
    background-color: #2e3336;
    border-color: #3f464d;
    color: #58626d; }
  .c-layout-footer-7 .c-prefooter {
    padding: 70px 0 35px 0; }
  .c-layout-footer-7 .c-content-title-1 .c-title {
    color: #d5dfe5;
    margin-bottom: 25px; }
  .c-layout-footer-7 .c-head {
    margin: 5px auto; }
    .c-layout-footer-7 .c-head .c-left .socicon {
      margin-top: 3px; }
      .c-layout-footer-7 .c-head .c-left .socicon .socicon-btn {
        width: auto;
        height: auto;
        padding: 13px;
        background-color: #2a2e31; }
        .c-layout-footer-7 .c-head .c-left .socicon .socicon-btn + .socicon-btn {
          margin-left: 5px; }
    .c-layout-footer-7 .c-head .c-right {
      float: right; }
      .c-layout-footer-7 .c-head .c-right .socicon-btn {
        margin: 0; }
      .c-layout-footer-7 .c-head .c-right .c-title {
        float: left;
        margin: 20px 30px 0;
        color: #d5dfe5; }
      .c-layout-footer-7 .c-head .c-right .c-icons {
        float: left; }
        .c-layout-footer-7 .c-head .c-right .c-icons > .socicon-btn {
          width: auto;
          height: auto;
          padding: 13px;
          float: left;
          margin-left: -1px;
          border-color: #353b3e; }
          .c-layout-footer-7 .c-head .c-right .c-icons > .socicon-btn:hover {
            background-color: #32383b; }
  .c-layout-footer-7 .c-body .c-twitter {
    width: 340px;
    color: #b1c4d0; }
  .c-layout-footer-7 .c-body .c-links {
    float: left;
    padding: 0; }
    .c-layout-footer-7 .c-body .c-links > li {
      list-style-type: none;
      line-height: 30px; }
      .c-layout-footer-7 .c-body .c-links > li > a {
        color: #b1c4d0; }
        .c-layout-footer-7 .c-body .c-links > li > a:hover {
          color: #e7eef3; }
    .c-layout-footer-7 .c-body .c-links + .c-links {
      padding-left: 50px; }
  .c-layout-footer-7 .c-body .c-address {
    margin: 0;
    color: #7e8b93; }
  .c-layout-footer-7 .c-foot {
    padding-bottom: 5px; }
    .c-layout-footer-7 .c-foot .c-text {
      margin: 0; }
  .c-layout-footer-7 .c-line {
    width: 100%;
    height: 1px;
    background-color: #393f42;
    margin: 40px auto; }
  .c-layout-footer-7 .c-postfooter {
    padding: 30px 0; }
    .c-layout-footer-7 .c-postfooter .c-copyright {
      font-size: 15px;
      margin: 0; }
    .c-layout-footer-7 .c-postfooter .c-payments {
      margin-right: -5px; }
    .c-layout-footer-7 .c-postfooter .c-col {
      height: 38px;
      line-height: 38px; }

@media (max-width: 991px) {
  /* 991px */
  .c-layout-footer-7 .c-head .c-right {
    float: none;
    margin-top: 30px; }
    .c-layout-footer-7 .c-head .c-right > .c-title {
      display: none; }
  .c-layout-footer-7 .c-body .c-links, .c-layout-footer-7 .c-body .c-twitter {
    margin-bottom: 40px; }
  .c-layout-footer-7 .c-body .c-twitter {
    width: 100%; }
  .c-layout-footer-7 .c-foot .c-text {
    margin-bottom: 40px; } }

.c-layout-go2top {
  display: inline-block;
  position: fixed;
  bottom: 20px;
  right: 10px;
  cursor: pointer; }
  .c-layout-go2top > i {
    opacity: 0.5;
    filter: alphaopacity=50;
    color: #89939e;
    font-size: 38px;
    font-weight: 300; }
  .c-layout-go2top:hover {
    opacity: 0.8;
    filter: alphaopacity=80; }

.c-content-color-demo {
  border: 1px solid #eee;
  margin: 0 0 20px 0;
  cursor: pointer; }
  .c-content-color-demo .c-color-view {
    padding: 35px 10px;
    text-align: center;
    font-size: 18px; }
  .c-content-color-demo .c-color-info {
    border-top: 1px solid #eee;
    padding: 10px 10px;
    text-align: center; }

.c-content-box.c-size-sm {
  padding: 30px 0; }

.c-content-box.c-size-md {
  padding: 60px 0; }

.c-content-box.c-size-lg {
  padding: 80px 0; }

.c-content-box.c-size-xg {
  padding: 100px 0; }

.c-content-box.c-no-bottom-padding {
  padding-bottom: 0; }

.c-content-box.c-no-top-padding {
  padding-top: 0; }

.c-content-box.c-no-padding {
  padding: 0; }

.c-content-box.c-overflow-hide {
  overflow: hidden; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-box > .container-fluid {
    padding: 0 100px; }
  .c-content-box.c-size-sm {
    padding: 20px 0; }
  .c-content-box.c-size-md {
    padding: 30px 0; }
  .c-content-box.c-size-lg {
    padding: 40px 0; }
  .c-content-box.c-size-xg {
    padding: 60px 0; } }

.c-content-line-icon {
  background-image: url(../img/content/line-icons/default.png);
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: cover; }
  .c-content-line-icon.c-white {
    background-image: url(../img/content/line-icons/white.png); }
  .c-content-line-icon.c-icon-screen-chart {
    background-position: 0px -1300%; }
  .c-content-line-icon.c-icon-support {
    background-position: 0px -2500%; }
  .c-content-line-icon.c-icon-bulb {
    background-position: 0px 1200%; }
  .c-content-line-icon.c-icon-comment {
    background-position: 0px -300%; }
  .c-content-line-icon.c-icon-globe {
    background-position: 0px -2600%; }
  .c-content-line-icon.c-icon-sticker {
    background-position: 0px  2300%; }
  .c-content-line-icon.c-icon-md {
    width: 40px;
    height: 40px; }
  .c-content-line-icon.c-icon-sm {
    width: 30px;
    height: 30px; }
  .c-content-line-icon.c-icon-xs {
    width: 20px;
    height: 20px; }
  .c-content-line-icon.c-icon-1 {
    background-position: 0px 0%; }
  .c-content-line-icon.c-icon-2 {
    background-position: 0px 100%; }
  .c-content-line-icon.c-icon-3 {
    background-position: 0px 200%; }
  .c-content-line-icon.c-icon-4 {
    background-position: 0px 300%; }
  .c-content-line-icon.c-icon-5 {
    background-position: 0px 400%; }
  .c-content-line-icon.c-icon-6 {
    background-position: 0px 500%; }
  .c-content-line-icon.c-icon-7 {
    background-position: 0px 600%; }
  .c-content-line-icon.c-icon-8 {
    background-position: 0px 700%; }
  .c-content-line-icon.c-icon-9 {
    background-position: 0px 800%; }
  .c-content-line-icon.c-icon-10 {
    background-position: 0px 900%; }
  .c-content-line-icon.c-icon-11 {
    background-position: 0px 1000%; }
  .c-content-line-icon.c-icon-12 {
    background-position: 0px 1100%; }
  .c-content-line-icon.c-icon-13 {
    background-position: 0px 1200%; }
  .c-content-line-icon.c-icon-14 {
    background-position: 0px 1300%; }
  .c-content-line-icon.c-icon-15 {
    background-position: 0px 1400%; }
  .c-content-line-icon.c-icon-16 {
    background-position: 0px 1500%; }
  .c-content-line-icon.c-icon-17 {
    background-position: 0px 1600%; }
  .c-content-line-icon.c-icon-18 {
    background-position: 0px 1700%; }
  .c-content-line-icon.c-icon-19 {
    background-position: 0px 1800%; }
  .c-content-line-icon.c-icon-20 {
    background-position: 0px 1900%; }
  .c-content-line-icon.c-icon-21 {
    background-position: 0px 2000%; }
  .c-content-line-icon.c-icon-22 {
    background-position: 0px 2100%; }
  .c-content-line-icon.c-icon-23 {
    background-position: 0px 2200%; }
  .c-content-line-icon.c-icon-24 {
    background-position: 0px 2300%; }
  .c-content-line-icon.c-icon-25 {
    background-position: 0px 2400%; }
  .c-content-line-icon.c-icon-26 {
    background-position: 0px 2500%; }
  .c-content-line-icon.c-icon-27 {
    background-position: 0px 2600%; }
  .c-content-line-icon.c-icon-28 {
    background-position: 0px 2700%; }
  .c-content-line-icon.c-icon-29 {
    background-position: 0px 2800%; }
  .c-content-line-icon.c-icon-30 {
    background-position: 0px 2900%; }
  .c-content-line-icon.c-icon-31 {
    background-position: 0px 3000%; }
  .c-content-line-icon.c-icon-32 {
    background-position: 0px 3100%; }
  .c-content-line-icon.c-icon-33 {
    background-position: 0px 3200%; }
  .c-content-line-icon.c-icon-34 {
    background-position: 0px 3300%; }
  .c-content-line-icon.c-icon-35 {
    background-position: 0px 3400%; }
  .c-content-line-icon.c-icon-36 {
    background-position: 0px 3500%; }
  .c-content-line-icon.c-icon-37 {
    background-position: 0px 3600%; }
  .c-content-line-icon.c-icon-38 {
    background-position: 0px 3700%; }
  .c-content-line-icon.c-icon-39 {
    background-position: 0px 3800%; }
  .c-content-line-icon.c-icon-40 {
    background-position: 0px 3900%; }
  .c-content-line-icon.c-icon-41 {
    background-position: 0px 4000%; }
  .c-content-line-icon.c-icon-42 {
    background-position: 0px 4100%; }
  .c-content-line-icon.c-icon-43 {
    background-position: 0px 4200%; }
  .c-content-line-icon.c-icon-44 {
    background-position: 0px 4300%; }
  .c-content-line-icon.c-icon-45 {
    background-position: 0px 4400%; }
  .c-content-line-icon.c-icon-46 {
    background-position: 0px 4500%; }
  .c-content-line-icon.c-icon-47 {
    background-position: 0px 4600%; }
  .c-content-line-icon.c-icon-48 {
    background-position: 0px 4700%; }
  .c-content-line-icon.c-icon-49 {
    background-position: 0px 4800%; }
  .c-content-line-icon.c-icon-50 {
    background-position: 0px 4900%; }

.c-content-v-center .c-wrapper {
  width: 100%;
  height: 100%;
  display: table; }
  .c-content-v-center .c-wrapper .c-body {
    display: table-cell;
    vertical-align: middle; }

.c-content-list-1 {
  padding: 0;
  margin: 20px 0; }
  .c-content-list-1 > li {
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    color: #5c6873;
    margin-bottom: 7px; }
  .c-content-list-1 > li:before {
    content: '';
    width: 21px;
    height: 2px;
    float: left;
    margin: 9px 15px 0 0;
    background: #ddd; }
  .c-content-list-1.c-separator-dot > li:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: #ddd;
    margin-right: 10px;
    margin-top: 8px;
    opacity: 0.6;
    filter: alphaopacity=60; }
  .c-content-list-1.c-separator-dot.c-square > li:before {
    border-radius: 0; }

.c-content-iconlist-1 {
  padding: 0; }
  .c-content-iconlist-1 > li {
    list-style: none;
    display: inline-block;
    padding: 2px; }
    .c-content-iconlist-1 > li:last-child {
      padding-right: 0; }
    .c-content-iconlist-1 > li > a {
      border: 0; }
    .c-content-iconlist-1 > li i {
      position: relative;
      background: #f2f2f2;
      text-align: center;
      font-size: 20px;
      color: #cccccc;
      padding: 13px;
      width: 45px;
      height: 45px; }
    .c-content-iconlist-1 > li:hover > a {
      border: 0; }
    .c-content-iconlist-1 > li:hover i {
      border: 0; }

.c-content-list-adjusted {
  padding: 0;
  margin: 0;
  list-style: none; }
  .c-content-list-adjusted > li {
    display: table-cell;
    width: 1%;
    padding-right: 5px; }
    .c-content-list-adjusted > li:last-child {
      padding-right: 0; }

.c-content-pagination {
  display: inline-block;
  padding: 0;
  margin: 0; }
  .c-content-pagination > li {
    list-style: none;
    display: inline-block; }
    .c-content-pagination > li > span,
    .c-content-pagination > li > a {
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
      font-size: 17px;
      color: #5c6873;
      font-weight: 400;
      padding: 7px 4px 6px 4px;
      border-radius: 40px;
      border: 1px solid #eeeeee; }
    .c-content-pagination > li.c-space > span {
      border-radius: 0;
      border: 0; }
  .c-content-pagination.c-square > li > span,
  .c-content-pagination.c-square > li > a {
    border-radius: 0; }

.c-content-overlay {
  overflow: hidden;
  position: relative; }
  .c-content-overlay .c-overlay-object {
    position: relative;
    z-index: 1; }
  .c-content-overlay .c-overlay-wrapper {
    opacity: 0;
    filter: alphaopacity=0;
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    transition: all 0.2s;
    background: rgba(0, 0, 0, 0.4); }
    .c-content-overlay .c-overlay-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .c-content-overlay .c-overlay-wrapper:before {
      display: inline-block;
      content: '';
      vertical-align: middle;
      height: 100%; }
    .c-content-overlay .c-overlay-wrapper .c-overlay-content {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      height: auto;
      width: 95%; }
      .c-content-overlay .c-overlay-wrapper .c-overlay-content i {
        display: inline-block;
        width: 44px;
        height: 44px;
        border-radius: 40px;
        text-align: center;
        padding: 14px 6px 8px 6px;
        color: #fff;
        font-size: 16px;
        background: #000000;
        opacity: 0.7;
        filter: alphaopacity=70;
        margin: 0 5px; }
        .c-content-overlay .c-overlay-wrapper .c-overlay-content i:hover {
          opacity: 1;
          filter: alphaopacity=100; }
    .c-content-overlay .c-overlay-wrapper.c-overlay-padding {
      left: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px); }
  .c-content-overlay:hover {
    cursor: pointer; }
    .c-content-overlay:hover .c-overlay-wrapper {
      top: 0;
      opacity: 1;
      filter: alphaopacity=100; }
      .c-content-overlay:hover .c-overlay-wrapper.c-overlay-padding {
        top: 10px; }
  .c-content-overlay .c-overlay-border {
    border: 1px solid rgba(255, 255, 255, 0.2);
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    position: absolute;
    z-index: 1; }

.c-content-loader {
  display: none;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -30px;
  background: #ccc;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  -webkit-animation: c-scaleout 1s infinite ease-in-out;
  animation: c-scaleout 1s infinite ease-in-out; }

@-webkit-keyframes c-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes c-scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0; } }

.c-content-panel {
  padding: 0;
  border: 1px solid #f1f1f1;
  margin-bottom: 30px; }
  .c-content-panel .c-label {
    display: inline-block;
    position: absolute;
    background: #eee;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 500;
    color: #565d66; }
  .c-content-panel .c-body {
    padding: 40px 20px 20px 20px; }

.c-content-ver-nav .c-menu {
  margin: 0;
  padding: 0; }
  .c-content-ver-nav .c-menu > li {
    list-style: none;
    padding: 7px 0;
    border-bottom: 1px solid #f2f2f2; }
    .c-content-ver-nav .c-menu > li > a {
      font-weight: 400; }
      .c-content-ver-nav .c-menu > li > a > .badge {
        float: right; }
    .c-content-ver-nav .c-menu > li:before {
      color: #5c6873;
      content: "\f105";
      font-family: FontAwesome;
      font-weight: 100;
      width: 12px;
      height: 12px;
      font-size: 14px;
      float: left;
      margin: 2px 5px 0 0; }
  .c-content-ver-nav .c-menu.c-arrow-dot > li:before {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 9px 12px 0 0;
    content: '';
    opacity: 0.6;
    filter: alphaopacity=60;
    background: #5c6873; }
  .c-content-ver-nav .c-menu.c-arrow-dot.c-square > li:before {
    border-radius: 0; }

.c-checkbox {
  position: relative;
  /* handling click events */
  /* when checkbox is checked */ }
  .c-checkbox.c-checkbox-inline {
    display: inline-block; }
  .form-inline .c-checkbox.c-checkbox-inline {
    margin-right: 20px;
    top: 3px; }
  .c-checkbox input[type=checkbox] {
    visibility: hidden;
    position: absolute; }
  .c-checkbox label {
    cursor: pointer;
    padding-left: 30px; }
  .c-checkbox label > span {
    display: block;
    position: absolute;
    left: 0;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .c-checkbox label > span.inc {
    background: #fff;
    left: -10px;
    top: -10px;
    height: 40px;
    width: 40px;
    opacity: 0;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important; }
  .c-checkbox label > .box {
    top: 1px;
    border: 2px solid #d0d7de;
    height: 20px;
    width: 20px;
    z-index: 5;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    transition-delay: 0.2s; }
    .ie .c-checkbox label > .box {
      top: 2px; }
  .c-checkbox label > .check {
    top: -2px;
    left: 6px;
    width: 10px;
    height: 20px;
    border: 2px solid #d0d7de;
    border-top: none;
    border-left: none;
    opacity: 0;
    z-index: 5;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s; }
  .c-checkbox label > span.inc {
    -webkit-animation: growCircle 0.3s ease;
    -moz-animation: growCircle 0.3s ease;
    animation: growCircle 0.3s ease; }
  .c-checkbox input[type=checkbox]:checked ~ label > .box {
    opacity: 0;
    -webkit-transform: scale(0) rotate(-180deg);
    -moz-transform: scale(0) rotate(-180deg);
    transform: scale(0) rotate(-180deg); }
  .c-checkbox input[type=checkbox]:checked ~ label > .check {
    opacity: 1;
    -webkit-transform: scale(1) rotate(45deg);
    -moz-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg); }
  .c-checkbox input[type=checkbox]:disabled ~ label,
  .c-checkbox input[type=checkbox][disabled] ~ label {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-checkbox input[type=checkbox]:disabled ~ label > .box,
  .c-checkbox input[type=checkbox][disabled] ~ label > .box {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
  .c-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-checkbox.has-error label {
    color: #e7505a; }
  .c-checkbox.has-error label > .box {
    border-color: #e7505a; }
  .c-checkbox.has-error label > .check {
    border-color: #e7505a; }
  .c-checkbox.has-info label {
    color: #4eabe6; }
  .c-checkbox.has-info label > .box {
    border-color: #4eabe6; }
  .c-checkbox.has-info label > .check {
    border-color: #4eabe6; }
  .c-checkbox.has-success label {
    color: #5dc09c; }
  .c-checkbox.has-success label > .box {
    border-color: #5dc09c; }
  .c-checkbox.has-success label > .check {
    border-color: #5dc09c; }
  .c-checkbox.has-warning label {
    color: #c8d046; }
  .c-checkbox.has-warning label > .box {
    border-color: #c8d046; }
  .c-checkbox.has-warning label > .check {
    border-color: #c8d046; }

.c-form-checkboxes.has-error > label {
  color: #e7505a; }

.c-form-checkboxes.has-info > label {
  color: #4eabe6; }

.c-form-checkboxes.has-success > label {
  color: #5dc09c; }

.c-form-checkboxes.has-warning > label {
  color: #c8d046; }

.c-checkbox-list {
  margin: 10px 0; }
  .form-horizontal .c-checkbox-list {
    margin-top: 0px; }
  .c-checkbox-list .c-checkbox {
    display: block;
    margin-bottom: 10px; }

.c-checkbox-inline {
  margin: 10px 0; }
  .form-horizontal .c-checkbox-inline {
    margin-top: 8px; }
  .c-checkbox-inline .c-checkbox {
    display: inline-block;
    margin-right: 20px; }
    .c-checkbox-inline .c-checkbox:last-child {
      margin-right: 0; }

/* bubble animation */
@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25); } }

@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -moz-transform: scale(1.25); } }

@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    transform: scale(1.25); } }

.c-radio {
  position: relative;
  /* handling click events */
  /* when radio is checked */ }
  .c-radio input[type=radio] {
    visibility: hidden;
    position: absolute; }
  .c-radio label {
    line-height: 1px;
    cursor: pointer;
    padding-left: 30px; }
  .c-radio label > span {
    display: block;
    position: absolute;
    left: 0;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s; }
  .c-radio label > span.inc {
    background: #fff;
    left: -10px;
    top: -10px;
    height: 40px;
    width: 40px;
    opacity: 0;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important; }
  .c-radio label > .box {
    top: 1px;
    border: 2px solid #d0d7de;
    height: 20px;
    width: 20px;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    z-index: 5; }
    .ie .c-radio label > .box {
      top: 2px; }
  .c-radio label > .check {
    top: 6px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: #d0d7de;
    opacity: 0;
    z-index: 6;
    display: block;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0); }
  .c-radio label > span.inc {
    -webkit-animation: growCircleRadio 0.3s ease;
    -moz-animation: growCircleRadio 0.3s ease;
    animation: growCircleRadio 0.3s ease; }
  .c-radio input[type=radio]:checked ~ label > .check {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1); }
  .c-radio input[type=radio]:disabled ~ label,
  .c-radio input[type=radio][disabled] ~ label {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-radio input[type=radio]:disabled ~ label > .box,
  .c-radio input[type=radio][disabled] ~ label > .box {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-radio input[type=radio]:disabled:checked ~ label > .check,
  .c-radio input[type=radio][disabled]:checked ~ label > .check {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; }
  .c-radio.has-error label {
    color: #e7505a; }
  .c-radio.has-error label > .box {
    border-color: #e7505a; }
  .c-radio.has-error label > .check {
    background: #e7505a; }
  .c-radio.has-info label {
    color: #4eabe6; }
  .c-radio.has-info label > .box {
    border-color: #4eabe6; }
  .c-radio.has-info label > .check {
    background: #4eabe6; }
  .c-radio.has-success label {
    color: #5dc09c; }
  .c-radio.has-success label > .box {
    border-color: #5dc09c; }
  .c-radio.has-success label > .check {
    background: #5dc09c; }
  .c-radio.has-warning label {
    color: #c8d046; }
  .c-radio.has-warning label > .box {
    border-color: #c8d046; }
  .c-radio.has-warning label > .check {
    background: #c8d046; }

.form-c-radios.has-error > label {
  color: #e7505a; }

.form-c-radios.has-info > label {
  color: #4eabe6; }

.form-c-radios.has-success > label {
  color: #5dc09c; }

.form-c-radios.has-warning > label {
  color: #c8d046; }

.c-radio-list {
  margin: 10px 0; }
  .form-horizontal .c-radio-list {
    margin-top: 0px; }
  .c-radio-list .c-radio {
    display: block;
    margin-bottom: 10px; }

.c-radio-inline {
  margin: 10px 0; }
  .form-horizontal .c-radio-inline {
    margin-top: 8px; }
  .c-radio-inline .c-radio {
    display: inline-block;
    margin-right: 20px; }
    .c-radio-inline .c-radio:last-child {
      margin-right: 0; }

/* bubble animation */
@-webkit-keyframes growCircleRadio {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25); } }

@-moz-keyframes growCircleRadio {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -moz-transform: scale(1.25); } }

@keyframes growCircleRadio {
  0%, 100% {
    transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    transform: scale(1.25); } }

.c-content-toggler {
  padding: 5px 7px;
  display: none;
  outline: none;
  background: #f8f9fa; }
  .c-content-toggler > .c-line {
    width: 15px;
    display: block;
    height: 2px;
    padding: 0px;
    margin: 3px 0;
    background-color: #9facba; }
    .c-content-toggler > .c-line:first-child {
      margin-top: 3px; }
  .c-content-toggler:active,
  .c-content-toggler:focus,
  .c-content-toggler:hover {
    background: #e9ecef; }
    .c-content-toggler:active > .c-line,
    .c-content-toggler:focus > .c-line,
    .c-content-toggler:hover > .c-line {
      background-color: #90a0b0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-toggler {
    display: inline-block; } }

.c-content-title-1 > .c-line-center {
  width: 30px;
  height: 3px;
  background-color: #32c5d2;
  margin: 0 auto 30px auto; }

.c-content-title-1 > .c-line-right,
.c-content-title-1 > .c-line-left {
  width: 30px;
  height: 3px;
  background-color: #32c5d2;
  margin: 0 0 30px 0; }

.c-content-title-1 > .c-line-right {
  margin: 0 0 30px auto; }

.c-content-title-1 > h3 {
  font-size: 28px;
  color: #3f444a;
  font-weight: 500;
  margin: 0 0 30px 0; }
  .c-content-title-1 > h3 > i {
    font-size: 22px;
    color: #88909a; }
  .c-content-title-1 > h3.c-font-uppercase {
    font-size: 30px; }

.c-content-title-1 > p {
  font-size: 17px;
  font-weight: 400;
  color: #5c6873;
  margin: 30px 0; }
  .c-content-title-1 > p.c-font-uppercase {
    font-size: 15px; }

.c-content-title-1 > hr {
  margin-bottom: 30px; }

.c-content-title-1.c-title-md > .c-line-left,
.c-content-title-1.c-title-md > .c-line-center {
  margin-bottom: 20px; }

.c-content-title-1.c-title-md > h3 {
  font-size: 22px;
  margin-bottom: 22px; }
  .c-content-title-1.c-title-md > h3 > i {
    font-size: 18px;
    color: #88909a; }
  .c-content-title-1.c-title-md > h3.c-font-uppercase {
    font-size: 20px; }

.c-content-title-1.c-title-md > p {
  font-size: 17px;
  margin: 20px 0; }
  .c-content-title-1.c-title-md > p.c-font-uppercase {
    font-size: 14px; }

.c-content-title-1.c-title-sm > .c-line-left,
.c-content-title-1.c-title-sm > .c-line-center {
  margin-bottom: 15px; }

.c-content-title-1.c-title-sm > h3 {
  font-size: 18px;
  margin-bottom: 15px; }
  .c-content-title-1.c-title-sm > h3 > i {
    font-size: 16px;
    color: #88909a; }
  .c-content-title-1.c-title-sm > h3.c-font-uppercase {
    font-size: 14px; }

.c-content-title-1.c-title-sm > p {
  font-size: 16px;
  margin: 15px 0; }
  .c-content-title-1.c-title-sm > p.c-font-uppercase {
    font-size: 14px; }

.c-content-title-2 {
  margin-bottom: 20px; }
  .c-content-title-2 > .c-line {
    height: 2px;
    background-color: #88909a;
    opacity: 0.8;
    filter: alphaopacity=80; }
    .c-content-title-2 > .c-line.c-line-short {
      width: 180px;
      margin: 0 auto; }
    .c-content-title-2 > .c-line.c-dot {
      height: 1px;
      position: relative; }
      .c-content-title-2 > .c-line.c-dot:after {
        background-color: #88909a;
        left: 50%;
        display: inline-block;
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        top: -4px;
        margin-left: -5px;
        border-radius: 10px !important; }
      .c-content-title-2 > .c-line.c-dot.c-dot-left:after {
        left: 0; }
      .c-content-title-2 > .c-line.c-dot.c-dot-right:after {
        left: auto;
        right: 0; }
      .c-content-title-2 > .c-line.c-dot.c-dot-square:after {
        border-radius: 0 !important; }
  .c-content-title-2 > h3 {
    font-size: 30px;
    color: #3f444a;
    font-weight: 500;
    margin: 0 0 30px 0; }
    .c-content-title-2 > h3 > i {
      font-size: 22px;
      color: #88909a; }
  .c-content-title-2 > p {
    font-size: 17px;
    font-weight: 400;
    color: #5c6873;
    margin: 30px 0; }
    .c-content-title-2 > p.c-font-uppercase {
      font-size: 15px; }
  .c-content-title-2 > hr {
    margin-bottom: 30px; }
  .c-content-title-2.c-title-md > .c-line-left,
  .c-content-title-2.c-title-md > .c-line-center {
    margin-bottom: 20px; }
  .c-content-title-2.c-title-md > h3 {
    font-size: 22px;
    margin-bottom: 22px; }
    .c-content-title-2.c-title-md > h3 > i {
      font-size: 18px;
      color: #88909a; }
    .c-content-title-2.c-title-md > h3.c-font-uppercase {
      font-size: 20px; }
  .c-content-title-2.c-title-md > p {
    font-size: 17px;
    margin: 20px 0; }
    .c-content-title-2.c-title-md > p.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-2.c-title-sm > .c-line-left,
  .c-content-title-2.c-title-sm > .c-line-center {
    margin-bottom: 15px; }
  .c-content-title-2.c-title-sm > h3 {
    font-size: 18px;
    margin-bottom: 15px; }
    .c-content-title-2.c-title-sm > h3 > i {
      font-size: px;
      color: #88909a; }
    .c-content-title-2.c-title-sm > h3.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-2.c-title-sm > p {
    font-size: 16px;
    margin: 15px 0; }
    .c-content-title-2.c-title-sm > p.c-font-uppercase {
      font-size: 14px; }

.c-content-title-3 {
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: 4px solid #88909a; }
  .c-content-title-3 > h3 {
    font-size: 28px;
    color: #3f444a;
    font-weight: 500;
    margin: 0 0 15px 0; }
    .c-content-title-3 > h3 > i {
      font-size: 22px;
      color: #88909a; }
    .c-content-title-3 > h3.c-font-uppercase {
      font-size: 30px; }
  .c-content-title-3 > p {
    font-size: 17px;
    font-weight: 400;
    color: #5c6873;
    margin: 15px 0 0 0; }
    .c-content-title-3 > p.c-font-uppercase {
      font-size: 15px; }
  .c-content-title-3.c-right {
    padding-left: 0px;
    padding-right: 20px;
    border-left: 0;
    border-right: 4px solid #88909a; }
    .c-content-title-3.c-right > h3 {
      text-align: right; }
    .c-content-title-3.c-right > p {
      text-align: right; }
  .c-content-title-3.c-title-md > .c-line-left,
  .c-content-title-3.c-title-md > .c-line-center {
    margin-bottom: 15px; }
  .c-content-title-3.c-title-md > h3 {
    font-size: 22px;
    margin-bottom: 22px; }
    .c-content-title-3.c-title-md > h3 > i {
      font-size: 18px;
      color: #88909a; }
    .c-content-title-3.c-title-md > h3.c-font-uppercase {
      font-size: 20px; }
  .c-content-title-3.c-title-md > p {
    font-size: 17px;
    margin: 15px 0; }
    .c-content-title-3.c-title-md > p.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-3.c-title-sm > .c-line-left,
  .c-content-title-3.c-title-sm > .c-line-center {
    margin-bottom: 10px; }
  .c-content-title-3.c-title-sm > h3 {
    font-size: 18px;
    margin-bottom: 10px; }
    .c-content-title-3.c-title-sm > h3 > i {
      font-size: px;
      color: #88909a; }
    .c-content-title-3.c-title-sm > h3.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-3.c-title-sm > p {
    font-size: 16px;
    margin: 10px 0; }
    .c-content-title-3.c-title-sm > p.c-font-uppercase {
      font-size: 14px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-title-3 {
    margin-bottom: 10px;
    padding-left: 20px;
    border-left: 4px solid #88909a; }
    .c-content-title-3 > h3 {
      font-size: 22px;
      margin-bottom: 10px; }
      .c-content-title-3 > h3 > i {
        font-size: 20px; }
      .c-content-title-3 > h3.c-font-uppercase {
        font-size: 20px; }
    .c-content-title-3 > p {
      font-size: 17px;
      font-weight: 400;
      color: #5c6873;
      margin: 15px 0 0 0; }
      .c-content-title-3 > p.c-font-uppercase {
        font-size: 15px; }
    .c-content-title-3.c-right {
      padding-left: 0px;
      padding-right: 20px;
      border-left: 0;
      border-right: 4px solid #88909a; }
      .c-content-title-3.c-right > h3 {
        text-align: right; }
      .c-content-title-3.c-right > p {
        text-align: right; }
    .c-content-title-3.c-title-md > .c-line-left,
    .c-content-title-3.c-title-md > .c-line-center {
      margin-bottom: 15px; }
    .c-content-title-3.c-title-md > h3 {
      font-size: 22px;
      margin-bottom: 22px; }
      .c-content-title-3.c-title-md > h3 > i {
        font-size: 18px;
        color: #88909a; }
      .c-content-title-3.c-title-md > h3.c-font-uppercase {
        font-size: 20px; }
    .c-content-title-3.c-title-md > p {
      font-size: 17px;
      margin: 15px 0; }
      .c-content-title-3.c-title-md > p.c-font-uppercase {
        font-size: 14px; }
    .c-content-title-3.c-title-sm > .c-line-left,
    .c-content-title-3.c-title-sm > .c-line-center {
      margin-bottom: 10px; }
    .c-content-title-3.c-title-sm > h3 {
      font-size: 18px;
      margin-bottom: 10px; }
      .c-content-title-3.c-title-sm > h3 > i {
        font-size: px;
        color: #88909a; }
      .c-content-title-3.c-title-sm > h3.c-font-uppercase {
        font-size: 14px; }
    .c-content-title-3.c-title-sm > p {
      font-size: 16px;
      margin: 10px 0; }
      .c-content-title-3.c-title-sm > p.c-font-uppercase {
        font-size: 14px; } }

.c-content-title-4 {
  margin-bottom: 50px; }
  .c-content-title-4 > h3 {
    font-size: 28px;
    color: #3f444a;
    font-weight: 500;
    margin: 0 0 15px 0; }
    .c-content-title-4 > h3 > i {
      font-size: 22px;
      color: #88909a; }
    .c-content-title-4 > h3.c-font-uppercase {
      font-size: 30px; }
    .c-content-title-4 > h3.c-line-strike {
      position: relative;
      z-index: 1; }
      .c-content-title-4 > h3.c-line-strike:before {
        overflow: hidden;
        border-top: 1px solid #d0d7de;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1; }
      .c-content-title-4 > h3.c-line-strike span {
        padding: 0 20px; }
  .c-content-title-4 > p {
    font-size: 17px;
    font-weight: 400;
    color: #5c6873;
    margin: 15px 0 0 0; }
    .c-content-title-4 > p.c-font-uppercase {
      font-size: 15px; }
  .c-content-title-4.c-right > h3 {
    text-align: right; }
  .c-content-title-4.c-right > p {
    text-align: right; }
  .c-content-title-4.c-title-md > .c-line-left,
  .c-content-title-4.c-title-md > .c-line-center {
    margin-bottom: 15px; }
  .c-content-title-4.c-title-md > h3 {
    font-size: 22px;
    margin-bottom: 22px; }
    .c-content-title-4.c-title-md > h3 > i {
      font-size: 18px;
      color: #88909a; }
    .c-content-title-4.c-title-md > h3.c-font-uppercase {
      font-size: 20px; }
  .c-content-title-4.c-title-md > p {
    font-size: 17px;
    margin: 15px 0; }
    .c-content-title-4.c-title-md > p.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-4.c-title-sm > .c-line-left,
  .c-content-title-4.c-title-sm > .c-line-center {
    margin-bottom: 10px; }
  .c-content-title-4.c-title-sm > h3 {
    font-size: 18px;
    margin-bottom: 10px; }
    .c-content-title-4.c-title-sm > h3 > i {
      font-size: px;
      color: #88909a; }
    .c-content-title-4.c-title-sm > h3.c-font-uppercase {
      font-size: 14px; }
  .c-content-title-4.c-title-sm > p {
    font-size: 16px;
    margin: 10px 0; }
    .c-content-title-4.c-title-sm > p.c-font-uppercase {
      font-size: 14px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-title-4 {
    margin-bottom: 10px; }
    .c-content-title-4 > h3 {
      font-size: 22px;
      margin-bottom: 10px; }
      .c-content-title-4 > h3 > i {
        font-size: 20px; }
      .c-content-title-4 > h3.c-font-uppercase {
        font-size: 20px; }
    .c-content-title-4 > p {
      font-size: 17px;
      font-weight: 400;
      color: #5c6873;
      margin: 15px 0 0 0; }
      .c-content-title-4 > p.c-font-uppercase {
        font-size: 15px; }
    .c-content-title-4.c-right > h3 {
      text-align: right; }
    .c-content-title-4.c-right > p {
      text-align: right; }
    .c-content-title-4.c-title-md > .c-line-left,
    .c-content-title-4.c-title-md > .c-line-center {
      margin-bottom: 15px; }
    .c-content-title-4.c-title-md > h3 {
      font-size: 22px;
      margin-bottom: 22px; }
      .c-content-title-4.c-title-md > h3 > i {
        font-size: 18px;
        color: #88909a; }
      .c-content-title-4.c-title-md > h3.c-font-uppercase {
        font-size: 20px; }
    .c-content-title-4.c-title-md > p {
      font-size: 17px;
      margin: 15px 0; }
      .c-content-title-4.c-title-md > p.c-font-uppercase {
        font-size: 14px; }
    .c-content-title-4.c-title-sm > .c-line-left,
    .c-content-title-4.c-title-sm > .c-line-center {
      margin-bottom: 10px; }
    .c-content-title-4.c-title-sm > h3 {
      font-size: 18px;
      margin-bottom: 10px; }
      .c-content-title-4.c-title-sm > h3 > i {
        font-size: px;
        color: #88909a; }
      .c-content-title-4.c-title-sm > h3.c-font-uppercase {
        font-size: 14px; }
    .c-content-title-4.c-title-sm > p {
      font-size: 16px;
      margin: 10px 0; }
      .c-content-title-4.c-title-sm > p.c-font-uppercase {
        font-size: 14px; } }

.c-content-divider {
  display: block;
  height: 2px;
  background: #3f444a;
  margin: 20px 0;
  position: relative; }
  .c-content-divider > i {
    color: #3f444a;
    left: 50%;
    display: inline-block;
    position: absolute;
    width: 34px;
    height: 34px;
    text-align: center;
    top: -16px;
    font-size: 18px;
    vertical-align: middle; }
    .c-content-divider > i.c-rounded {
      border-radius: 100%; }
    .c-content-divider > i.c-square {
      border-radius: 34px; }
    .c-content-divider > i.icon-dot {
      padding: 0;
      width: 14px;
      height: 14px;
      top: -6px;
      background: #3f444a;
      border-radius: 14px; }
      .c-content-divider > i.icon-dot.c-square {
        border-radius: 0; }
  .c-content-divider > span {
    display: block;
    background: #fff;
    padding: 5px 7px;
    width: auto;
    position: relative;
    top: -18px;
    margin: 0 auto; }
  .c-content-divider.c-left {
    margin-left: 25px; }
    .c-content-divider.c-left > i {
      left: -25px; }
  .c-content-divider.c-right {
    margin-right: 25px; }
    .c-content-divider.c-right > i {
      left: auto;
      right: -25px; }
  .c-content-divider.c-icon-bg > i {
    padding-top: 8px;
    width: 35px;
    height: 35px;
    top: -17px;
    border-radius: 35px;
    background: #3f444a; }
  .c-content-divider.c-icon-bg.c-left {
    margin-left: 45px; }
    .c-content-divider.c-icon-bg.c-left > i {
      left: -45px; }
  .c-content-divider.c-icon-bg.c-right {
    margin-right: 45px; }
    .c-content-divider.c-icon-bg.c-right > i {
      left: auto;
      right: -45px; }
  .c-content-divider.c-divider-sm {
    height: 1px; }
    .c-content-divider.c-divider-sm > .icon-dot {
      width: 10px;
      height: 10px;
      top: -4px;
      background: #3f444a;
      border-radius: 10px; }
      .c-content-divider.c-divider-sm > .icon-dot.c-square {
        border-radius: 0; }

.c-content-label {
  padding: 4px 10px 2px 10px;
  color: #ffffff;
  background-color: #32c5d2;
  font-weight: 400;
  font-size: 16px;
  display: inline-block; }
  .c-content-label.c-font-uppercase {
    font-size: 14px; }
  .c-content-label.c-label-sm {
    font-size: 13px;
    padding: 6px 12px 4px 12px; }
    .c-content-label.c-label-sm.c-font-uppercase {
      font-size: 14px; }
  .c-content-label.c-label-lg {
    font-size: 18px;
    padding: 6px 14px 4px 14px; }
    .c-content-label.c-label-lg.c-font-uppercase {
      font-size: 20px; }

.c-content-testimonials-1 .c-testimonial {
  max-width: 600px;
  margin: 0 auto;
  text-align: center; }
  .c-content-testimonials-1 .c-testimonial > p {
    margin: 0 0 20px 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 300;
    text-align: center; }
  .c-content-testimonials-1 .c-testimonial > h3 {
    margin: 0 0 20px 0;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px; }
    .c-content-testimonials-1 .c-testimonial > h3 > span {
      font-weight: 600; }

.c-content-testimonials-1.c-option-2 .c-testimonial > p {
  color: #3f444a; }

.c-content-testimonials-1.c-option-2 .c-testimonial > h3 {
  color: #5c6873; }

.c-content-testimonials-1 .owl-carousel:not(.c-theme) .owl-controls .owl-pagination .owl-page span {
  background: #e1e1e1; }

.c-content-testimonials-1 .owl-carousel:not(.c-theme) .owl-controls .owl-pagination .owl-page.active span {
  background: #fff; }

.c-content-testimonial-2 {
  background: #fff;
  padding: 50px 30px 50px 30px; }
  .c-content-testimonial-2 .c-testimonial {
    font-size: 30px;
    line-height: 35px;
    font-weight: 300;
    color: #3f444a;
    margin: 30px 0 40px 0; }
  .c-content-testimonial-2 .c-author {
    bottom: 0;
    left: 0; }
    .c-content-testimonial-2 .c-author > .c-name {
      display: inline-block;
      font-size: 16px;
      color: #5c6873;
      font-weight: 400;
      margin: 6px 0 0 0; }
    .c-content-testimonial-2 .c-author > .c-portrait {
      background-size: cover;
      width: 55px;
      height: 55px;
      border-radius: 58px;
      float: left;
      margin-right: 20px; }
    .c-content-testimonial-2 .c-author > .c-position {
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 0 0; }

.c-content-testimonial-2-slider .c-title {
  position: absolute;
  top: 30px;
  left: 45px;
  z-index: 10;
  font-size: 14px;
  padding: 4px 10px 2px 10px;
  font-weight: 400;
  color: #ffffff; }

.c-content-testimonial-2-slider .owl-controls {
  margin: 0;
  text-align: right;
  position: absolute;
  top: 30px;
  right: 30px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-testimonial-2 {
    margin-bottom: 20px; } }

.c-content-testimonial-3 {
  padding-bottom: 20px; }
  .c-content-testimonial-3 > .c-content {
    padding: 29px;
    position: relative; }
    .c-content-testimonial-3 > .c-content:after,
    .c-content-testimonial-3 > .c-content:before {
      top: 100%;
      left: 60px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .c-content-testimonial-3 > .c-content:before {
      border-width: 17px;
      margin-left: -17px; }
    .c-content-testimonial-3 > .c-content:after {
      border-width: 15px;
      margin-left: -15px; }
    .c-content-testimonial-3 > .c-content > i {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #ffffff; }
  .c-content-testimonial-3 > .c-person {
    padding: 25px 0 0 25px;
    position: relative; }
    .c-content-testimonial-3 > .c-person > img {
      float: left;
      margin: 0 10px 0 0;
      border-radius: 50%;
      width: 70px; }
    .c-content-testimonial-3 > .c-person > .c-person-detail {
      padding: 15px 0 12px 0; }
      .c-content-testimonial-3 > .c-person > .c-person-detail > .c-name {
        margin: 0; }
      .c-content-testimonial-3 > .c-person > .c-person-detail > .c-position {
        margin: 3px 0 0 0; }
  .c-content-testimonial-3.c-option-default > .c-content {
    color: #5c6873;
    background: #ffffff; }
    .c-content-testimonial-3.c-option-default > .c-content:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff; }
  .c-content-testimonial-3.c-option-default > .c-person > .c-person-detail > .c-name {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-default > .c-person > .c-person-detail > .c-position {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-light > .c-content {
    color: #5c6873;
    background: #f4f5f7; }
    .c-content-testimonial-3.c-option-light > .c-content:after {
      border-color: rgba(244, 245, 247, 0);
      border-top-color: #f4f5f7; }
  .c-content-testimonial-3.c-option-light > .c-person > .c-person-detail > .c-name {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-light > .c-person > .c-person-detail > .c-position {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-dark > .c-content {
    color: #5c6873;
    background: #ffffff; }
    .c-content-testimonial-3.c-option-dark > .c-content:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff; }
  .c-content-testimonial-3.c-option-dark > .c-person > .c-person-detail > .c-name {
    color: #ffffff; }
  .c-content-testimonial-3.c-option-dark > .c-person > .c-person-detail > .c-position {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-light-transparent > .c-content {
    color: #5c6873;
    background: rgba(255, 255, 255, 0.5); }
    .c-content-testimonial-3.c-option-light-transparent > .c-content:after {
      border-top-color: rgba(255, 255, 255, 0.5); }
  .c-content-testimonial-3.c-option-light-transparent > .c-person > .c-person-detail > .c-name {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-light-transparent > .c-person > .c-person-detail > .c-position {
    color: #5c6873; }
  .c-content-testimonial-3.c-option-dark-transparent > .c-content {
    color: #e4e7e9;
    background: rgba(0, 0, 0, 0.5); }
    .c-content-testimonial-3.c-option-dark-transparent > .c-content:after {
      border-top-color: rgba(0, 0, 0, 0.5); }
  .c-content-testimonial-3.c-option-dark-transparent > .c-person > .c-person-detail > .c-name {
    color: #ffffff; }
  .c-content-testimonial-3.c-option-dark-transparent > .c-person > .c-person-detail > .c-position {
    color: #5c6873; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-testimonial-3 {
    margin: 0;
    width: 100%; }
    .c-content-testimonial-3 > .c-content {
      margin-left: 0; }
    .c-content-testimonial-3 > .c-person {
      float: left; }
      .c-content-testimonial-3 > .c-person > img {
        float: left;
        border-radius: 50%;
        width: 80px; }
      .c-content-testimonial-3 > .c-person > .c-person-detail {
        margin-top: 5px;
        float: left; } }

.c-content-testimonials-4 .owl-theme .owl-controls .owl-nav div {
  background: url(../img/content/line-icons/grey1.png);
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover; }
  .c-content-testimonials-4 .owl-theme .owl-controls .owl-nav div.owl-prev {
    background-position: 0px 3600%;
    left: -120px;
    position: absolute;
    opacity: 0.5; }
    .c-content-testimonials-4 .owl-theme .owl-controls .owl-nav div.owl-prev:hover {
      opacity: 1; }
  .c-content-testimonials-4 .owl-theme .owl-controls .owl-nav div.owl-next {
    background-position: 0px 3700%;
    right: -120px;
    position: absolute;
    opacity: 0.5; }
    .c-content-testimonials-4 .owl-theme .owl-controls .owl-nav div.owl-next:hover {
      opacity: 1; }

.c-content-testimonials-4.c-icon-white .owl-theme .owl-controls .owl-nav div {
  background-image: url(../img/content/line-icons/white.png); }

.c-content-testimonials-4 .owl-nav {
  position: absolute;
  top: 115px;
  left: 50%; }

.c-content-testimonials-4 .c-content {
  text-align: center;
  padding: 0 150px; }

.c-content-testimonials-4 .c-person {
  padding-top: 27px; }
  .c-content-testimonials-4 .c-person > .c-person-detail {
    text-align: center;
    padding-top: 23px; }
  .c-content-testimonials-4 .c-person > img {
    border-radius: 50%;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    margin-left: auto;
    margin-right: auto; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-testimonials-4 .c-content {
    padding: 0 100px; }
  .c-content-testimonials-4 .owl-nav {
    top: 135px; } }

@media (max-width: 767px) {
  /* 766px */
  .c-content-testimonials-4 .c-content {
    padding: 0; }
  .c-content-testimonials-4 .owl-nav {
    visibility: hidden; } }

.c-content-bar-1 {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center; }
  .c-content-bar-1.c-align-left {
    text-align: left; }
  .c-content-bar-1 > h3 {
    font-size: 30px;
    color: #3f444a;
    font-weight: 500;
    margin-bottom: 30px; }
  .c-content-bar-1 > p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #5c6873; }
  .c-content-bar-1.c-bordered {
    border: 3px solid transparent;
    padding: 20px; }

.c-content-bar-2 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-bar-2 .c-content-title-1 > h3 {
    color: #3f444a;
    margin-bottom: 20px; }
  .c-content-bar-2 .c-content-title-1 > p {
    color: #5c6873;
    font-size: 16px;
    margin: 0 0 20px 0;
    line-height: 25px; }
  .c-content-bar-2 .c-content-v-center .c-body {
    padding: 30px; }
    .c-content-bar-2 .c-content-v-center .c-body h3 {
      text-transform: uppercase;
      text-align: center;
      line-height: 33px;
      font-size: 21px;
      font-weight: 500;
      margin: 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-bar-2 .c-content-v-center {
    margin-top: 20px; }
    .c-content-bar-2 .c-content-v-center .c-body {
      padding: 20px; } }

.c-content-bar-3 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-bar-3 .c-content-title-1 > h3 {
    padding: 0;
    color: #ffffff;
    font-weight: 500;
    margin: 0 0 25px 0; }
  .c-content-bar-3 .c-content-title-1 > p {
    padding: 0;
    color: #5c6873;
    text-transform: uppercase;
    margin: 0;
    font-size: 16px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-bar-3 > .c-content-title-1 {
    margin-bottom: 40px; } }

@media (max-width: 767px) {
  /* <= 767px */
  .c-content-bar-3 {
    text-align: center; }
    .c-content-bar-3 > .c-content-title-1 {
      text-align: center; } }

.c-content-bar-4 {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center; }
  .c-content-bar-4 > h3 {
    display: inline-block;
    color: #ffffff;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    margin: 100px auto 40px auto;
    padding: 40px;
    border: 2px solid rgba(255, 255, 255, 0.15); }
  .c-content-bar-4.c-opt-2 > h3 {
    margin-top: 30px;
    margin-bottom: 0; }
  .c-content-bar-4.c-opt-2 > .row > div > p {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 25px;
    padding: 40px; }

.c-content-bar-5 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-bar-5 > h3 {
    font-size: 30px;
    color: #3f444a;
    font-weight: 500;
    margin-bottom: 30px; }
  .c-content-bar-5 > p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #5c6873; }
  .c-content-bar-5.c-bordered {
    border: 3px solid transparent;
    padding: 40px; }

.c-content-media-1 {
  padding: 30px;
  background-color: #ffffff;
  margin: 0;
  position: relative; }
  .c-content-media-1.c-bordered {
    border: 1px solid #f0f1f2; }
  .c-content-media-1 .c-content-label {
    padding: 4px 10px 2px 10px; }
  .c-content-media-1 .c-title {
    display: block;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    color: #3f444a;
    margin: 30px 0 20px 0; }
  .c-content-media-1 p {
    font-size: 19px;
    color: #5c6873;
    font-weight: 300;
    line-height: 24px; }
  .c-content-media-1 .c-date {
    margin-bottom: 10px;
    margin-top: 30px;
    display: inline-block;
    color: #5c6873;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500; }
  .c-content-media-1 .c-author {
    overflow: auto;
    margin-bottom: 10px;
    margin-top: 30px; }
    .c-content-media-1 .c-author > .c-portrait {
      position: relative;
      background-size: cover;
      width: 55px;
      height: 55px;
      border-radius: 58px;
      float: left;
      margin-right: 20px; }
    .c-content-media-1 .c-author > .c-name {
      float: left;
      font-size: 16px;
      font-weight: 400;
      color: #5c6873;
      margin: 15px 0 0 0; }
    .c-content-media-1 .c-author > p {
      font-size: 12px;
      color: #32c5d2;
      margin: 0; }
  .c-content-media-1 .c-action {
    margin-top: 20px;
    text-align: right; }

.c-content-media-1-slider > .c-content-label {
  padding: 4px 10px 2px 10px;
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 10; }

.c-content-media-1-slider .owl-controls {
  margin: 0;
  text-align: right;
  position: absolute;
  top: 30px;
  right: 30px; }

.c-content-media-1-slider.c-pagination-bottom .owl-controls {
  top: auto;
  bottom: 20px;
  right: 30px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-media-1 {
    margin-bottom: 20px; }
  .c-content-media-1-slider {
    margin-bottom: 20px; } }

.c-content-media-2 {
  padding: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; }
  .c-content-media-2 .c-panel {
    position: absolute;
    bottom: 10px;
    right: 30px; }
    .c-content-media-2 .c-panel > .c-fav {
      font-size: 20px;
      color: #ffffff; }
      .c-content-media-2 .c-panel > .c-fav > i {
        position: relative;
        top: 2px;
        margin-right: 10px;
        display: inline-block; }
        .c-content-media-2 .c-panel > .c-fav > i:hover {
          color: #32c5d2;
          cursor: pointer; }
      .c-content-media-2 .c-panel > .c-fav > p {
        display: inline-block; }

.c-content-media-2-slider .c-content-label {
  position: absolute;
  top: 25px;
  left: 40px;
  z-index: 10; }

.c-content-media-2-slider .owl-controls {
  margin: 0;
  text-align: right;
  position: absolute;
  top: 30px;
  right: 30px; }

.c-content-media-2-slider.c-pagination-bottom .owl-controls {
  top: auto;
  bottom: 20px;
  right: 30px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-media-2 {
    margin-bottom: 20px; }
  .c-content-media-2-slider {
    margin-bottom: 20px; } }

.c-content-pricing-1 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-pricing-1 > .c-tile-container {
    width: 100%;
    position: relative;
    text-align: center; }
    .c-content-pricing-1 > .c-tile-container > .c-tile {
      background: #ffffff;
      width: 31.9%;
      height: 100%;
      float: left;
      padding: 90px 0;
      margin: 0 20px; }
      .c-content-pricing-1 > .c-tile-container > .c-tile.c-bordered {
        border: 3px solid transparent; }
      .c-content-pricing-1 > .c-tile-container > .c-tile.c-shadow {
        box-shadow: 0px 3px 18px -5px rgba(0, 0, 0, 0.5); }
      .c-content-pricing-1 > .c-tile-container > .c-tile > .c-label {
        color: #fff;
        padding: 4px 10px 2px 10px;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 0 30px 0; }
      .c-content-pricing-1 > .c-tile-container > .c-tile > p {
        font-size: 15px;
        font-weight: 400; }
      .c-content-pricing-1 > .c-tile-container > .c-tile > .btn {
        margin-top: 40px; }
      .c-content-pricing-1 > .c-tile-container > .c-tile > .c-price {
        color: #3f444a;
        font-size: 60px;
        font-weight: bold; }
        .c-content-pricing-1 > .c-tile-container > .c-tile > .c-price > sup {
          font-size: 28px;
          font-weight: normal;
          vertical-align: super; }
    .c-content-pricing-1 > .c-tile-container > .c-tile-small {
      margin: 40px 0;
      padding: 50px 0; }
  .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile > .c-label {
    color: #ffffff; }
  .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile.c-highlight {
    background-color: #32c5d2; }
    .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile.c-highlight > .c-label {
      background-color: #ffffff;
      color: #32c5d2; }
    .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile.c-highlight > p {
      color: #ffffff; }
    .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile.c-highlight > .c-price {
      color: #3f444a; }
      .c-content-pricing-1.c-option-2 > .c-tile-container > .c-tile.c-highlight > .c-price > sup {
        color: #3f444a; }

@media (max-width: 991px) {
  /* <= 991px */
  .c-content-pricing-1 > .c-title > h3 {
    margin: 20px 0 50px 0; }
  .c-content-pricing-1 > .c-tile-container > .c-tile {
    width: 100%;
    margin: 20px 0; } }

.c-content-pricing-1 {
  padding: 0;
  margin: 0; }
  .c-content-pricing-1 .c-content.c-padding-adjustment {
    margin-right: -30px; }
  .c-content-pricing-1 .c-content > .c-row {
    padding: 30px;
    text-align: center;
    border-top: 1px solid;
    font-weight: 500; }
    .c-content-pricing-1 .c-content > .c-row > .c-sub-title {
      display: none; }
    .c-content-pricing-1 .c-content > .c-row:first-child, .c-content-pricing-1 .c-content > .c-row:last-child {
      border-top: none; }
    .c-content-pricing-1 .c-content > .c-row.c-type {
      padding: 30px;
      font-weight: 500; }
    .c-content-pricing-1 .c-content > .c-row.c-title {
      text-align: left;
      padding-left: 0px;
      font-weight: normal; }
    .c-content-pricing-1 .c-content > .c-row.c-align-left {
      padding-left: 0px;
      text-align: left; }
    .c-content-pricing-1 .c-content > .c-row.c-purchase > .c-btn-purchase {
      padding: 12px 30px 10px 30px; }
  .c-content-pricing-1.c-opt-1 .c-content.c-column-even {
    background: #fafbfb; }
  .c-content-pricing-1.c-opt-1 .c-content > .c-row {
    color: #5f6f77;
    border-top-color: #f4f4f4; }
    .c-content-pricing-1.c-opt-1 .c-content > .c-row.c-type {
      color: #5f6f77; }
    .c-content-pricing-1.c-opt-1 .c-content > .c-row.c-title {
      color: #84969f; }
    .c-content-pricing-1.c-opt-1 .c-content > .c-row.c-dollar {
      color: #b9c4ca; }
    .c-content-pricing-1.c-opt-1 .c-content > .c-row > .fa.fa-check {
      color: #75c199; }
    .c-content-pricing-1.c-opt-1 .c-content > .c-row > .fa.fa-times {
      color: #ff6b57; }
  .c-content-pricing-1.c-opt-2 .c-content.c-column-even {
    background: rgba(255, 255, 255, 0.02); }
  .c-content-pricing-1.c-opt-2 .c-content > .c-row {
    color: #969ab9;
    border-top-color: rgba(255, 255, 255, 0.04); }
    .c-content-pricing-1.c-opt-2 .c-content > .c-row.c-type {
      color: #fbfefe; }
    .c-content-pricing-1.c-opt-2 .c-content > .c-row.c-title {
      color: #969ab9; }
    .c-content-pricing-1.c-opt-2 .c-content > .c-row.c-dollar {
      color: #5b5f84; }
    .c-content-pricing-1.c-opt-2 .c-content > .c-row > .fa.fa-check {
      color: #75c199; }
    .c-content-pricing-1.c-opt-2 .c-content > .c-row > .fa.fa-times {
      color: #ff6b57; }

.c-title-pricing-1 {
  margin-top: 40px; }
  .c-title-pricing-1 > h4 {
    float: left;
    margin-top: 0px;
    margin-bottom: 70px; }
  .c-title-pricing-1 > .c-try {
    position: absolute;
    top: 30px;
    right: 15px;
    float: left; }
    .c-title-pricing-1 > .c-try > .c-btn-try {
      padding: 12px 30px 10px 30px; }
  .c-title-pricing-1.c-opt-title-1 > h4 {
    color: #455055; }
  .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try {
    background: #ff6b57;
    color: #ffffff; }
    .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try:hover, .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try:focus, .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try:active, .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try.active, .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try .open > .dropdown-toggle.c-btn-try {
      background: #e16450; }
    .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try:active, .c-title-pricing-1.c-opt-title-1 > .c-try > .c-btn-try.active {
      background: #c3604b;
      box-shadow: none; }
  .c-title-pricing-1.c-opt-title-2 > h4 {
    color: #fbfefe; }
    .c-title-pricing-1.c-opt-title-2 > h4 > span {
      color: #41c6ec; }
  .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try {
    background: #ff6b57;
    color: #ffffff; }
    .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try:hover, .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try:focus, .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try:active, .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try.active, .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try .open > .dropdown-toggle.c-btn-try {
      background: #e16450; }
    .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try:active, .c-title-pricing-1.c-opt-title-2 > .c-try > .c-btn-try.active {
      background: #c3604b;
      box-shadow: none; }

@media (max-width: 991px) {
  /* 991px */
  .c-title-pricing-1 {
    margin-top: 40px; }
    .c-title-pricing-1 > h4 {
      float: left;
      margin-top: 0px;
      margin-bottom: 70px; }
    .c-title-pricing-1 > .c-try {
      position: absolute;
      top: -7px;
      right: 15px;
      float: left; }
  .c-content-pricing-1 > .c-sm-hidden {
    display: none; }
  .c-content-pricing-1 .c-content > .c-row {
    padding: 30px;
    text-align: center; }
    .c-content-pricing-1 .c-content > .c-row > .c-sub-title {
      display: inline; }
    .c-content-pricing-1 .c-content > .c-row.c-align-left {
      padding-left: 30px;
      text-align: center; }
    .c-content-pricing-1 .c-content > .c-row.c-title {
      text-align: center;
      padding-left: 30px; }
    .c-content-pricing-1 .c-content > .c-row.c-empty {
      display: none; } }

@media (max-width: 767px) {
  /* 766px */
  .c-title-pricing-1 {
    text-align: center;
    padding: 0px; }
    .c-title-pricing-1 > h4 {
      width: 100%;
      clear: both;
      margin: 0px auto 30px; }
    .c-title-pricing-1 > .c-try {
      position: static;
      clear: both;
      width: 100%;
      margin-bottom: 50px; }
      .c-title-pricing-1 > .c-try > .c-btn-try {
        margin-left: auto;
        margin-right: auto; }
  .c-content-pricing-1 .c-content {
    margin-bottom: 80px; }
    .c-content-pricing-1 .c-content.c-padding-adjustment {
      margin-left: 0px;
      margin-right: 0px; } }

.c-content-pricing-2 {
  text-align: center;
  padding: 50px 0;
  margin-bottom: 20px; }
  .c-content-pricing-2 > .c-icon {
    margin-bottom: 43px; }
  .c-content-pricing-2 > .c-title {
    margin-bottom: 5px; }
  .c-content-pricing-2 > .c-price {
    margin-bottom: 33px; }
  .c-content-pricing-2 > .c-items > ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 38px; }
    .c-content-pricing-2 > .c-items > ul > li {
      padding: 4px 0;
      font-size: 16px; }
  .c-content-pricing-2.c-padding-first {
    margin-right: -15px; }
  .c-content-pricing-2.c-featured-no-padding, .c-content-pricing-2.c-featured-extra-padding, .c-content-pricing-2.c-featured-overlap {
    margin-top: -30px;
    padding: 80px 0;
    border-radius: 3px;
    position: relative;
    z-index: 1; }
  .c-content-pricing-2.c-featured-no-padding {
    margin-left: -15px;
    margin-right: -15px; }
  .c-content-pricing-2.c-featured-extra-padding {
    margin-left: -13px;
    margin-right: -13px; }
  .c-content-pricing-2.c-featured-overlap {
    margin-left: -17px;
    margin-right: -17px; }
  .c-content-pricing-2.c-padding-last {
    margin-left: -15px; }
  .c-content-pricing-2.c-opt-1 {
    background: #f8f8f8; }
    .c-content-pricing-2.c-opt-1.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-1 > .c-icon > i {
      color: #c8d1e1; }
    .c-content-pricing-2.c-opt-1 > .c-title {
      color: #5f6f77; }
    .c-content-pricing-2.c-opt-1 > .c-price {
      color: #8d9db6; }
    .c-content-pricing-2.c-opt-1 > .c-items {
      color: #aab5c6; }
  .c-content-pricing-2.c-opt-1-featured {
    background: #ff6b57; }
    .c-content-pricing-2.c-opt-1-featured.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-1-featured > .c-icon > i {
      color: #ffb1a7; }
    .c-content-pricing-2.c-opt-1-featured > .c-title {
      color: #ffffff; }
    .c-content-pricing-2.c-opt-1-featured > .c-price {
      color: #ffd5cf; }
    .c-content-pricing-2.c-opt-1-featured > .c-items {
      color: #ffd5cf; }
  .c-content-pricing-2.c-opt-2 {
    background: #3e4265; }
    .c-content-pricing-2.c-opt-2.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-2 > .c-icon > i {
      color: #505477; }
    .c-content-pricing-2.c-opt-2 > .c-title {
      color: #ffffff; }
    .c-content-pricing-2.c-opt-2 > .c-price {
      color: #969ab9; }
    .c-content-pricing-2.c-opt-2 > .c-items {
      color: #969ab9; }
  .c-content-pricing-2.c-opt-2-featured {
    background: #414567; }
    .c-content-pricing-2.c-opt-2-featured.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-2-featured > .c-icon > i {
      color: #ff6b57; }
    .c-content-pricing-2.c-opt-2-featured > .c-title {
      color: #ffffff; }
    .c-content-pricing-2.c-opt-2-featured > .c-price {
      color: #22afd7; }
    .c-content-pricing-2.c-opt-2-featured > .c-items {
      color: #969ab9; }
  .c-content-pricing-2.c-opt-3 {
    background: #ffffff; }
    .c-content-pricing-2.c-opt-3.c-bg-outline {
      border: 2px solid #dce2e7; }
    .c-content-pricing-2.c-opt-3 > .c-icon > i {
      color: #c8d1e1; }
    .c-content-pricing-2.c-opt-3 > .c-title {
      color: #5f6f77; }
    .c-content-pricing-2.c-opt-3 > .c-price {
      color: #8d9db6; }
    .c-content-pricing-2.c-opt-3 > .c-items {
      color: #aab5c6; }
  .c-content-pricing-2.c-opt-3-featured {
    background: #ffffff; }
    .c-content-pricing-2.c-opt-3-featured.c-bg-outline {
      border: 2px solid #53b0bb; }
    .c-content-pricing-2.c-opt-3-featured > .c-icon > i {
      color: #53b0bb; }
    .c-content-pricing-2.c-opt-3-featured > .c-title {
      color: #5f6f77; }
    .c-content-pricing-2.c-opt-3-featured > .c-price {
      color: #8d9db6; }
    .c-content-pricing-2.c-opt-3-featured > .c-items {
      color: #aab5c6; }
  .c-content-pricing-2.c-opt-4 {
    background: rgba(255, 255, 255, 0.05); }
    .c-content-pricing-2.c-opt-4.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-4 > .c-icon > i {
      color: #808ca4; }
    .c-content-pricing-2.c-opt-4 > .c-title {
      color: #ffffff; }
    .c-content-pricing-2.c-opt-4 > .c-price {
      color: #b5bdcc; }
    .c-content-pricing-2.c-opt-4 > .c-items {
      color: #b5bdcc; }
  .c-content-pricing-2.c-opt-4-featured {
    background: rgba(255, 255, 255, 0.07); }
    .c-content-pricing-2.c-opt-4-featured.c-bg-outline {
      border: 2px solid transparent; }
    .c-content-pricing-2.c-opt-4-featured > .c-icon > i {
      color: #22afd7; }
    .c-content-pricing-2.c-opt-4-featured > .c-title {
      color: #ffffff; }
    .c-content-pricing-2.c-opt-4-featured > .c-price {
      color: #b5bdcc; }
    .c-content-pricing-2.c-opt-4-featured > .c-items {
      color: #b5bdcc; }
  .c-content-pricing-2.c-opt-5 {
    background: #fafafa; }
    .c-content-pricing-2.c-opt-5.c-bg-outline {
      border: 2px solid #fafafa; }
    .c-content-pricing-2.c-opt-5 > .c-icon > i {
      color: #8d98a3; }
    .c-content-pricing-2.c-opt-5 > .c-title {
      color: #3d4e5e; }
    .c-content-pricing-2.c-opt-5 > .c-price {
      color: #8d98a3; }
    .c-content-pricing-2.c-opt-5 > .c-items {
      color: #afb6bc; }
  .c-content-pricing-2.c-opt-5-featured {
    background: #22272c; }
    .c-content-pricing-2.c-opt-5-featured.c-bg-outline {
      border: 2px solid #2a333c; }
    .c-content-pricing-2.c-opt-5-featured > .c-icon > i {
      color: #30363b; }
    .c-content-pricing-2.c-opt-5-featured > .c-title {
      color: #fbfefe; }
    .c-content-pricing-2.c-opt-5-featured > .c-price {
      color: #525960; }
    .c-content-pricing-2.c-opt-5-featured > .c-items {
      color: #525960; }

.c-title-pricing-2 > h4 {
  margin: 15px 0; }

.c-title-pricing-2 > p {
  margin-bottom: 100px; }

.c-title-pricing-2.c-opt-title-1 > h4 {
  color: #455055; }
  .c-title-pricing-2.c-opt-title-1 > h4 > span {
    color: #22afd7; }

.c-title-pricing-2.c-opt-title-1 > p {
  color: #a0b0b8; }

.c-title-pricing-2.c-opt-title-2 > h4 {
  color: #fbfefe; }
  .c-title-pricing-2.c-opt-title-2 > h4 > span {
    color: #22afd7; }

.c-title-pricing-2.c-opt-title-2 > p {
  color: #969ab9; }

.c-title-pricing-2.c-opt-title-3 > h4 {
  color: #455055; }
  .c-title-pricing-2.c-opt-title-3 > h4 > span {
    color: #53b0bb; }

.c-title-pricing-2.c-opt-title-3 > p {
  color: #a0b0b8; }

.c-title-pricing-2.c-opt-title-4 > h4 {
  color: #ffffff; }
  .c-title-pricing-2.c-opt-title-4 > h4 > span {
    color: #ffffff; }

.c-title-pricing-2.c-opt-title-4 > p {
  color: #b5bdcc; }

.c-title-pricing-2.c-opt-title-5 > h4 {
  color: #3d4e5e; }
  .c-title-pricing-2.c-opt-title-5 > h4 > span {
    color: #3d4e5e; }

.c-title-pricing-2.c-opt-title-5 > p {
  color: #a0b0b8; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-pricing-2.c-padding-first {
    margin-right: -15px;
    margin-top: 0px; }
  .c-content-pricing-2.c-featured-no-padding, .c-content-pricing-2.c-featured-extra-padding, .c-content-pricing-2.c-featured-overlap {
    border-radius: 0px;
    margin: 0px 0px 0px -10px;
    padding: 50px 0; }
  .c-content-pricing-2.c-featured-overlap {
    padding: 52px 0; }
  .c-content-pricing-2.c-padding-last {
    margin-left: 0px;
    margin-top: 5px; } }

@media (max-width: 767px) {
  /* 766px */
  .c-content-pricing-2.c-padding-first {
    margin: 0px; }
  .c-content-pricing-2.c-featured-no-padding, .c-content-pricing-2.c-featured-extra-padding, .c-content-pricing-2.c-featured-overlap {
    margin-top: 5px;
    margin-left: 0px;
    border-radius: 0px;
    padding: 50px 0; }
  .c-content-pricing-2.c-padding-last {
    margin-left: 0px;
    margin-top: 5px; } }

.c-content-product-1 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-product-1 .row {
    position: relative; }
    .c-content-product-1 .row .c-media > img {
      width: 100%; }
    .c-content-product-1 .row .c-body {
      padding: 80px 0 20px 20px; }
      .c-content-product-1 .row .c-body > .c-row {
        padding: 0 0 0 40px; }
        .c-content-product-1 .row .c-body > .c-row > li {
          width: 50%;
          display: inline-block;
          list-style: none;
          float: left;
          padding: 0 50px 0 0;
          margin-bottom: 35px; }
          .c-content-product-1 .row .c-body > .c-row > li:last-child {
            padding: 0 0 0 50px; }
          .c-content-product-1 .row .c-body > .c-row > li > h4 {
            font-size: 20px;
            color: #3f444a;
            font-weight: 600;
            margin-bottom: 20px;
            text-transform: uppercase; }
          .c-content-product-1 .row .c-body > .c-row > li > p {
            color: #5c6873;
            font-weight: 300; }
      .c-content-product-1 .row .c-body > .btn {
        display: block;
        margin: 0 auto 60px auto;
        clear: both; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-product-1 .row .c-media > img {
    width: 40%;
    margin: 0 auto;
    display: block; }
  .c-content-product-1 .row .c-body {
    padding: 30px 0 10px; }
    .c-content-product-1 .row .c-body > .c-row {
      padding: 0; }
      .c-content-product-1 .row .c-body > .c-row > li {
        width: 100%;
        margin: 40px 0;
        float: none; }
        .c-content-product-1 .row .c-body > .c-row > li:last-child {
          padding: 0; } }

.c-content-product-2 .c-info {
  padding: 10px 20px; }
  .c-content-product-2 .c-info .c-price {
    margin-bottom: 0; }

.c-content-product-2 .c-label {
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  z-index: 9; }
  .c-content-product-2 .c-label.c-label-right {
    right: 0;
    top: 0; }

.c-content-product-2 .c-btn-product {
  border: none;
  color: #a5b0bc; }
  .c-content-product-2 .c-btn-product.btn-lg {
    font-size: 12px;
    padding-top: 14px;
    padding-bottom: 13px; }
  .c-content-product-2 .c-btn-product.btn-sm {
    font-size: 11px;
    padding-top: 10px;
    padding-bottom: 8px; }

.c-content-product-2 .btn + .btn {
  margin-left: 5px; }

.c-content-product-3 {
  padding: 0;
  margin: 0; }
  .c-content-product-3 .c-content {
    padding: 40px; }
    .c-content-product-3 .c-content .c-title {
      margin: 0 0 25px;
      font-weight: 500; }
    .c-content-product-3 .c-content .c-price {
      margin-top: 10px;
      font-weight: lighter; }
    .c-content-product-3 .c-content .btn {
      position: absolute;
      bottom: 40px; }
  .c-content-product-3 .c-border-padding {
    border: 1px solid rgba(255, 255, 255, 0.2);
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    position: absolute;
    z-index: 1; }

.c-content-product-4 {
  padding: 0;
  margin: 0;
  height: 500px; }
  .c-content-product-4.c-content-bg-1 {
    background-color: #eff3f5; }
  .c-content-product-4.c-content-bg-2 {
    background-color: #f0ece8; }
  .c-content-product-4 .c-content .c-title {
    margin: 0 0 25px;
    font-weight: 500; }
  .c-content-product-4 .c-content .c-price {
    margin-top: 20px; }
  .c-content-product-4 .c-content .btn {
    margin-top: 15px; }
  .c-content-product-4 .c-content.c-align-right {
    padding: 100px 100px 0 0;
    text-align: right; }
  .c-content-product-4 .c-content.c-align-left {
    padding: 100px 0 0 100px; }
  .c-content-product-4 .c-border-padding {
    border: 1px solid rgba(255, 255, 255, 0.2);
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    position: absolute;
    z-index: 1; }
  .c-content-product-4 .c-side-image {
    height: 500px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: bottom; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-product-4 .c-side-image {
    background-size: contain; }
  .c-content-product-4 .c-content.c-align-right {
    padding: 40px 20px 0 0; }
  .c-content-product-4 .c-content.c-align-left {
    padding: 40px 0 0 20px; } }

@media (max-width: 767px) {
  /* 767px */
  .c-content-product-4 {
    height: 760px; }
    .c-content-product-4 .c-wrapper {
      padding-top: 30px; }
    .c-content-product-4 .c-side-image {
      height: 350px; } }

.c-content-product-5 {
  padding: 0;
  margin: 0; }
  .c-content-product-5 h3 {
    padding: 0;
    margin: 0; }
  .c-content-product-5 .c-title.c-margin-tb-30 {
    line-height: 1.5em;
    margin: 30px 0; }
  .c-content-product-5 .c-line {
    padding: 15px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
  .c-content-product-5 .c-detail {
    position: absolute;
    bottom: 0;
    padding: 30px 45px; }
    .c-content-product-5 .c-detail .c-desc {
      margin: 10px 0; }
    .c-content-product-5 .c-detail .btn {
      float: right;
      margin: 30px 0; }
  .c-content-product-5 .c-wrapper.c-center-vertical {
    padding: 15% 0; }

@media (max-width: 767px) {
  /* 767px */
  .c-content-product-5 .c-detail {
    padding: 10px 10px; } }

.c-content-contact-1 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-contact-1:before {
    background-color: rgba(255, 255, 255, 0.5); }
  .c-content-contact-1:after {
    background-color: rgba(255, 255, 255, 0.5); }
  .c-content-contact-1 > .row .c-body {
    position: relative;
    z-index: 100;
    background: #ffffff;
    margin: 40px 0;
    padding: 60px 40px; }
    .c-content-contact-1 > .row .c-body > .c-section {
      margin-bottom: 15px;
      text-align: right; }
      .c-content-contact-1 > .row .c-body > .c-section > h3 {
        font-size: 30px;
        color: #3f444a;
        font-weight: bold;
        margin: 0 0 30px 0;
        text-transform: uppercase; }
      .c-content-contact-1 > .row .c-body > .c-section > p {
        font-size: 16px;
        line-height: 24px;
        color: #7f8c97;
        font-weight: 300; }
      .c-content-contact-1 > .row .c-body > .c-section > p,
      .c-content-contact-1 > .row .c-body > .c-section > .c-content-iconlist-1 {
        margin-top: 20px; }

.c-content-contact-1-gmap {
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-contact-1-gmap {
    display: block;
    position: static;
    width: 100%;
    height: 300px !important; }
  .c-content-contact-1 > .row > div {
    width: 100%; }
    .c-content-contact-1 > .row > div .c-body {
      position: static;
      display: block;
      padding: 30px 20px 10px 20px;
      background: #fafafa;
      margin: 0;
      text-align: left; }
      .c-content-contact-1 > .row > div .c-body:after {
        content: "";
        display: table;
        clear: both; } }

.c-content-feedback-1 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-feedback-1 > .row > div .c-content-title-1 > .c-line-left {
    margin-bottom: 30px; }
  .c-content-feedback-1 > .row > div .c-content-title-1 > p {
    margin-bottom: 30px;
    font-weight: 300; }
  .c-content-feedback-1 > .row > div > .c-container {
    background-size: auto;
    padding: 30px;
    margin: 0 30px 60px 0; }
    .c-content-feedback-1 > .row > div > .c-container > .c-inverse > h3 {
      color: #ffffff; }
    .c-content-feedback-1 > .row > div > .c-container > .c-inverse > .c-line-left {
      background-color: #ffffff; }
    .c-content-feedback-1 > .row > div > .c-container > .c-inverse > p {
      color: #ffffff; }
    .c-content-feedback-1 > .row > div > .c-container:last-child {
      margin-bottom: 0; }
      .c-content-feedback-1 > .row > div > .c-container:last-child p {
        margin-bottom: 0; }
  .c-content-feedback-1 > .row > div > .c-contact {
    margin-left: 30px; }
  .c-content-feedback-1 > .row > div form {
    margin-bottom: 20px; }
    .c-content-feedback-1 > .row > div form .input-group {
      width: 340px; }
  .c-content-feedback-1 .input-group {
    border: 0; }
    .c-content-feedback-1 .input-group .input-group-addon,
    .c-content-feedback-1 .input-group .btn {
      border: 0; }
  .c-content-feedback-1.c-option-2 {
    padding: 60px 0; }
    .c-content-feedback-1.c-option-2 > .c-content-title-1 > h3 {
      margin-left: -18px; }
      .c-content-feedback-1.c-option-2 > .c-content-title-1 > h3.c-feedback-rtl-title {
        margin-left: 18px; }
    .c-content-feedback-1.c-option-2 > .c-content-title-1 > .c-left {
      margin-bottom: 0;
      left: -7.5px;
      background-color: #ffffff; }
    .c-content-feedback-1.c-option-2 > .c-content-title-1 > .c-right {
      display: block;
      text-align: center;
      position: relative;
      left: 7.5px;
      top: -3px; }
    .c-content-feedback-1.c-option-2 > .c-content-title-1 > .c-line-center {
      position: relative;
      display: block;
      text-align: center;
      width: 15px; }
    .c-content-feedback-1.c-option-2 > .c-content-title-1 > p {
      margin-left: 18px; }
      .c-content-feedback-1.c-option-2 > .c-content-title-1 > p.c-feedback-rtl-subtitle {
        margin-left: -18px; }
      .c-content-feedback-1.c-option-2 > .c-content-title-1 > p > span {
        color: #7f8b96; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feedback-1 > .row > div > .c-container {
    margin-right: 0;
    margin-bottom: 40px; }
  .c-content-feedback-1 > .row > div > .c-contact {
    padding: 40px 30px 0 30px;
    margin: 0; }
  .c-content-feedback-1 > .row > div form .input-group {
    width: 100%; }
  .c-content-feedback-1.c-option-2 > .row > div > .c-contact {
    padding: 30px;
    margin-top: 40px;
    background-color: #ffffff; } }

.c-content-recent-posts-1 {
  padding: 0;
  margin: 0; }
  .c-content-recent-posts-1 > li {
    padding: 15px;
    list-style: none;
    border: 1px solid #eeeeee;
    border-bottom: 0; }
    .c-content-recent-posts-1 > li:last-child {
      border-bottom: 1px solid #eeeeee; }
    .c-content-recent-posts-1 > li > .c-image {
      width: 60px;
      display: inline-block;
      margin-right: 10px;
      float: left; }
    .c-content-recent-posts-1 > li > .c-post {
      padding: 5px 0; }
      .c-content-recent-posts-1 > li > .c-post .c-title {
        font-weight: 400; }
      .c-content-recent-posts-1 > li > .c-post .c-date {
        font-size: 15px;
        color: #5c6873; }
    .c-content-recent-posts-1 > li:after {
      content: "";
      display: table;
      clear: both; }

.c-content-blog-post-1:after {
  content: "";
  display: table;
  clear: both; }

.c-content-blog-post-1 .c-title {
  font-size: 30px;
  color: #3f444a;
  font-weight: 300;
  margin: 30px 0 20px 0; }

.c-content-blog-post-1 .c-desc {
  font-size: 17px;
  color: #5c6873;
  font-weight: 300;
  margin: 0px 0 30px 0; }

.c-content-blog-post-1 .c-panel {
  padding: 5px 10px;
  border: 1px solid #eeeeee; }
  .c-content-blog-post-1 .c-panel:after {
    content: "";
    display: table;
    clear: both; }
  .c-content-blog-post-1 .c-panel .c-author,
  .c-content-blog-post-1 .c-panel .c-date,
  .c-content-blog-post-1 .c-panel .c-comments {
    float: left;
    display: inline-block;
    font-size: 14px;
    color: #5c6873;
    font-weight: 400;
    margin: 6px 10px 3px 0; }
  .c-content-blog-post-1 .c-panel .c-comments {
    float: right; }
    .c-content-blog-post-1 .c-panel .c-comments i {
      position: relative;
      top: 2px;
      color: #8f9ba6; }
  .c-content-blog-post-1 .c-panel .c-tags {
    padding: 0;
    margin: 3px 0px 5px 0;
    float: right;
    display: inline-block; }
    .c-content-blog-post-1 .c-panel .c-tags > li {
      font-size: 13px;
      text-transform: uppercase;
      display: inline-block;
      list-style: none;
      padding: 2px 6px 1px 6px;
      margin: 0 2px; }
      .c-content-blog-post-1 .c-panel .c-tags > li:last-child {
        margin-right: 0; }
  .c-content-blog-post-1 .c-panel .c-author {
    display: inline-block; }

.c-content-blog-post-1 .c-comments {
  margin: 40px 0; }
  .c-content-blog-post-1 .c-comments .c-comment-list {
    margin-bottom: 60px; }
    .c-content-blog-post-1 .c-comments .c-comment-list .media {
      margin-bottom: 30px; }
      .c-content-blog-post-1 .c-comments .c-comment-list .media .media-body > .media:last-child {
        margin-bottom: 0; }
    .c-content-blog-post-1 .c-comments .c-comment-list .media-object {
      height: 50px;
      width: 50px;
      border-radius: 50px !important; }
    .c-content-blog-post-1 .c-comments .c-comment-list .media-heading > a {
      text-transform: uppercase; }
    .c-content-blog-post-1 .c-comments .c-comment-list .media-heading > .c-date {
      text-transform: uppercase;
      font-size: 16px; }

.c-content-blog-post-1-list {
  padding-right: 30px; }
  .c-content-blog-post-1-list:after {
    content: "";
    display: table;
    clear: both; }
  .c-content-blog-post-1-list .c-content-blog-post-1 {
    margin-bottom: 80px; }
  .c-content-blog-post-1-list .c-pagination {
    text-align: center; }

.c-content-blog-post-1-view {
  padding-right: 30px; }
  .c-content-blog-post-1-view:after {
    content: "";
    display: table;
    clear: both; }
  .c-content-blog-post-1-view .c-navigation {
    text-align: center; }

.c-content-blog-post-card-1 .c-body {
  padding: 20px 0; }
  .c-content-blog-post-card-1 .c-body .c-title {
    font-size: 19px;
    font-weight: 500;
    color: #3f444a;
    margin-bottom: 10px; }
  .c-content-blog-post-card-1 .c-body .c-author {
    font-size: 14px;
    font-weight: 400;
    color: #5c6873;
    margin-bottom: 10px; }
  .c-content-blog-post-card-1 .c-body .c-panel {
    padding: 5px 0;
    border-top: 1px solid #eeeeee; }
    .c-content-blog-post-card-1 .c-body .c-panel:after {
      content: "";
      display: table;
      clear: both; }
    .c-content-blog-post-card-1 .c-body .c-panel .c-comments {
      float: left;
      display: inline-block;
      font-size: 15px;
      color: #5c6873;
      font-weight: 400;
      margin: 6px 10px 3px 0; }
      .c-content-blog-post-card-1 .c-body .c-panel .c-comments i {
        position: relative;
        top: 2px;
        color: #8f9ba6; }
    .c-content-blog-post-card-1 .c-body .c-panel .c-tags {
      padding: 0;
      margin: 3px 0px 5px 0;
      float: right;
      display: inline-block; }
      .c-content-blog-post-card-1 .c-body .c-panel .c-tags > li {
        font-size: 13px;
        text-transform: uppercase;
        display: inline-block;
        list-style: none;
        padding: 2px 6px 1px 6px;
        margin: 0 2px; }
        .c-content-blog-post-card-1 .c-body .c-panel .c-tags > li:last-child {
          margin-right: 0; }
    .c-content-blog-post-card-1 .c-body .c-panel .c-author {
      display: inline-block; }
  .c-content-blog-post-card-1 .c-body p {
    display: block;
    clear: both;
    padding: 10px 0 0 0;
    color: #5c6873; }

.c-content-blog-post-card-1.c-option-2 .c-body {
  padding: 30px;
  background: #fff; }
  .c-content-blog-post-card-1.c-option-2 .c-body p {
    padding-bottom: 0; }

.c-content-blog-post-card-1.c-bordered {
  border: 1px solid #eeeeee; }

.c-content-blog-post-card-1-grid {
  padding-right: 40px; }
  .c-content-blog-post-card-1-grid .c-content-blog-post-card-1 {
    margin-bottom: 30px; }
  .c-content-blog-post-card-1-grid .c-pagination {
    margin-top: 30px;
    text-align: center; }

.c-content-blog-post-card-1-slider .c-content-label {
  padding: 6px 10px 2px 10px;
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 10; }

.c-content-counter-1 > .row > div {
  text-align: center; }
  .c-content-counter-1 > .row > div > .c-title {
    font-weight: 300;
    font-size: 20px;
    color: #3f444a;
    margin: 20px 0 15px 0; }
  .c-content-counter-1 > .row > div > .c-counter {
    font-size: 45px;
    font-weight: 300;
    color: #32c5d2;
    margin: 10px auto 30px auto;
    background-color: #fff;
    width: 200px;
    height: 200px;
    padding: 65px 0;
    display: block;
    border-radius: 200px;
    border: 1px solid transparent; }
    .c-content-counter-1 > .row > div > .c-counter.c-bordered {
      border: 4px solid transparent;
      background: none; }
  .c-content-counter-1 > .row > div > .c-content {
    color: #5c6873;
    padding: 0 30px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-counter-1 > .row > div > .c-title {
    margin-top: 5px; }
  .c-content-counter-1 > .row > div > .c-first {
    margin-top: 0; }
  .c-content-counter-1 > .row > div > .c-content {
    margin-bottom: 60px; } }

.c-content-feature-1 {
  padding-right: 40px; }
  .c-content-feature-1 > h4 {
    font-size: 18px;
    color: #3f444a;
    margin-top: 0;
    font-weight: 500;
    text-transform: uppercase; }
  .c-content-feature-1 p {
    font-size: 18px;
    color: #5c6873; }
  .c-content-feature-1 > h3 {
    font-size: 30px;
    color: #3f444a;
    font-weight: 500;
    margin: 30px 0 25px 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-1 {
    padding-right: 20px; } }

.c-content-feature-2 {
  padding: 40px 40px 40px 40px;
  margin-bottom: 20px;
  background: #ffffff; }
  .c-content-feature-2 .c-icon-wrapper {
    width: 70px;
    height: 70px;
    padding: 10px 0 10px 0;
    float: left;
    margin-right: 25px; }
    .c-content-feature-2 .c-icon-wrapper > .c-content-line-icon {
      width: 60px;
      height: 60px;
      float: left; }
  .c-content-feature-2 .c-title {
    font-size: 20px;
    color: #3f444a;
    margin-top: 0;
    font-weight: 500;
    text-transform: uppercase; }
  .c-content-feature-2 p {
    font-size: 17px;
    color: #5c6873;
    font-weight: 300;
    padding-left: 95px;
    margin: 0; }
  .c-content-feature-2.c-option-2 {
    cursor: pointer;
    padding: 20px 0; }
    .c-content-feature-2.c-option-2 .c-icon-wrapper {
      background: none;
      padding: 15px;
      border: 1px solid #eeeeee; }
      .c-content-feature-2.c-option-2 .c-icon-wrapper > .c-content-line-icon {
        width: 40px;
        height: 40px;
        float: left; }
    .c-content-feature-2.c-option-2:hover .c-icon-wrapper {
      border: 0; }
      .c-content-feature-2.c-option-2:hover .c-icon-wrapper > .c-content-line-icon {
        background-image: url(../img/content/line-icons/white.png); }

.c-content-feature-2-grid .c-content-v-center {
  margin-bottom: 20px; }

.c-content-feature-3 {
  padding: 0;
  margin-bottom: 70px;
  text-align: center; }
  .c-content-feature-3 > p {
    color: #ffffff;
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    padding: 40px;
    margin: 0; }
  .c-content-feature-3 > .btn {
    margin-top: 5px; }

.c-content-feature-3-grid {
  text-align: center; }
  .c-content-feature-3-grid > .c-title {
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 40px;
    margin: 100px auto 20px auto;
    display: inline-block; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-3 {
    margin-bottom: 40px; }
    .c-content-feature-3 > p {
      padding: 20px; }
    .c-content-feature-3 > .btn {
      margin-top: 5px; }
  .c-content-feature-3-grid > .c-title {
    margin: 70px auto 10px auto; } }

.c-content-feature-4 .c-content-area {
  width: 40%; }

.c-content-feature-4 .c-feature-bg {
  width: 60%; }

.c-content-feature-4 .c-feature-bg,
.c-content-feature-4 .c-content-area {
  height: 750px;
  margin-bottom: -750px; }

.c-content-feature-4 .c-content-left {
  float: left; }
  .c-content-feature-4 .c-content-left.c-semi-circle {
    border-top-right-radius: 35% 50%;
    border-bottom-right-radius: 35% 50%; }
  .c-content-feature-4 .c-content-left.c-diagonal {
    border-right: 200px solid;
    border-top: 750px solid transparent; }
  .c-content-feature-4 .c-content-left.c-arrow {
    border-right: 200px solid;
    border-top: 375px solid transparent;
    border-bottom: 375px solid transparent; }

.c-content-feature-4 .c-content-right {
  float: right; }
  .c-content-feature-4 .c-content-right.c-semi-circle {
    border-top-left-radius: 35% 50%;
    border-bottom-left-radius: 35% 50%; }
  .c-content-feature-4 .c-content-right.c-diagonal {
    border-left: 200px solid;
    border-top: 750px solid transparent; }
  .c-content-feature-4 .c-content-right.c-arrow {
    border-left: 200px solid;
    border-top: 375px solid transparent;
    border-bottom: 375px solid transparent; }

.c-content-feature-4 .c-feature-content {
  padding: 0;
  width: 30%; }
  .c-content-feature-4 .c-feature-content.c-left {
    float: left; }
  .c-content-feature-4 .c-feature-content.c-right {
    float: right; }
  .c-content-feature-4 .c-feature-content .c-content-v-center {
    height: 750px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-4 {
    height: auto;
    margin-bottom: 0; }
    .c-content-feature-4 .c-content-area {
      width: 0px; }
    .c-content-feature-4 .c-feature-bg {
      width: 100%;
      height: 350px;
      margin-bottom: -350px; }
    .c-content-feature-4 .c-content-left {
      float: left; }
      .c-content-feature-4 .c-content-left.c-semi-circle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .c-content-feature-4 .c-content-left.c-diagonal {
        border-right: 0;
        border-top: 0; }
      .c-content-feature-4 .c-content-left.c-arrow {
        border-right: 0;
        border-top: 0;
        border-bottom: 0; }
    .c-content-feature-4 .c-content-right {
      float: right; }
      .c-content-feature-4 .c-content-right.c-semi-circle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .c-content-feature-4 .c-content-right.c-diagonal {
        border-left: 0;
        border-top: 0; }
      .c-content-feature-4 .c-content-right.c-arrow {
        border-left: 0;
        border-top: 0;
        border-bottom: 0; }
    .c-content-feature-4 .c-feature-content {
      padding: 60px 0;
      height: auto;
      width: 100%; }
      .c-content-feature-4 .c-feature-content > .c-content-v-center {
        height: auto;
        padding-top: 350px; }
      .c-content-feature-4 .c-feature-content.c-left {
        float: left; }
      .c-content-feature-4 .c-feature-content.c-right {
        float: right; } }

.c-content-feature-5 .c-content-title-1 {
  margin-top: 25px; }

.c-content-feature-5 .c-text {
  position: relative;
  background-color: #ffffff;
  width: 40%;
  padding: 25px;
  z-index: 1; }

.c-content-feature-5 .c-photo {
  position: absolute;
  top: 0;
  right: 65px; }

@media (max-width: 1199px) {
  /* 991px */
  .c-content-feature-5 .c-content-title-1 {
    margin: 0; }
  .c-content-feature-5 .c-text {
    position: static;
    width: 100%;
    z-index: auto; }
  .c-content-feature-5 .c-photo {
    position: static;
    margin: 20px 0px; } }

.c-content-feature-6 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-feature-6 > .c-list {
    margin: 70px 0 0 0;
    display: table;
    width: 100%;
    padding: 0; }
    .c-content-feature-6 > .c-list > li {
      padding: 0 1px 0 0;
      display: table-cell;
      width: 33.3333%; }
      .c-content-feature-6 > .c-list > li > .c-card {
        padding: 40px 40px;
        margin-right: 2px;
        text-align: left; }
        .c-content-feature-6 > .c-list > li > .c-card > h3 {
          margin: 5px 0;
          color: #3f444a; }
        .c-content-feature-6 > .c-list > li > .c-card > p {
          padding: 10px 0;
          margin: 0;
          color: #5c6873; }
      .c-content-feature-6 > .c-list > li > .c-bg-opacity-1 {
        background-color: rgba(255, 255, 255, 0.63); }
      .c-content-feature-6 > .c-list > li > .c-bg-opacity-2 {
        background-color: rgba(255, 255, 255, 0.04); }
    .c-content-feature-6 > .c-list > li:last-child {
      padding: 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-6 > .c-list {
    margin: 40px 0 0 0; }
    .c-content-feature-6 > .c-list > li {
      padding: 0 0 1px 0;
      width: 100%;
      float: left; }
      .c-content-feature-6 > .c-list > li > .c-card {
        padding: 30px;
        margin-right: 0;
        margin-bottom: 2px; } }

.c-content-feature-7 {
  padding: 0;
  margin: 0; }
  .c-content-feature-7 h3 {
    color: #3f444a; }
  .c-content-feature-7 h4 {
    color: #5c6873; }
  .c-content-feature-7 p {
    color: #5c6873; }
  .c-content-feature-7 > li {
    display: table-cell;
    width: 1%; }
    .c-content-feature-7 > li > .c-card {
      padding: 50px 40px; }
      .c-content-feature-7 > li > .c-card > h3 {
        font-size: 23px; }
      .c-content-feature-7 > li > .c-card > h4 {
        font-size: 21px; }
      .c-content-feature-7 > li > .c-card > p {
        padding: 20px 0 40px 0;
        font-size: 16px; }
    .c-content-feature-7 > li > .c-first {
      padding: 50px 40px 50px 0; }
    .c-content-feature-7 > li > .c-last {
      padding: 50px 0 50px 40px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-7 > li {
    display: block;
    width: 100%; }
    .c-content-feature-7 > li > .c-card {
      padding: 20px 20px 40px 20px;
      margin-right: 0;
      margin-bottom: 2px; } }

.c-content-feature-8 {
  padding: 0;
  margin: 0; }
  .c-content-feature-8 h3 {
    color: #3f444a; }
  .c-content-feature-8 p {
    color: #5c6873; }
  .c-content-feature-8 > .c-grid {
    display: table;
    padding: 0;
    width: 100%; }
    .c-content-feature-8 > .c-grid > li {
      display: table-cell;
      width: 50%;
      padding: 0 5px 0 0;
      float: left; }
      .c-content-feature-8 > .c-grid > li > .c-card {
        padding: 80px; }
        .c-content-feature-8 > .c-grid > li > .c-card.c-bg-opacity-1 {
          background-color: rgba(255, 255, 255, 0.1); }
        .c-content-feature-8 > .c-grid > li > .c-card.c-bg-opacity-2 {
          background-color: rgba(255, 255, 255, 0.8); }
        .c-content-feature-8 > .c-grid > li > .c-card > h3 {
          margin: 0 0 20px 0; }
        .c-content-feature-8 > .c-grid > li > .c-card > p {
          margin: 0;
          line-height: 22px; }
      .c-content-feature-8 > .c-grid > li:last-child {
        padding: 0 0 0 5px; }
  .c-content-feature-8.c-opt-1 > .c-grid > li > .c-img {
    background-color: #7e8daa;
    padding: 128px 80px 129px 80px; }
    .c-content-feature-8.c-opt-1 > .c-grid > li > .c-img h3 {
      margin: 0; }
  .c-content-feature-8.c-opt-2 > .c-grid > li > .c-img {
    background-color: #7e8daa;
    padding: 156px 80px;
    position: relative; }
    .c-content-feature-8.c-opt-2 > .c-grid > li > .c-img h3 {
      margin: 0;
      letter-spacing: 0.5px;
      position: absolute;
      bottom: 20px;
      right: 20px; }

@media (max-width: 1199px) {
  /* 1199px */
  .c-content-feature-8 > .c-grid > li > .c-card {
    padding: 70px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-8 > .c-grid > li {
    padding: 0 0 10px 0;
    width: 100%; }
    .c-content-feature-8 > .c-grid > li:last-child {
      padding: 0 0 10px 0; }
  .c-content-feature-8 > .c-img {
    padding: 0 0 10px 0; } }

@media (max-width: 480px) {
  /* 480px */
  .c-content-feature-8 > .c-grid > li > .c-card {
    padding: 50px; } }

.c-content-feature-9 {
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 20px 0; }
  .c-content-feature-9 h3 {
    color: #3f444a; }
  .c-content-feature-9 p {
    color: #5c6873; }
  .c-content-feature-9 > .c-list {
    display: table;
    width: 100%;
    padding: 0;
    position: relative; }
    .c-content-feature-9 > .c-list > li {
      display: table-cell;
      width: 33.33333%;
      padding: 0 5px 0 0;
      position: relative; }
      .c-content-feature-9 > .c-list > li > .c-card {
        padding: 40px;
        margin-right: 2px;
        position: relative; }
        .c-content-feature-9 > .c-list > li > .c-card > i {
          width: 54px;
          height: 54px;
          border-radius: 27px;
          text-align: center;
          padding: 14px;
          position: absolute;
          z-index: 5; }
        .c-content-feature-9 > .c-list > li > .c-card > .c-float-left {
          float: left;
          margin-right: 22px; }
        .c-content-feature-9 > .c-list > li > .c-card > .c-content-left {
          padding-left: 76px; }
        .c-content-feature-9 > .c-list > li > .c-card .c-content > h3 {
          margin: 0 0 15px 0; }
        .c-content-feature-9 > .c-list > li > .c-card > .c-bg-opacity-4 {
          background-color: rgba(255, 191, 182, 0.2); }
      .c-content-feature-9 > .c-list > li > .c-bg-opacity-2 {
        background-color: rgba(255, 255, 255, 0.63); }
      .c-content-feature-9 > .c-list > li > .c-bg-opacity-3 {
        background-color: rgba(255, 191, 182, 0.11); }
      .c-content-feature-9 > .c-list > li > .c-border-bottom {
        width: 95%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        margin-left: 2.5%; }
      .c-content-feature-9 > .c-list > li > .c-mobile {
        display: none; }
      .c-content-feature-9 > .c-list > li:last-child {
        padding: 0 0 0 5px; }
    .c-content-feature-9 > .c-list > .c-border-middle {
      height: 90%;
      width: 1px;
      position: absolute;
      margin-top: 0.5%;
      top: 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-9 > .c-list > li {
    display: block;
    width: 100%;
    padding: 0 15px 10px 15px; }
    .c-content-feature-9 > .c-list > li > .c-card {
      padding: 40px 20px;
      margin-right: 0;
      margin-bottom: 2px; }
    .c-content-feature-9 > .c-list > li > .c-border-bottom {
      width: 100%;
      height: 1px;
      position: inherit;
      bottom: 0;
      margin-left: 0; }
    .c-content-feature-9 > .c-list > li > .c-mobile {
      display: block; }
    .c-content-feature-9 > .c-list > li:last-child {
      padding: 0 15px; }
  .c-content-feature-9 > .c-list > .c-border-middle {
    display: none; } }

.c-content-feature-10 {
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 20px 0; }
  .c-content-feature-10 h3 {
    color: #3f444a; }
  .c-content-feature-10 p {
    color: #5c6873; }
  .c-content-feature-10 i {
    color: #3f444a; }
  .c-content-feature-10 .c-bg-opacity-1 {
    background-color: rgba(255, 255, 255, 0.1);
    border: none; }
  .c-content-feature-10 .c-bg-opacity-2 {
    background-color: rgba(0, 0, 0, 0.1); }
  .c-content-feature-10 .c-border-opacity {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .c-content-feature-10 > .c-list {
    display: table;
    width: 100%;
    padding: 0;
    position: relative; }
    .c-content-feature-10 > .c-list > li {
      display: table-cell;
      width: 50%;
      padding: 0 5px 0 0;
      position: relative; }
      .c-content-feature-10 > .c-list > li > .c-card {
        padding: 40px;
        margin-right: 2px; }
        .c-content-feature-10 > .c-list > li > .c-card > i {
          width: 67px;
          height: 67px;
          border-radius: 33.5px;
          text-align: center;
          padding: 20px; }
        .c-content-feature-10 > .c-list > li > .c-card > .c-float-right {
          float: right;
          margin-left: 40px; }
        .c-content-feature-10 > .c-list > li > .c-card > .c-float-left {
          float: left;
          margin-right: 40px; }
        .c-content-feature-10 > .c-list > li > .c-card > .c-content-right {
          padding-right: 107px; }
        .c-content-feature-10 > .c-list > li > .c-card > .c-content-left {
          padding-left: 107px; }
        .c-content-feature-10 > .c-list > li > .c-card .c-content > h3 {
          margin: 0 0 15px 0; }
      .c-content-feature-10 > .c-list > li > .c-border-bottom {
        width: 95%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        margin-left: 2.5%; }
      .c-content-feature-10 > .c-list > li > .c-mobile {
        display: none; }
      .c-content-feature-10 > .c-list > li:last-child {
        padding: 0 0 0 5px; }
    .c-content-feature-10 > .c-list > .c-border-middle {
      height: 90%;
      width: 1px;
      position: absolute;
      margin-top: 0.5%;
      top: 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-10 > .c-list > li {
    display: block;
    width: 100%;
    padding: 0 15px 10px 15px; }
    .c-content-feature-10 > .c-list > li > .c-card {
      padding: 40px 20px;
      margin-right: 0;
      margin-bottom: 2px; }
    .c-content-feature-10 > .c-list > li > .c-border-bottom {
      width: 100%;
      height: 1px;
      position: inherit;
      bottom: 0;
      margin-left: 0; }
    .c-content-feature-10 > .c-list > li > .c-mobile {
      display: block; }
    .c-content-feature-10 > .c-list > li:last-child {
      padding: 0 15px; }
  .c-content-feature-10 > .c-list > .c-border-middle {
    display: none; } }

.c-content-feature-11 {
  padding: 0;
  margin: 0; }
  .c-content-feature-11 .c-grid {
    padding-right: 35px; }
    .c-content-feature-11 .c-grid > .c-grid-row {
      display: table;
      padding: 0;
      width: 100%; }
      .c-content-feature-11 .c-grid > .c-grid-row:last-child {
        margin: 0; }
      .c-content-feature-11 .c-grid > .c-grid-row > li {
        display: table-cell;
        width: 50%;
        padding-right: 10px; }
        .c-content-feature-11 .c-grid > .c-grid-row > li > .c-card {
          padding: 40px;
          text-align: left; }
          .c-content-feature-11 .c-grid > .c-grid-row > li > .c-card > h3 {
            margin: 0 0 20px 0;
            color: #3f444a; }
          .c-content-feature-11 .c-grid > .c-grid-row > li > .c-card > p {
            margin: 0;
            line-height: 22px;
            color: #5c6873; }
        .c-content-feature-11 .c-grid > .c-grid-row > li > .c-bg-opacity-1 {
          background-color: rgba(255, 255, 255, 0.7); }
        .c-content-feature-11 .c-grid > .c-grid-row > li > .c-bg-opacity-2 {
          background-color: rgba(255, 255, 255, 0.04); }
  .c-content-feature-11 .c-video {
    padding-left: 45px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-11 .c-grid {
    padding: 0 15px; }
    .c-content-feature-11 .c-grid > .c-grid-row {
      margin: 0; }
      .c-content-feature-11 .c-grid > .c-grid-row > li {
        display: block;
        width: 100%;
        padding: 0 0 10px 0; }
        .c-content-feature-11 .c-grid > .c-grid-row > li > .c-card {
          padding: 30px; }
        .c-content-feature-11 .c-grid > .c-grid-row > li:last-child {
          padding: 0 0 10px 0; }
  .c-content-feature-11 .c-video {
    padding: 10px 15px 0 15px; } }

.c-content-feature-13 .c-feature-13-container {
  padding: 80px; }

.c-content-feature-13 .c-feature-13-tile {
  padding: 20px; }
  .c-content-feature-13 .c-feature-13-tile > i {
    float: left; }
  .c-content-feature-13 .c-feature-13-tile > .c-feature-13-content {
    padding-left: 45px; }
    .c-content-feature-13 .c-feature-13-tile > .c-feature-13-content > h4 {
      margin-top: 0;
      font-size: 18px; }

.c-content-feature-14 {
  padding: 80px 0;
  overflow: auto; }
  .c-content-feature-14 img {
    max-width: 100%; }
  .c-content-feature-14 .c-feature-14-title {
    margin-top: 4em; }
  .c-content-feature-14 .c-feature-gallery {
    margin: 20px 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-14 {
    padding: 60px 0;
    border-bottom: 1px solid #eee; }
    .c-content-feature-14:last-child {
      border-bottom: none; }
    .c-content-feature-14 img {
      display: block;
      margin: 0 auto; }
    .c-content-feature-14 .c-feature-14-title {
      margin-top: 0; }
    .c-content-feature-14 .c-feature-14-btn {
      text-align: center;
      margin-bottom: 20px; }
      .c-content-feature-14 .c-feature-14-btn a {
        margin: 0 0 10px 0; } }

.c-content-feature-15 .c-feature-15-container {
  padding: 60px;
  margin: 80px 0; }
  .c-content-feature-15 .c-feature-15-container .c-feature-15-title {
    margin-bottom: 30px;
    padding-left: 15px;
    border-left: 3px solid; }
  .c-content-feature-15 .c-feature-15-container .c-feature-15-desc {
    margin-bottom: 30px; }
  .c-content-feature-15 .c-feature-15-container .c-feature-15-img {
    width: 100%;
    height: auto; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-feature-15 .c-feature-15-container .c-feature-15-img {
    margin-bottom: 30px; } }

.c-content-feature-16 .c-feature-16-container {
  padding: 80px 60px; }
  .c-content-feature-16 .c-feature-16-container .c-feature-16-line {
    width: 3em;
    height: 1px;
    margin-bottom: 20px; }
  .c-content-feature-16 .c-feature-16-container .c-feature-16-title {
    margin-bottom: 30px; }
  .c-content-feature-16 .c-feature-16-container .c-feature-16-desc {
    margin-bottom: 30px; }

.c-content-person-1 .c-body {
  padding: 20px 0; }
  .c-content-person-1 .c-body .c-head .c-name {
    display: inline-block;
    float: left;
    font-size: 20px;
    font-weight: 400;
    color: #3f444a; }
  .c-content-person-1 .c-body .c-head .c-socials {
    display: inline-block;
    float: right;
    list-style: none;
    padding: 0;
    margin: 4px 0 0 0; }
    .c-content-person-1 .c-body .c-head .c-socials > li {
      padding: 0;
      margin: 0;
      display: inline-block; }
      .c-content-person-1 .c-body .c-head .c-socials > li > a:hover {
        text-decoration: none; }
      .c-content-person-1 .c-body .c-head .c-socials > li > a > i {
        color: #a3aeb9;
        padding: 4px;
        font-size: 16px;
        border-radius: 100%; }
        .c-content-person-1 .c-body .c-head .c-socials > li > a > i:hover {
          color: #fff; }
  .c-content-person-1 .c-body .c-position {
    display: block;
    clear: both;
    margin-top: 0px;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #7a838e; }
  .c-content-person-1 .c-body p {
    display: block;
    clear: both;
    padding: 10px 0 0 0;
    color: #5c6873; }

.c-content-person-1.c-option-2 .c-body {
  padding: 30px;
  background: #fff; }
  .c-content-person-1.c-option-2 .c-body p {
    padding-bottom: 0; }

.c-content-person-1.c-bordered {
  border: 1px solid #eeeeee;
  padding: 20px; }

.c-content-person-1.c-shadow {
  box-shadow: 0px 3px 18px -5px rgba(0, 0, 0, 0.3); }

.c-content-client-logos-1 {
  padding-left: 20px; }
  .c-content-client-logos-1 .c-logos {
    padding-left: 20px; }
    .c-content-client-logos-1 .c-logos .row > div {
      padding: 0; }
  .c-content-client-logos-1 .c-logo {
    display: block;
    overflow: hidden; }
  .c-content-client-logos-1 .c-logo-1 {
    border-right: 1px solid #edeff0;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-2 {
    border-right: 1px solid #edeff0;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-3 {
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-4 {
    border-right: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-5 {
    border-right: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-img-pos {
    position: relative;
    left: 50%;
    margin-left: -90px; }

@media (max-width: 1199px) {
  /* <= 1199px */
  .c-content-client-logos-1 .c-logo-2 {
    border-right: 1px solid #edeff0;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-3 {
    border-right: none;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-4 {
    border-right: 1px solid #edeff0;
    border-bottom: none; } }

@media (max-width: 991px) {
  /* <= 991px */
  .c-content-client-logos-1 .c-logos .c-logo-2 {
    border-right: none;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logos .c-logo-3 {
    border-right: 1px solid #edeff0;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logos .c-logo-4 {
    border-right: none;
    border-bottom: 1px solid #edeff0; } }

@media (max-width: 768px) {
  /* >= 768px */
  .c-content-client-logos-1 .c-logo-2 {
    border-right: none;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-3 {
    border-bottom: 1px solid #edeff0;
    border-right: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-4 {
    border-right: none;
    border-bottom: 1px solid #edeff0; } }

@media (max-width: 767px) {
  /* <= 767px */
  .c-content-client-logos-1 {
    padding: 0;
    margin-top: 40px; }
    .c-content-client-logos-1 .c-logos {
      padding: 0; }
    .c-content-client-logos-1 .c-logo-2 {
      border-right: 1px solid #edeff0;
      border-bottom: 1px solid #edeff0; }
    .c-content-client-logos-1 .c-logo-3 {
      border-bottom: 1px solid #edeff0;
      border-right: none; }
    .c-content-client-logos-1 .c-logo-4 {
      border-right: 1px solid #edeff0;
      border-bottom: none; } }

@media (max-width: 480px) {
  /* >= 480px */
  .c-content-client-logos-1 .c-logo-2 {
    border-right: none;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-3 {
    border-right: 1px solid #edeff0;
    border-bottom: 1px solid #edeff0; }
  .c-content-client-logos-1 .c-logo-4 {
    border-right: none;
    border-bottom: 1px solid #edeff0; } }

.c-content-client-logos-slider-1 .item {
  text-align: center; }
  .c-content-client-logos-slider-1 .item img {
    max-width: 230px;
    opacity: 0.8;
    filter: alphaopacity=80;
    transition: all 0.2s; }
    .c-content-client-logos-slider-1 .item img:hover {
      opacity: 1;
      filter: alphaopacity=100;
      transition: all 0.2s; }

.c-content-subscribe-form-1 {
  padding: 5px 0 13px 0; }
  .c-content-subscribe-form-1 .c-title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 10px; }
  .c-content-subscribe-form-1 .c-body {
    color: #6a7887; }
  .c-content-subscribe-form-1 form {
    padding-top: 30px; }
  .c-content-subscribe-form-1 .input-group > .form-control {
    color: #6f7b89;
    font-size: 17px;
    font-weight: 300;
    border-radius: 0;
    box-shadow: none;
    border-color: #41464c;
    background: transparent;
    padding: 8px 16px; }
    .c-content-subscribe-form-1 .input-group > .form-control::-moz-placeholder {
      color: #58626d; }
    .c-content-subscribe-form-1 .input-group > .form-control:-moz-placeholder {
      color: #58626d; }
    .c-content-subscribe-form-1 .input-group > .form-control:-ms-input-placeholder {
      color: #58626d; }
    .c-content-subscribe-form-1 .input-group > .form-control::-webkit-input-placeholder {
      color: #58626d; }
    .c-content-subscribe-form-1 .input-group > .form-control:focus {
      box-shadow: none;
      border-color: #474f59; }
  .c-content-subscribe-form-1 .input-group .btn {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0;
    border: 0; }
  .c-content-subscribe-form-1.c-subscribe-form-light .c-title {
    color: #3f444a; }
  .c-content-subscribe-form-1.c-subscribe-form-light .c-body {
    color: #5c6873; }
  .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control {
    background: #ffffff;
    color: #5c6873;
    border-color: transparent; }
    .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control::-moz-placeholder {
      color: #73828f; }
    .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control:-moz-placeholder {
      color: #73828f; }
    .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control:-ms-input-placeholder {
      color: #73828f; }
    .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control::-webkit-input-placeholder {
      color: #73828f; }
    .c-content-subscribe-form-1.c-subscribe-form-light .input-group > .form-control:focus {
      border-color: transparent; }

.c-content-tab-1 .nav,
.c-content-tab-1 .nav.nav-justified {
  border: 0;
  border-bottom: 3px solid none; }
  .c-content-tab-1 .nav > li > a,
  .c-content-tab-1 .nav.nav-justified > li > a {
    color: #3f444a;
    border: 0;
    border-bottom: 3px solid none; }
  .c-content-tab-1 .nav > li:hover,
  .c-content-tab-1 .nav > li:focus,
  .c-content-tab-1 .nav > li:active,
  .c-content-tab-1 .nav > li.active,
  .c-content-tab-1 .nav.nav-justified > li:hover,
  .c-content-tab-1 .nav.nav-justified > li:focus,
  .c-content-tab-1 .nav.nav-justified > li:active,
  .c-content-tab-1 .nav.nav-justified > li.active {
    background: none; }
    .c-content-tab-1 .nav > li:hover > a,
    .c-content-tab-1 .nav > li:focus > a,
    .c-content-tab-1 .nav > li:active > a,
    .c-content-tab-1 .nav > li.active > a,
    .c-content-tab-1 .nav.nav-justified > li:hover > a,
    .c-content-tab-1 .nav.nav-justified > li:focus > a,
    .c-content-tab-1 .nav.nav-justified > li:active > a,
    .c-content-tab-1 .nav.nav-justified > li.active > a {
      background: none;
      transition: none;
      border: 0;
      border-bottom: 3px solid #32c5d2; }
  .c-content-tab-1 .nav.tabs-left,
  .c-content-tab-1 .nav.nav-justified.tabs-left {
    border: 0;
    border-right: 1px solid #eeeeee; }
    .c-content-tab-1 .nav.tabs-left > li > a,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li > a {
      border: 0;
      border-right: 3px solid transparent; }
    .c-content-tab-1 .nav.tabs-left > li:hover,
    .c-content-tab-1 .nav.tabs-left > li:focus,
    .c-content-tab-1 .nav.tabs-left > li:active,
    .c-content-tab-1 .nav.tabs-left > li.active,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li:hover,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li:focus,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li:active,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li.active {
      background: #f7f7f7; }
      .c-content-tab-1 .nav.tabs-left > li:hover > a,
      .c-content-tab-1 .nav.tabs-left > li:focus > a,
      .c-content-tab-1 .nav.tabs-left > li:active > a,
      .c-content-tab-1 .nav.tabs-left > li.active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-left > li:hover > a,
      .c-content-tab-1 .nav.nav-justified.tabs-left > li:focus > a,
      .c-content-tab-1 .nav.nav-justified.tabs-left > li:active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-left > li.active > a {
        border-radius: 0;
        background: none;
        transition: none;
        border: 0;
        border-right: 3px solid #32c5d2; }
    .c-content-tab-1 .nav.tabs-left > li:last-child > a,
    .c-content-tab-1 .nav.nav-justified.tabs-left > li:last-child > a {
      border-bottom: 0; }
  .c-content-tab-1 .nav.tabs-right,
  .c-content-tab-1 .nav.nav-justified.tabs-right {
    border: 0;
    border-left: 1px solid #eeeeee; }
    .c-content-tab-1 .nav.tabs-right > li > a,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li > a {
      border: 0;
      border-left: 3px solid transparent; }
    .c-content-tab-1 .nav.tabs-right > li:hover,
    .c-content-tab-1 .nav.tabs-right > li:focus,
    .c-content-tab-1 .nav.tabs-right > li:active,
    .c-content-tab-1 .nav.tabs-right > li.active,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li:hover,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li:focus,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li:active,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li.active {
      background: #f7f7f7; }
      .c-content-tab-1 .nav.tabs-right > li:hover > a,
      .c-content-tab-1 .nav.tabs-right > li:focus > a,
      .c-content-tab-1 .nav.tabs-right > li:active > a,
      .c-content-tab-1 .nav.tabs-right > li.active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-right > li:hover > a,
      .c-content-tab-1 .nav.nav-justified.tabs-right > li:focus > a,
      .c-content-tab-1 .nav.nav-justified.tabs-right > li:active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-right > li.active > a {
        border-radius: 0;
        background: none;
        transition: none;
        border: 0;
        border-left: 3px solid #32c5d2; }
    .c-content-tab-1 .nav.tabs-right > li:last-child > a,
    .c-content-tab-1 .nav.nav-justified.tabs-right > li:last-child > a {
      border-bottom: 0; }
  .c-content-tab-1 .nav.tabs-below,
  .c-content-tab-1 .nav.nav-justified.tabs-below {
    border: 0;
    border-top: 0; }
    .c-content-tab-1 .nav.tabs-below > li > a,
    .c-content-tab-1 .nav.nav-justified.tabs-below > li > a {
      border: 0;
      border-top: 3px solid transparent; }
    .c-content-tab-1 .nav.tabs-below > li:hover,
    .c-content-tab-1 .nav.tabs-below > li:focus,
    .c-content-tab-1 .nav.tabs-below > li:active,
    .c-content-tab-1 .nav.tabs-below > li.active,
    .c-content-tab-1 .nav.nav-justified.tabs-below > li:hover,
    .c-content-tab-1 .nav.nav-justified.tabs-below > li:focus,
    .c-content-tab-1 .nav.nav-justified.tabs-below > li:active,
    .c-content-tab-1 .nav.nav-justified.tabs-below > li.active {
      background: none; }
      .c-content-tab-1 .nav.tabs-below > li:hover > a,
      .c-content-tab-1 .nav.tabs-below > li:focus > a,
      .c-content-tab-1 .nav.tabs-below > li:active > a,
      .c-content-tab-1 .nav.tabs-below > li.active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-below > li:hover > a,
      .c-content-tab-1 .nav.nav-justified.tabs-below > li:focus > a,
      .c-content-tab-1 .nav.nav-justified.tabs-below > li:active > a,
      .c-content-tab-1 .nav.nav-justified.tabs-below > li.active > a {
        border-radius: 0;
        background: none;
        transition: none;
        border: 0;
        border-top: 3px solid #32c5d2; }

.c-content-tab-1 .tab-content {
  padding: 0; }
  .c-content-tab-1 .tab-content.c-bordered {
    border: 1px solid #eeeeee; }
  .c-content-tab-1 .tab-content.c-left-bordered {
    border-left: 1px solid #eeeeee; }
  .c-content-tab-1 .tab-content.c-right-bordered {
    border-right: 1px solid #eeeeee; }
  .c-content-tab-1 .tab-content.c-padding-sm {
    padding: 5px; }
  .c-content-tab-1 .tab-content.c-padding {
    padding: 15px; }
  .c-content-tab-1 .tab-content.c-padding-lg {
    padding: 30px; }

.c-content-tab-2 > .nav {
  text-align: center; }
  .c-content-tab-2 > .nav > li {
    float: none;
    display: inline-block; }
    .c-content-tab-2 > .nav > li > a > i {
      font-size: 38px; }
    .c-content-tab-2 > .nav > li > a > .c-title {
      display: block;
      margin: 20px 0;
      color: #525e64; }
    .c-content-tab-2 > .nav > li > a > .c-content-line-icon {
      width: 55px;
      height: 55px; }
    .c-content-tab-2 > .nav > li.active > .c-arrow {
      position: relative; }
      .c-content-tab-2 > .nav > li.active > .c-arrow:before {
        bottom: 100%;
        left: 50%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 13px solid transparent;
        margin-left: -13px; }
    .c-content-tab-2 > .nav > li:not(.active) > a > .c-content-line-icon {
      opacity: 0.9;
      filter: alphaopacity=90;
      background-image: url(../img/content/line-icons/dark1.png); }
    .c-content-tab-2 > .nav > li + li {
      margin-left: 20px; }

.c-content-tab-2 > .c-tab-content .tab-content {
  padding: 80px 0;
  overflow: auto; }

.c-content-tab-2 > .c-tab-content .c-tab-pane > h4 {
  margin-bottom: 16px; }

.c-content-tab-2 > .c-tab-content .c-tab-pane > img {
  float: right;
  margin-left: 100px;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1); }

.c-content-tab-2 > .c-tab-content .c-tab-pane > .c-title {
  line-height: 1.6em; }

.c-content-tab-2 > .c-tab-content .c-tab-pane p {
  color: #838e9a; }

.c-content-tab-2 > .c-tab-content .c-tab-pane > .btn {
  margin-top: 12px; }

.c-content-tab-2.c-opt-1 > .nav li > a {
  color: #cfd9e6;
  background-color: transparent;
  transition: all 0.2s; }
  .c-content-tab-2.c-opt-1 > .nav li > a:hover {
    color: #32c5d2;
    transition: all 0.2s; }

.c-content-tab-2.c-opt-1 > .nav li:hover > a {
  color: #32c5d2; }

.c-content-tab-2.c-opt-1 > .nav li.active > a {
  color: #32c5d2; }
  .c-content-tab-2.c-opt-1 > .nav li.active > a:hover {
    color: #32c5d2; }

.c-content-tab-2.c-opt-1 > .nav li.active > .c-arrow:before {
  border-bottom-color: #2f353b; }

.c-content-tab-2.c-opt-1 > .nav.c-hover-lighten a:hover {
  opacity: 1; }

.c-content-tab-2.c-opt-1 > .c-tab-content {
  background-color: #2f353b; }
  .c-content-tab-2.c-opt-1 > .c-tab-content .c-tab-pane > h4 {
    color: #ffffff; }
  .c-content-tab-2.c-opt-1 > .c-tab-content .c-tab-pane > .c-title {
    color: #97a1ac; }

.c-title-tab-2 > h4 {
  margin: 15px 0 36px;
  font-weight: 400; }
  .c-title-tab-2 > h4 > .c-title-border {
    width: 65px;
    height: 2px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px; }

.c-title-tab-2.c-opt-title-1 > h4 {
  color: #455055; }
  .c-title-tab-2.c-opt-title-1 > h4 > .c-title-border {
    background-color: #22afd7; }

.c-title-tab-2.c-opt-title-2 > h4 {
  color: #455055; }
  .c-title-tab-2.c-opt-title-2 > h4 > .c-title-border {
    background-color: #71c9ce; }

.c-title-tab-2.c-opt-title-3 > h4 {
  color: #ffffff; }
  .c-title-tab-2.c-opt-title-3 > h4 > .c-title-border {
    background-color: #ff6b57; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-tab-2 > .nav a > i {
    font-size: 40px; }
  .c-content-tab-2 > .nav a > .c-title {
    margin: 10px 0; } }

@media (max-width: 767px) {
  /* 766px */
  .c-content-tab-2 > .nav li.active > .c-arrow {
    position: static; }
    .c-content-tab-2 > .nav li.active > .c-arrow:before {
      border: 0; }
  .c-content-tab-2 > .c-tab-content img {
    width: 100%;
    float: none;
    clear: both;
    padding: 0;
    margin-bottom: 20px; }
  .c-content-tab-2 > .c-tab-content h4 {
    margin: 20px 0; } }

.c-content-tab-3 .nav {
  text-align: center;
  width: 1170px;
  margin: auto; }
  .c-content-tab-3 .nav > li {
    float: left;
    display: inline-block; }
    .c-content-tab-3 .nav > li > a {
      border-radius: 0px;
      padding: 20px 50px; }
    .c-content-tab-3 .nav > li + li {
      margin-left: 2px; }
      .c-content-tab-3 .nav > li + li > .c-separator {
        top: 20px;
        left: -2px;
        width: 2px;
        height: 21px;
        position: absolute; }
    .c-content-tab-3 .nav > li.active > .c-separator {
      visibility: hidden; }
    .c-content-tab-3 .nav > li.active + li > .c-separator {
      visibility: hidden; }

.c-content-tab-3 > .c-tab-content {
  padding: 85px 0 60px 0; }
  .c-content-tab-3 > .c-tab-content ul.c-items {
    margin: 0;
    padding: 0; }
    .c-content-tab-3 > .c-tab-content ul.c-items > li {
      margin: 0 0 0 -13px;
      list-style: none; }
      .c-content-tab-3 > .c-tab-content ul.c-items > li + li {
        padding-top: 25px; }
      .c-content-tab-3 > .c-tab-content ul.c-items > li > h4 {
        margin: 0 0 15px; }
        .c-content-tab-3 > .c-tab-content ul.c-items > li > h4 > i {
          margin-right: 10px; }
  .c-content-tab-3 > .c-tab-content .btn {
    margin: 32px 0 0 -13px; }
  .c-content-tab-3 > .c-tab-content ul.c-photos {
    margin-right: -30px; }
    .c-content-tab-3 > .c-tab-content ul.c-photos > li {
      list-style: none; }
      .c-content-tab-3 > .c-tab-content ul.c-photos > li + li {
        margin-bottom: 20px; }

.c-content-tab-3.c-opt-1 .nav > li > a {
  color: #7f96b2;
  background-color: #edf1f5; }
  .c-content-tab-3.c-opt-1 .nav > li > a:hover {
    background-color: #ffffff; }

.c-content-tab-3.c-opt-1 .nav > li.active > a {
  color: #32c5d2;
  background-color: #ffffff; }

.c-content-tab-3.c-opt-1 > .c-tab-content {
  background-color: #ffffff; }
  .c-content-tab-3.c-opt-1 > .c-tab-content ul.c-items > li > h4 {
    color: #5f6f77; }
    .c-content-tab-3.c-opt-1 > .c-tab-content ul.c-items > li > h4 > i {
      color: #78a2c9; }
  .c-content-tab-3.c-opt-1 > .c-tab-content ul.c-items > li > p {
    color: #7d929d; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-tab-3 .nav > li {
    width: 100%;
    border-bottom: 1px solid #fff; }
    .c-content-tab-3 .nav > li > .c-separator {
      display: none; }
    .c-content-tab-3 .nav > li + li {
      margin: 0; }
    .c-content-tab-3 .nav > li > a {
      text-align: left; }
  .c-content-tab-3 > .c-tab-content {
    padding: 25px 0 20px 0; }
    .c-content-tab-3 > .c-tab-content .c-photos {
      margin: 50px -28px 0;
      padding: 0; }
      .c-content-tab-3 > .c-tab-content .c-photos + .c-photos {
        margin: 0 -28px 0; }
        .c-content-tab-3 > .c-tab-content .c-photos + .c-photos > li + li {
          margin-top: 20px; } }

.c-content-tab-4 > .nav > li {
  text-align: center; }
  .c-content-tab-4 > .nav > li > a {
    font-weight: 600;
    padding: 20px 15px;
    border: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .c-content-tab-4 > .nav > li + li {
    padding-left: 1px; }
  .c-content-tab-4 > .nav > li:last-child {
    margin-left: 0.03px; }

.c-content-tab-4 > .tab-content .c-tab-items {
  list-style-type: none;
  margin: 0;
  padding: 30px 20px 30px 0; }
  .c-content-tab-4 > .tab-content .c-tab-items > li .c-photo {
    padding-left: 27px; }
  .c-content-tab-4 > .tab-content .c-tab-items > li h4 {
    margin: 0;
    padding-bottom: 8px; }
  .c-content-tab-4 > .tab-content .c-tab-items > li p, .c-content-tab-4 > .tab-content .c-tab-items > li h4 {
    margin-left: -6px; }
  .c-content-tab-4 > .tab-content .c-tab-items > li:nth-child(even) {
    padding: 25px 0; }
  .c-content-tab-4 > .tab-content .c-tab-items > li:last-child {
    padding-bottom: 0; }

.c-content-tab-4.c-tab-bordered > .tab-content {
  border: 1px solid #eef1f3;
  border-top: 0; }

.c-content-tab-4.c-opt-1 > .nav > li > a {
  color: #ffffff;
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-1 > .nav > li > a:hover, .c-content-tab-4.c-opt-1 > .nav > li > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-1 > .nav > li.active > a,
.c-content-tab-4.c-opt-1 > .nav > li:active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-1 > .nav > li:nth-child(even) > a {
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-1 > .nav > li:nth-child(even) > a:hover, .c-content-tab-4.c-opt-1 > .nav > li:nth-child(even) > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-1 > .nav > li:nth-child(even).active > a, .c-content-tab-4.c-opt-1 > .nav > li:nth-child(even):active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-1 > .tab-content {
  background-color: #ffffff; }
  .c-content-tab-4.c-opt-1 > .tab-content .c-tab-items > li h4 {
    color: #5f6f77; }
  .c-content-tab-4.c-opt-1 > .tab-content .c-tab-items > li p {
    color: #9aaab3; }

.c-content-tab-4.c-opt-2 > .nav > li > a {
  color: #ffffff;
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-2 > .nav > li > a:hover, .c-content-tab-4.c-opt-2 > .nav > li > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-2 > .nav > li.active > a,
.c-content-tab-4.c-opt-2 > .nav > li:active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-2 > .nav > li:nth-child(even) > a {
  background-color: #cfdae4; }
  .c-content-tab-4.c-opt-2 > .nav > li:nth-child(even) > a:hover, .c-content-tab-4.c-opt-2 > .nav > li:nth-child(even) > a:focus {
    background-color: #6d8fad; }

.c-content-tab-4.c-opt-2 > .nav > li:nth-child(even).active > a, .c-content-tab-4.c-opt-2 > .nav > li:nth-child(even):active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-2 > .tab-content {
  background-color: #ffffff; }
  .c-content-tab-4.c-opt-2 > .tab-content .c-tab-items > li h4 {
    color: #5f6f77; }
  .c-content-tab-4.c-opt-2 > .tab-content .c-tab-items > li p {
    color: #9aaab3; }

.c-content-tab-4.c-opt-3 > .nav > li > a {
  color: #ffffff;
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-3 > .nav > li > a:hover, .c-content-tab-4.c-opt-3 > .nav > li > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-3 > .nav > li.active > a,
.c-content-tab-4.c-opt-3 > .nav > li:active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-3 > .nav > li:nth-child(even) > a {
  background-color: #cfdae4; }
  .c-content-tab-4.c-opt-3 > .nav > li:nth-child(even) > a:hover, .c-content-tab-4.c-opt-3 > .nav > li:nth-child(even) > a:focus {
    background-color: #6d8fad; }

.c-content-tab-4.c-opt-3 > .nav > li:nth-child(even).active > a, .c-content-tab-4.c-opt-3 > .nav > li:nth-child(even):active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-3 > .tab-content {
  background-color: #f6f9fb; }
  .c-content-tab-4.c-opt-3 > .tab-content .c-tab-items > li h4 {
    color: #5f6f77; }
  .c-content-tab-4.c-opt-3 > .tab-content .c-tab-items > li p {
    color: #9aaab3; }

.c-content-tab-4.c-opt-4 > .nav > li > a {
  color: #ffffff;
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-4 > .nav > li > a:hover, .c-content-tab-4.c-opt-4 > .nav > li > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-4 > .nav > li.active > a,
.c-content-tab-4.c-opt-4 > .nav > li:active > a {
  color: #ff6b57;
  background-color: #3e495e; }

.c-content-tab-4.c-opt-4 > .nav > li:nth-child(even) > a {
  background-color: #cfdae4; }
  .c-content-tab-4.c-opt-4 > .nav > li:nth-child(even) > a:hover, .c-content-tab-4.c-opt-4 > .nav > li:nth-child(even) > a:focus {
    background-color: #6d8fad; }

.c-content-tab-4.c-opt-4 > .nav > li:nth-child(even).active > a, .c-content-tab-4.c-opt-4 > .nav > li:nth-child(even):active > a {
  color: #ff6b57;
  background-color: #3e495e; }

.c-content-tab-4.c-opt-4 > .tab-content {
  background-color: #3e495e; }
  .c-content-tab-4.c-opt-4 > .tab-content .c-tab-items > li h4 {
    color: #ffffff; }
  .c-content-tab-4.c-opt-4 > .tab-content .c-tab-items > li p {
    color: #616b7f; }

.c-content-tab-4.c-opt-5 > .nav > li > a {
  color: #ffffff;
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-5 > .nav > li > a:hover, .c-content-tab-4.c-opt-5 > .nav > li > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-5 > .nav > li.active > a,
.c-content-tab-4.c-opt-5 > .nav > li:active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-5 > .nav > li:nth-child(even) > a {
  background-color: #d5e0ea; }
  .c-content-tab-4.c-opt-5 > .nav > li:nth-child(even) > a:hover, .c-content-tab-4.c-opt-5 > .nav > li:nth-child(even) > a:focus {
    background-color: #6f95b7; }

.c-content-tab-4.c-opt-5 > .nav > li:nth-child(even).active > a, .c-content-tab-4.c-opt-5 > .nav > li:nth-child(even):active > a {
  color: #ffffff;
  background-color: #5bc2ce; }

.c-content-tab-4.c-opt-5 > .tab-content {
  background-color: #ffffff; }
  .c-content-tab-4.c-opt-5 > .tab-content .c-tab-items > li h4 {
    color: #5f6f77; }
  .c-content-tab-4.c-opt-5 > .tab-content .c-tab-items > li p {
    color: #9aaab3; }

@media (max-width: 1199px) {
  /* 1199px */
  .c-content-tab-4 > .nav > li:last-child {
    margin-left: 0.06px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-content-tab-4 > .nav > li:last-child {
    margin-left: 0.01px; } }

@media (max-width: 767px) {
  /* 766px */
  .c-content-tab-4 > .tab-content .c-tab-items > li .c-photo {
    padding-bottom: 15px; }
  .c-content-tab-4 > .tab-content .c-tab-items > li h4, .c-content-tab-4 > .tab-content .c-tab-items > li p {
    padding: 0 25px; } }

.c-content-tab-5 .c-nav-tab {
  text-align: center;
  margin-bottom: 45px; }
  .c-content-tab-5 .c-nav-tab > li {
    display: inline-block;
    float: none;
    margin-bottom: 15px; }
    .c-content-tab-5 .c-nav-tab > li > a {
      background-color: #eff4f6;
      border-radius: 0;
      padding: 10px 20px; }
    .c-content-tab-5 .c-nav-tab > li + li {
      margin-left: 0px; }
  .c-content-tab-5 .c-nav-tab.c-arrow a {
    position: relative; }
    .c-content-tab-5 .c-nav-tab.c-arrow a:after {
      left: 0;
      top: 80%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-top: 0;
      border: 8px solid transparent; }
    .c-content-tab-5 .c-nav-tab.c-arrow a:hover:after {
      -webkit-transition: back 0.4s ease-out;
      -moz-transition: all 0.4s ease-out;
      -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out; }

.c-content-tab-5 .c-nav-tab > li.active > a,
.c-content-tab-5 .c-nav-tab > li a:hover {
  color: #fff;
  background-color: #d05163; }

.c-content-tab-5 .c-nav-tab.c-arrow a:hover:after {
  border-left-color: #d05163; }

.c-content-tab-5 .c-nav-tab.c-arrow .active > a:after {
  border-left-color: #d05163; }

.c-content-tab-5 .c-space {
  margin: 15px 0; }

@media (max-width: 767px) {
  /* 766px */
  .c-content-tab-5 .c-no-left {
    padding-left: 15px; }
  .c-content-tab-5 .c-no-right {
    padding-right: 15px; } }

.c-content-step-1 {
  text-align: center;
  padding-bottom: 20px; }
  .c-content-step-1 > .c-icon {
    padding-bottom: 30px; }
    .c-content-step-1 > .c-icon > .c-hr {
      display: inline-block; }
      .c-content-step-1 > .c-icon > .c-hr:before, .c-content-step-1 > .c-icon > .c-hr:after {
        border-top: 1px solid black;
        display: block;
        height: 1px;
        content: " ";
        width: 38%;
        position: absolute;
        left: 0;
        top: 2.2em; }
      .c-content-step-1 > .c-icon > .c-hr:after {
        right: 0;
        left: auto; }
      .c-content-step-1 > .c-icon > .c-hr.c-hr-first:before {
        width: 34%;
        left: 4%; }
      .c-content-step-1 > .c-icon > .c-hr.c-hr-last:after {
        width: 34%;
        right: 4%; }
      .c-content-step-1 > .c-icon > .c-hr > i {
        font-size: 4.2em; }
  .c-content-step-1 > .c-title {
    margin-top: 20px; }
  .c-content-step-1 > .c-description {
    padding: 0px 60px;
    margin: 20px 0 30px 0; }
  .c-content-step-1.c-opt-1 > .c-icon {
    color: #7d90b0; }
    .c-content-step-1.c-opt-1 > .c-icon > .c-hr:before, .c-content-step-1.c-opt-1 > .c-icon > .c-hr:after {
      border-color: #919191; }
  .c-content-step-1.c-opt-1 > .c-description {
    color: #5c6873; }

.c-title-step-1 > h4 {
  margin: 15px 0; }

.c-title-step-1 > p {
  margin-bottom: 68px; }

.c-title-step-1.c-opt-title-1 > h4 {
  color: #455055; }
  .c-title-step-1.c-opt-title-1 > h4 > span {
    color: #455055; }

.c-title-step-1.c-opt-title-1 > p {
  color: #a0b0b8; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-step-1 {
    padding-bottom: 60px; }
    .c-content-step-1 > .c-icon > .c-hr:after {
      width: 34%;
      right: 4%; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-first:after {
      width: 38%;
      right: 0;
      left: auto; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-last:before {
      width: 41%;
      left: 2%; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-last:after {
      width: 42%;
      right: 2%; } }

@media (max-width: 767px) {
  /* 766px */
  .c-content-step-1 {
    padding-bottom: 60px; }
    .c-content-step-1 > .c-icon > .c-hr:before {
      width: 34%;
      left: 4%; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-first:after {
      width: 35%;
      right: 4%; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-last:before {
      width: 35%;
      left: 4%; }
    .c-content-step-1 > .c-icon > .c-hr.c-hr-last:after {
      width: 35%;
      right: 4%; }
    .c-content-step-1 > .c-explore {
      padding-bottom: 60px; } }

.c-content-step-3 .c-steps-3-block i {
  font-size: 30px;
  border: solid 2px #fff;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  padding-top: 13px;
  margin-right: 11px;
  float: left; }

.c-content-step-3 .c-steps-3-block h2 {
  margin-top: 15px; }

.c-content-step-3 .c-steps-3-block span {
  width: 36px;
  height: 71px;
  background: url(../img/content/misc/step3-angle-right.png) no-repeat 100% 50%;
  position: absolute;
  top: 0;
  right: 10px; }

.c-content-step-3 .c-steps-3-block > .c-steps-3-title {
  padding-right: 10%;
  padding-left: 71px; }

@media (max-width: 991px) {
  .c-content-step-3 {
    padding: 15px; }
    .c-content-step-3 .c-steps-3-block {
      padding: 15px;
      border: 1px solid;
      border-color: rgba(255, 255, 255, 0.7);
      margin-bottom: 20px; }
      .c-content-step-3 .c-steps-3-block:last-child {
        margin-bottom: 0; }
    .c-content-step-3 span {
      display: none;
      background: none; } }

.c-content-tile-1 {
  overflow: hidden; }
  .c-content-tile-1 .c-tile-content {
    overflow: hidden; }
    .c-content-tile-1 .c-tile-content.c-arrow-left, .c-content-tile-1 .c-tile-content.c-arrow-right {
      position: relative; }
      .c-content-tile-1 .c-tile-content.c-arrow-left:after, .c-content-tile-1 .c-tile-content.c-arrow-right:after {
        top: calc(50% - 13px);
        border: solid transparent;
        content: " ";
        position: absolute;
        pointer-events: none;
        border-width: 13px;
        z-index: 99; }
    .c-content-tile-1 .c-tile-content.c-arrow-left:after {
      right: 0;
      border-right-color: #ffffff; }
    .c-content-tile-1 .c-tile-content.c-arrow-right:after {
      left: 0;
      border-left-color: #ffffff; }
    .c-content-tile-1 .c-tile-content.c-arrow-green.c-arrow-left:after {
      border-right-color: #32c5d2; }
    .c-content-tile-1 .c-tile-content.c-arrow-green.c-arrow-right:after {
      border-left-color: #32c5d2; }
    .c-content-tile-1 .c-tile-content.c-arrow-red-2.c-arrow-left:after {
      border-right-color: #e7505a; }
    .c-content-tile-1 .c-tile-content.c-arrow-red-2.c-arrow-right:after {
      border-left-color: #e7505a; }
    .c-content-tile-1 .c-tile-content.c-arrow-brown-2.c-arrow-left:after {
      border-right-color: #7a6a61; }
    .c-content-tile-1 .c-tile-content.c-arrow-brown-2.c-arrow-right:after {
      border-left-color: #7a6a61; }
    .c-content-tile-1 .c-tile-content.c-arrow-blue-3.c-arrow-left:after {
      border-right-color: #57bfe1; }
    .c-content-tile-1 .c-tile-content.c-arrow-blue-3.c-arrow-right:after {
      border-left-color: #57bfe1; }
    .c-content-tile-1 .c-tile-content .btn {
      margin: 10px 0; }
  .c-content-tile-1 .c-wrapper {
    padding: 10%; }
    .c-content-tile-1 .c-wrapper .c-tile-title {
      color: #3f444a;
      margin-top: 0;
      font-size: 20px; }
    .c-content-tile-1 .c-wrapper .c-tile-body {
      color: #5c6873;
      font-size: 17px; }
  .c-content-tile-1 .c-image {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; }

@media (max-width: 767px) {
  /* 767px */
  .c-content-tile-1 .c-tile-content.c-arrow-left:after, .c-content-tile-1 .c-tile-content.c-arrow-right:after {
    left: calc(50% - 13px); }
  .c-content-tile-1 .c-tile-content.c-arrow-left:after {
    bottom: 0;
    border-bottom-color: #ffffff;
    right: auto;
    top: auto;
    border-right-color: transparent; }
  .c-content-tile-1 .c-tile-content.c-arrow-right:after {
    top: 0;
    border-top-color: #ffffff;
    border-left-color: transparent; }
  .c-content-tile-1 .c-tile-content.c-arrow-green.c-arrow-left:after {
    border-right-color: transparent;
    border-bottom-color: #32c5d2; }
  .c-content-tile-1 .c-tile-content.c-arrow-green.c-arrow-right:after {
    border-left-color: transparent;
    border-top-color: #32c5d2; }
  .c-content-tile-1 .c-tile-content.c-arrow-red-2.c-arrow-left:after {
    border-right-color: transparent;
    border-bottom-color: #e7505a; }
  .c-content-tile-1 .c-tile-content.c-arrow-red-2.c-arrow-right:after {
    border-left-color: transparent;
    border-top-color: #e7505a; }
  .c-content-tile-1 .c-tile-content.c-arrow-brown-2.c-arrow-left:after {
    border-right-color: transparent;
    border-bottom-color: #7a6a61; }
  .c-content-tile-1 .c-tile-content.c-arrow-brown-2.c-arrow-right:after {
    border-left-color: transparent;
    border-top-color: #7a6a61; }
  .c-content-tile-1 .c-tile-content.c-arrow-blue-3.c-arrow-left:after {
    border-right-color: transparent;
    border-bottom-color: #57bfe1; }
  .c-content-tile-1 .c-tile-content.c-arrow-blue-3.c-arrow-right:after {
    border-left-color: transparent;
    border-top-color: #57bfe1; } }

.c-content-accordion-1 .panel-group {
  margin-bottom: 0; }

.c-content-accordion-1 .panel {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  border: 0;
  margin-bottom: 10px; }
  .c-content-accordion-1 .panel:last-child {
    margin-bottom: 0; }
  .c-content-accordion-1 .panel:last-child {
    margin-bottom: 0; }
  .c-content-accordion-1 .panel > .panel-heading {
    padding: 0;
    color: #ffffff; }
    .c-content-accordion-1 .panel > .panel-heading > .panel-title {
      padding: 0;
      margin: 0; }
      .c-content-accordion-1 .panel > .panel-heading > .panel-title > a {
        transition: all 0.2s;
        color: #fff;
        display: block;
        padding: 30px 30px 25px 30px;
        border-radius: 0;
        box-shadow: none; }
        .c-content-accordion-1 .panel > .panel-heading > .panel-title > a > i {
          margin-right: 5px;
          font-size: 17px; }
        .c-content-accordion-1 .panel > .panel-heading > .panel-title > a.collapsed {
          background-color: #fff;
          color: #3f444a; }
          .c-content-accordion-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
          .c-content-accordion-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
            color: #fff;
            transition: all 0.2s; }
  .c-content-accordion-1 .panel > .panel-collapse > .panel-body {
    border-top: none;
    padding: 0px 30px 35px 30px;
    color: #ffffff; }

.c-content-accordion-1.c-accordion-white .panel > .panel-heading > .panel-title > a {
  background-color: #FFFFFF; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-white .panel > .panel-heading > .panel-title > a {
    border: 2px solid #e6e6e6 !important; }
  .c-content-accordion-1.c-accordion-white .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-white .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-white .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #FFFFFF; }

.c-content-accordion-1.c-accordion-white .panel > .panel-collapse > .panel-body {
  background-color: #FFFFFF; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-white .panel > .panel-collapse > .panel-body {
    border: 2px solid #e6e6e6 !important; }

.c-content-accordion-1.c-accordion-black .panel > .panel-heading > .panel-title > a {
  background-color: #000000; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-black .panel > .panel-heading > .panel-title > a {
    border: 2px solid black !important; }
  .c-content-accordion-1.c-accordion-black .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-black .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-black .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #000000; }

.c-content-accordion-1.c-accordion-black .panel > .panel-collapse > .panel-body {
  background-color: #000000; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-black .panel > .panel-collapse > .panel-body {
    border: 2px solid black !important; }

.c-content-accordion-1.c-accordion-regular .panel > .panel-heading > .panel-title > a {
  background-color: #7f8c97; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-regular .panel > .panel-heading > .panel-title > a {
    border: 2px solid #66727d !important; }
  .c-content-accordion-1.c-accordion-regular .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-regular .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-regular .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #7f8c97; }

.c-content-accordion-1.c-accordion-regular .panel > .panel-collapse > .panel-body {
  background-color: #7f8c97; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-regular .panel > .panel-collapse > .panel-body {
    border: 2px solid #66727d !important; }

.c-content-accordion-1.c-accordion-green .panel > .panel-heading > .panel-title > a {
  background-color: #32c5d2; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green .panel > .panel-heading > .panel-title > a {
    border: 2px solid #26a1ab !important; }
  .c-content-accordion-1.c-accordion-green .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-green .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-green .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #32c5d2; }

.c-content-accordion-1.c-accordion-green .panel > .panel-collapse > .panel-body {
  background-color: #32c5d2; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green .panel > .panel-collapse > .panel-body {
    border: 2px solid #26a1ab !important; }

.c-content-accordion-1.c-accordion-green-1 .panel > .panel-heading > .panel-title > a {
  background-color: #52c9ba; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #37b1a1 !important; }
  .c-content-accordion-1.c-accordion-green-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-green-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-green-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #52c9ba; }

.c-content-accordion-1.c-accordion-green-1 .panel > .panel-collapse > .panel-body {
  background-color: #52c9ba; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #37b1a1 !important; }

.c-content-accordion-1.c-accordion-green-2 .panel > .panel-heading > .panel-title > a {
  background-color: #5dc09c; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #42a883 !important; }
  .c-content-accordion-1.c-accordion-green-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-green-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-green-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #5dc09c; }

.c-content-accordion-1.c-accordion-green-2 .panel > .panel-collapse > .panel-body {
  background-color: #5dc09c; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #42a883 !important; }

.c-content-accordion-1.c-accordion-green-3 .panel > .panel-heading > .panel-title > a {
  background-color: #84c68f; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #61b66f !important; }
  .c-content-accordion-1.c-accordion-green-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-green-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-green-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #84c68f; }

.c-content-accordion-1.c-accordion-green-3 .panel > .panel-collapse > .panel-body {
  background-color: #84c68f; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-green-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #61b66f !important; }

.c-content-accordion-1.c-accordion-red .panel > .panel-heading > .panel-title > a {
  background-color: #eb5d68; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red .panel > .panel-heading > .panel-title > a {
    border: 2px solid #e5303e !important; }
  .c-content-accordion-1.c-accordion-red .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-red .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-red .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #eb5d68; }

.c-content-accordion-1.c-accordion-red .panel > .panel-collapse > .panel-body {
  background-color: #eb5d68; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red .panel > .panel-collapse > .panel-body {
    border: 2px solid #e5303e !important; }

.c-content-accordion-1.c-accordion-red-1 .panel > .panel-heading > .panel-title > a {
  background-color: #cd6262; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #bf3d3d !important; }
  .c-content-accordion-1.c-accordion-red-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-red-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-red-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #cd6262; }

.c-content-accordion-1.c-accordion-red-1 .panel > .panel-collapse > .panel-body {
  background-color: #cd6262; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #bf3d3d !important; }

.c-content-accordion-1.c-accordion-red-2 .panel > .panel-heading > .panel-title > a {
  background-color: #e7505a; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #e12330 !important; }
  .c-content-accordion-1.c-accordion-red-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-red-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-red-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #e7505a; }

.c-content-accordion-1.c-accordion-red-2 .panel > .panel-collapse > .panel-body {
  background-color: #e7505a; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #e12330 !important; }

.c-content-accordion-1.c-accordion-red-3 .panel > .panel-heading > .panel-title > a {
  background-color: #d05163; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #bb3346 !important; }
  .c-content-accordion-1.c-accordion-red-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-red-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-red-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #d05163; }

.c-content-accordion-1.c-accordion-red-3 .panel > .panel-collapse > .panel-body {
  background-color: #d05163; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-red-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #bb3346 !important; }

.c-content-accordion-1.c-accordion-yellow .panel > .panel-heading > .panel-title > a {
  background-color: #FF6B57; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow .panel > .panel-heading > .panel-title > a {
    border: 2px solid #ff3e24 !important; }
  .c-content-accordion-1.c-accordion-yellow .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-yellow .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-yellow .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #FF6B57; }

.c-content-accordion-1.c-accordion-yellow .panel > .panel-collapse > .panel-body {
  background-color: #FF6B57; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow .panel > .panel-collapse > .panel-body {
    border: 2px solid #ff3e24 !important; }

.c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-heading > .panel-title > a {
  background-color: #c8d046; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #adb52e !important; }
  .c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #c8d046; }

.c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-collapse > .panel-body {
  background-color: #c8d046; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #adb52e !important; }

.c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-heading > .panel-title > a {
  background-color: #c5bf66; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #b4ad44 !important; }
  .c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #c5bf66; }

.c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-collapse > .panel-body {
  background-color: #c5bf66; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #b4ad44 !important; }

.c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-heading > .panel-title > a {
  background-color: #c5b96b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #b6a747 !important; }
  .c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #c5b96b; }

.c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-collapse > .panel-body {
  background-color: #c5b96b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-yellow-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #b6a747 !important; }

.c-content-accordion-1.c-accordion-blue .panel > .panel-heading > .panel-title > a {
  background-color: #3498DB; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue .panel > .panel-heading > .panel-title > a {
    border: 2px solid #217dbb !important; }
  .c-content-accordion-1.c-accordion-blue .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-blue .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-blue .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #3498DB; }

.c-content-accordion-1.c-accordion-blue .panel > .panel-collapse > .panel-body {
  background-color: #3498DB; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue .panel > .panel-collapse > .panel-body {
    border: 2px solid #217dbb !important; }

.c-content-accordion-1.c-accordion-blue-1 .panel > .panel-heading > .panel-title > a {
  background-color: #5e9cd1; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #3883c4 !important; }
  .c-content-accordion-1.c-accordion-blue-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-blue-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-blue-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #5e9cd1; }

.c-content-accordion-1.c-accordion-blue-1 .panel > .panel-collapse > .panel-body {
  background-color: #5e9cd1; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #3883c4 !important; }

.c-content-accordion-1.c-accordion-blue-2 .panel > .panel-heading > .panel-title > a {
  background-color: #5893dd; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #2e78d4 !important; }
  .c-content-accordion-1.c-accordion-blue-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-blue-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-blue-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #5893dd; }

.c-content-accordion-1.c-accordion-blue-2 .panel > .panel-collapse > .panel-body {
  background-color: #5893dd; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #2e78d4 !important; }

.c-content-accordion-1.c-accordion-blue-3 .panel > .panel-heading > .panel-title > a {
  background-color: #57bfe1; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #2cafd9 !important; }
  .c-content-accordion-1.c-accordion-blue-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-blue-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-blue-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #57bfe1; }

.c-content-accordion-1.c-accordion-blue-3 .panel > .panel-collapse > .panel-body {
  background-color: #57bfe1; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-blue-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #2cafd9 !important; }

.c-content-accordion-1.c-accordion-purple .panel > .panel-heading > .panel-title > a {
  background-color: #b771b0; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple .panel > .panel-heading > .panel-title > a {
    border: 2px solid #a3529b !important; }
  .c-content-accordion-1.c-accordion-purple .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-purple .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-purple .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #b771b0; }

.c-content-accordion-1.c-accordion-purple .panel > .panel-collapse > .panel-body {
  background-color: #b771b0; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple .panel > .panel-collapse > .panel-body {
    border: 2px solid #a3529b !important; }

.c-content-accordion-1.c-accordion-purple-1 .panel > .panel-heading > .panel-title > a {
  background-color: #a962bb; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #9147a3 !important; }
  .c-content-accordion-1.c-accordion-purple-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-purple-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-purple-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #a962bb; }

.c-content-accordion-1.c-accordion-purple-1 .panel > .panel-collapse > .panel-body {
  background-color: #a962bb; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #9147a3 !important; }

.c-content-accordion-1.c-accordion-purple-2 .panel > .panel-heading > .panel-title > a {
  background-color: #aa67a3; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #8e5088 !important; }
  .c-content-accordion-1.c-accordion-purple-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-purple-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-purple-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #aa67a3; }

.c-content-accordion-1.c-accordion-purple-2 .panel > .panel-collapse > .panel-body {
  background-color: #aa67a3; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #8e5088 !important; }

.c-content-accordion-1.c-accordion-purple-3 .panel > .panel-heading > .panel-title > a {
  background-color: #ac3773; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #852b59 !important; }
  .c-content-accordion-1.c-accordion-purple-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-purple-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-purple-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #ac3773; }

.c-content-accordion-1.c-accordion-purple-3 .panel > .panel-collapse > .panel-body {
  background-color: #ac3773; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-purple-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #852b59 !important; }

.c-content-accordion-1.c-accordion-brown .panel > .panel-heading > .panel-title > a {
  background-color: #8a7f68; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown .panel > .panel-heading > .panel-title > a {
    border: 2px solid #6d6452 !important; }
  .c-content-accordion-1.c-accordion-brown .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-brown .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-brown .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #8a7f68; }

.c-content-accordion-1.c-accordion-brown .panel > .panel-collapse > .panel-body {
  background-color: #8a7f68; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown .panel > .panel-collapse > .panel-body {
    border: 2px solid #6d6452 !important; }

.c-content-accordion-1.c-accordion-brown-1 .panel > .panel-heading > .panel-title > a {
  background-color: #685e47; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #4a4332 !important; }
  .c-content-accordion-1.c-accordion-brown-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-brown-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-brown-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #685e47; }

.c-content-accordion-1.c-accordion-brown-1 .panel > .panel-collapse > .panel-body {
  background-color: #685e47; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #4a4332 !important; }

.c-content-accordion-1.c-accordion-brown-2 .panel > .panel-heading > .panel-title > a {
  background-color: #7a6a61; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #5e514a !important; }
  .c-content-accordion-1.c-accordion-brown-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-brown-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-brown-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #7a6a61; }

.c-content-accordion-1.c-accordion-brown-2 .panel > .panel-collapse > .panel-body {
  background-color: #7a6a61; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #5e514a !important; }

.c-content-accordion-1.c-accordion-brown-3 .panel > .panel-heading > .panel-title > a {
  background-color: #9d8b81; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #847167 !important; }
  .c-content-accordion-1.c-accordion-brown-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-brown-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-brown-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #9d8b81; }

.c-content-accordion-1.c-accordion-brown-3 .panel > .panel-collapse > .panel-body {
  background-color: #9d8b81; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-brown-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #847167 !important; }

.c-content-accordion-1.c-accordion-dark .panel > .panel-heading > .panel-title > a {
  background-color: #2f353b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark .panel > .panel-heading > .panel-title > a {
    border: 2px solid #181b1f !important; }
  .c-content-accordion-1.c-accordion-dark .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-dark .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-dark .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #2f353b; }

.c-content-accordion-1.c-accordion-dark .panel > .panel-collapse > .panel-body {
  background-color: #2f353b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark .panel > .panel-collapse > .panel-body {
    border: 2px solid #181b1f !important; }

.c-content-accordion-1.c-accordion-dark-1 .panel > .panel-heading > .panel-title > a {
  background-color: #525e64; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #3b4448 !important; }
  .c-content-accordion-1.c-accordion-dark-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-dark-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-dark-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #525e64; }

.c-content-accordion-1.c-accordion-dark-1 .panel > .panel-collapse > .panel-body {
  background-color: #525e64; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #3b4448 !important; }

.c-content-accordion-1.c-accordion-dark-2 .panel > .panel-heading > .panel-title > a {
  background-color: #31383c; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #1a1e20 !important; }
  .c-content-accordion-1.c-accordion-dark-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-dark-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-dark-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #31383c; }

.c-content-accordion-1.c-accordion-dark-2 .panel > .panel-collapse > .panel-body {
  background-color: #31383c; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #1a1e20 !important; }

.c-content-accordion-1.c-accordion-dark-3 .panel > .panel-heading > .panel-title > a {
  background-color: #41515b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #2c373d !important; }
  .c-content-accordion-1.c-accordion-dark-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-dark-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-dark-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #41515b; }

.c-content-accordion-1.c-accordion-dark-3 .panel > .panel-collapse > .panel-body {
  background-color: #41515b; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-dark-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #2c373d !important; }

.c-content-accordion-1.c-accordion-grey .panel > .panel-heading > .panel-title > a {
  background-color: #eeeeee; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey .panel > .panel-heading > .panel-title > a {
    border: 2px solid #d4d4d4 !important; }
  .c-content-accordion-1.c-accordion-grey .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-grey .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-grey .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #eeeeee; }

.c-content-accordion-1.c-accordion-grey .panel > .panel-collapse > .panel-body {
  background-color: #eeeeee; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey .panel > .panel-collapse > .panel-body {
    border: 2px solid #d4d4d4 !important; }

.c-content-accordion-1.c-accordion-grey-1 .panel > .panel-heading > .panel-title > a {
  background-color: #f7f7f7; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-1 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #dedede !important; }
  .c-content-accordion-1.c-accordion-grey-1 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-grey-1 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-grey-1 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #f7f7f7; }

.c-content-accordion-1.c-accordion-grey-1 .panel > .panel-collapse > .panel-body {
  background-color: #f7f7f7; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-1 .panel > .panel-collapse > .panel-body {
    border: 2px solid #dedede !important; }

.c-content-accordion-1.c-accordion-grey-2 .panel > .panel-heading > .panel-title > a {
  background-color: #677083; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-2 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #515866 !important; }
  .c-content-accordion-1.c-accordion-grey-2 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-grey-2 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-grey-2 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #677083; }

.c-content-accordion-1.c-accordion-grey-2 .panel > .panel-collapse > .panel-body {
  background-color: #677083; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-2 .panel > .panel-collapse > .panel-body {
    border: 2px solid #515866 !important; }

.c-content-accordion-1.c-accordion-grey-3 .panel > .panel-heading > .panel-title > a {
  background-color: #7f8c97; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-3 .panel > .panel-heading > .panel-title > a {
    border: 2px solid #66727d !important; }
  .c-content-accordion-1.c-accordion-grey-3 .panel > .panel-heading > .panel-title > a.collapsed {
    background-color: #fff; }
    .c-content-accordion-1.c-accordion-grey-3 .panel > .panel-heading > .panel-title > a.collapsed:hover,
    .c-content-accordion-1.c-accordion-grey-3 .panel > .panel-heading > .panel-title > a.collapsed:focus {
      color: #fff;
      background-color: #7f8c97; }

.c-content-accordion-1.c-accordion-grey-3 .panel > .panel-collapse > .panel-body {
  background-color: #7f8c97; }
  .c-accordion-bordered .c-content-accordion-1.c-accordion-grey-3 .panel > .panel-collapse > .panel-body {
    border: 2px solid #66727d !important; }

@media (max-width: 1199px) {
  /* 991px */
  .c-content-accordion-1 .c-accordion {
    margin: 0;
    padding: 0; } }

.c-content-login-form .modal-dialog {
  width: 450px; }
  .c-content-login-form .modal-dialog .modal-content {
    margin-top: 125px; }
    .c-content-login-form .modal-dialog .modal-content .modal-body {
      padding: 10px 40px 30px 40px; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .form-group {
        margin: 20px 0; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-btn-login {
        padding-left: 40px;
        padding-right: 40px; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-btn-forgot {
        margin-top: 8px;
        float: right;
        display: inline-block; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-content-list-adjusted {
        margin-top: 30px; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-content-divider {
        text-align: center; }
        .c-content-login-form .modal-dialog .modal-content .modal-body .c-content-divider > span {
          display: inline-block;
          background: #fff;
          padding: 5px 8px;
          position: relative;
          top: -1.05em;
          margin: auto; }
    .c-content-login-form .modal-dialog .modal-content .modal-footer {
      background: #f7f7f7;
      padding: 20px 40px;
      text-align: left; }
      .c-content-login-form .modal-dialog .modal-content .modal-footer .c-text-account {
        display: inline-block;
        margin-top: 5px; }
      .c-content-login-form .modal-dialog .modal-content .modal-footer .c-btn-signup {
        float: right;
        padding-left: 30px;
        padding-right: 30px; }

@media (max-width: 1390px) {
  .c-content-login-form .modal-dialog .modal-content {
    margin-top: 85px; } }

@media (max-width: 991px) {
  /* 991px */
  .c-content-login-form {
    top: 0px; }
    .c-content-login-form .modal-dialog .modal-content .modal-body {
      padding: 10px 20px 10px 20px; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .form-group {
        margin: 15px 0; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-btn-login {
        padding-left: 15px;
        padding-right: 15px; }
      .c-content-login-form .modal-dialog .modal-content .modal-body .c-content-list-adjusted > li {
        width: 100%;
        display: block;
        padding: 0;
        margin: 5px 0 0 0; } }

@media (max-width: 768px) {
  /* 768px */
  .c-content-login-form {
    top: 0px; }
    .c-content-login-form .modal-dialog {
      width: auto; } }

.c-content-app-1 {
  padding: 0;
  margin: 0;
  width: 100%; }
  .c-content-app-1 > .c-diagram {
    width: 100%;
    position: relative;
    min-height: 420px;
    margin-top: 150px; }
    .c-content-app-1 > .c-diagram > .c-wireframe {
      position: absolute;
      width: 207px;
      height: 420px;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -103.5px;
      /*rtl:end:ignore*/ }
    .c-content-app-1 > .c-diagram > .c-lines-1 {
      position: absolute;
      height: 368px;
      width: 428px;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -224px;
      /*rtl:end:ignore*/
      top: -60px; }
    .c-content-app-1 > .c-diagram > .c-lines-2 {
      position: absolute;
      height: 362px;
      width: 422px;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -220px;
      /*rtl:end:ignore*/
      top: -56px; }
    .c-content-app-1 > .c-diagram > .c-phone-img {
      position: absolute;
      background-color: #ffffff;
      width: 182px;
      height: 324px;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -91px;
      /*rtl:end:ignore*/
      top: 48px; }
    .c-content-app-1 > .c-diagram > .c-box {
      width: 250px; }
      .c-content-app-1 > .c-diagram > .c-box > h3 {
        font-weight: bold;
        letter-spacing: 1px; }
    .c-content-app-1 > .c-diagram > .c-point-1 {
      position: absolute;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -450px;
      /*rtl:end:ignore*/
      top: 7px;
      text-align: right; }
    .c-content-app-1 > .c-diagram > .c-point-2 {
      position: absolute;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: 180px;
      /*rtl:end:ignore*/
      top: -67px; }
    .c-content-app-1 > .c-diagram > .c-point-3 {
      position: absolute;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: -490px;
      /*rtl:end:ignore*/
      top: 280px;
      text-align: right; }
    .c-content-app-1 > .c-diagram > .c-point-4 {
      position: absolute;
      /*rtl:begin:ignore*/
      left: 50%;
      margin-left: 220px;
      /*rtl:end:ignore*/
      top: 180px; }

@media (max-width: 991px) {
  /* 991px */
  .c-content-app-1 > .c-diagram {
    margin-top: 60px; }
    .c-content-app-1 > .c-diagram > .c-lines-1 {
      display: none; }
    .c-content-app-1 > .c-diagram > .c-lines-2 {
      display: none; }
    .c-content-app-1 > .c-diagram > .c-wireframe {
      position: relative;
      margin: auto;
      top: auto;
      left: auto; }
    .c-content-app-1 > .c-diagram > .c-box {
      position: relative;
      margin: auto;
      top: auto;
      left: auto;
      padding: 40px 0;
      text-align: left; }
      .c-content-app-1 > .c-diagram > .c-box.c-app-left {
        width: 48%;
        float: left; }
      .c-content-app-1 > .c-diagram > .c-box.c-app-right {
        width: 48%;
        float: right; } }

@media (max-width: 767px) {
  /* 767px */
  .c-content-app-1 > .c-diagram > .c-box.c-app-left {
    width: 100%; }
  .c-content-app-1 > .c-diagram > .c-box.c-app-right {
    width: 100%; } }

.c-bg-splash {
  position: absolute;
  top: 0;
  bottom: 0; }
  .c-bg-splash.c-splash-left {
    left: 0;
    right: 50%;
    background-color: #d6b4b4; }
  .c-bg-splash.c-splash-right {
    right: 0;
    left: 50%;
    background-color: #b9d3d5; }

.c-shop-banner-height {
  height: 700px; }

.c-shop-banner-1 .c-shop-banner-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%; }
  .c-shop-banner-1 .c-shop-banner-content > .c-shop-banner-img {
    position: absolute;
    bottom: 0;
    width: 70%;
    right: -50px; }
  .c-shop-banner-1 .c-shop-banner-content > .c-shop-banner-title {
    position: absolute;
    top: 40%;
    left: 20px; }
    .c-shop-banner-1 .c-shop-banner-content > .c-shop-banner-title > h1 {
      font-size: 70px;
      color: #ffffff;
      margin-bottom: 60px; }

@media (max-width: 991px) {
  /* 991px */
  .c-bg-splash {
    display: none; }
  .c-shop-banner-height {
    height: 1400px; }
  .c-shop-banner-1 .c-shop-banner-m-bg {
    background-color: #d6b4b4;
    overflow: hidden;
    height: 700px; }
    .c-shop-banner-1 .c-shop-banner-m-bg:last-child {
      background-color: #b9d3d5; } }

.c-shop-banner-2 .c-line {
  padding: 15px 0;
  border-top: 1px solid #d8dde1;
  border-bottom: 1px solid #d8dde1; }

.c-shop-banner-2 .btn {
  margin-top: 50px; }

.c-shop-banner-2 .c-image {
  height: 470px;
  background-size: contain; }

.c-shop-banner-2 .c-ad {
  margin: 164px 0; }

@media (max-width: 991px) {
  /* 991px */
  .c-shop-banner-2 .c-ad {
    margin: 58px 0 40px; }
  .c-shop-banner-2 .c-image {
    height: 300px;
    background-position: bottom; } }

@media (max-width: 767px) {
  /* 767px */
  .c-shop-banner-2 .c-image {
    height: 250px; } }

.c-shop-banner-3 .c-title {
  margin: 0; }

.c-shop-banner-3 .c-desc {
  margin: 30px 0; }

.c-cookies-bar-1 {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: auto;
  bottom: auto;
  z-index: 10000;
  opacity: 0; }
  .c-cookies-bar-1.c-cookies-bar-top {
    top: 0; }
  .c-cookies-bar-1.c-cookies-bar-bottom {
    bottom: 0; }
  .c-cookies-bar-1 > .c-cookies-bar-container {
    margin: 20px; }
    .c-cookies-bar-1 > .c-cookies-bar-container .c-cookies-bar-content {
      margin: 5px; }
    .c-cookies-bar-1 > .c-cookies-bar-container .c-cookies-bar-btn {
      text-align: right; }

.c-cookies-bar-2 {
  display: block;
  max-width: 800px;
  height: auto;
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  margin: 20px auto;
  opacity: 0; }
  .c-cookies-bar-2.c-cookies-bar-top {
    top: 20px; }
  .c-cookies-bar-2.c-cookies-bar-top-left {
    left: 20px;
    top: 20px;
    right: auto; }
  .c-cookies-bar-2.c-cookies-bar-top-right {
    right: 20px;
    top: 20px;
    left: auto; }
  .c-cookies-bar-2.c-cookies-bar-bottom {
    bottom: 20px; }
  .c-cookies-bar-2.c-cookies-bar-bottom-left {
    left: 20px;
    bottom: 20px;
    right: auto; }
  .c-cookies-bar-2.c-cookies-bar-bottom-right {
    right: 20px;
    bottom: 20px;
    left: auto; }
  .c-cookies-bar-2 > .c-cookies-bar-container {
    margin: 20px; }
    .c-cookies-bar-2 > .c-cookies-bar-container .c-cookies-bar-content {
      margin: 5px; }
    .c-cookies-bar-2 > .c-cookies-bar-container .c-cookies-bar-btn {
      text-align: right;
      margin: 10px 0; }

@media (max-width: 991px) {
  .c-cookies-bar-1 > .c-cookies-bar-container .c-cookies-bar-content {
    margin-bottom: 25px; }
  .c-cookies-bar-1 > .c-cookies-bar-container .c-cookies-bar-content,
  .c-cookies-bar-1 > .c-cookies-bar-container .c-cookies-bar-btn {
    text-align: center; }
  .c-cookies-bar-2 {
    margin: 20px; }
    .c-cookies-bar-2.c-cookies-bar-top-left {
      left: 0;
      top: 20px;
      right: 0; }
    .c-cookies-bar-2.c-cookies-bar-top-right {
      left: 0;
      top: 20px;
      right: 0; }
    .c-cookies-bar-2.c-cookies-bar-bottom-left {
      left: 0;
      bottom: 20px;
      right: 0; }
    .c-cookies-bar-2.c-cookies-bar-bottom-right {
      left: 0;
      bottom: 20px;
      right: 0; }
    .c-cookies-bar-2 > .c-cookies-bar-container .c-cookies-bar-content {
      margin-bottom: 25px; }
    .c-cookies-bar-2 > .c-cookies-bar-container .c-cookies-bar-content,
    .c-cookies-bar-2 > .c-cookies-bar-container .c-cookies-bar-btn {
      text-align: center; } }

.c-shop-wishlist-1 {
  padding: 0;
  margin: 0; }
  .c-shop-wishlist-1 .c-row-item {
    padding: 35px 0; }
  .c-shop-wishlist-1 .btn + .btn {
    margin-left: 5px; }

.c-shop-cart-page-1 > .c-cart-table-title,
.c-shop-cart-page-1 > .c-cart-table-row {
  border-bottom: 1px solid;
  border-color: rgba(135, 151, 174, 0.15); }

.c-shop-cart-page-1 > .c-cart-table-row {
  padding: 15px 0; }
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-item-title {
    margin: 0 15px 20px 15px;
    padding: 10px;
    border: 2px solid;
    float: left; }
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-image {
    position: relative;
    clear: both; }
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-image > img {
      width: 65%; }
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-desc h3 {
    margin-top: 0; }
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-remove > .c-cart-remove-desktop {
    line-height: 1em;
    font-size: 24px; }

.c-shop-cart-page-1 > .c-cart-buttons {
  margin-top: 20px;
  clear: both; }
  .c-shop-cart-page-1 > .c-cart-buttons > a {
    display: inline-block; }
  .c-shop-cart-page-1 > .c-cart-buttons > .c-cart-float-l {
    float: left; }
  .c-shop-cart-page-1 > .c-cart-buttons > .c-cart-float-r {
    float: right; }

.c-shop-cart-page-1 .c-cart-subtotal-border {
  border-bottom: 1px solid;
  border-color: rgba(135, 151, 174, 0.15); }

.c-shop-cart-page-1 .c-cart-sub-title,
.c-shop-cart-page-1 .c-cart-remove-mobile,
.c-shop-cart-page-1 .c-cart-item-title {
  display: none; }

@media (max-width: 991px) {
  /* 991px */
  .c-shop-cart-page-1 .c-cart-table-title,
  .c-shop-cart-page-1 .c-cart-remove-desktop {
    display: none; }
  .c-shop-cart-page-1 .c-cart-sub-title,
  .c-shop-cart-page-1 .c-cart-remove-mobile {
    display: block; }
  .c-shop-cart-page-1 > .c-cart-table-row {
    box-shadow: 0px 4px 4px -4px #ccc;
    border: none; }
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-item-title {
      margin-top: 10px;
      display: block; }
      .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-item-title.c-cart-item-first {
        margin-top: 0; }
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-ref,
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-qty,
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-price,
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-total {
      margin: 20px 0; }
    .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-remove {
      text-align: center;
      margin: 20px 0; }
      .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-remove > .c-cart-remove-mobile {
        display: inline-block; } }

@media (max-width: 767px) {
  /* 767px */
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-image > img {
    width: 100%; }
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-ref,
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-price,
  .c-shop-cart-page-1 > .c-cart-table-row > .c-cart-remove {
    clear: both; } }

@media (max-width: 480px) {
  /* 480px */
  .c-shop-cart-page-1 > .c-cart-buttons > a {
    display: block;
    margin-bottom: 20px; }
    .c-shop-cart-page-1 > .c-cart-buttons > a:last-child {
      margin: 0; }
  .c-shop-cart-page-1 > .c-cart-buttons > .c-cart-float-l {
    float: none; }
  .c-shop-cart-page-1 > .c-cart-buttons > .c-cart-float-r {
    float: none; } }

.c-shop-advanced-search-1 {
  padding: 0;
  margin: 0; }
  .c-shop-advanced-search-1 .btn + .btn {
    margin-left: 5px; }

.c-shop-filter-search-1 li + li {
  margin-top: 30px; }

.c-shop-filter-search-1 .c-checkbox label > .box {
  top: 4px;
  height: 15px;
  width: 15px; }

.c-shop-filter-search-1 .c-checkbox label > .check {
  top: 2px;
  left: 4px;
  width: 8px;
  height: 15px; }

.c-shop-filter-search-1 .c-checkbox label > .inc {
  left: -12px;
  top: -8px; }

.c-shop-filter-search-1 .c-review-star {
  margin: 0; }

.c-shop-filter-search-1 .c-checkbox-height {
  height: 25px; }

.c-shop-filter-search-1 .c-price-range-box {
  width: 100%; }
  .c-shop-filter-search-1 .c-price-range-box .c-price {
    width: 50%; }

.c-shop-filter-search-1 .c-price-range-slider.c-theme-1 .slider .slider-selection {
  background: #32c5d2; }

.c-shop-filter-search-1 .c-price-range-slider.c-theme-1 .slider .slider-handle {
  background: #27A8B4; }

.c-shop-filter-search-1 .c-price-range-slider.c-theme-2 .slider .slider-selection {
  background: #e7505a; }

.c-shop-filter-search-1 .c-price-range-slider.c-theme-2 .slider .slider-handle {
  background: #E22C38; }

.c-shop-result-filter-1 .c-filter {
  float: right; }
  .c-shop-result-filter-1 .c-filter + .c-filter {
    padding-right: 20px; }
  .c-shop-result-filter-1 .c-filter .c-input {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 0; }

.c-shop-order-complete-1 {
  padding: 0;
  margin: 0; }
  .c-shop-order-complete-1 .c-message {
    padding: 30px 10px; }
  .c-shop-order-complete-1 .c-order-summary {
    padding: 0 20%; }
  .c-shop-order-complete-1 .c-row-item {
    padding: 30px 0; }
  .c-shop-order-complete-1 .c-row-total {
    margin-right: 30px; }
  .c-shop-order-complete-1 .c-list li + li {
    margin-top: 10px; }
  .c-shop-order-complete-1 .c-list-inline li + li {
    margin-left: 20px; }

@media (max-width: 991px) {
  /* 991px */
  .c-shop-order-complete-1 .c-order-summary {
    padding: 0;
    margin: 0; }
  .c-shop-order-complete-1 .c-image {
    padding-bottom: 30px; } }

.c-shop-login-register-1 {
  padding: 0;
  margin: 0; }
  .c-shop-login-register-1 .c-content-divider > span {
    display: table; }
  .c-shop-login-register-1 .c-panel {
    border-color: #ebebeb; }
  .c-shop-login-register-1 .c-panel-body {
    padding-top: 30px;
    padding-bottom: 30px; }
  .c-shop-login-register-1 .c-margin-fix {
    margin-bottom: 3px; }

.c-shop-form-1 {
  padding: 0;
  margin: 0; }
  .c-shop-form-1 .c-shipping-price {
    margin-left: 30px; }
  .c-shop-form-1 .btn + .btn {
    margin-left: 5px; }

.c-shop-product-details-2 .c-product-gallery {
  margin: 0 auto;
  width: 100%;
  overflow: hidden; }
  .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-content {
    background: #fff;
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden; }
    .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-content img {
      width: 100%; }
    .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-content > .c-zoom {
      position: relative;
      width: 100%;
      height: 600px;
      overflow: hidden; }
  .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-thumbnail {
    margin-top: 10px; }
    .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-thumbnail > .c-product-thumb {
      cursor: pointer;
      padding: 0 5px;
      margin-bottom: 10px;
      overflow: hidden; }
      .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-thumbnail > .c-product-thumb img {
        width: 100%; }
      .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-thumbnail > .c-product-thumb.c-left-thumb {
        padding-left: 0; }
      .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-thumbnail > .c-product-thumb.c-right-thumb {
        padding-right: 0; }

.c-shop-product-details-2.c-opt-1 .c-product-gallery-content {
  height: 520px;
  overflow: hidden; }
  .c-shop-product-details-2.c-opt-1 .c-product-gallery-content > .c-zoom {
    height: 520px; }

.c-shop-product-details-2 .c-product-meta > .c-content-title-1 {
  float: left; }

.c-shop-product-details-2 .c-product-meta > .c-product-badge {
  text-align: right; }
  .c-shop-product-details-2 .c-product-meta > .c-product-badge > .c-product-new {
    float: right;
    padding: 5px 10px;
    background-color: #32c5d2;
    color: #ffffff; }
  .c-shop-product-details-2 .c-product-meta > .c-product-badge > .c-product-sale {
    float: right;
    padding: 5px 10px;
    background-color: #eb5d68;
    color: #ffffff; }

.c-shop-product-details-2 .c-product-meta > .c-product-review {
  clear: both;
  margin-bottom: 2em; }
  .c-shop-product-details-2 .c-product-meta > .c-product-review > .c-product-rating {
    display: inline-block;
    padding-right: 1em;
    border-right: 1px solid;
    border-color: #cccccc; }
  .c-shop-product-details-2 .c-product-meta > .c-product-review > .c-product-write-review {
    display: inline-block;
    padding-left: 1em; }

.c-shop-product-details-2 .c-product-meta > .c-product-price {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 1em; }

.c-shop-product-details-2 .c-product-meta > .c-product-short-desc {
  margin-bottom: 1em; }

.c-shop-product-details-2 .c-product-meta > .c-product-variant select {
  border-color: #d0d7de;
  padding: 6px; }

.c-shop-product-details-2 .c-product-meta .c-product-meta-label {
  float: left;
  margin-right: 1em;
  margin-top: 0.25em; }

.c-shop-product-details-2 .c-product-meta .c-product-margin-1 {
  margin-right: 33px; }

.c-shop-product-details-2 .c-product-meta .c-product-margin-2 {
  margin-right: 36px; }

@media (max-width: 991px) {
  /* 991px */
  .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-content {
    height: 460px; }
    .c-shop-product-details-2 .c-product-gallery > .c-product-gallery-content > .c-zoom {
      height: 460px; }
  .c-shop-product-details-2 .c-product-meta {
    margin-top: 1.5em; } }

.c-shop-product-details-4 .c-product-header > .c-content-title-1 {
  display: inline-block; }
  .c-shop-product-details-4 .c-product-header > .c-content-title-1 > h3 {
    margin-bottom: 0.5em; }

.c-shop-product-details-4 .c-product-header > .c-product-badge {
  display: inline-block; }
  .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-sale,
  .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-new {
    padding: 1em 0.9em;
    border-radius: 2em;
    margin: 0 0 0 1em;
    color: #ffffff;
    display: inline-block; }
  .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-new {
    background-color: #32c5d2; }
  .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-sale {
    background-color: #eb5d68; }

.c-shop-product-details-4 .c-product-header > .c-product-review {
  float: right;
  padding-top: 0.5em; }
  .c-shop-product-details-4 .c-product-header > .c-product-review > .c-product-rating {
    display: inline-block;
    padding-right: 1em;
    border-right: 1px solid;
    border-color: #cccccc; }
  .c-shop-product-details-4 .c-product-header > .c-product-review > .c-product-write-review {
    display: inline-block;
    padding-left: 1em; }

.c-shop-product-details-4 .c-product-gallery {
  overflow: hidden;
  clear: both;
  margin: 40px 0;
  height: 500px; }
  .c-shop-product-details-4 .c-product-gallery .c-product-gallery-content {
    background: #fff;
    height: 500px;
    overflow: hidden; }
    .c-shop-product-details-4 .c-product-gallery .c-product-gallery-content > .c-zoom {
      position: relative;
      height: 500px;
      overflow: hidden; }
  .c-shop-product-details-4 .c-product-gallery .c-product-gallery-thumbnail .c-product-thumb {
    cursor: pointer;
    overflow: hidden;
    height: 250px !important; }
  .c-shop-product-details-4 .c-product-gallery img {
    width: 100%; }

.c-shop-product-details-4.c-opt-1 .c-product-gallery-content {
  height: 520px;
  overflow: hidden; }
  .c-shop-product-details-4.c-opt-1 .c-product-gallery-content > .c-zoom {
    height: 520px; }

.c-shop-product-details-4 .c-product-meta .c-product-price {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 1em;
  text-align: right;
  padding: 0.5em 0;
  float: right; }

.c-shop-product-details-4 .c-product-meta .c-product-short-desc {
  padding: 1em; }

.c-shop-product-details-4 .c-product-meta .c-product-variant select {
  border-color: #d0d7de;
  padding: 6px; }

.c-shop-product-details-4 .c-product-meta .c-product-meta-label {
  float: left;
  margin-right: 1em;
  margin-top: 0.25em; }

.c-shop-product-details-4 .c-product-meta .c-add-cart {
  display: block;
  float: right; }

@media (max-width: 991px) {
  /* 991px */
  .c-shop-product-details-4 .c-product-header > .c-content-title-1 {
    display: block;
    text-align: center; }
  .c-shop-product-details-4 .c-product-header > .c-product-badge {
    display: block;
    text-align: center;
    margin-bottom: 0.5em; }
    .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-new,
    .c-shop-product-details-4 .c-product-header > .c-product-badge > .c-product-sale {
      margin: 0; }
  .c-shop-product-details-4 .c-product-header > .c-product-review {
    float: none;
    text-align: center; }
  .c-shop-product-details-4 .c-product-gallery {
    height: 1000px; }
  .c-shop-product-details-4 .c-product-meta {
    margin-top: 1.5em; }
    .c-shop-product-details-4 .c-product-meta .c-product-short-desc {
      margin-bottom: 1em; }
    .c-shop-product-details-4 .c-product-meta .c-add-cart {
      float: none;
      margin: 1em auto; } }

.c-shop-product-tab-1 {
  padding-bottom: 60px; }
  .c-shop-product-tab-1 .c-product-tab-container {
    width: 100%;
    position: relative; }
  .c-shop-product-tab-1 .nav {
    padding: 0 8em;
    margin-bottom: 1em; }
    .c-shop-product-tab-1 .nav > li {
      padding: 0 0.5em; }
      .c-shop-product-tab-1 .nav > li > a {
        color: #5c6873;
        background-color: #ffffff; }
        .c-shop-product-tab-1 .nav > li > a:hover, .c-shop-product-tab-1 .nav > li > a:focus {
          background-color: #ffffff;
          color: #eb5d68;
          border-top: 2px solid;
          border-color: #eb5d68; }
      .c-shop-product-tab-1 .nav > li.active > a,
      .c-shop-product-tab-1 .nav > li:active > a {
        color: #eb5d68;
        background-color: #ffffff;
        border-top: 2px solid;
        border-color: #eb5d68; }
  .c-shop-product-tab-1 .tab-content .c-product-tab-meta-bg {
    padding: 40px 0; }
    .c-shop-product-tab-1 .tab-content .c-product-tab-meta-bg .c-product-tab-meta {
      display: inline-block;
      margin: 0 auto;
      padding: 0 1em; }
  .c-shop-product-tab-1 .tab-content .c-product-desc {
    padding: 60px 0;
    overflow: auto; }
    .c-shop-product-tab-1 .tab-content .c-product-desc img {
      max-width: 100%; }
    .c-shop-product-tab-1 .tab-content .c-product-desc .c-content-title-1 {
      margin-top: 5em; }
    .c-shop-product-tab-1 .tab-content .c-product-desc .c-float-r {
      float: right; }
    .c-shop-product-tab-1 .tab-content .c-product-desc.c-opt-2 .c-content-title-1 {
      margin-top: 0; }
  .c-shop-product-tab-1 .tab-content .c-product-review-margin {
    padding: 1em 0; }
  .c-shop-product-tab-1 .tab-content .c-user-avatar {
    overflow: hidden;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 60px;
    float: left;
    margin-right: 15px; }
    .c-shop-product-tab-1 .tab-content .c-user-avatar > img {
      width: 100%; }
  .c-shop-product-tab-1 .tab-content .c-product-review-name {
    display: inline-block;
    float: left; }
  .c-shop-product-tab-1 .tab-content .c-product-review-content {
    padding-left: 95px; }
  .c-shop-product-tab-1 .tab-content .c-product-review-input {
    margin-top: 2em;
    padding-top: 0.5em;
    border-top: 1px solid;
    border-color: rgba(0, 0, 0, 0.2); }
    .c-shop-product-tab-1 .tab-content .c-product-review-input > h3 {
      margin-bottom: 1em; }
    .c-shop-product-tab-1 .tab-content .c-product-review-input > .c-review-rating-input {
      color: #5c6873; }
      .c-shop-product-tab-1 .tab-content .c-product-review-input > .c-review-rating-input > i:hover {
        color: #eb5d68; }
    .c-shop-product-tab-1 .tab-content .c-product-review-input > textarea {
      width: 100%;
      height: 4em;
      margin-bottom: 1em; }

@media (max-width: 991px) {
  /* 1199px */
  .c-shop-product-tab-1 .nav {
    padding: 0; }
  .c-shop-product-tab-1 .tab-content .c-product-desc img {
    width: 100%; }
  .c-shop-product-tab-1 .tab-content .c-product-desc .c-content-title-1 {
    margin-top: 1em; }
  .c-shop-product-tab-1 .tab-content .c-product-review-content {
    padding-left: 0; } }

.c-shop-product-compare .c-product-compare-table {
  width: 100%; }
  .c-shop-product-compare .c-product-compare-table tr > th {
    border-top: 2px solid;
    border-bottom: 1px solid;
    border-color: #cccccc; }
  .c-shop-product-compare .c-product-compare-table tr > td {
    padding: 0.5em;
    border-bottom: 1px solid;
    border-color: #cccccc; }
  .c-shop-product-compare .c-product-compare-table .c-compare-info {
    background-color: #f2f2f2; }

.c-shop-product-compare .c-compare-item {
  text-align: center; }
  .c-shop-product-compare .c-compare-item > a > img {
    width: 80px; }

.c-order-history-2 > .c-cart-table-title,
.c-order-history-2 > .c-cart-table-row {
  border-bottom: 1px solid;
  border-color: rgba(135, 151, 174, 0.15); }

.c-order-history-2 > .c-cart-table-row {
  padding: 15px 0; }
  .c-order-history-2 > .c-cart-table-row > .c-cart-item-title {
    margin: 0 15px 20px 15px;
    padding: 10px;
    border: 2px solid;
    float: left; }
  .c-order-history-2 > .c-cart-table-row > .c-cart-image {
    position: relative;
    clear: both; }
    .c-order-history-2 > .c-cart-table-row > .c-cart-image > img {
      width: 100%; }
  .c-order-history-2 > .c-cart-table-row > .c-cart-desc p {
    margin-top: 0; }
  .c-order-history-2 > .c-cart-table-row > .c-cart-remove > .c-cart-remove-desktop {
    line-height: 1em;
    font-size: 24px; }

.c-order-history-2 > .c-cart-buttons {
  margin-top: 20px;
  clear: both; }
  .c-order-history-2 > .c-cart-buttons > a {
    display: inline-block; }
  .c-order-history-2 > .c-cart-buttons > .c-cart-float-l {
    float: left; }
  .c-order-history-2 > .c-cart-buttons > .c-cart-float-r {
    float: right; }

.c-order-history-2 .c-cart-subtotal-border {
  border-bottom: 1px solid;
  border-color: rgba(135, 151, 174, 0.15); }

.c-order-history-2 .c-cart-sub-title,
.c-order-history-2 .c-cart-remove-mobile,
.c-order-history-2 .c-cart-item-title {
  display: none; }

@media (max-width: 991px) {
  /* 991px */
  .c-order-history-2 .c-cart-table-title,
  .c-order-history-2 .c-cart-remove-desktop {
    display: none; }
  .c-order-history-2 .c-cart-sub-title,
  .c-order-history-2 .c-cart-remove-mobile {
    display: block; }
  .c-order-history-2 > .c-cart-table-row {
    box-shadow: 0px 4px 4px -4px #ccc;
    border: none;
    padding: 15px; }
    .c-order-history-2 > .c-cart-table-row > .c-cart-item-title {
      margin-top: 10px;
      display: block; }
      .c-order-history-2 > .c-cart-table-row > .c-cart-item-title.c-cart-item-first {
        margin-top: 0; }
    .c-order-history-2 > .c-cart-table-row > .c-cart-remove {
      text-align: center;
      margin: 20px 0; }
      .c-order-history-2 > .c-cart-table-row > .c-cart-remove > .c-cart-remove-mobile {
        display: inline-block; } }

@media (max-width: 767px) {
  /* 767px */
  .c-order-history-2 > .c-cart-table-row > .c-cart-image > img {
    width: 100%; }
  .c-order-history-2 > .c-cart-table-row > .c-cart-ref,
  .c-order-history-2 > .c-cart-table-row > .c-cart-price,
  .c-order-history-2 > .c-cart-table-row > .c-cart-remove {
    clear: both; } }

@media (max-width: 480px) {
  /* 480px */
  .c-order-history-2 > .c-cart-buttons > a {
    display: block;
    margin-bottom: 20px; }
    .c-order-history-2 > .c-cart-buttons > a:last-child {
      margin: 0; }
  .c-order-history-2 > .c-cart-buttons > .c-cart-float-l {
    float: none; }
  .c-order-history-2 > .c-cart-buttons > .c-cart-float-r {
    float: none; } }

.c-page-faq-2 .c-faq-tabs {
  border: 1px solid;
  border-color: #ddd; }
  .c-page-faq-2 .c-faq-tabs > li {
    float: none; }
    .c-page-faq-2 .c-faq-tabs > li > a {
      border: none; }
      .c-page-faq-2 .c-faq-tabs > li > a:hover,
      .c-page-faq-2 .c-faq-tabs > li > a:focus {
        border: none; }
    .c-page-faq-2 .c-faq-tabs > li.active {
      background-color: #eee; }
      .c-page-faq-2 .c-faq-tabs > li.active > a {
        background-color: transparent;
        color: #32c5d2; }

.c-page-faq-2 .c-content-accordion-1 .panel {
  border-bottom: 1px solid;
  border-color: #eee;
  margin: 0; }
  .c-page-faq-2 .c-content-accordion-1 .panel:last-child {
    border: none; }
  .c-page-faq-2 .c-content-accordion-1 .panel > .panel-heading > .panel-title > a {
    color: #3f444a;
    padding: 10px;
    font-size: 19px; }
    .c-page-faq-2 .c-content-accordion-1 .panel > .panel-heading > .panel-title > a > i {
      width: 17px; }
    .c-page-faq-2 .c-content-accordion-1 .panel > .panel-heading > .panel-title > a.collapsed {
      color: #3f444a; }
  .c-page-faq-2 .c-content-accordion-1 .panel > .panel-collapse > .panel-body {
    color: #3f444a;
    padding-bottom: 20px;
    padding-left: 35px;
    font-size: 15px; }

@media (max-width: 991px) {
  .c-page-faq-2 .c-content-title-1 {
    margin-top: 40px; } }

.c-content-isotope-gallery .c-content-isotope-item {
  height: auto;
  position: relative; }
  .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container {
    position: relative; }
    .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-image {
      width: 100%;
      height: auto; }
    .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
      opacity: 0;
      webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-icon {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }
      .c-content-isotope-gallery .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay:hover {
        opacity: 1;
        cursor: pointer; }

.c-content-isotope-gallery.c-opt-1 .c-content-isotope-item,
.c-content-isotope-gallery.c-opt-4 .c-content-isotope-item,
.c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
  width: 20%; }
  .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double,
  .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double,
  .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
    width: 40%; }

.c-content-isotope-gallery.c-opt-2 {
  margin: -10px; }
  .c-content-isotope-gallery.c-opt-2 .c-content-isotope-item {
    width: 20%;
    padding: 10px; }
    .c-content-isotope-gallery.c-opt-2 .c-content-isotope-item .c-content-isotope-image-container {
      padding: 7px;
      background-color: rgba(0, 0, 0, 0.1); }

.c-content-isotope-gallery.c-opt-3 {
  margin: -5px; }
  .c-content-isotope-gallery.c-opt-3 .c-content-isotope-item {
    width: 50%;
    padding: 5px; }

.c-content-isotope-gallery.c-opt-4 {
  margin: -5px; }
  .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item {
    padding: 5px; }

.c-content-isotope-filter-1,
.c-content-isotope-filter-2 {
  margin: 20px auto;
  display: block; }
  .c-content-isotope-filter-1 > .c-isotope-filter-btn,
  .c-content-isotope-filter-2 > .c-isotope-filter-btn {
    border: none;
    background-color: transparent;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 20px;
    opacity: 0.6;
    font-size: 15px;
    webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    .c-content-isotope-filter-1 > .c-isotope-filter-btn.c-active,
    .c-content-isotope-filter-1 > .c-isotope-filter-btn:hover,
    .c-content-isotope-filter-2 > .c-isotope-filter-btn.c-active,
    .c-content-isotope-filter-2 > .c-isotope-filter-btn:hover {
      opacity: 1;
      border-bottom: 3px solid; }
    .c-content-isotope-filter-1 > .c-isotope-filter-btn:focus,
    .c-content-isotope-filter-1 > .c-isotope-filter-btn:active,
    .c-content-isotope-filter-2 > .c-isotope-filter-btn:focus,
    .c-content-isotope-filter-2 > .c-isotope-filter-btn:active {
      outline: none; }

@media (max-width: 991px) {
  .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item,
  .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item,
  .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item {
    width: 50%; }
    .c-content-isotope-gallery.c-opt-1 .c-content-isotope-item.c-item-size-double,
    .c-content-isotope-gallery.c-opt-4 .c-content-isotope-item.c-item-size-double,
    .c-content-isotope-gallery.c-opt-5 .c-content-isotope-item.c-item-size-double {
      width: 100%; }
  .c-content-isotope-gallery.c-opt-2 .c-content-isotope-item {
    width: 50%; }
  .c-content-isotope-gallery.c-opt-3 .c-content-isotope-item {
    width: 100%; } }

@media (max-width: 480px) {
  .c-content-isotope-gallery.c-opt-2 .c-content-isotope-item {
    width: 100%; } }

.c-content-isotope-grid .c-content-isotope-item {
  height: auto;
  position: relative;
  overflow: hidden; }
  .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container {
    position: relative;
    overflow: hidden; }
    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container .c-content-isotope-image {
      width: 100%;
      height: auto;
      webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 30px;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
      opacity: 0;
      webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content {
        text-align: center; }
        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-title {
          margin-top: 0;
          font-size: 18px;
          font-weight: 600; }
        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-desc {
          font-size: 14px; }
        .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container > .c-content-isotope-overlay > .c-content-isotope-overlay-content > .c-content-isotope-overlay-price {
          font-size: 28px; }
    .c-content-isotope-grid .c-content-isotope-item > .c-content-isotope-image-container:hover > .c-content-isotope-overlay {
      opacity: 1; }

.c-content-isotope-grid.c-opt-1 {
  margin: -10px; }
  .c-content-isotope-grid.c-opt-1 .c-content-isotope-item {
    width: 25%;
    padding: 10px; }
    .c-content-isotope-grid.c-opt-1 .c-content-isotope-item.c-item-size-double {
      width: 50%; }
    .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container {
      padding: 7px;
      background-color: rgba(0, 0, 0, 0.1); }
      .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container > .c-content-isotope-overlay {
        width: auto;
        height: auto;
        left: 7px;
        right: 7px;
        top: auto;
        bottom: -100%; }
      .c-content-isotope-grid.c-opt-1 .c-content-isotope-item .c-content-isotope-image-container:hover > .c-content-isotope-overlay {
        bottom: 7px; }

.c-content-isotope-grid.c-opt-2 {
  margin: -10px; }
  .c-content-isotope-grid.c-opt-2 .c-content-isotope-item {
    width: 25%;
    padding: 10px; }
    .c-content-isotope-grid.c-opt-2 .c-content-isotope-item.c-item-size-double {
      width: 50%; }
    .c-content-isotope-grid.c-opt-2 .c-content-isotope-item .c-content-isotope-image-container {
      padding: 7px;
      background-color: rgba(0, 0, 0, 0.1); }
      .c-content-isotope-grid.c-opt-2 .c-content-isotope-item .c-content-isotope-image-container .c-content-isotope-overlay-content {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }

.c-content-isotope-grid.c-opt-3 {
  margin: -10px; }
  .c-content-isotope-grid.c-opt-3 .c-content-isotope-item {
    width: 25%;
    padding: 10px; }
    .c-content-isotope-grid.c-opt-3 .c-content-isotope-item.c-item-size-double {
      width: 50%; }
    .c-content-isotope-grid.c-opt-3 .c-content-isotope-item .c-content-isotope-image-container {
      padding: 7px;
      background-color: #fff;
      border: 1px solid #ccc;
      width: 100%; }
      .c-content-isotope-grid.c-opt-3 .c-content-isotope-item .c-content-isotope-image-container .c-content-isotope-overlay-content {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }
        .c-content-isotope-grid.c-opt-3 .c-content-isotope-item .c-content-isotope-image-container .c-content-isotope-overlay-content > .c-content-isotope-overlay-btn {
          margin: 10px 0 0 0; }
          .c-content-isotope-grid.c-opt-3 .c-content-isotope-item .c-content-isotope-image-container .c-content-isotope-overlay-content > .c-content-isotope-overlay-btn:first-child {
            margin-top: 0; }
      .c-content-isotope-grid.c-opt-3 .c-content-isotope-item .c-content-isotope-image-container:hover {
        box-shadow: 0px 0px 15px #000; }

@media (max-width: 991px) {
  .c-content-isotope-grid.c-opt-1 .c-content-isotope-item,
  .c-content-isotope-grid.c-opt-2 .c-content-isotope-item,
  .c-content-isotope-grid.c-opt-3 .c-content-isotope-item {
    width: 50%; }
    .c-content-isotope-grid.c-opt-1 .c-content-isotope-item.c-item-size-double,
    .c-content-isotope-grid.c-opt-2 .c-content-isotope-item.c-item-size-double,
    .c-content-isotope-grid.c-opt-3 .c-content-isotope-item.c-item-size-double {
      width: 100%; } }

@media (max-width: 480px) {
  .c-content-isotope-grid.c-opt-1 .c-content-isotope-item,
  .c-content-isotope-grid.c-opt-2 .c-content-isotope-item,
  .c-content-isotope-grid.c-opt-3 .c-content-isotope-item {
    width: 100%; } }

.c-progress-bar .c-progress-bar-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px; }

.c-progress-bar .c-progress-bar-container .c-progress-bar-icon-line {
  display: inline-block;
  font-size: 30px;
  width: 35px;
  margin-right: 15px; }

.c-progress-bar .c-progress-bar-container .c-progress-bar-line {
  position: relative; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line .progressbar-text {
    display: none; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-display-value='true'] .progressbar-text {
    display: block; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"].c-progress-bar-line-with-icon {
    margin: -37px 0 20px 50px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"] .progressbar-text {
    text-align: right; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"] .c-progress-bar-label {
    font-size: 16px;
    margin-bottom: 0; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="1"] svg {
    height: 5px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="2"] svg {
    height: 11px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="3"] svg {
    height: 17px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="4"] svg {
    height: 23px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="5"] svg {
    height: 29px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="6"] svg {
    height: 35px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="7"] svg {
    height: 41px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="8"] svg {
    height: 47px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="9"] svg {
    height: 53px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="10"] svg {
    height: 69px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="11"] svg {
    height: 75px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="12"] svg {
    height: 81px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="13"] svg {
    height: 87px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="14"] svg {
    height: 93px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"][data-stroke-width="15"] svg {
    height: 99px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"] svg {
    width: 100%; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="circle"] {
    padding: 20px 50px; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="circle"] .progressbar-text,
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="circle"] .c-progress-bar-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="circle"] .progressbar-text {
      text-align: center;
      font-size: 40px; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="circle"] .c-progress-bar-icon {
      font-size: 45px; }
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] {
    padding: 20px 50px;
    margin-bottom: 30px; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] .progressbar-text,
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] .c-progress-bar-icon {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] .progressbar-text {
      text-align: center;
      font-size: 40px; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] .c-progress-bar-icon {
      font-size: 45px; }

.c-progress-bar.c-progress-bar-rtl .c-progress-bar-container .c-progress-bar-line[data-progress-bar="semicircle"] .progressbar-text {
  /*rtl:ignore*/
  transform: translate(50%, 0px) !important; }

@media (max-width: 1024px) {
  .c-progress-bar .c-progress-bar-container .c-progress-bar-line {
    padding: 20px 10px !important; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line[data-progress-bar="line"] {
      padding: 0 !important; } }

@media (max-width: 991px) {
  .c-progress-bar .c-progress-bar-container {
    margin: 0 20%; }
    .c-progress-bar .c-progress-bar-container .c-progress-bar-line {
      padding: 20px 50px !important; }
  .c-progress-bar .c-progress-bar-desc-container {
    margin: 0 20% 30px 20%; } }

@media (max-width: 480px) {
  .c-progress-bar .c-progress-bar-container {
    margin: 0; }
  .c-progress-bar .c-progress-bar-desc-container {
    margin: 0 0 30px 0; } }
