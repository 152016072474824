.text-centered {
  text-align: center;
}
.species-information-font {
  font-weight: 600;
  font-size: 16px;
  margin-left: 3px;
  vertical-align: middle;
}
.red-rectangle {
  width: 20px;
  height: 30px;
  background: red;
}
.green-rectangle {
  width: 20px;
  height: 30px;
  background: green;
}
.yellow-rectangle {
  width: 20px;
  height: 30px;
  background: yellow;
}
.grey-rectangle {
  width: 20px;
  height: 30px;
  background: grey;
}
.trend-image {
  width: 25px;
  height: auto;
}
.table-fixed thead {
  width: 97%;
}
.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
}
.color-red {
  color: red !important;
}
.color-green {
  color: green;
}
.color-yellow {
  color: yellow;
}
.color-grey {
  color: grey;
}
.panel-species-info {
  background: #23b5c8;
  border-color: #23a5c8;
}
.panel-species-info .panel-heading {
  background: #23a5c8;
  border-color: #23a5c8;
}
.arrow-big {
  font-size: 20px;
}
.table.table-font-big th {
  font-size: 16px;
}
.i-box-mimi {
  margin-top: 20px;
}
.input-font-mimi-normal {
  font-size: 18px;
}
.input-font-mimi-big {
  font-size: 20px;
}
.font-mimi-big {
  font-size: 20px;
}
.sk-spinner-chasing-dots.sk-spinner {
  width: 30px;
  height: 30px;
}
.new-theme-icon {
  background-position: 0 0;
}
.icon-phylum {
  background-image: url(phylum.png) !important;
}
.icon-class {
  background-image: url(class.png) !important;
}
.icon-order {
  background-image: url(order.png) !important;
}
.icon-family {
  background-image: url(family.png) !important;
}
.mimi-table-cell-font {
  font-size: 18px;
}
/* ========================================= */
/* -----------------------------------------*/
/*   LANDING PAGE SECTION                   */
/* -----------------------------------------*/
/* ========================================= */
.mimi-focus-home-icon-species {
  background: url(../images/species_logo.png) no-repeat center;
}
.mimi-focus-home-icon-habitat {
  background: url(../images/habitat_logo.png) no-repeat center;
}
/* ========================================= */
/* -----------------------------------------*/
/*   LINKS PAGE SECTION                   	*/
/* -----------------------------------------*/
/* ========================================= */
.mimi-logo-links {
  max-width: 350px;
  height: auto;
}
/* ========================================= */
/* -----------------------------------------*/
/*   PANELS SPECIES - HABITAT             	*/
/* -----------------------------------------*/
/* ========================================= */
.p-xl {
  padding: 40px;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.lazur-bg,
.bg-info {
  background-color: #23c6c8;
  color: #ffffff;
}
.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}
.light-cyan-bg {
  background-color: #E0FFFF;
}
.c-legenda {
  margin-left: 0px;
  padding-left: 0px;
}
.mimi-legenda-block {
  width: 20px;
  height: 10px;
  margin-right: 4px;
  background-color: black;
  display: inline-block;
}
.legenda-green {
  background-color: green;
}
.legenda-yellow {
  background-color: yellow;
}
.legenda-red {
  background-color: red;
}
.legenda-grey {
  background-color: grey;
}
.c-works {
  padding: 0;
  overflow: auto;
  margin: 10px 0 0 0;
}
.c-works li.mimi-legenda {
  list-style: none;
  padding: 0;
  margin: 0 20px 5px 0;
  vertical-align: top;
}
.c-works li.mimi-legenda ul li {
  display: block;
  /* height: 25px; */
  line-height: 25px;
}
.c-works li.mimi-legenda ul li img {
  vertical-align: middle;
  width: 20px;
  height: auto;
  margin-right: 4px;
}
.c-works li.mimi-legenda-advanced {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0 20px 5px 0;
  vertical-align: top;
}
.c-works li.mimi-legenda-advanced ul li {
  display: block;
  /* height: 25px; */
  line-height: 25px;
}
.c-works li.mimi-legenda-advanced ul li img {
  vertical-align: middle;
  width: 20px;
  height: auto;
  margin-right: 4px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 992px) {
  .c-layout-header .c-brand {
    margin: 20px 0 37px 0;
  }
  .c-layout-header-fixed .c-layout-page {
    margin-top: 178px;
  }
  .c-layout-header .c-navbar .container-fluid {
    padding: 0 20px !important;
  }
  .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-brand {
    transition: margin 0.2s;
    margin: 2px 0 0 0;
  }
  .c-page-on-scroll.c-layout-header-fixed .c-layout-header .c-brand .c-desktop-logo-inverse {
    display: inline-block;
  }
}
.mimi-table-striped > thead > tr > th {
  background-color: #4F81BD;
  color: white;
}
.mimi-table-striped > tbody > tr:nth-child(odd) > td,
.mimi-table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #CFD7E7;
  color: black !important;
}
.mimi-table-striped > tbody > tr:nth-child(even) > td,
.mimi-table-striped > tbody > tr:nth-child(even) > th {
  background-color: #E8ECF4;
  color: black !important;
}
.mimi-table-red-heading > thead > tr > th {
  background-color: #953734;
  color: white;
}
.c-padding-8 {
  padding: 8px;
}
.c-info-species-head-theme-bg {
  background-color: #4F81BD;
}
.c-info-species-body-theme-bg {
  background-color: #E8ECF4;
}
.c-margin-bottom-10 {
  margin-bottom: 10px;
}
.c-line-height-8 {
  line-height: 8px;
}
.c-font-italic {
  font-style: italic;
}
.btn-files-link {
  color: #1f4482;
  background: #4F81BD;
  border-color: #395E89;
}
.c-layout-header.c-layout-header-6 .c-topbar {
  padding: 2px 0 10px 5px;
}
@media (max-width: 992px) {
  .c-layout-header.c-layout-header-6 .c-topbar .c-brand > .c-logo::after {
    display: none;
  }
  .c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-page {
    margin-top: 110px;
  }
  .c-layout-header.c-layout-header-6 .c-topbar .c-brand {
    margin: 10px 0 0 0;
  }
}
.map-style {
  width: 100%;
  height: 650px;
}
@media (max-width: 992px) {
  .map-style {
    width: 100%;
    height: 360px;
  }
}

/*# sourceMappingURL=app.css.map */
