@charset "UTF-8";
/*********************************
COMPONENTS 
*********************************/
code {
  border: 1px solid #d4d4d4;
  border-radius: 2px; }

.close {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #3f444a;
  font-size: 28px;
  outline: none !important;
  opacity: 0.5;
  filter: alphaopacity=50;
  -webkit-transition: back 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out; }
  .close:hover {
    opacity: 0.8;
    filter: alphaopacity=80;
    -webkit-transition: back 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out; }

label {
  font-weight: 500;
  font-size: 18px; }

.badge {
  font-weight: 400;
  padding: 4px 7px; }

.pager li > a, .pager li > span {
  border-radius: 20px; }

.form-control {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-shadow: none;
  outline: none;
  font-weight: 300;
  background: white;
  border-color: #d0d7de; }
  .input-group-lg .form-control,
  .form-control.input-lg {
    font-weight: 300;
    font-size: 14px; }
  .form-control:active,
  .form-control:focus {
    box-shadow: none !important; }
  .form-control.c-square {
    border-radius: 0 !important; }
  .form-control.c-border-2px {
    border-color: white;
    border-width: 2px; }
  .form-control:focus,
  .form-control:active,
  .form-control.active {
    border-color: #94a4b4; }
    .form-control:focus.c-border-2px,
    .form-control:active.c-border-2px,
    .form-control.active.c-border-2px {
      border-color: #cccccc;
      border-width: 2px; }
  .form-control.form-control-transparent {
    background: none; }

.input-group .input-group-btn > .btn,
.input-group .input-group-addon {
  border-color: #d0d7de; }

.input-group.c-square .input-group-btn > .btn,
.input-group.c-square .input-group-addon {
  border-radius: 0 !important; }

.input-group.c-border-2px .input-group-btn > .btn,
.input-group.c-border-2px .input-group-addon {
  border-color: white;
  border-width: 2px; }

.help-block {
  color: #677581;
  font-size: 15px; }

label.control-label {
  padding-top: 5px;
  font-weight: 300;
  color: #2e353a; }

.checkbox label,
.radio label,
.c-checkbox label,
.c-radio label {
  color: #454e56;
  font-weight: 300; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #5dc09c; }

.has-success .form-control {
  border-color: #5dc09c;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-success .form-control:focus {
    border-color: #42a883;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-success .input-group-addon {
  color: #5dc09c;
  border-color: #5dc09c;
  background-color: #cbebdf; }

.has-success .form-control-feedback {
  color: #5dc09c; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #c8d046; }

.has-warning .form-control {
  border-color: #c8d046;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-warning .form-control:focus {
    border-color: #adb52e;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-warning .input-group-addon {
  color: #c8d046;
  border-color: #c8d046;
  background-color: #ecefc0; }

.has-warning .form-control-feedback {
  color: #c8d046; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #e7505a; }

.has-error .form-control {
  border-color: #e7505a;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-error .form-control:focus {
    border-color: #e12330;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-error .input-group-addon {
  color: #e7505a;
  border-color: #e7505a;
  background-color: #f9d7d9; }

.has-error .form-control-feedback {
  color: #e7505a; }

.btn {
  outline: none !important;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  padding: 6px 20px 4px 20px;
  font-size: 16px; }
  .btn > i {
    margin-right: 5px;
    text-align: center;
    font-weight: 300; }
  .btn.btn-sm {
    padding: 6px 18px 4px 18px; }
    .btn.btn-sm > i {
      margin-right: 5px;
      font-size: 14px; }
  .btn.btn-md {
    padding: 9px 20px 7px 20px; }
  .btn.btn-xs {
    padding: 1px 8px 1px 8px; }
    .btn.btn-xs > i {
      margin-right: 3px;
      font-size: 14px; }
  .btn.btn-lg {
    padding: 11px 26px 9px 26px; }
  .btn.btn-xlg {
    padding: 16px 44px 14px 44px; }

.btn.c-btn-border-2x {
  border-width: 2px;
  padding: 6px 20px 4px 20px; }
  .btn.c-btn-border-2x.btn-sm {
    padding: 5px 18px 3px 18px; }
  .btn.c-btn-border-2x.btn-xs {
    padding: 0px 8px 1px 8px; }
  .btn.c-btn-border-2x.btn-md {
    padding: 8px 20px 6px 20px; }
  .btn.c-btn-border-2x.btn-lg {
    padding: 10px 26px 8px 26px; }
  .btn.c-btn-border-2x.btn-xlg {
    padding: 16px 44px 14px 44px; }
  .btn.c-btn-border-2x.c-btn-uppercase {
    padding: 6px 20px 4px 20px; }
    .btn.c-btn-border-2x.c-btn-uppercase.btn-sm {
      padding: 5px 18px 3px 18px; }
    .btn.c-btn-border-2x.c-btn-uppercase.btn-xs {
      padding: 1px 8px 0px 8px; }
    .btn.c-btn-border-2x.c-btn-uppercase.btn-md {
      padding: 10px 20px 8px 20px; }
    .btn.c-btn-border-2x.c-btn-uppercase.btn-lg {
      padding: 12px 26px 12px 26px; }
    .btn.c-btn-border-2x.c-btn-uppercase.btn-xlg {
      padding: 18px 44px 16px 44px; }

.btn.c-btn-border-1x {
  border-width: 1px; }

.btn + .btn {
  margin-left: 10px; }

.btn.c-btn-icon {
  min-height: 56px;
  padding: 0;
  padding: 5px 20px 5px 20px; }
  .btn.c-btn-icon > i {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -ms-border-radius: 3px 0 0 3px;
    -o-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    margin: -5px 0 0 -20px;
    float: left;
    display: block;
    position: absolute;
    height: 56px;
    width: 56px;
    font-size: 32px;
    vertical-align: middle;
    padding-top: 10px; }
  .btn.c-btn-icon > .c-helper {
    margin: 4px 20px -2px 55px;
    text-align: left;
    display: block;
    font-size: 14px; }
  .btn.c-btn-icon > .c-caption {
    text-align: left;
    margin: 0px 20px 0px 55px;
    display: block;
    font-size: 18px;
    font-weight: 500; }

.c-btn-circle {
  border-radius: 30px !important; }

.c-btn-bold {
  font-weight: bold; }

.c-btn-sbold {
  font-weight: 500; }

.c-btn-square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.c-btn-uppercase {
  text-transform: uppercase; }
  .c-btn-uppercase.btn {
    font-size: 14px;
    padding: 7px 20px 5px 20px; }
  .c-btn-uppercase.btn-md {
    font-size: 16px;
    padding: 10px 20px 9px 20px; }
  .c-btn-uppercase.btn-lg {
    font-size: 16px;
    padding: 12px 26px 11px 26px; }
  .c-btn-uppercase.btn-xlg {
    font-size: 18px;
    padding: 20px 44px 15px 44px; }
  .c-btn-uppercase.btn-sm {
    padding: 6px 18px 4px 18px; }
  .c-btn-uppercase.btn-xs {
    padding: 2px 8px 0px 8px; }

.btn-default {
  color: #99a5b9;
  background: #ffffff;
  border-color: #e1e6ee; }
  .btn-default.btn-no-focus:focus,
  .btn-default.btn-no-focus.focus {
    color: #99a5b9;
    background: #ffffff;
    border-color: #e1e6ee; }
  .btn-default.btn-no-focus:hover,
  .btn-default:hover,
  .btn-default.btn-no-focus:active,
  .btn-default:active,
  .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #ffffff;
    background: #8998b5;
    border-color: #8998b5; }
  .btn-default:active,
  .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  .btn-default.disabled,
  .btn-default.disabled:hover,
  .btn-default.disabled:not(.btn-no-focus):focus,
  .btn-default.disabled:not(.btn-no-focus).focus,
  .btn-default.disabled:active,
  .btn-default.disabled.active,
  .btn-default[disabled],
  .btn-default[disabled]:hover,
  .btn-default[disabled]:not(.btn-no-focus):focus,
  .btn-default[disabled]:not(.btn-no-focus).focus,
  .btn-default[disabled]:active,
  .btn-default[disabled].active,
  fieldset[disabled] .btn-default,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-default:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-default:active,
  fieldset[disabled] .btn-default.active {
    background: #ffffff;
    border-color: #e1e6ee; }
  .btn-default .badge {
    color: #ffffff;
    background: #99a5b9; }

.btn-primary {
  color: #ffffff;
  background: #5e9cd1;
  border-color: #5e9cd1; }
  .btn-primary.btn-no-focus:focus,
  .btn-primary.btn-no-focus.focus {
    color: #ffffff;
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .btn-primary.btn-no-focus:hover,
  .btn-primary:hover,
  .btn-primary.btn-no-focus:active,
  .btn-primary:active,
  .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background: #3883c4;
    border-color: #3883c4; }
  .btn-primary:active,
  .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled,
  .btn-primary.disabled:hover,
  .btn-primary.disabled:not(.btn-no-focus):focus,
  .btn-primary.disabled:not(.btn-no-focus).focus,
  .btn-primary.disabled:active,
  .btn-primary.disabled.active,
  .btn-primary[disabled],
  .btn-primary[disabled]:hover,
  .btn-primary[disabled]:not(.btn-no-focus):focus,
  .btn-primary[disabled]:not(.btn-no-focus).focus,
  .btn-primary[disabled]:active,
  .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-primary:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .btn-primary .badge {
    color: #5e9cd1;
    background: #ffffff; }

.btn-success {
  color: #ffffff;
  background: #5dc09c;
  border-color: #5dc09c; }
  .btn-success.btn-no-focus:focus,
  .btn-success.btn-no-focus.focus {
    color: #ffffff;
    background: #5dc09c;
    border-color: #5dc09c; }
  .btn-success.btn-no-focus:hover,
  .btn-success:hover,
  .btn-success.btn-no-focus:active,
  .btn-success:active,
  .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #ffffff;
    background: #42a883;
    border-color: #42a883; }
  .btn-success:active,
  .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled,
  .btn-success.disabled:hover,
  .btn-success.disabled:not(.btn-no-focus):focus,
  .btn-success.disabled:not(.btn-no-focus).focus,
  .btn-success.disabled:active,
  .btn-success.disabled.active,
  .btn-success[disabled],
  .btn-success[disabled]:hover,
  .btn-success[disabled]:not(.btn-no-focus):focus,
  .btn-success[disabled]:not(.btn-no-focus).focus,
  .btn-success[disabled]:active,
  .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-success:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background: #5dc09c;
    border-color: #5dc09c; }
  .btn-success .badge {
    color: #5dc09c;
    background: #ffffff; }

.btn-danger {
  color: #ffffff;
  background: #e7505a;
  border-color: #e7505a; }
  .btn-danger.btn-no-focus:focus,
  .btn-danger.btn-no-focus.focus {
    color: #ffffff;
    background: #e7505a;
    border-color: #e7505a; }
  .btn-danger.btn-no-focus:hover,
  .btn-danger:hover,
  .btn-danger.btn-no-focus:active,
  .btn-danger:active,
  .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background: #e12330;
    border-color: #e12330; }
  .btn-danger:active,
  .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled,
  .btn-danger.disabled:hover,
  .btn-danger.disabled:not(.btn-no-focus):focus,
  .btn-danger.disabled:not(.btn-no-focus).focus,
  .btn-danger.disabled:active,
  .btn-danger.disabled.active,
  .btn-danger[disabled],
  .btn-danger[disabled]:hover,
  .btn-danger[disabled]:not(.btn-no-focus):focus,
  .btn-danger[disabled]:not(.btn-no-focus).focus,
  .btn-danger[disabled]:active,
  .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-danger:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background: #e7505a;
    border-color: #e7505a; }
  .btn-danger .badge {
    color: #e7505a;
    background: #ffffff; }

.btn-warning {
  color: #ffffff;
  background: #c8d046;
  border-color: #c8d046; }
  .btn-warning.btn-no-focus:focus,
  .btn-warning.btn-no-focus.focus {
    color: #ffffff;
    background: #c8d046;
    border-color: #c8d046; }
  .btn-warning.btn-no-focus:hover,
  .btn-warning:hover,
  .btn-warning.btn-no-focus:active,
  .btn-warning:active,
  .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background: #adb52e;
    border-color: #adb52e; }
  .btn-warning:active,
  .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled,
  .btn-warning.disabled:hover,
  .btn-warning.disabled:not(.btn-no-focus):focus,
  .btn-warning.disabled:not(.btn-no-focus).focus,
  .btn-warning.disabled:active,
  .btn-warning.disabled.active,
  .btn-warning[disabled],
  .btn-warning[disabled]:hover,
  .btn-warning[disabled]:not(.btn-no-focus):focus,
  .btn-warning[disabled]:not(.btn-no-focus).focus,
  .btn-warning[disabled]:active,
  .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-warning:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background: #c8d046;
    border-color: #c8d046; }
  .btn-warning .badge {
    color: #c8d046;
    background: #ffffff; }

.btn-info {
  color: #ffffff;
  background: #4eabe6;
  border-color: #4eabe6; }
  .btn-info.btn-no-focus:focus,
  .btn-info.btn-no-focus.focus {
    color: #ffffff;
    background: #4eabe6;
    border-color: #4eabe6; }
  .btn-info.btn-no-focus:hover,
  .btn-info:hover,
  .btn-info.btn-no-focus:active,
  .btn-info:active,
  .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #ffffff;
    background: #2196e0;
    border-color: #2196e0; }
  .btn-info:active,
  .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  .btn-info.disabled,
  .btn-info.disabled:hover,
  .btn-info.disabled:not(.btn-no-focus):focus,
  .btn-info.disabled:not(.btn-no-focus).focus,
  .btn-info.disabled:active,
  .btn-info.disabled.active,
  .btn-info[disabled],
  .btn-info[disabled]:hover,
  .btn-info[disabled]:not(.btn-no-focus):focus,
  .btn-info[disabled]:not(.btn-no-focus).focus,
  .btn-info[disabled]:active,
  .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:not(.btn-no-focus):focus,
  fieldset[disabled] .btn-info:not(.btn-no-focus).focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background: #4eabe6;
    border-color: #4eabe6; }
  .btn-info .badge {
    color: #4eabe6;
    background: #ffffff; }

.c-btn-white {
  border-color: #FFFFFF;
  border-width: 1px;
  color: #2f353b;
  background: #FFFFFF;
  border-color: #FFFFFF; }
  .c-btn-white.btn-no-focus:focus,
  .c-btn-white.btn-no-focus.focus {
    color: #2f353b;
    background: #FFFFFF;
    border-color: #FFFFFF; }
  .c-btn-white.btn-no-focus:hover,
  .c-btn-white:hover,
  .c-btn-white.btn-no-focus:active,
  .c-btn-white:active,
  .c-btn-white.active,
  .open > .c-btn-white.dropdown-toggle {
    color: #2f353b;
    background: #ebebeb;
    border-color: #ebebeb; }
  .c-btn-white:active,
  .c-btn-white.active,
  .open > .c-btn-white.dropdown-toggle {
    background-image: none; }
  .c-btn-white.disabled,
  .c-btn-white.disabled:hover,
  .c-btn-white.disabled:not(.btn-no-focus):focus,
  .c-btn-white.disabled:not(.btn-no-focus).focus,
  .c-btn-white.disabled:active,
  .c-btn-white.disabled.active,
  .c-btn-white[disabled],
  .c-btn-white[disabled]:hover,
  .c-btn-white[disabled]:not(.btn-no-focus):focus,
  .c-btn-white[disabled]:not(.btn-no-focus).focus,
  .c-btn-white[disabled]:active,
  .c-btn-white[disabled].active,
  fieldset[disabled] .c-btn-white,
  fieldset[disabled] .c-btn-white:hover,
  fieldset[disabled] .c-btn-white:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-white:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-white:active,
  fieldset[disabled] .c-btn-white.active {
    background: #FFFFFF;
    border-color: #FFFFFF; }
  .c-btn-white .badge {
    color: #FFFFFF;
    background: #2f353b; }

.c-btn-black {
  border-color: #000000;
  border-width: 1px;
  color: #ffffff;
  background: #000000;
  border-color: #000000; }
  .c-btn-black.btn-no-focus:focus,
  .c-btn-black.btn-no-focus.focus {
    color: #ffffff;
    background: #000000;
    border-color: #000000; }
  .c-btn-black.btn-no-focus:hover,
  .c-btn-black:hover,
  .c-btn-black.btn-no-focus:active,
  .c-btn-black:active,
  .c-btn-black.active,
  .open > .c-btn-black.dropdown-toggle {
    color: #ffffff;
    background: black;
    border-color: black; }
  .c-btn-black:active,
  .c-btn-black.active,
  .open > .c-btn-black.dropdown-toggle {
    background-image: none; }
  .c-btn-black.disabled,
  .c-btn-black.disabled:hover,
  .c-btn-black.disabled:not(.btn-no-focus):focus,
  .c-btn-black.disabled:not(.btn-no-focus).focus,
  .c-btn-black.disabled:active,
  .c-btn-black.disabled.active,
  .c-btn-black[disabled],
  .c-btn-black[disabled]:hover,
  .c-btn-black[disabled]:not(.btn-no-focus):focus,
  .c-btn-black[disabled]:not(.btn-no-focus).focus,
  .c-btn-black[disabled]:active,
  .c-btn-black[disabled].active,
  fieldset[disabled] .c-btn-black,
  fieldset[disabled] .c-btn-black:hover,
  fieldset[disabled] .c-btn-black:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-black:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-black:active,
  fieldset[disabled] .c-btn-black.active {
    background: #000000;
    border-color: #000000; }
  .c-btn-black .badge {
    color: #000000;
    background: #ffffff; }

.c-btn-regular {
  border-color: #7f8c97;
  border-width: 1px;
  color: #ffffff;
  background: #7f8c97;
  border-color: #7f8c97; }
  .c-btn-regular.btn-no-focus:focus,
  .c-btn-regular.btn-no-focus.focus {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-regular.btn-no-focus:hover,
  .c-btn-regular:hover,
  .c-btn-regular.btn-no-focus:active,
  .c-btn-regular:active,
  .c-btn-regular.active,
  .open > .c-btn-regular.dropdown-toggle {
    color: #ffffff;
    background: #6a7883;
    border-color: #6a7883; }
  .c-btn-regular:active,
  .c-btn-regular.active,
  .open > .c-btn-regular.dropdown-toggle {
    background-image: none; }
  .c-btn-regular.disabled,
  .c-btn-regular.disabled:hover,
  .c-btn-regular.disabled:not(.btn-no-focus):focus,
  .c-btn-regular.disabled:not(.btn-no-focus).focus,
  .c-btn-regular.disabled:active,
  .c-btn-regular.disabled.active,
  .c-btn-regular[disabled],
  .c-btn-regular[disabled]:hover,
  .c-btn-regular[disabled]:not(.btn-no-focus):focus,
  .c-btn-regular[disabled]:not(.btn-no-focus).focus,
  .c-btn-regular[disabled]:active,
  .c-btn-regular[disabled].active,
  fieldset[disabled] .c-btn-regular,
  fieldset[disabled] .c-btn-regular:hover,
  fieldset[disabled] .c-btn-regular:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-regular:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-regular:active,
  fieldset[disabled] .c-btn-regular.active {
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-regular .badge {
    color: #7f8c97;
    background: #ffffff; }

.c-btn-green {
  border-color: #32c5d2;
  border-width: 1px;
  color: #ffffff;
  background: #32c5d2;
  border-color: #32c5d2; }
  .c-btn-green.btn-no-focus:focus,
  .c-btn-green.btn-no-focus.focus {
    color: #ffffff;
    background: #32c5d2;
    border-color: #32c5d2; }
  .c-btn-green.btn-no-focus:hover,
  .c-btn-green:hover,
  .c-btn-green.btn-no-focus:active,
  .c-btn-green:active,
  .c-btn-green.active,
  .open > .c-btn-green.dropdown-toggle {
    color: #ffffff;
    background: #27a8b4;
    border-color: #27a8b4; }
  .c-btn-green:active,
  .c-btn-green.active,
  .open > .c-btn-green.dropdown-toggle {
    background-image: none; }
  .c-btn-green.disabled,
  .c-btn-green.disabled:hover,
  .c-btn-green.disabled:not(.btn-no-focus):focus,
  .c-btn-green.disabled:not(.btn-no-focus).focus,
  .c-btn-green.disabled:active,
  .c-btn-green.disabled.active,
  .c-btn-green[disabled],
  .c-btn-green[disabled]:hover,
  .c-btn-green[disabled]:not(.btn-no-focus):focus,
  .c-btn-green[disabled]:not(.btn-no-focus).focus,
  .c-btn-green[disabled]:active,
  .c-btn-green[disabled].active,
  fieldset[disabled] .c-btn-green,
  fieldset[disabled] .c-btn-green:hover,
  fieldset[disabled] .c-btn-green:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-green:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-green:active,
  fieldset[disabled] .c-btn-green.active {
    background: #32c5d2;
    border-color: #32c5d2; }
  .c-btn-green .badge {
    color: #32c5d2;
    background: #ffffff; }

.c-btn-green-1 {
  border-color: #52c9ba;
  border-width: 1px;
  color: #ffffff;
  background: #52c9ba;
  border-color: #52c9ba; }
  .c-btn-green-1.btn-no-focus:focus,
  .c-btn-green-1.btn-no-focus.focus {
    color: #ffffff;
    background: #52c9ba;
    border-color: #52c9ba; }
  .c-btn-green-1.btn-no-focus:hover,
  .c-btn-green-1:hover,
  .c-btn-green-1.btn-no-focus:active,
  .c-btn-green-1:active,
  .c-btn-green-1.active,
  .open > .c-btn-green-1.dropdown-toggle {
    color: #ffffff;
    background: #3ab9a9;
    border-color: #3ab9a9; }
  .c-btn-green-1:active,
  .c-btn-green-1.active,
  .open > .c-btn-green-1.dropdown-toggle {
    background-image: none; }
  .c-btn-green-1.disabled,
  .c-btn-green-1.disabled:hover,
  .c-btn-green-1.disabled:not(.btn-no-focus):focus,
  .c-btn-green-1.disabled:not(.btn-no-focus).focus,
  .c-btn-green-1.disabled:active,
  .c-btn-green-1.disabled.active,
  .c-btn-green-1[disabled],
  .c-btn-green-1[disabled]:hover,
  .c-btn-green-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-green-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-green-1[disabled]:active,
  .c-btn-green-1[disabled].active,
  fieldset[disabled] .c-btn-green-1,
  fieldset[disabled] .c-btn-green-1:hover,
  fieldset[disabled] .c-btn-green-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-green-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-green-1:active,
  fieldset[disabled] .c-btn-green-1.active {
    background: #52c9ba;
    border-color: #52c9ba; }
  .c-btn-green-1 .badge {
    color: #52c9ba;
    background: #ffffff; }

.c-btn-green-2 {
  border-color: #5dc09c;
  border-width: 1px;
  color: #ffffff;
  background: #5dc09c;
  border-color: #5dc09c; }
  .c-btn-green-2.btn-no-focus:focus,
  .c-btn-green-2.btn-no-focus.focus {
    color: #ffffff;
    background: #5dc09c;
    border-color: #5dc09c; }
  .c-btn-green-2.btn-no-focus:hover,
  .c-btn-green-2:hover,
  .c-btn-green-2.btn-no-focus:active,
  .c-btn-green-2:active,
  .c-btn-green-2.active,
  .open > .c-btn-green-2.dropdown-toggle {
    color: #ffffff;
    background: #44b089;
    border-color: #44b089; }
  .c-btn-green-2:active,
  .c-btn-green-2.active,
  .open > .c-btn-green-2.dropdown-toggle {
    background-image: none; }
  .c-btn-green-2.disabled,
  .c-btn-green-2.disabled:hover,
  .c-btn-green-2.disabled:not(.btn-no-focus):focus,
  .c-btn-green-2.disabled:not(.btn-no-focus).focus,
  .c-btn-green-2.disabled:active,
  .c-btn-green-2.disabled.active,
  .c-btn-green-2[disabled],
  .c-btn-green-2[disabled]:hover,
  .c-btn-green-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-green-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-green-2[disabled]:active,
  .c-btn-green-2[disabled].active,
  fieldset[disabled] .c-btn-green-2,
  fieldset[disabled] .c-btn-green-2:hover,
  fieldset[disabled] .c-btn-green-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-green-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-green-2:active,
  fieldset[disabled] .c-btn-green-2.active {
    background: #5dc09c;
    border-color: #5dc09c; }
  .c-btn-green-2 .badge {
    color: #5dc09c;
    background: #ffffff; }

.c-btn-green-3 {
  border-color: #84c68f;
  border-width: 1px;
  color: #ffffff;
  background: #84c68f;
  border-color: #84c68f; }
  .c-btn-green-3.btn-no-focus:focus,
  .c-btn-green-3.btn-no-focus.focus {
    color: #ffffff;
    background: #84c68f;
    border-color: #84c68f; }
  .c-btn-green-3.btn-no-focus:hover,
  .c-btn-green-3:hover,
  .c-btn-green-3.btn-no-focus:active,
  .c-btn-green-3:active,
  .c-btn-green-3.active,
  .open > .c-btn-green-3.dropdown-toggle {
    color: #ffffff;
    background: #68b976;
    border-color: #68b976; }
  .c-btn-green-3:active,
  .c-btn-green-3.active,
  .open > .c-btn-green-3.dropdown-toggle {
    background-image: none; }
  .c-btn-green-3.disabled,
  .c-btn-green-3.disabled:hover,
  .c-btn-green-3.disabled:not(.btn-no-focus):focus,
  .c-btn-green-3.disabled:not(.btn-no-focus).focus,
  .c-btn-green-3.disabled:active,
  .c-btn-green-3.disabled.active,
  .c-btn-green-3[disabled],
  .c-btn-green-3[disabled]:hover,
  .c-btn-green-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-green-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-green-3[disabled]:active,
  .c-btn-green-3[disabled].active,
  fieldset[disabled] .c-btn-green-3,
  fieldset[disabled] .c-btn-green-3:hover,
  fieldset[disabled] .c-btn-green-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-green-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-green-3:active,
  fieldset[disabled] .c-btn-green-3.active {
    background: #84c68f;
    border-color: #84c68f; }
  .c-btn-green-3 .badge {
    color: #84c68f;
    background: #ffffff; }

.c-btn-red {
  border-color: #eb5d68;
  border-width: 1px;
  color: #ffffff;
  background: #eb5d68;
  border-color: #eb5d68; }
  .c-btn-red.btn-no-focus:focus,
  .c-btn-red.btn-no-focus.focus {
    color: #ffffff;
    background: #eb5d68;
    border-color: #eb5d68; }
  .c-btn-red.btn-no-focus:hover,
  .c-btn-red:hover,
  .c-btn-red.btn-no-focus:active,
  .c-btn-red:active,
  .c-btn-red.active,
  .open > .c-btn-red.dropdown-toggle {
    color: #ffffff;
    background: #e73946;
    border-color: #e73946; }
  .c-btn-red:active,
  .c-btn-red.active,
  .open > .c-btn-red.dropdown-toggle {
    background-image: none; }
  .c-btn-red.disabled,
  .c-btn-red.disabled:hover,
  .c-btn-red.disabled:not(.btn-no-focus):focus,
  .c-btn-red.disabled:not(.btn-no-focus).focus,
  .c-btn-red.disabled:active,
  .c-btn-red.disabled.active,
  .c-btn-red[disabled],
  .c-btn-red[disabled]:hover,
  .c-btn-red[disabled]:not(.btn-no-focus):focus,
  .c-btn-red[disabled]:not(.btn-no-focus).focus,
  .c-btn-red[disabled]:active,
  .c-btn-red[disabled].active,
  fieldset[disabled] .c-btn-red,
  fieldset[disabled] .c-btn-red:hover,
  fieldset[disabled] .c-btn-red:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-red:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-red:active,
  fieldset[disabled] .c-btn-red.active {
    background: #eb5d68;
    border-color: #eb5d68; }
  .c-btn-red .badge {
    color: #eb5d68;
    background: #ffffff; }

.c-btn-red-1 {
  border-color: #cd6262;
  border-width: 1px;
  color: #ffffff;
  background: #cd6262;
  border-color: #cd6262; }
  .c-btn-red-1.btn-no-focus:focus,
  .c-btn-red-1.btn-no-focus.focus {
    color: #ffffff;
    background: #cd6262;
    border-color: #cd6262; }
  .c-btn-red-1.btn-no-focus:hover,
  .c-btn-red-1:hover,
  .c-btn-red-1.btn-no-focus:active,
  .c-btn-red-1:active,
  .c-btn-red-1.active,
  .open > .c-btn-red-1.dropdown-toggle {
    color: #ffffff;
    background: #c34343;
    border-color: #c34343; }
  .c-btn-red-1:active,
  .c-btn-red-1.active,
  .open > .c-btn-red-1.dropdown-toggle {
    background-image: none; }
  .c-btn-red-1.disabled,
  .c-btn-red-1.disabled:hover,
  .c-btn-red-1.disabled:not(.btn-no-focus):focus,
  .c-btn-red-1.disabled:not(.btn-no-focus).focus,
  .c-btn-red-1.disabled:active,
  .c-btn-red-1.disabled.active,
  .c-btn-red-1[disabled],
  .c-btn-red-1[disabled]:hover,
  .c-btn-red-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-red-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-red-1[disabled]:active,
  .c-btn-red-1[disabled].active,
  fieldset[disabled] .c-btn-red-1,
  fieldset[disabled] .c-btn-red-1:hover,
  fieldset[disabled] .c-btn-red-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-red-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-red-1:active,
  fieldset[disabled] .c-btn-red-1.active {
    background: #cd6262;
    border-color: #cd6262; }
  .c-btn-red-1 .badge {
    color: #cd6262;
    background: #ffffff; }

.c-btn-red-2 {
  border-color: #e7505a;
  border-width: 1px;
  color: #ffffff;
  background: #e7505a;
  border-color: #e7505a; }
  .c-btn-red-2.btn-no-focus:focus,
  .c-btn-red-2.btn-no-focus.focus {
    color: #ffffff;
    background: #e7505a;
    border-color: #e7505a; }
  .c-btn-red-2.btn-no-focus:hover,
  .c-btn-red-2:hover,
  .c-btn-red-2.btn-no-focus:active,
  .c-btn-red-2:active,
  .c-btn-red-2.active,
  .open > .c-btn-red-2.dropdown-toggle {
    color: #ffffff;
    background: #e22c38;
    border-color: #e22c38; }
  .c-btn-red-2:active,
  .c-btn-red-2.active,
  .open > .c-btn-red-2.dropdown-toggle {
    background-image: none; }
  .c-btn-red-2.disabled,
  .c-btn-red-2.disabled:hover,
  .c-btn-red-2.disabled:not(.btn-no-focus):focus,
  .c-btn-red-2.disabled:not(.btn-no-focus).focus,
  .c-btn-red-2.disabled:active,
  .c-btn-red-2.disabled.active,
  .c-btn-red-2[disabled],
  .c-btn-red-2[disabled]:hover,
  .c-btn-red-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-red-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-red-2[disabled]:active,
  .c-btn-red-2[disabled].active,
  fieldset[disabled] .c-btn-red-2,
  fieldset[disabled] .c-btn-red-2:hover,
  fieldset[disabled] .c-btn-red-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-red-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-red-2:active,
  fieldset[disabled] .c-btn-red-2.active {
    background: #e7505a;
    border-color: #e7505a; }
  .c-btn-red-2 .badge {
    color: #e7505a;
    background: #ffffff; }

.c-btn-red-3 {
  border-color: #d05163;
  border-width: 1px;
  color: #ffffff;
  background: #d05163;
  border-color: #d05163; }
  .c-btn-red-3.btn-no-focus:focus,
  .c-btn-red-3.btn-no-focus.focus {
    color: #ffffff;
    background: #d05163;
    border-color: #d05163; }
  .c-btn-red-3.btn-no-focus:hover,
  .c-btn-red-3:hover,
  .c-btn-red-3.btn-no-focus:active,
  .c-btn-red-3:active,
  .c-btn-red-3.active,
  .open > .c-btn-red-3.dropdown-toggle {
    color: #ffffff;
    background: #c33549;
    border-color: #c33549; }
  .c-btn-red-3:active,
  .c-btn-red-3.active,
  .open > .c-btn-red-3.dropdown-toggle {
    background-image: none; }
  .c-btn-red-3.disabled,
  .c-btn-red-3.disabled:hover,
  .c-btn-red-3.disabled:not(.btn-no-focus):focus,
  .c-btn-red-3.disabled:not(.btn-no-focus).focus,
  .c-btn-red-3.disabled:active,
  .c-btn-red-3.disabled.active,
  .c-btn-red-3[disabled],
  .c-btn-red-3[disabled]:hover,
  .c-btn-red-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-red-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-red-3[disabled]:active,
  .c-btn-red-3[disabled].active,
  fieldset[disabled] .c-btn-red-3,
  fieldset[disabled] .c-btn-red-3:hover,
  fieldset[disabled] .c-btn-red-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-red-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-red-3:active,
  fieldset[disabled] .c-btn-red-3.active {
    background: #d05163;
    border-color: #d05163; }
  .c-btn-red-3 .badge {
    color: #d05163;
    background: #ffffff; }

.c-btn-yellow {
  border-color: #FF6B57;
  border-width: 1px;
  color: #ffffff;
  background: #FF6B57;
  border-color: #FF6B57; }
  .c-btn-yellow.btn-no-focus:focus,
  .c-btn-yellow.btn-no-focus.focus {
    color: #ffffff;
    background: #FF6B57;
    border-color: #FF6B57; }
  .c-btn-yellow.btn-no-focus:hover,
  .c-btn-yellow:hover,
  .c-btn-yellow.btn-no-focus:active,
  .c-btn-yellow:active,
  .c-btn-yellow.active,
  .open > .c-btn-yellow.dropdown-toggle {
    color: #ffffff;
    background: #ff472e;
    border-color: #ff472e; }
  .c-btn-yellow:active,
  .c-btn-yellow.active,
  .open > .c-btn-yellow.dropdown-toggle {
    background-image: none; }
  .c-btn-yellow.disabled,
  .c-btn-yellow.disabled:hover,
  .c-btn-yellow.disabled:not(.btn-no-focus):focus,
  .c-btn-yellow.disabled:not(.btn-no-focus).focus,
  .c-btn-yellow.disabled:active,
  .c-btn-yellow.disabled.active,
  .c-btn-yellow[disabled],
  .c-btn-yellow[disabled]:hover,
  .c-btn-yellow[disabled]:not(.btn-no-focus):focus,
  .c-btn-yellow[disabled]:not(.btn-no-focus).focus,
  .c-btn-yellow[disabled]:active,
  .c-btn-yellow[disabled].active,
  fieldset[disabled] .c-btn-yellow,
  fieldset[disabled] .c-btn-yellow:hover,
  fieldset[disabled] .c-btn-yellow:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-yellow:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-yellow:active,
  fieldset[disabled] .c-btn-yellow.active {
    background: #FF6B57;
    border-color: #FF6B57; }
  .c-btn-yellow .badge {
    color: #FF6B57;
    background: #ffffff; }

.c-btn-yellow-1 {
  border-color: #c8d046;
  border-width: 1px;
  color: #ffffff;
  background: #c8d046;
  border-color: #c8d046; }
  .c-btn-yellow-1.btn-no-focus:focus,
  .c-btn-yellow-1.btn-no-focus.focus {
    color: #ffffff;
    background: #c8d046;
    border-color: #c8d046; }
  .c-btn-yellow-1.btn-no-focus:hover,
  .c-btn-yellow-1:hover,
  .c-btn-yellow-1.btn-no-focus:active,
  .c-btn-yellow-1:active,
  .c-btn-yellow-1.active,
  .open > .c-btn-yellow-1.dropdown-toggle {
    color: #ffffff;
    background: #b5bd30;
    border-color: #b5bd30; }
  .c-btn-yellow-1:active,
  .c-btn-yellow-1.active,
  .open > .c-btn-yellow-1.dropdown-toggle {
    background-image: none; }
  .c-btn-yellow-1.disabled,
  .c-btn-yellow-1.disabled:hover,
  .c-btn-yellow-1.disabled:not(.btn-no-focus):focus,
  .c-btn-yellow-1.disabled:not(.btn-no-focus).focus,
  .c-btn-yellow-1.disabled:active,
  .c-btn-yellow-1.disabled.active,
  .c-btn-yellow-1[disabled],
  .c-btn-yellow-1[disabled]:hover,
  .c-btn-yellow-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-yellow-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-yellow-1[disabled]:active,
  .c-btn-yellow-1[disabled].active,
  fieldset[disabled] .c-btn-yellow-1,
  fieldset[disabled] .c-btn-yellow-1:hover,
  fieldset[disabled] .c-btn-yellow-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-yellow-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-yellow-1:active,
  fieldset[disabled] .c-btn-yellow-1.active {
    background: #c8d046;
    border-color: #c8d046; }
  .c-btn-yellow-1 .badge {
    color: #c8d046;
    background: #ffffff; }

.c-btn-yellow-2 {
  border-color: #c5bf66;
  border-width: 1px;
  color: #ffffff;
  background: #c5bf66;
  border-color: #c5bf66; }
  .c-btn-yellow-2.btn-no-focus:focus,
  .c-btn-yellow-2.btn-no-focus.focus {
    color: #ffffff;
    background: #c5bf66;
    border-color: #c5bf66; }
  .c-btn-yellow-2.btn-no-focus:hover,
  .c-btn-yellow-2:hover,
  .c-btn-yellow-2.btn-no-focus:active,
  .c-btn-yellow-2:active,
  .c-btn-yellow-2.active,
  .open > .c-btn-yellow-2.dropdown-toggle {
    color: #ffffff;
    background: #bab348;
    border-color: #bab348; }
  .c-btn-yellow-2:active,
  .c-btn-yellow-2.active,
  .open > .c-btn-yellow-2.dropdown-toggle {
    background-image: none; }
  .c-btn-yellow-2.disabled,
  .c-btn-yellow-2.disabled:hover,
  .c-btn-yellow-2.disabled:not(.btn-no-focus):focus,
  .c-btn-yellow-2.disabled:not(.btn-no-focus).focus,
  .c-btn-yellow-2.disabled:active,
  .c-btn-yellow-2.disabled.active,
  .c-btn-yellow-2[disabled],
  .c-btn-yellow-2[disabled]:hover,
  .c-btn-yellow-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-yellow-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-yellow-2[disabled]:active,
  .c-btn-yellow-2[disabled].active,
  fieldset[disabled] .c-btn-yellow-2,
  fieldset[disabled] .c-btn-yellow-2:hover,
  fieldset[disabled] .c-btn-yellow-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-yellow-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-yellow-2:active,
  fieldset[disabled] .c-btn-yellow-2.active {
    background: #c5bf66;
    border-color: #c5bf66; }
  .c-btn-yellow-2 .badge {
    color: #c5bf66;
    background: #ffffff; }

.c-btn-yellow-3 {
  border-color: #c5b96b;
  border-width: 1px;
  color: #ffffff;
  background: #c5b96b;
  border-color: #c5b96b; }
  .c-btn-yellow-3.btn-no-focus:focus,
  .c-btn-yellow-3.btn-no-focus.focus {
    color: #ffffff;
    background: #c5b96b;
    border-color: #c5b96b; }
  .c-btn-yellow-3.btn-no-focus:hover,
  .c-btn-yellow-3:hover,
  .c-btn-yellow-3.btn-no-focus:active,
  .c-btn-yellow-3:active,
  .c-btn-yellow-3.active,
  .open > .c-btn-yellow-3.dropdown-toggle {
    color: #ffffff;
    background: #baab4e;
    border-color: #baab4e; }
  .c-btn-yellow-3:active,
  .c-btn-yellow-3.active,
  .open > .c-btn-yellow-3.dropdown-toggle {
    background-image: none; }
  .c-btn-yellow-3.disabled,
  .c-btn-yellow-3.disabled:hover,
  .c-btn-yellow-3.disabled:not(.btn-no-focus):focus,
  .c-btn-yellow-3.disabled:not(.btn-no-focus).focus,
  .c-btn-yellow-3.disabled:active,
  .c-btn-yellow-3.disabled.active,
  .c-btn-yellow-3[disabled],
  .c-btn-yellow-3[disabled]:hover,
  .c-btn-yellow-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-yellow-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-yellow-3[disabled]:active,
  .c-btn-yellow-3[disabled].active,
  fieldset[disabled] .c-btn-yellow-3,
  fieldset[disabled] .c-btn-yellow-3:hover,
  fieldset[disabled] .c-btn-yellow-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-yellow-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-yellow-3:active,
  fieldset[disabled] .c-btn-yellow-3.active {
    background: #c5b96b;
    border-color: #c5b96b; }
  .c-btn-yellow-3 .badge {
    color: #c5b96b;
    background: #ffffff; }

.c-btn-blue {
  border-color: #3498DB;
  border-width: 1px;
  color: #ffffff;
  background: #3498DB;
  border-color: #3498DB; }
  .c-btn-blue.btn-no-focus:focus,
  .c-btn-blue.btn-no-focus.focus {
    color: #ffffff;
    background: #3498DB;
    border-color: #3498DB; }
  .c-btn-blue.btn-no-focus:hover,
  .c-btn-blue:hover,
  .c-btn-blue.btn-no-focus:active,
  .c-btn-blue:active,
  .c-btn-blue.active,
  .open > .c-btn-blue.dropdown-toggle {
    color: #ffffff;
    background: #2383c4;
    border-color: #2383c4; }
  .c-btn-blue:active,
  .c-btn-blue.active,
  .open > .c-btn-blue.dropdown-toggle {
    background-image: none; }
  .c-btn-blue.disabled,
  .c-btn-blue.disabled:hover,
  .c-btn-blue.disabled:not(.btn-no-focus):focus,
  .c-btn-blue.disabled:not(.btn-no-focus).focus,
  .c-btn-blue.disabled:active,
  .c-btn-blue.disabled.active,
  .c-btn-blue[disabled],
  .c-btn-blue[disabled]:hover,
  .c-btn-blue[disabled]:not(.btn-no-focus):focus,
  .c-btn-blue[disabled]:not(.btn-no-focus).focus,
  .c-btn-blue[disabled]:active,
  .c-btn-blue[disabled].active,
  fieldset[disabled] .c-btn-blue,
  fieldset[disabled] .c-btn-blue:hover,
  fieldset[disabled] .c-btn-blue:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-blue:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-blue:active,
  fieldset[disabled] .c-btn-blue.active {
    background: #3498DB;
    border-color: #3498DB; }
  .c-btn-blue .badge {
    color: #3498DB;
    background: #ffffff; }

.c-btn-blue-1 {
  border-color: #5e9cd1;
  border-width: 1px;
  color: #ffffff;
  background: #5e9cd1;
  border-color: #5e9cd1; }
  .c-btn-blue-1.btn-no-focus:focus,
  .c-btn-blue-1.btn-no-focus.focus {
    color: #ffffff;
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .c-btn-blue-1.btn-no-focus:hover,
  .c-btn-blue-1:hover,
  .c-btn-blue-1.btn-no-focus:active,
  .c-btn-blue-1:active,
  .c-btn-blue-1.active,
  .open > .c-btn-blue-1.dropdown-toggle {
    color: #ffffff;
    background: #3e88c8;
    border-color: #3e88c8; }
  .c-btn-blue-1:active,
  .c-btn-blue-1.active,
  .open > .c-btn-blue-1.dropdown-toggle {
    background-image: none; }
  .c-btn-blue-1.disabled,
  .c-btn-blue-1.disabled:hover,
  .c-btn-blue-1.disabled:not(.btn-no-focus):focus,
  .c-btn-blue-1.disabled:not(.btn-no-focus).focus,
  .c-btn-blue-1.disabled:active,
  .c-btn-blue-1.disabled.active,
  .c-btn-blue-1[disabled],
  .c-btn-blue-1[disabled]:hover,
  .c-btn-blue-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-blue-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-blue-1[disabled]:active,
  .c-btn-blue-1[disabled].active,
  fieldset[disabled] .c-btn-blue-1,
  fieldset[disabled] .c-btn-blue-1:hover,
  fieldset[disabled] .c-btn-blue-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-blue-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-blue-1:active,
  fieldset[disabled] .c-btn-blue-1.active {
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .c-btn-blue-1 .badge {
    color: #5e9cd1;
    background: #ffffff; }

.c-btn-blue-2 {
  border-color: #5893dd;
  border-width: 1px;
  color: #ffffff;
  background: #5893dd;
  border-color: #5893dd; }
  .c-btn-blue-2.btn-no-focus:focus,
  .c-btn-blue-2.btn-no-focus.focus {
    color: #ffffff;
    background: #5893dd;
    border-color: #5893dd; }
  .c-btn-blue-2.btn-no-focus:hover,
  .c-btn-blue-2:hover,
  .c-btn-blue-2.btn-no-focus:active,
  .c-btn-blue-2:active,
  .c-btn-blue-2.active,
  .open > .c-btn-blue-2.dropdown-toggle {
    color: #ffffff;
    background: #367dd6;
    border-color: #367dd6; }
  .c-btn-blue-2:active,
  .c-btn-blue-2.active,
  .open > .c-btn-blue-2.dropdown-toggle {
    background-image: none; }
  .c-btn-blue-2.disabled,
  .c-btn-blue-2.disabled:hover,
  .c-btn-blue-2.disabled:not(.btn-no-focus):focus,
  .c-btn-blue-2.disabled:not(.btn-no-focus).focus,
  .c-btn-blue-2.disabled:active,
  .c-btn-blue-2.disabled.active,
  .c-btn-blue-2[disabled],
  .c-btn-blue-2[disabled]:hover,
  .c-btn-blue-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-blue-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-blue-2[disabled]:active,
  .c-btn-blue-2[disabled].active,
  fieldset[disabled] .c-btn-blue-2,
  fieldset[disabled] .c-btn-blue-2:hover,
  fieldset[disabled] .c-btn-blue-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-blue-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-blue-2:active,
  fieldset[disabled] .c-btn-blue-2.active {
    background: #5893dd;
    border-color: #5893dd; }
  .c-btn-blue-2 .badge {
    color: #5893dd;
    background: #ffffff; }

.c-btn-blue-3 {
  border-color: #57bfe1;
  border-width: 1px;
  color: #ffffff;
  background: #57bfe1;
  border-color: #57bfe1; }
  .c-btn-blue-3.btn-no-focus:focus,
  .c-btn-blue-3.btn-no-focus.focus {
    color: #ffffff;
    background: #57bfe1;
    border-color: #57bfe1; }
  .c-btn-blue-3.btn-no-focus:hover,
  .c-btn-blue-3:hover,
  .c-btn-blue-3.btn-no-focus:active,
  .c-btn-blue-3:active,
  .c-btn-blue-3.active,
  .open > .c-btn-blue-3.dropdown-toggle {
    color: #ffffff;
    background: #34b2db;
    border-color: #34b2db; }
  .c-btn-blue-3:active,
  .c-btn-blue-3.active,
  .open > .c-btn-blue-3.dropdown-toggle {
    background-image: none; }
  .c-btn-blue-3.disabled,
  .c-btn-blue-3.disabled:hover,
  .c-btn-blue-3.disabled:not(.btn-no-focus):focus,
  .c-btn-blue-3.disabled:not(.btn-no-focus).focus,
  .c-btn-blue-3.disabled:active,
  .c-btn-blue-3.disabled.active,
  .c-btn-blue-3[disabled],
  .c-btn-blue-3[disabled]:hover,
  .c-btn-blue-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-blue-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-blue-3[disabled]:active,
  .c-btn-blue-3[disabled].active,
  fieldset[disabled] .c-btn-blue-3,
  fieldset[disabled] .c-btn-blue-3:hover,
  fieldset[disabled] .c-btn-blue-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-blue-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-blue-3:active,
  fieldset[disabled] .c-btn-blue-3.active {
    background: #57bfe1;
    border-color: #57bfe1; }
  .c-btn-blue-3 .badge {
    color: #57bfe1;
    background: #ffffff; }

.c-btn-purple {
  border-color: #b771b0;
  border-width: 1px;
  color: #ffffff;
  background: #b771b0;
  border-color: #b771b0; }
  .c-btn-purple.btn-no-focus:focus,
  .c-btn-purple.btn-no-focus.focus {
    color: #ffffff;
    background: #b771b0;
    border-color: #b771b0; }
  .c-btn-purple.btn-no-focus:hover,
  .c-btn-purple:hover,
  .c-btn-purple.btn-no-focus:active,
  .c-btn-purple:active,
  .c-btn-purple.active,
  .open > .c-btn-purple.dropdown-toggle {
    color: #ffffff;
    background: #a956a1;
    border-color: #a956a1; }
  .c-btn-purple:active,
  .c-btn-purple.active,
  .open > .c-btn-purple.dropdown-toggle {
    background-image: none; }
  .c-btn-purple.disabled,
  .c-btn-purple.disabled:hover,
  .c-btn-purple.disabled:not(.btn-no-focus):focus,
  .c-btn-purple.disabled:not(.btn-no-focus).focus,
  .c-btn-purple.disabled:active,
  .c-btn-purple.disabled.active,
  .c-btn-purple[disabled],
  .c-btn-purple[disabled]:hover,
  .c-btn-purple[disabled]:not(.btn-no-focus):focus,
  .c-btn-purple[disabled]:not(.btn-no-focus).focus,
  .c-btn-purple[disabled]:active,
  .c-btn-purple[disabled].active,
  fieldset[disabled] .c-btn-purple,
  fieldset[disabled] .c-btn-purple:hover,
  fieldset[disabled] .c-btn-purple:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-purple:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-purple:active,
  fieldset[disabled] .c-btn-purple.active {
    background: #b771b0;
    border-color: #b771b0; }
  .c-btn-purple .badge {
    color: #b771b0;
    background: #ffffff; }

.c-btn-purple-1 {
  border-color: #a962bb;
  border-width: 1px;
  color: #ffffff;
  background: #a962bb;
  border-color: #a962bb; }
  .c-btn-purple-1.btn-no-focus:focus,
  .c-btn-purple-1.btn-no-focus.focus {
    color: #ffffff;
    background: #a962bb;
    border-color: #a962bb; }
  .c-btn-purple-1.btn-no-focus:hover,
  .c-btn-purple-1:hover,
  .c-btn-purple-1.btn-no-focus:active,
  .c-btn-purple-1:active,
  .c-btn-purple-1.active,
  .open > .c-btn-purple-1.dropdown-toggle {
    color: #ffffff;
    background: #974aaa;
    border-color: #974aaa; }
  .c-btn-purple-1:active,
  .c-btn-purple-1.active,
  .open > .c-btn-purple-1.dropdown-toggle {
    background-image: none; }
  .c-btn-purple-1.disabled,
  .c-btn-purple-1.disabled:hover,
  .c-btn-purple-1.disabled:not(.btn-no-focus):focus,
  .c-btn-purple-1.disabled:not(.btn-no-focus).focus,
  .c-btn-purple-1.disabled:active,
  .c-btn-purple-1.disabled.active,
  .c-btn-purple-1[disabled],
  .c-btn-purple-1[disabled]:hover,
  .c-btn-purple-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-purple-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-purple-1[disabled]:active,
  .c-btn-purple-1[disabled].active,
  fieldset[disabled] .c-btn-purple-1,
  fieldset[disabled] .c-btn-purple-1:hover,
  fieldset[disabled] .c-btn-purple-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-purple-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-purple-1:active,
  fieldset[disabled] .c-btn-purple-1.active {
    background: #a962bb;
    border-color: #a962bb; }
  .c-btn-purple-1 .badge {
    color: #a962bb;
    background: #ffffff; }

.c-btn-purple-2 {
  border-color: #aa67a3;
  border-width: 1px;
  color: #ffffff;
  background: #aa67a3;
  border-color: #aa67a3; }
  .c-btn-purple-2.btn-no-focus:focus,
  .c-btn-purple-2.btn-no-focus.focus {
    color: #ffffff;
    background: #aa67a3;
    border-color: #aa67a3; }
  .c-btn-purple-2.btn-no-focus:hover,
  .c-btn-purple-2:hover,
  .c-btn-purple-2.btn-no-focus:active,
  .c-btn-purple-2:active,
  .c-btn-purple-2.active,
  .open > .c-btn-purple-2.dropdown-toggle {
    color: #ffffff;
    background: #95538e;
    border-color: #95538e; }
  .c-btn-purple-2:active,
  .c-btn-purple-2.active,
  .open > .c-btn-purple-2.dropdown-toggle {
    background-image: none; }
  .c-btn-purple-2.disabled,
  .c-btn-purple-2.disabled:hover,
  .c-btn-purple-2.disabled:not(.btn-no-focus):focus,
  .c-btn-purple-2.disabled:not(.btn-no-focus).focus,
  .c-btn-purple-2.disabled:active,
  .c-btn-purple-2.disabled.active,
  .c-btn-purple-2[disabled],
  .c-btn-purple-2[disabled]:hover,
  .c-btn-purple-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-purple-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-purple-2[disabled]:active,
  .c-btn-purple-2[disabled].active,
  fieldset[disabled] .c-btn-purple-2,
  fieldset[disabled] .c-btn-purple-2:hover,
  fieldset[disabled] .c-btn-purple-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-purple-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-purple-2:active,
  fieldset[disabled] .c-btn-purple-2.active {
    background: #aa67a3;
    border-color: #aa67a3; }
  .c-btn-purple-2 .badge {
    color: #aa67a3;
    background: #ffffff; }

.c-btn-purple-3 {
  border-color: #ac3773;
  border-width: 1px;
  color: #ffffff;
  background: #ac3773;
  border-color: #ac3773; }
  .c-btn-purple-3.btn-no-focus:focus,
  .c-btn-purple-3.btn-no-focus.focus {
    color: #ffffff;
    background: #ac3773;
    border-color: #ac3773; }
  .c-btn-purple-3.btn-no-focus:hover,
  .c-btn-purple-3:hover,
  .c-btn-purple-3.btn-no-focus:active,
  .c-btn-purple-3:active,
  .c-btn-purple-3.active,
  .open > .c-btn-purple-3.dropdown-toggle {
    color: #ffffff;
    background: #8d2d5e;
    border-color: #8d2d5e; }
  .c-btn-purple-3:active,
  .c-btn-purple-3.active,
  .open > .c-btn-purple-3.dropdown-toggle {
    background-image: none; }
  .c-btn-purple-3.disabled,
  .c-btn-purple-3.disabled:hover,
  .c-btn-purple-3.disabled:not(.btn-no-focus):focus,
  .c-btn-purple-3.disabled:not(.btn-no-focus).focus,
  .c-btn-purple-3.disabled:active,
  .c-btn-purple-3.disabled.active,
  .c-btn-purple-3[disabled],
  .c-btn-purple-3[disabled]:hover,
  .c-btn-purple-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-purple-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-purple-3[disabled]:active,
  .c-btn-purple-3[disabled].active,
  fieldset[disabled] .c-btn-purple-3,
  fieldset[disabled] .c-btn-purple-3:hover,
  fieldset[disabled] .c-btn-purple-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-purple-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-purple-3:active,
  fieldset[disabled] .c-btn-purple-3.active {
    background: #ac3773;
    border-color: #ac3773; }
  .c-btn-purple-3 .badge {
    color: #ac3773;
    background: #ffffff; }

.c-btn-brown {
  border-color: #8a7f68;
  border-width: 1px;
  color: #ffffff;
  background: #8a7f68;
  border-color: #8a7f68; }
  .c-btn-brown.btn-no-focus:focus,
  .c-btn-brown.btn-no-focus.focus {
    color: #ffffff;
    background: #8a7f68;
    border-color: #8a7f68; }
  .c-btn-brown.btn-no-focus:hover,
  .c-btn-brown:hover,
  .c-btn-brown.btn-no-focus:active,
  .c-btn-brown:active,
  .c-btn-brown.active,
  .open > .c-btn-brown.dropdown-toggle {
    color: #ffffff;
    background: #736a56;
    border-color: #736a56; }
  .c-btn-brown:active,
  .c-btn-brown.active,
  .open > .c-btn-brown.dropdown-toggle {
    background-image: none; }
  .c-btn-brown.disabled,
  .c-btn-brown.disabled:hover,
  .c-btn-brown.disabled:not(.btn-no-focus):focus,
  .c-btn-brown.disabled:not(.btn-no-focus).focus,
  .c-btn-brown.disabled:active,
  .c-btn-brown.disabled.active,
  .c-btn-brown[disabled],
  .c-btn-brown[disabled]:hover,
  .c-btn-brown[disabled]:not(.btn-no-focus):focus,
  .c-btn-brown[disabled]:not(.btn-no-focus).focus,
  .c-btn-brown[disabled]:active,
  .c-btn-brown[disabled].active,
  fieldset[disabled] .c-btn-brown,
  fieldset[disabled] .c-btn-brown:hover,
  fieldset[disabled] .c-btn-brown:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-brown:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-brown:active,
  fieldset[disabled] .c-btn-brown.active {
    background: #8a7f68;
    border-color: #8a7f68; }
  .c-btn-brown .badge {
    color: #8a7f68;
    background: #ffffff; }

.c-btn-brown-1 {
  border-color: #685e47;
  border-width: 1px;
  color: #ffffff;
  background: #685e47;
  border-color: #685e47; }
  .c-btn-brown-1.btn-no-focus:focus,
  .c-btn-brown-1.btn-no-focus.focus {
    color: #ffffff;
    background: #685e47;
    border-color: #685e47; }
  .c-btn-brown-1.btn-no-focus:hover,
  .c-btn-brown-1:hover,
  .c-btn-brown-1.btn-no-focus:active,
  .c-btn-brown-1:active,
  .c-btn-brown-1.active,
  .open > .c-btn-brown-1.dropdown-toggle {
    color: #ffffff;
    background: #504836;
    border-color: #504836; }
  .c-btn-brown-1:active,
  .c-btn-brown-1.active,
  .open > .c-btn-brown-1.dropdown-toggle {
    background-image: none; }
  .c-btn-brown-1.disabled,
  .c-btn-brown-1.disabled:hover,
  .c-btn-brown-1.disabled:not(.btn-no-focus):focus,
  .c-btn-brown-1.disabled:not(.btn-no-focus).focus,
  .c-btn-brown-1.disabled:active,
  .c-btn-brown-1.disabled.active,
  .c-btn-brown-1[disabled],
  .c-btn-brown-1[disabled]:hover,
  .c-btn-brown-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-brown-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-brown-1[disabled]:active,
  .c-btn-brown-1[disabled].active,
  fieldset[disabled] .c-btn-brown-1,
  fieldset[disabled] .c-btn-brown-1:hover,
  fieldset[disabled] .c-btn-brown-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-brown-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-brown-1:active,
  fieldset[disabled] .c-btn-brown-1.active {
    background: #685e47;
    border-color: #685e47; }
  .c-btn-brown-1 .badge {
    color: #685e47;
    background: #ffffff; }

.c-btn-brown-2 {
  border-color: #7a6a61;
  border-width: 1px;
  color: #ffffff;
  background: #7a6a61;
  border-color: #7a6a61; }
  .c-btn-brown-2.btn-no-focus:focus,
  .c-btn-brown-2.btn-no-focus.focus {
    color: #ffffff;
    background: #7a6a61;
    border-color: #7a6a61; }
  .c-btn-brown-2.btn-no-focus:hover,
  .c-btn-brown-2:hover,
  .c-btn-brown-2.btn-no-focus:active,
  .c-btn-brown-2:active,
  .c-btn-brown-2.active,
  .open > .c-btn-brown-2.dropdown-toggle {
    color: #ffffff;
    background: #63564f;
    border-color: #63564f; }
  .c-btn-brown-2:active,
  .c-btn-brown-2.active,
  .open > .c-btn-brown-2.dropdown-toggle {
    background-image: none; }
  .c-btn-brown-2.disabled,
  .c-btn-brown-2.disabled:hover,
  .c-btn-brown-2.disabled:not(.btn-no-focus):focus,
  .c-btn-brown-2.disabled:not(.btn-no-focus).focus,
  .c-btn-brown-2.disabled:active,
  .c-btn-brown-2.disabled.active,
  .c-btn-brown-2[disabled],
  .c-btn-brown-2[disabled]:hover,
  .c-btn-brown-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-brown-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-brown-2[disabled]:active,
  .c-btn-brown-2[disabled].active,
  fieldset[disabled] .c-btn-brown-2,
  fieldset[disabled] .c-btn-brown-2:hover,
  fieldset[disabled] .c-btn-brown-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-brown-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-brown-2:active,
  fieldset[disabled] .c-btn-brown-2.active {
    background: #7a6a61;
    border-color: #7a6a61; }
  .c-btn-brown-2 .badge {
    color: #7a6a61;
    background: #ffffff; }

.c-btn-brown-3 {
  border-color: #9d8b81;
  border-width: 1px;
  color: #ffffff;
  background: #9d8b81;
  border-color: #9d8b81; }
  .c-btn-brown-3.btn-no-focus:focus,
  .c-btn-brown-3.btn-no-focus.focus {
    color: #ffffff;
    background: #9d8b81;
    border-color: #9d8b81; }
  .c-btn-brown-3.btn-no-focus:hover,
  .c-btn-brown-3:hover,
  .c-btn-brown-3.btn-no-focus:active,
  .c-btn-brown-3:active,
  .c-btn-brown-3.active,
  .open > .c-btn-brown-3.dropdown-toggle {
    color: #ffffff;
    background: #8a766b;
    border-color: #8a766b; }
  .c-btn-brown-3:active,
  .c-btn-brown-3.active,
  .open > .c-btn-brown-3.dropdown-toggle {
    background-image: none; }
  .c-btn-brown-3.disabled,
  .c-btn-brown-3.disabled:hover,
  .c-btn-brown-3.disabled:not(.btn-no-focus):focus,
  .c-btn-brown-3.disabled:not(.btn-no-focus).focus,
  .c-btn-brown-3.disabled:active,
  .c-btn-brown-3.disabled.active,
  .c-btn-brown-3[disabled],
  .c-btn-brown-3[disabled]:hover,
  .c-btn-brown-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-brown-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-brown-3[disabled]:active,
  .c-btn-brown-3[disabled].active,
  fieldset[disabled] .c-btn-brown-3,
  fieldset[disabled] .c-btn-brown-3:hover,
  fieldset[disabled] .c-btn-brown-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-brown-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-brown-3:active,
  fieldset[disabled] .c-btn-brown-3.active {
    background: #9d8b81;
    border-color: #9d8b81; }
  .c-btn-brown-3 .badge {
    color: #9d8b81;
    background: #ffffff; }

.c-btn-dark {
  border-color: #2f353b;
  border-width: 1px;
  color: #ffffff;
  background: #2f353b;
  border-color: #2f353b; }
  .c-btn-dark.btn-no-focus:focus,
  .c-btn-dark.btn-no-focus.focus {
    color: #ffffff;
    background: #2f353b;
    border-color: #2f353b; }
  .c-btn-dark.btn-no-focus:hover,
  .c-btn-dark:hover,
  .c-btn-dark.btn-no-focus:active,
  .c-btn-dark:active,
  .c-btn-dark.active,
  .open > .c-btn-dark.dropdown-toggle {
    color: #ffffff;
    background: #1d2124;
    border-color: #1d2124; }
  .c-btn-dark:active,
  .c-btn-dark.active,
  .open > .c-btn-dark.dropdown-toggle {
    background-image: none; }
  .c-btn-dark.disabled,
  .c-btn-dark.disabled:hover,
  .c-btn-dark.disabled:not(.btn-no-focus):focus,
  .c-btn-dark.disabled:not(.btn-no-focus).focus,
  .c-btn-dark.disabled:active,
  .c-btn-dark.disabled.active,
  .c-btn-dark[disabled],
  .c-btn-dark[disabled]:hover,
  .c-btn-dark[disabled]:not(.btn-no-focus):focus,
  .c-btn-dark[disabled]:not(.btn-no-focus).focus,
  .c-btn-dark[disabled]:active,
  .c-btn-dark[disabled].active,
  fieldset[disabled] .c-btn-dark,
  fieldset[disabled] .c-btn-dark:hover,
  fieldset[disabled] .c-btn-dark:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-dark:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-dark:active,
  fieldset[disabled] .c-btn-dark.active {
    background: #2f353b;
    border-color: #2f353b; }
  .c-btn-dark .badge {
    color: #2f353b;
    background: #ffffff; }

.c-btn-dark-1 {
  border-color: #525e64;
  border-width: 1px;
  color: #ffffff;
  background: #525e64;
  border-color: #525e64; }
  .c-btn-dark-1.btn-no-focus:focus,
  .c-btn-dark-1.btn-no-focus.focus {
    color: #ffffff;
    background: #525e64;
    border-color: #525e64; }
  .c-btn-dark-1.btn-no-focus:hover,
  .c-btn-dark-1:hover,
  .c-btn-dark-1.btn-no-focus:active,
  .c-btn-dark-1:active,
  .c-btn-dark-1.active,
  .open > .c-btn-dark-1.dropdown-toggle {
    color: #ffffff;
    background: #40494e;
    border-color: #40494e; }
  .c-btn-dark-1:active,
  .c-btn-dark-1.active,
  .open > .c-btn-dark-1.dropdown-toggle {
    background-image: none; }
  .c-btn-dark-1.disabled,
  .c-btn-dark-1.disabled:hover,
  .c-btn-dark-1.disabled:not(.btn-no-focus):focus,
  .c-btn-dark-1.disabled:not(.btn-no-focus).focus,
  .c-btn-dark-1.disabled:active,
  .c-btn-dark-1.disabled.active,
  .c-btn-dark-1[disabled],
  .c-btn-dark-1[disabled]:hover,
  .c-btn-dark-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-dark-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-dark-1[disabled]:active,
  .c-btn-dark-1[disabled].active,
  fieldset[disabled] .c-btn-dark-1,
  fieldset[disabled] .c-btn-dark-1:hover,
  fieldset[disabled] .c-btn-dark-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-dark-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-dark-1:active,
  fieldset[disabled] .c-btn-dark-1.active {
    background: #525e64;
    border-color: #525e64; }
  .c-btn-dark-1 .badge {
    color: #525e64;
    background: #ffffff; }

.c-btn-dark-2 {
  border-color: #31383c;
  border-width: 1px;
  color: #ffffff;
  background: #31383c;
  border-color: #31383c; }
  .c-btn-dark-2.btn-no-focus:focus,
  .c-btn-dark-2.btn-no-focus.focus {
    color: #ffffff;
    background: #31383c;
    border-color: #31383c; }
  .c-btn-dark-2.btn-no-focus:hover,
  .c-btn-dark-2:hover,
  .c-btn-dark-2.btn-no-focus:active,
  .c-btn-dark-2:active,
  .c-btn-dark-2.active,
  .open > .c-btn-dark-2.dropdown-toggle {
    color: #ffffff;
    background: #1f2326;
    border-color: #1f2326; }
  .c-btn-dark-2:active,
  .c-btn-dark-2.active,
  .open > .c-btn-dark-2.dropdown-toggle {
    background-image: none; }
  .c-btn-dark-2.disabled,
  .c-btn-dark-2.disabled:hover,
  .c-btn-dark-2.disabled:not(.btn-no-focus):focus,
  .c-btn-dark-2.disabled:not(.btn-no-focus).focus,
  .c-btn-dark-2.disabled:active,
  .c-btn-dark-2.disabled.active,
  .c-btn-dark-2[disabled],
  .c-btn-dark-2[disabled]:hover,
  .c-btn-dark-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-dark-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-dark-2[disabled]:active,
  .c-btn-dark-2[disabled].active,
  fieldset[disabled] .c-btn-dark-2,
  fieldset[disabled] .c-btn-dark-2:hover,
  fieldset[disabled] .c-btn-dark-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-dark-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-dark-2:active,
  fieldset[disabled] .c-btn-dark-2.active {
    background: #31383c;
    border-color: #31383c; }
  .c-btn-dark-2 .badge {
    color: #31383c;
    background: #ffffff; }

.c-btn-dark-3 {
  border-color: #41515b;
  border-width: 1px;
  color: #ffffff;
  background: #41515b;
  border-color: #41515b; }
  .c-btn-dark-3.btn-no-focus:focus,
  .c-btn-dark-3.btn-no-focus.focus {
    color: #ffffff;
    background: #41515b;
    border-color: #41515b; }
  .c-btn-dark-3.btn-no-focus:hover,
  .c-btn-dark-3:hover,
  .c-btn-dark-3.btn-no-focus:active,
  .c-btn-dark-3:active,
  .c-btn-dark-3.active,
  .open > .c-btn-dark-3.dropdown-toggle {
    color: #ffffff;
    background: #303c43;
    border-color: #303c43; }
  .c-btn-dark-3:active,
  .c-btn-dark-3.active,
  .open > .c-btn-dark-3.dropdown-toggle {
    background-image: none; }
  .c-btn-dark-3.disabled,
  .c-btn-dark-3.disabled:hover,
  .c-btn-dark-3.disabled:not(.btn-no-focus):focus,
  .c-btn-dark-3.disabled:not(.btn-no-focus).focus,
  .c-btn-dark-3.disabled:active,
  .c-btn-dark-3.disabled.active,
  .c-btn-dark-3[disabled],
  .c-btn-dark-3[disabled]:hover,
  .c-btn-dark-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-dark-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-dark-3[disabled]:active,
  .c-btn-dark-3[disabled].active,
  fieldset[disabled] .c-btn-dark-3,
  fieldset[disabled] .c-btn-dark-3:hover,
  fieldset[disabled] .c-btn-dark-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-dark-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-dark-3:active,
  fieldset[disabled] .c-btn-dark-3.active {
    background: #41515b;
    border-color: #41515b; }
  .c-btn-dark-3 .badge {
    color: #41515b;
    background: #ffffff; }

.c-btn-grey {
  border-color: #eeeeee;
  border-width: 1px;
  color: #6e6e6e;
  background: #eeeeee;
  border-color: #eeeeee; }
  .c-btn-grey.btn-no-focus:focus,
  .c-btn-grey.btn-no-focus.focus {
    color: #6e6e6e;
    background: #eeeeee;
    border-color: #eeeeee; }
  .c-btn-grey.btn-no-focus:hover,
  .c-btn-grey:hover,
  .c-btn-grey.btn-no-focus:active,
  .c-btn-grey:active,
  .c-btn-grey.active,
  .open > .c-btn-grey.dropdown-toggle {
    color: #6e6e6e;
    background: #dadada;
    border-color: #dadada; }
  .c-btn-grey:active,
  .c-btn-grey.active,
  .open > .c-btn-grey.dropdown-toggle {
    background-image: none; }
  .c-btn-grey.disabled,
  .c-btn-grey.disabled:hover,
  .c-btn-grey.disabled:not(.btn-no-focus):focus,
  .c-btn-grey.disabled:not(.btn-no-focus).focus,
  .c-btn-grey.disabled:active,
  .c-btn-grey.disabled.active,
  .c-btn-grey[disabled],
  .c-btn-grey[disabled]:hover,
  .c-btn-grey[disabled]:not(.btn-no-focus):focus,
  .c-btn-grey[disabled]:not(.btn-no-focus).focus,
  .c-btn-grey[disabled]:active,
  .c-btn-grey[disabled].active,
  fieldset[disabled] .c-btn-grey,
  fieldset[disabled] .c-btn-grey:hover,
  fieldset[disabled] .c-btn-grey:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-grey:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-grey:active,
  fieldset[disabled] .c-btn-grey.active {
    background: #eeeeee;
    border-color: #eeeeee; }
  .c-btn-grey .badge {
    color: #eeeeee;
    background: #6e6e6e; }

.c-btn-grey-1 {
  border-color: #f7f7f7;
  border-width: 1px;
  color: #777777;
  background: #f7f7f7;
  border-color: #f7f7f7; }
  .c-btn-grey-1.btn-no-focus:focus,
  .c-btn-grey-1.btn-no-focus.focus {
    color: #777777;
    background: #f7f7f7;
    border-color: #f7f7f7; }
  .c-btn-grey-1.btn-no-focus:hover,
  .c-btn-grey-1:hover,
  .c-btn-grey-1.btn-no-focus:active,
  .c-btn-grey-1:active,
  .c-btn-grey-1.active,
  .open > .c-btn-grey-1.dropdown-toggle {
    color: #777777;
    background: #e3e3e3;
    border-color: #e3e3e3; }
  .c-btn-grey-1:active,
  .c-btn-grey-1.active,
  .open > .c-btn-grey-1.dropdown-toggle {
    background-image: none; }
  .c-btn-grey-1.disabled,
  .c-btn-grey-1.disabled:hover,
  .c-btn-grey-1.disabled:not(.btn-no-focus):focus,
  .c-btn-grey-1.disabled:not(.btn-no-focus).focus,
  .c-btn-grey-1.disabled:active,
  .c-btn-grey-1.disabled.active,
  .c-btn-grey-1[disabled],
  .c-btn-grey-1[disabled]:hover,
  .c-btn-grey-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-grey-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-grey-1[disabled]:active,
  .c-btn-grey-1[disabled].active,
  fieldset[disabled] .c-btn-grey-1,
  fieldset[disabled] .c-btn-grey-1:hover,
  fieldset[disabled] .c-btn-grey-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-grey-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-grey-1:active,
  fieldset[disabled] .c-btn-grey-1.active {
    background: #f7f7f7;
    border-color: #f7f7f7; }
  .c-btn-grey-1 .badge {
    color: #f7f7f7;
    background: #777777; }

.c-btn-grey-2 {
  border-color: #677083;
  border-width: 1px;
  color: #ffffff;
  background: #677083;
  border-color: #677083; }
  .c-btn-grey-2.btn-no-focus:focus,
  .c-btn-grey-2.btn-no-focus.focus {
    color: #ffffff;
    background: #677083;
    border-color: #677083; }
  .c-btn-grey-2.btn-no-focus:hover,
  .c-btn-grey-2:hover,
  .c-btn-grey-2.btn-no-focus:active,
  .c-btn-grey-2:active,
  .c-btn-grey-2.active,
  .open > .c-btn-grey-2.dropdown-toggle {
    color: #ffffff;
    background: #555c6c;
    border-color: #555c6c; }
  .c-btn-grey-2:active,
  .c-btn-grey-2.active,
  .open > .c-btn-grey-2.dropdown-toggle {
    background-image: none; }
  .c-btn-grey-2.disabled,
  .c-btn-grey-2.disabled:hover,
  .c-btn-grey-2.disabled:not(.btn-no-focus):focus,
  .c-btn-grey-2.disabled:not(.btn-no-focus).focus,
  .c-btn-grey-2.disabled:active,
  .c-btn-grey-2.disabled.active,
  .c-btn-grey-2[disabled],
  .c-btn-grey-2[disabled]:hover,
  .c-btn-grey-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-grey-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-grey-2[disabled]:active,
  .c-btn-grey-2[disabled].active,
  fieldset[disabled] .c-btn-grey-2,
  fieldset[disabled] .c-btn-grey-2:hover,
  fieldset[disabled] .c-btn-grey-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-grey-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-grey-2:active,
  fieldset[disabled] .c-btn-grey-2.active {
    background: #677083;
    border-color: #677083; }
  .c-btn-grey-2 .badge {
    color: #677083;
    background: #ffffff; }

.c-btn-grey-3 {
  border-color: #7f8c97;
  border-width: 1px;
  color: #ffffff;
  background: #7f8c97;
  border-color: #7f8c97; }
  .c-btn-grey-3.btn-no-focus:focus,
  .c-btn-grey-3.btn-no-focus.focus {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-grey-3.btn-no-focus:hover,
  .c-btn-grey-3:hover,
  .c-btn-grey-3.btn-no-focus:active,
  .c-btn-grey-3:active,
  .c-btn-grey-3.active,
  .open > .c-btn-grey-3.dropdown-toggle {
    color: #ffffff;
    background: #6a7883;
    border-color: #6a7883; }
  .c-btn-grey-3:active,
  .c-btn-grey-3.active,
  .open > .c-btn-grey-3.dropdown-toggle {
    background-image: none; }
  .c-btn-grey-3.disabled,
  .c-btn-grey-3.disabled:hover,
  .c-btn-grey-3.disabled:not(.btn-no-focus):focus,
  .c-btn-grey-3.disabled:not(.btn-no-focus).focus,
  .c-btn-grey-3.disabled:active,
  .c-btn-grey-3.disabled.active,
  .c-btn-grey-3[disabled],
  .c-btn-grey-3[disabled]:hover,
  .c-btn-grey-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-grey-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-grey-3[disabled]:active,
  .c-btn-grey-3[disabled].active,
  fieldset[disabled] .c-btn-grey-3,
  fieldset[disabled] .c-btn-grey-3:hover,
  fieldset[disabled] .c-btn-grey-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-grey-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-grey-3:active,
  fieldset[disabled] .c-btn-grey-3.active {
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-grey-3 .badge {
    color: #7f8c97;
    background: #ffffff; }

.c-btn-border-1x.c-btn-white {
  border-color: #FFFFFF;
  color: #FFFFFF;
  background: none;
  border-color: #FFFFFF; }
  .c-btn-border-1x.c-btn-white.c-btn-border-opacity-04 {
    border-color: rgba(255, 255, 255, 0.4); }
  .c-btn-border-1x.c-btn-white.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-white.btn-no-focus.focus {
    color: #FFFFFF;
    background: none;
    border-color: #FFFFFF; }
  .c-btn-border-1x.c-btn-white.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-white:hover,
  .c-btn-border-1x.c-btn-white.btn-no-focus:active,
  .c-btn-border-1x.c-btn-white:active,
  .c-btn-border-1x.c-btn-white.active,
  .open > .c-btn-border-1x.c-btn-white.dropdown-toggle {
    color: #2f353b;
    background: #FFFFFF;
    border-color: #FFFFFF; }
  .c-btn-border-1x.c-btn-white:active,
  .c-btn-border-1x.c-btn-white.active,
  .open > .c-btn-border-1x.c-btn-white.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-white.disabled,
  .c-btn-border-1x.c-btn-white.disabled:hover,
  .c-btn-border-1x.c-btn-white.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-white.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-white.disabled:active,
  .c-btn-border-1x.c-btn-white.disabled.active,
  .c-btn-border-1x.c-btn-white[disabled],
  .c-btn-border-1x.c-btn-white[disabled]:hover,
  .c-btn-border-1x.c-btn-white[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-white[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-white[disabled]:active,
  .c-btn-border-1x.c-btn-white[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-white,
  fieldset[disabled] .c-btn-border-1x.c-btn-white:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-white:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-white:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-white:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-white.active {
    background: none;
    border-color: #FFFFFF; }
  .c-btn-border-1x.c-btn-white .badge {
    color: none;
    background: #FFFFFF; }

.c-btn-border-1x.c-btn-black {
  border-color: #000000;
  color: #000000;
  background: none;
  border-color: #000000; }
  .c-btn-border-1x.c-btn-black.c-btn-border-opacity-04 {
    border-color: rgba(0, 0, 0, 0.4); }
  .c-btn-border-1x.c-btn-black.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-black.btn-no-focus.focus {
    color: #000000;
    background: none;
    border-color: #000000; }
  .c-btn-border-1x.c-btn-black.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-black:hover,
  .c-btn-border-1x.c-btn-black.btn-no-focus:active,
  .c-btn-border-1x.c-btn-black:active,
  .c-btn-border-1x.c-btn-black.active,
  .open > .c-btn-border-1x.c-btn-black.dropdown-toggle {
    color: #ffffff;
    background: #000000;
    border-color: #000000; }
  .c-btn-border-1x.c-btn-black:active,
  .c-btn-border-1x.c-btn-black.active,
  .open > .c-btn-border-1x.c-btn-black.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-black.disabled,
  .c-btn-border-1x.c-btn-black.disabled:hover,
  .c-btn-border-1x.c-btn-black.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-black.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-black.disabled:active,
  .c-btn-border-1x.c-btn-black.disabled.active,
  .c-btn-border-1x.c-btn-black[disabled],
  .c-btn-border-1x.c-btn-black[disabled]:hover,
  .c-btn-border-1x.c-btn-black[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-black[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-black[disabled]:active,
  .c-btn-border-1x.c-btn-black[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-black,
  fieldset[disabled] .c-btn-border-1x.c-btn-black:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-black:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-black:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-black:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-black.active {
    background: none;
    border-color: #000000; }
  .c-btn-border-1x.c-btn-black .badge {
    color: none;
    background: #000000; }

.c-btn-border-1x.c-btn-regular {
  border-color: #7f8c97;
  color: #7f8c97;
  background: none;
  border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-regular.c-btn-border-opacity-04 {
    border-color: rgba(127, 140, 151, 0.4); }
  .c-btn-border-1x.c-btn-regular.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-regular.btn-no-focus.focus {
    color: #7f8c97;
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-regular.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-regular:hover,
  .c-btn-border-1x.c-btn-regular.btn-no-focus:active,
  .c-btn-border-1x.c-btn-regular:active,
  .c-btn-border-1x.c-btn-regular.active,
  .open > .c-btn-border-1x.c-btn-regular.dropdown-toggle {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-regular:active,
  .c-btn-border-1x.c-btn-regular.active,
  .open > .c-btn-border-1x.c-btn-regular.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-regular.disabled,
  .c-btn-border-1x.c-btn-regular.disabled:hover,
  .c-btn-border-1x.c-btn-regular.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-regular.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-regular.disabled:active,
  .c-btn-border-1x.c-btn-regular.disabled.active,
  .c-btn-border-1x.c-btn-regular[disabled],
  .c-btn-border-1x.c-btn-regular[disabled]:hover,
  .c-btn-border-1x.c-btn-regular[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-regular[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-regular[disabled]:active,
  .c-btn-border-1x.c-btn-regular[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-regular.active {
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-regular .badge {
    color: none;
    background: #7f8c97; }

.c-btn-border-1x.c-btn-green {
  border-color: #32c5d2;
  color: #32c5d2;
  background: none;
  border-color: #32c5d2; }
  .c-btn-border-1x.c-btn-green.c-btn-border-opacity-04 {
    border-color: rgba(50, 197, 210, 0.4); }
  .c-btn-border-1x.c-btn-green.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-green.btn-no-focus.focus {
    color: #32c5d2;
    background: none;
    border-color: #32c5d2; }
  .c-btn-border-1x.c-btn-green.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-green:hover,
  .c-btn-border-1x.c-btn-green.btn-no-focus:active,
  .c-btn-border-1x.c-btn-green:active,
  .c-btn-border-1x.c-btn-green.active,
  .open > .c-btn-border-1x.c-btn-green.dropdown-toggle {
    color: #ffffff;
    background: #32c5d2;
    border-color: #32c5d2; }
  .c-btn-border-1x.c-btn-green:active,
  .c-btn-border-1x.c-btn-green.active,
  .open > .c-btn-border-1x.c-btn-green.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-green.disabled,
  .c-btn-border-1x.c-btn-green.disabled:hover,
  .c-btn-border-1x.c-btn-green.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green.disabled:active,
  .c-btn-border-1x.c-btn-green.disabled.active,
  .c-btn-border-1x.c-btn-green[disabled],
  .c-btn-border-1x.c-btn-green[disabled]:hover,
  .c-btn-border-1x.c-btn-green[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green[disabled]:active,
  .c-btn-border-1x.c-btn-green[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green,
  fieldset[disabled] .c-btn-border-1x.c-btn-green:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-green:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green.active {
    background: none;
    border-color: #32c5d2; }
  .c-btn-border-1x.c-btn-green .badge {
    color: none;
    background: #32c5d2; }

.c-btn-border-1x.c-btn-green-1 {
  border-color: #52c9ba;
  color: #52c9ba;
  background: none;
  border-color: #52c9ba; }
  .c-btn-border-1x.c-btn-green-1.c-btn-border-opacity-04 {
    border-color: rgba(82, 201, 186, 0.4); }
  .c-btn-border-1x.c-btn-green-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-green-1.btn-no-focus.focus {
    color: #52c9ba;
    background: none;
    border-color: #52c9ba; }
  .c-btn-border-1x.c-btn-green-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-green-1:hover,
  .c-btn-border-1x.c-btn-green-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-green-1:active,
  .c-btn-border-1x.c-btn-green-1.active,
  .open > .c-btn-border-1x.c-btn-green-1.dropdown-toggle {
    color: #ffffff;
    background: #52c9ba;
    border-color: #52c9ba; }
  .c-btn-border-1x.c-btn-green-1:active,
  .c-btn-border-1x.c-btn-green-1.active,
  .open > .c-btn-border-1x.c-btn-green-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-green-1.disabled,
  .c-btn-border-1x.c-btn-green-1.disabled:hover,
  .c-btn-border-1x.c-btn-green-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-1.disabled:active,
  .c-btn-border-1x.c-btn-green-1.disabled.active,
  .c-btn-border-1x.c-btn-green-1[disabled],
  .c-btn-border-1x.c-btn-green-1[disabled]:hover,
  .c-btn-border-1x.c-btn-green-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-1[disabled]:active,
  .c-btn-border-1x.c-btn-green-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-1.active {
    background: none;
    border-color: #52c9ba; }
  .c-btn-border-1x.c-btn-green-1 .badge {
    color: none;
    background: #52c9ba; }

.c-btn-border-1x.c-btn-green-2 {
  border-color: #5dc09c;
  color: #5dc09c;
  background: none;
  border-color: #5dc09c; }
  .c-btn-border-1x.c-btn-green-2.c-btn-border-opacity-04 {
    border-color: rgba(93, 192, 156, 0.4); }
  .c-btn-border-1x.c-btn-green-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-green-2.btn-no-focus.focus {
    color: #5dc09c;
    background: none;
    border-color: #5dc09c; }
  .c-btn-border-1x.c-btn-green-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-green-2:hover,
  .c-btn-border-1x.c-btn-green-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-green-2:active,
  .c-btn-border-1x.c-btn-green-2.active,
  .open > .c-btn-border-1x.c-btn-green-2.dropdown-toggle {
    color: #ffffff;
    background: #5dc09c;
    border-color: #5dc09c; }
  .c-btn-border-1x.c-btn-green-2:active,
  .c-btn-border-1x.c-btn-green-2.active,
  .open > .c-btn-border-1x.c-btn-green-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-green-2.disabled,
  .c-btn-border-1x.c-btn-green-2.disabled:hover,
  .c-btn-border-1x.c-btn-green-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-2.disabled:active,
  .c-btn-border-1x.c-btn-green-2.disabled.active,
  .c-btn-border-1x.c-btn-green-2[disabled],
  .c-btn-border-1x.c-btn-green-2[disabled]:hover,
  .c-btn-border-1x.c-btn-green-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-2[disabled]:active,
  .c-btn-border-1x.c-btn-green-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-2.active {
    background: none;
    border-color: #5dc09c; }
  .c-btn-border-1x.c-btn-green-2 .badge {
    color: none;
    background: #5dc09c; }

.c-btn-border-1x.c-btn-green-3 {
  border-color: #84c68f;
  color: #84c68f;
  background: none;
  border-color: #84c68f; }
  .c-btn-border-1x.c-btn-green-3.c-btn-border-opacity-04 {
    border-color: rgba(132, 198, 143, 0.4); }
  .c-btn-border-1x.c-btn-green-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-green-3.btn-no-focus.focus {
    color: #84c68f;
    background: none;
    border-color: #84c68f; }
  .c-btn-border-1x.c-btn-green-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-green-3:hover,
  .c-btn-border-1x.c-btn-green-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-green-3:active,
  .c-btn-border-1x.c-btn-green-3.active,
  .open > .c-btn-border-1x.c-btn-green-3.dropdown-toggle {
    color: #ffffff;
    background: #84c68f;
    border-color: #84c68f; }
  .c-btn-border-1x.c-btn-green-3:active,
  .c-btn-border-1x.c-btn-green-3.active,
  .open > .c-btn-border-1x.c-btn-green-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-green-3.disabled,
  .c-btn-border-1x.c-btn-green-3.disabled:hover,
  .c-btn-border-1x.c-btn-green-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-3.disabled:active,
  .c-btn-border-1x.c-btn-green-3.disabled.active,
  .c-btn-border-1x.c-btn-green-3[disabled],
  .c-btn-border-1x.c-btn-green-3[disabled]:hover,
  .c-btn-border-1x.c-btn-green-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-green-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-green-3[disabled]:active,
  .c-btn-border-1x.c-btn-green-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-green-3.active {
    background: none;
    border-color: #84c68f; }
  .c-btn-border-1x.c-btn-green-3 .badge {
    color: none;
    background: #84c68f; }

.c-btn-border-1x.c-btn-red {
  border-color: #eb5d68;
  color: #eb5d68;
  background: none;
  border-color: #eb5d68; }
  .c-btn-border-1x.c-btn-red.c-btn-border-opacity-04 {
    border-color: rgba(235, 93, 104, 0.4); }
  .c-btn-border-1x.c-btn-red.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-red.btn-no-focus.focus {
    color: #eb5d68;
    background: none;
    border-color: #eb5d68; }
  .c-btn-border-1x.c-btn-red.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-red:hover,
  .c-btn-border-1x.c-btn-red.btn-no-focus:active,
  .c-btn-border-1x.c-btn-red:active,
  .c-btn-border-1x.c-btn-red.active,
  .open > .c-btn-border-1x.c-btn-red.dropdown-toggle {
    color: #ffffff;
    background: #eb5d68;
    border-color: #eb5d68; }
  .c-btn-border-1x.c-btn-red:active,
  .c-btn-border-1x.c-btn-red.active,
  .open > .c-btn-border-1x.c-btn-red.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-red.disabled,
  .c-btn-border-1x.c-btn-red.disabled:hover,
  .c-btn-border-1x.c-btn-red.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red.disabled:active,
  .c-btn-border-1x.c-btn-red.disabled.active,
  .c-btn-border-1x.c-btn-red[disabled],
  .c-btn-border-1x.c-btn-red[disabled]:hover,
  .c-btn-border-1x.c-btn-red[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red[disabled]:active,
  .c-btn-border-1x.c-btn-red[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red,
  fieldset[disabled] .c-btn-border-1x.c-btn-red:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-red:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red.active {
    background: none;
    border-color: #eb5d68; }
  .c-btn-border-1x.c-btn-red .badge {
    color: none;
    background: #eb5d68; }

.c-btn-border-1x.c-btn-red-1 {
  border-color: #cd6262;
  color: #cd6262;
  background: none;
  border-color: #cd6262; }
  .c-btn-border-1x.c-btn-red-1.c-btn-border-opacity-04 {
    border-color: rgba(205, 98, 98, 0.4); }
  .c-btn-border-1x.c-btn-red-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-red-1.btn-no-focus.focus {
    color: #cd6262;
    background: none;
    border-color: #cd6262; }
  .c-btn-border-1x.c-btn-red-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-red-1:hover,
  .c-btn-border-1x.c-btn-red-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-red-1:active,
  .c-btn-border-1x.c-btn-red-1.active,
  .open > .c-btn-border-1x.c-btn-red-1.dropdown-toggle {
    color: #ffffff;
    background: #cd6262;
    border-color: #cd6262; }
  .c-btn-border-1x.c-btn-red-1:active,
  .c-btn-border-1x.c-btn-red-1.active,
  .open > .c-btn-border-1x.c-btn-red-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-red-1.disabled,
  .c-btn-border-1x.c-btn-red-1.disabled:hover,
  .c-btn-border-1x.c-btn-red-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-1.disabled:active,
  .c-btn-border-1x.c-btn-red-1.disabled.active,
  .c-btn-border-1x.c-btn-red-1[disabled],
  .c-btn-border-1x.c-btn-red-1[disabled]:hover,
  .c-btn-border-1x.c-btn-red-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-1[disabled]:active,
  .c-btn-border-1x.c-btn-red-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-1.active {
    background: none;
    border-color: #cd6262; }
  .c-btn-border-1x.c-btn-red-1 .badge {
    color: none;
    background: #cd6262; }

.c-btn-border-1x.c-btn-red-2 {
  border-color: #e7505a;
  color: #e7505a;
  background: none;
  border-color: #e7505a; }
  .c-btn-border-1x.c-btn-red-2.c-btn-border-opacity-04 {
    border-color: rgba(231, 80, 90, 0.4); }
  .c-btn-border-1x.c-btn-red-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-red-2.btn-no-focus.focus {
    color: #e7505a;
    background: none;
    border-color: #e7505a; }
  .c-btn-border-1x.c-btn-red-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-red-2:hover,
  .c-btn-border-1x.c-btn-red-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-red-2:active,
  .c-btn-border-1x.c-btn-red-2.active,
  .open > .c-btn-border-1x.c-btn-red-2.dropdown-toggle {
    color: #ffffff;
    background: #e7505a;
    border-color: #e7505a; }
  .c-btn-border-1x.c-btn-red-2:active,
  .c-btn-border-1x.c-btn-red-2.active,
  .open > .c-btn-border-1x.c-btn-red-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-red-2.disabled,
  .c-btn-border-1x.c-btn-red-2.disabled:hover,
  .c-btn-border-1x.c-btn-red-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-2.disabled:active,
  .c-btn-border-1x.c-btn-red-2.disabled.active,
  .c-btn-border-1x.c-btn-red-2[disabled],
  .c-btn-border-1x.c-btn-red-2[disabled]:hover,
  .c-btn-border-1x.c-btn-red-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-2[disabled]:active,
  .c-btn-border-1x.c-btn-red-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-2.active {
    background: none;
    border-color: #e7505a; }
  .c-btn-border-1x.c-btn-red-2 .badge {
    color: none;
    background: #e7505a; }

.c-btn-border-1x.c-btn-red-3 {
  border-color: #d05163;
  color: #d05163;
  background: none;
  border-color: #d05163; }
  .c-btn-border-1x.c-btn-red-3.c-btn-border-opacity-04 {
    border-color: rgba(208, 81, 99, 0.4); }
  .c-btn-border-1x.c-btn-red-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-red-3.btn-no-focus.focus {
    color: #d05163;
    background: none;
    border-color: #d05163; }
  .c-btn-border-1x.c-btn-red-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-red-3:hover,
  .c-btn-border-1x.c-btn-red-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-red-3:active,
  .c-btn-border-1x.c-btn-red-3.active,
  .open > .c-btn-border-1x.c-btn-red-3.dropdown-toggle {
    color: #ffffff;
    background: #d05163;
    border-color: #d05163; }
  .c-btn-border-1x.c-btn-red-3:active,
  .c-btn-border-1x.c-btn-red-3.active,
  .open > .c-btn-border-1x.c-btn-red-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-red-3.disabled,
  .c-btn-border-1x.c-btn-red-3.disabled:hover,
  .c-btn-border-1x.c-btn-red-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-3.disabled:active,
  .c-btn-border-1x.c-btn-red-3.disabled.active,
  .c-btn-border-1x.c-btn-red-3[disabled],
  .c-btn-border-1x.c-btn-red-3[disabled]:hover,
  .c-btn-border-1x.c-btn-red-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-red-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-red-3[disabled]:active,
  .c-btn-border-1x.c-btn-red-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-red-3.active {
    background: none;
    border-color: #d05163; }
  .c-btn-border-1x.c-btn-red-3 .badge {
    color: none;
    background: #d05163; }

.c-btn-border-1x.c-btn-yellow {
  border-color: #FF6B57;
  color: #FF6B57;
  background: none;
  border-color: #FF6B57; }
  .c-btn-border-1x.c-btn-yellow.c-btn-border-opacity-04 {
    border-color: rgba(255, 107, 87, 0.4); }
  .c-btn-border-1x.c-btn-yellow.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-yellow.btn-no-focus.focus {
    color: #FF6B57;
    background: none;
    border-color: #FF6B57; }
  .c-btn-border-1x.c-btn-yellow.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-yellow:hover,
  .c-btn-border-1x.c-btn-yellow.btn-no-focus:active,
  .c-btn-border-1x.c-btn-yellow:active,
  .c-btn-border-1x.c-btn-yellow.active,
  .open > .c-btn-border-1x.c-btn-yellow.dropdown-toggle {
    color: #ffffff;
    background: #FF6B57;
    border-color: #FF6B57; }
  .c-btn-border-1x.c-btn-yellow:active,
  .c-btn-border-1x.c-btn-yellow.active,
  .open > .c-btn-border-1x.c-btn-yellow.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-yellow.disabled,
  .c-btn-border-1x.c-btn-yellow.disabled:hover,
  .c-btn-border-1x.c-btn-yellow.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow.disabled:active,
  .c-btn-border-1x.c-btn-yellow.disabled.active,
  .c-btn-border-1x.c-btn-yellow[disabled],
  .c-btn-border-1x.c-btn-yellow[disabled]:hover,
  .c-btn-border-1x.c-btn-yellow[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow[disabled]:active,
  .c-btn-border-1x.c-btn-yellow[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow.active {
    background: none;
    border-color: #FF6B57; }
  .c-btn-border-1x.c-btn-yellow .badge {
    color: none;
    background: #FF6B57; }

.c-btn-border-1x.c-btn-yellow-1 {
  border-color: #c8d046;
  color: #c8d046;
  background: none;
  border-color: #c8d046; }
  .c-btn-border-1x.c-btn-yellow-1.c-btn-border-opacity-04 {
    border-color: rgba(200, 208, 70, 0.4); }
  .c-btn-border-1x.c-btn-yellow-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-yellow-1.btn-no-focus.focus {
    color: #c8d046;
    background: none;
    border-color: #c8d046; }
  .c-btn-border-1x.c-btn-yellow-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-yellow-1:hover,
  .c-btn-border-1x.c-btn-yellow-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-yellow-1:active,
  .c-btn-border-1x.c-btn-yellow-1.active,
  .open > .c-btn-border-1x.c-btn-yellow-1.dropdown-toggle {
    color: #ffffff;
    background: #c8d046;
    border-color: #c8d046; }
  .c-btn-border-1x.c-btn-yellow-1:active,
  .c-btn-border-1x.c-btn-yellow-1.active,
  .open > .c-btn-border-1x.c-btn-yellow-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-yellow-1.disabled,
  .c-btn-border-1x.c-btn-yellow-1.disabled:hover,
  .c-btn-border-1x.c-btn-yellow-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-1.disabled:active,
  .c-btn-border-1x.c-btn-yellow-1.disabled.active,
  .c-btn-border-1x.c-btn-yellow-1[disabled],
  .c-btn-border-1x.c-btn-yellow-1[disabled]:hover,
  .c-btn-border-1x.c-btn-yellow-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-1[disabled]:active,
  .c-btn-border-1x.c-btn-yellow-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-1.active {
    background: none;
    border-color: #c8d046; }
  .c-btn-border-1x.c-btn-yellow-1 .badge {
    color: none;
    background: #c8d046; }

.c-btn-border-1x.c-btn-yellow-2 {
  border-color: #c5bf66;
  color: #c5bf66;
  background: none;
  border-color: #c5bf66; }
  .c-btn-border-1x.c-btn-yellow-2.c-btn-border-opacity-04 {
    border-color: rgba(197, 191, 102, 0.4); }
  .c-btn-border-1x.c-btn-yellow-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-yellow-2.btn-no-focus.focus {
    color: #c5bf66;
    background: none;
    border-color: #c5bf66; }
  .c-btn-border-1x.c-btn-yellow-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-yellow-2:hover,
  .c-btn-border-1x.c-btn-yellow-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-yellow-2:active,
  .c-btn-border-1x.c-btn-yellow-2.active,
  .open > .c-btn-border-1x.c-btn-yellow-2.dropdown-toggle {
    color: #ffffff;
    background: #c5bf66;
    border-color: #c5bf66; }
  .c-btn-border-1x.c-btn-yellow-2:active,
  .c-btn-border-1x.c-btn-yellow-2.active,
  .open > .c-btn-border-1x.c-btn-yellow-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-yellow-2.disabled,
  .c-btn-border-1x.c-btn-yellow-2.disabled:hover,
  .c-btn-border-1x.c-btn-yellow-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-2.disabled:active,
  .c-btn-border-1x.c-btn-yellow-2.disabled.active,
  .c-btn-border-1x.c-btn-yellow-2[disabled],
  .c-btn-border-1x.c-btn-yellow-2[disabled]:hover,
  .c-btn-border-1x.c-btn-yellow-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-2[disabled]:active,
  .c-btn-border-1x.c-btn-yellow-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-2.active {
    background: none;
    border-color: #c5bf66; }
  .c-btn-border-1x.c-btn-yellow-2 .badge {
    color: none;
    background: #c5bf66; }

.c-btn-border-1x.c-btn-yellow-3 {
  border-color: #c5b96b;
  color: #c5b96b;
  background: none;
  border-color: #c5b96b; }
  .c-btn-border-1x.c-btn-yellow-3.c-btn-border-opacity-04 {
    border-color: rgba(197, 185, 107, 0.4); }
  .c-btn-border-1x.c-btn-yellow-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-yellow-3.btn-no-focus.focus {
    color: #c5b96b;
    background: none;
    border-color: #c5b96b; }
  .c-btn-border-1x.c-btn-yellow-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-yellow-3:hover,
  .c-btn-border-1x.c-btn-yellow-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-yellow-3:active,
  .c-btn-border-1x.c-btn-yellow-3.active,
  .open > .c-btn-border-1x.c-btn-yellow-3.dropdown-toggle {
    color: #ffffff;
    background: #c5b96b;
    border-color: #c5b96b; }
  .c-btn-border-1x.c-btn-yellow-3:active,
  .c-btn-border-1x.c-btn-yellow-3.active,
  .open > .c-btn-border-1x.c-btn-yellow-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-yellow-3.disabled,
  .c-btn-border-1x.c-btn-yellow-3.disabled:hover,
  .c-btn-border-1x.c-btn-yellow-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-3.disabled:active,
  .c-btn-border-1x.c-btn-yellow-3.disabled.active,
  .c-btn-border-1x.c-btn-yellow-3[disabled],
  .c-btn-border-1x.c-btn-yellow-3[disabled]:hover,
  .c-btn-border-1x.c-btn-yellow-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-yellow-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-yellow-3[disabled]:active,
  .c-btn-border-1x.c-btn-yellow-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-yellow-3.active {
    background: none;
    border-color: #c5b96b; }
  .c-btn-border-1x.c-btn-yellow-3 .badge {
    color: none;
    background: #c5b96b; }

.c-btn-border-1x.c-btn-blue {
  border-color: #3498DB;
  color: #3498DB;
  background: none;
  border-color: #3498DB; }
  .c-btn-border-1x.c-btn-blue.c-btn-border-opacity-04 {
    border-color: rgba(52, 152, 219, 0.4); }
  .c-btn-border-1x.c-btn-blue.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-blue.btn-no-focus.focus {
    color: #3498DB;
    background: none;
    border-color: #3498DB; }
  .c-btn-border-1x.c-btn-blue.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-blue:hover,
  .c-btn-border-1x.c-btn-blue.btn-no-focus:active,
  .c-btn-border-1x.c-btn-blue:active,
  .c-btn-border-1x.c-btn-blue.active,
  .open > .c-btn-border-1x.c-btn-blue.dropdown-toggle {
    color: #ffffff;
    background: #3498DB;
    border-color: #3498DB; }
  .c-btn-border-1x.c-btn-blue:active,
  .c-btn-border-1x.c-btn-blue.active,
  .open > .c-btn-border-1x.c-btn-blue.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-blue.disabled,
  .c-btn-border-1x.c-btn-blue.disabled:hover,
  .c-btn-border-1x.c-btn-blue.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue.disabled:active,
  .c-btn-border-1x.c-btn-blue.disabled.active,
  .c-btn-border-1x.c-btn-blue[disabled],
  .c-btn-border-1x.c-btn-blue[disabled]:hover,
  .c-btn-border-1x.c-btn-blue[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue[disabled]:active,
  .c-btn-border-1x.c-btn-blue[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue.active {
    background: none;
    border-color: #3498DB; }
  .c-btn-border-1x.c-btn-blue .badge {
    color: none;
    background: #3498DB; }

.c-btn-border-1x.c-btn-blue-1 {
  border-color: #5e9cd1;
  color: #5e9cd1;
  background: none;
  border-color: #5e9cd1; }
  .c-btn-border-1x.c-btn-blue-1.c-btn-border-opacity-04 {
    border-color: rgba(94, 156, 209, 0.4); }
  .c-btn-border-1x.c-btn-blue-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-blue-1.btn-no-focus.focus {
    color: #5e9cd1;
    background: none;
    border-color: #5e9cd1; }
  .c-btn-border-1x.c-btn-blue-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-blue-1:hover,
  .c-btn-border-1x.c-btn-blue-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-blue-1:active,
  .c-btn-border-1x.c-btn-blue-1.active,
  .open > .c-btn-border-1x.c-btn-blue-1.dropdown-toggle {
    color: #ffffff;
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .c-btn-border-1x.c-btn-blue-1:active,
  .c-btn-border-1x.c-btn-blue-1.active,
  .open > .c-btn-border-1x.c-btn-blue-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-blue-1.disabled,
  .c-btn-border-1x.c-btn-blue-1.disabled:hover,
  .c-btn-border-1x.c-btn-blue-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-1.disabled:active,
  .c-btn-border-1x.c-btn-blue-1.disabled.active,
  .c-btn-border-1x.c-btn-blue-1[disabled],
  .c-btn-border-1x.c-btn-blue-1[disabled]:hover,
  .c-btn-border-1x.c-btn-blue-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-1[disabled]:active,
  .c-btn-border-1x.c-btn-blue-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-1.active {
    background: none;
    border-color: #5e9cd1; }
  .c-btn-border-1x.c-btn-blue-1 .badge {
    color: none;
    background: #5e9cd1; }

.c-btn-border-1x.c-btn-blue-2 {
  border-color: #5893dd;
  color: #5893dd;
  background: none;
  border-color: #5893dd; }
  .c-btn-border-1x.c-btn-blue-2.c-btn-border-opacity-04 {
    border-color: rgba(88, 147, 221, 0.4); }
  .c-btn-border-1x.c-btn-blue-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-blue-2.btn-no-focus.focus {
    color: #5893dd;
    background: none;
    border-color: #5893dd; }
  .c-btn-border-1x.c-btn-blue-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-blue-2:hover,
  .c-btn-border-1x.c-btn-blue-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-blue-2:active,
  .c-btn-border-1x.c-btn-blue-2.active,
  .open > .c-btn-border-1x.c-btn-blue-2.dropdown-toggle {
    color: #ffffff;
    background: #5893dd;
    border-color: #5893dd; }
  .c-btn-border-1x.c-btn-blue-2:active,
  .c-btn-border-1x.c-btn-blue-2.active,
  .open > .c-btn-border-1x.c-btn-blue-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-blue-2.disabled,
  .c-btn-border-1x.c-btn-blue-2.disabled:hover,
  .c-btn-border-1x.c-btn-blue-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-2.disabled:active,
  .c-btn-border-1x.c-btn-blue-2.disabled.active,
  .c-btn-border-1x.c-btn-blue-2[disabled],
  .c-btn-border-1x.c-btn-blue-2[disabled]:hover,
  .c-btn-border-1x.c-btn-blue-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-2[disabled]:active,
  .c-btn-border-1x.c-btn-blue-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-2.active {
    background: none;
    border-color: #5893dd; }
  .c-btn-border-1x.c-btn-blue-2 .badge {
    color: none;
    background: #5893dd; }

.c-btn-border-1x.c-btn-blue-3 {
  border-color: #57bfe1;
  color: #57bfe1;
  background: none;
  border-color: #57bfe1; }
  .c-btn-border-1x.c-btn-blue-3.c-btn-border-opacity-04 {
    border-color: rgba(87, 191, 225, 0.4); }
  .c-btn-border-1x.c-btn-blue-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-blue-3.btn-no-focus.focus {
    color: #57bfe1;
    background: none;
    border-color: #57bfe1; }
  .c-btn-border-1x.c-btn-blue-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-blue-3:hover,
  .c-btn-border-1x.c-btn-blue-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-blue-3:active,
  .c-btn-border-1x.c-btn-blue-3.active,
  .open > .c-btn-border-1x.c-btn-blue-3.dropdown-toggle {
    color: #ffffff;
    background: #57bfe1;
    border-color: #57bfe1; }
  .c-btn-border-1x.c-btn-blue-3:active,
  .c-btn-border-1x.c-btn-blue-3.active,
  .open > .c-btn-border-1x.c-btn-blue-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-blue-3.disabled,
  .c-btn-border-1x.c-btn-blue-3.disabled:hover,
  .c-btn-border-1x.c-btn-blue-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-3.disabled:active,
  .c-btn-border-1x.c-btn-blue-3.disabled.active,
  .c-btn-border-1x.c-btn-blue-3[disabled],
  .c-btn-border-1x.c-btn-blue-3[disabled]:hover,
  .c-btn-border-1x.c-btn-blue-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-blue-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-blue-3[disabled]:active,
  .c-btn-border-1x.c-btn-blue-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-blue-3.active {
    background: none;
    border-color: #57bfe1; }
  .c-btn-border-1x.c-btn-blue-3 .badge {
    color: none;
    background: #57bfe1; }

.c-btn-border-1x.c-btn-purple {
  border-color: #b771b0;
  color: #b771b0;
  background: none;
  border-color: #b771b0; }
  .c-btn-border-1x.c-btn-purple.c-btn-border-opacity-04 {
    border-color: rgba(183, 113, 176, 0.4); }
  .c-btn-border-1x.c-btn-purple.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-purple.btn-no-focus.focus {
    color: #b771b0;
    background: none;
    border-color: #b771b0; }
  .c-btn-border-1x.c-btn-purple.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-purple:hover,
  .c-btn-border-1x.c-btn-purple.btn-no-focus:active,
  .c-btn-border-1x.c-btn-purple:active,
  .c-btn-border-1x.c-btn-purple.active,
  .open > .c-btn-border-1x.c-btn-purple.dropdown-toggle {
    color: #ffffff;
    background: #b771b0;
    border-color: #b771b0; }
  .c-btn-border-1x.c-btn-purple:active,
  .c-btn-border-1x.c-btn-purple.active,
  .open > .c-btn-border-1x.c-btn-purple.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-purple.disabled,
  .c-btn-border-1x.c-btn-purple.disabled:hover,
  .c-btn-border-1x.c-btn-purple.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple.disabled:active,
  .c-btn-border-1x.c-btn-purple.disabled.active,
  .c-btn-border-1x.c-btn-purple[disabled],
  .c-btn-border-1x.c-btn-purple[disabled]:hover,
  .c-btn-border-1x.c-btn-purple[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple[disabled]:active,
  .c-btn-border-1x.c-btn-purple[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple.active {
    background: none;
    border-color: #b771b0; }
  .c-btn-border-1x.c-btn-purple .badge {
    color: none;
    background: #b771b0; }

.c-btn-border-1x.c-btn-purple-1 {
  border-color: #a962bb;
  color: #a962bb;
  background: none;
  border-color: #a962bb; }
  .c-btn-border-1x.c-btn-purple-1.c-btn-border-opacity-04 {
    border-color: rgba(169, 98, 187, 0.4); }
  .c-btn-border-1x.c-btn-purple-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-purple-1.btn-no-focus.focus {
    color: #a962bb;
    background: none;
    border-color: #a962bb; }
  .c-btn-border-1x.c-btn-purple-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-purple-1:hover,
  .c-btn-border-1x.c-btn-purple-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-purple-1:active,
  .c-btn-border-1x.c-btn-purple-1.active,
  .open > .c-btn-border-1x.c-btn-purple-1.dropdown-toggle {
    color: #ffffff;
    background: #a962bb;
    border-color: #a962bb; }
  .c-btn-border-1x.c-btn-purple-1:active,
  .c-btn-border-1x.c-btn-purple-1.active,
  .open > .c-btn-border-1x.c-btn-purple-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-purple-1.disabled,
  .c-btn-border-1x.c-btn-purple-1.disabled:hover,
  .c-btn-border-1x.c-btn-purple-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-1.disabled:active,
  .c-btn-border-1x.c-btn-purple-1.disabled.active,
  .c-btn-border-1x.c-btn-purple-1[disabled],
  .c-btn-border-1x.c-btn-purple-1[disabled]:hover,
  .c-btn-border-1x.c-btn-purple-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-1[disabled]:active,
  .c-btn-border-1x.c-btn-purple-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-1.active {
    background: none;
    border-color: #a962bb; }
  .c-btn-border-1x.c-btn-purple-1 .badge {
    color: none;
    background: #a962bb; }

.c-btn-border-1x.c-btn-purple-2 {
  border-color: #aa67a3;
  color: #aa67a3;
  background: none;
  border-color: #aa67a3; }
  .c-btn-border-1x.c-btn-purple-2.c-btn-border-opacity-04 {
    border-color: rgba(170, 103, 163, 0.4); }
  .c-btn-border-1x.c-btn-purple-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-purple-2.btn-no-focus.focus {
    color: #aa67a3;
    background: none;
    border-color: #aa67a3; }
  .c-btn-border-1x.c-btn-purple-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-purple-2:hover,
  .c-btn-border-1x.c-btn-purple-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-purple-2:active,
  .c-btn-border-1x.c-btn-purple-2.active,
  .open > .c-btn-border-1x.c-btn-purple-2.dropdown-toggle {
    color: #ffffff;
    background: #aa67a3;
    border-color: #aa67a3; }
  .c-btn-border-1x.c-btn-purple-2:active,
  .c-btn-border-1x.c-btn-purple-2.active,
  .open > .c-btn-border-1x.c-btn-purple-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-purple-2.disabled,
  .c-btn-border-1x.c-btn-purple-2.disabled:hover,
  .c-btn-border-1x.c-btn-purple-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-2.disabled:active,
  .c-btn-border-1x.c-btn-purple-2.disabled.active,
  .c-btn-border-1x.c-btn-purple-2[disabled],
  .c-btn-border-1x.c-btn-purple-2[disabled]:hover,
  .c-btn-border-1x.c-btn-purple-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-2[disabled]:active,
  .c-btn-border-1x.c-btn-purple-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-2.active {
    background: none;
    border-color: #aa67a3; }
  .c-btn-border-1x.c-btn-purple-2 .badge {
    color: none;
    background: #aa67a3; }

.c-btn-border-1x.c-btn-purple-3 {
  border-color: #ac3773;
  color: #ac3773;
  background: none;
  border-color: #ac3773; }
  .c-btn-border-1x.c-btn-purple-3.c-btn-border-opacity-04 {
    border-color: rgba(172, 55, 115, 0.4); }
  .c-btn-border-1x.c-btn-purple-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-purple-3.btn-no-focus.focus {
    color: #ac3773;
    background: none;
    border-color: #ac3773; }
  .c-btn-border-1x.c-btn-purple-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-purple-3:hover,
  .c-btn-border-1x.c-btn-purple-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-purple-3:active,
  .c-btn-border-1x.c-btn-purple-3.active,
  .open > .c-btn-border-1x.c-btn-purple-3.dropdown-toggle {
    color: #ffffff;
    background: #ac3773;
    border-color: #ac3773; }
  .c-btn-border-1x.c-btn-purple-3:active,
  .c-btn-border-1x.c-btn-purple-3.active,
  .open > .c-btn-border-1x.c-btn-purple-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-purple-3.disabled,
  .c-btn-border-1x.c-btn-purple-3.disabled:hover,
  .c-btn-border-1x.c-btn-purple-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-3.disabled:active,
  .c-btn-border-1x.c-btn-purple-3.disabled.active,
  .c-btn-border-1x.c-btn-purple-3[disabled],
  .c-btn-border-1x.c-btn-purple-3[disabled]:hover,
  .c-btn-border-1x.c-btn-purple-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-purple-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-purple-3[disabled]:active,
  .c-btn-border-1x.c-btn-purple-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-purple-3.active {
    background: none;
    border-color: #ac3773; }
  .c-btn-border-1x.c-btn-purple-3 .badge {
    color: none;
    background: #ac3773; }

.c-btn-border-1x.c-btn-brown {
  border-color: #8a7f68;
  color: #8a7f68;
  background: none;
  border-color: #8a7f68; }
  .c-btn-border-1x.c-btn-brown.c-btn-border-opacity-04 {
    border-color: rgba(138, 127, 104, 0.4); }
  .c-btn-border-1x.c-btn-brown.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-brown.btn-no-focus.focus {
    color: #8a7f68;
    background: none;
    border-color: #8a7f68; }
  .c-btn-border-1x.c-btn-brown.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-brown:hover,
  .c-btn-border-1x.c-btn-brown.btn-no-focus:active,
  .c-btn-border-1x.c-btn-brown:active,
  .c-btn-border-1x.c-btn-brown.active,
  .open > .c-btn-border-1x.c-btn-brown.dropdown-toggle {
    color: #ffffff;
    background: #8a7f68;
    border-color: #8a7f68; }
  .c-btn-border-1x.c-btn-brown:active,
  .c-btn-border-1x.c-btn-brown.active,
  .open > .c-btn-border-1x.c-btn-brown.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-brown.disabled,
  .c-btn-border-1x.c-btn-brown.disabled:hover,
  .c-btn-border-1x.c-btn-brown.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown.disabled:active,
  .c-btn-border-1x.c-btn-brown.disabled.active,
  .c-btn-border-1x.c-btn-brown[disabled],
  .c-btn-border-1x.c-btn-brown[disabled]:hover,
  .c-btn-border-1x.c-btn-brown[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown[disabled]:active,
  .c-btn-border-1x.c-btn-brown[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown.active {
    background: none;
    border-color: #8a7f68; }
  .c-btn-border-1x.c-btn-brown .badge {
    color: none;
    background: #8a7f68; }

.c-btn-border-1x.c-btn-brown-1 {
  border-color: #685e47;
  color: #685e47;
  background: none;
  border-color: #685e47; }
  .c-btn-border-1x.c-btn-brown-1.c-btn-border-opacity-04 {
    border-color: rgba(104, 94, 71, 0.4); }
  .c-btn-border-1x.c-btn-brown-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-brown-1.btn-no-focus.focus {
    color: #685e47;
    background: none;
    border-color: #685e47; }
  .c-btn-border-1x.c-btn-brown-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-brown-1:hover,
  .c-btn-border-1x.c-btn-brown-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-brown-1:active,
  .c-btn-border-1x.c-btn-brown-1.active,
  .open > .c-btn-border-1x.c-btn-brown-1.dropdown-toggle {
    color: #ffffff;
    background: #685e47;
    border-color: #685e47; }
  .c-btn-border-1x.c-btn-brown-1:active,
  .c-btn-border-1x.c-btn-brown-1.active,
  .open > .c-btn-border-1x.c-btn-brown-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-brown-1.disabled,
  .c-btn-border-1x.c-btn-brown-1.disabled:hover,
  .c-btn-border-1x.c-btn-brown-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-1.disabled:active,
  .c-btn-border-1x.c-btn-brown-1.disabled.active,
  .c-btn-border-1x.c-btn-brown-1[disabled],
  .c-btn-border-1x.c-btn-brown-1[disabled]:hover,
  .c-btn-border-1x.c-btn-brown-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-1[disabled]:active,
  .c-btn-border-1x.c-btn-brown-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-1.active {
    background: none;
    border-color: #685e47; }
  .c-btn-border-1x.c-btn-brown-1 .badge {
    color: none;
    background: #685e47; }

.c-btn-border-1x.c-btn-brown-2 {
  border-color: #7a6a61;
  color: #7a6a61;
  background: none;
  border-color: #7a6a61; }
  .c-btn-border-1x.c-btn-brown-2.c-btn-border-opacity-04 {
    border-color: rgba(122, 106, 97, 0.4); }
  .c-btn-border-1x.c-btn-brown-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-brown-2.btn-no-focus.focus {
    color: #7a6a61;
    background: none;
    border-color: #7a6a61; }
  .c-btn-border-1x.c-btn-brown-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-brown-2:hover,
  .c-btn-border-1x.c-btn-brown-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-brown-2:active,
  .c-btn-border-1x.c-btn-brown-2.active,
  .open > .c-btn-border-1x.c-btn-brown-2.dropdown-toggle {
    color: #ffffff;
    background: #7a6a61;
    border-color: #7a6a61; }
  .c-btn-border-1x.c-btn-brown-2:active,
  .c-btn-border-1x.c-btn-brown-2.active,
  .open > .c-btn-border-1x.c-btn-brown-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-brown-2.disabled,
  .c-btn-border-1x.c-btn-brown-2.disabled:hover,
  .c-btn-border-1x.c-btn-brown-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-2.disabled:active,
  .c-btn-border-1x.c-btn-brown-2.disabled.active,
  .c-btn-border-1x.c-btn-brown-2[disabled],
  .c-btn-border-1x.c-btn-brown-2[disabled]:hover,
  .c-btn-border-1x.c-btn-brown-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-2[disabled]:active,
  .c-btn-border-1x.c-btn-brown-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-2.active {
    background: none;
    border-color: #7a6a61; }
  .c-btn-border-1x.c-btn-brown-2 .badge {
    color: none;
    background: #7a6a61; }

.c-btn-border-1x.c-btn-brown-3 {
  border-color: #9d8b81;
  color: #9d8b81;
  background: none;
  border-color: #9d8b81; }
  .c-btn-border-1x.c-btn-brown-3.c-btn-border-opacity-04 {
    border-color: rgba(157, 139, 129, 0.4); }
  .c-btn-border-1x.c-btn-brown-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-brown-3.btn-no-focus.focus {
    color: #9d8b81;
    background: none;
    border-color: #9d8b81; }
  .c-btn-border-1x.c-btn-brown-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-brown-3:hover,
  .c-btn-border-1x.c-btn-brown-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-brown-3:active,
  .c-btn-border-1x.c-btn-brown-3.active,
  .open > .c-btn-border-1x.c-btn-brown-3.dropdown-toggle {
    color: #ffffff;
    background: #9d8b81;
    border-color: #9d8b81; }
  .c-btn-border-1x.c-btn-brown-3:active,
  .c-btn-border-1x.c-btn-brown-3.active,
  .open > .c-btn-border-1x.c-btn-brown-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-brown-3.disabled,
  .c-btn-border-1x.c-btn-brown-3.disabled:hover,
  .c-btn-border-1x.c-btn-brown-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-3.disabled:active,
  .c-btn-border-1x.c-btn-brown-3.disabled.active,
  .c-btn-border-1x.c-btn-brown-3[disabled],
  .c-btn-border-1x.c-btn-brown-3[disabled]:hover,
  .c-btn-border-1x.c-btn-brown-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-brown-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-brown-3[disabled]:active,
  .c-btn-border-1x.c-btn-brown-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-brown-3.active {
    background: none;
    border-color: #9d8b81; }
  .c-btn-border-1x.c-btn-brown-3 .badge {
    color: none;
    background: #9d8b81; }

.c-btn-border-1x.c-btn-dark {
  border-color: #2f353b;
  color: #2f353b;
  background: none;
  border-color: #2f353b; }
  .c-btn-border-1x.c-btn-dark.c-btn-border-opacity-04 {
    border-color: rgba(47, 53, 59, 0.4); }
  .c-btn-border-1x.c-btn-dark.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-dark.btn-no-focus.focus {
    color: #2f353b;
    background: none;
    border-color: #2f353b; }
  .c-btn-border-1x.c-btn-dark.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-dark:hover,
  .c-btn-border-1x.c-btn-dark.btn-no-focus:active,
  .c-btn-border-1x.c-btn-dark:active,
  .c-btn-border-1x.c-btn-dark.active,
  .open > .c-btn-border-1x.c-btn-dark.dropdown-toggle {
    color: #ffffff;
    background: #2f353b;
    border-color: #2f353b; }
  .c-btn-border-1x.c-btn-dark:active,
  .c-btn-border-1x.c-btn-dark.active,
  .open > .c-btn-border-1x.c-btn-dark.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-dark.disabled,
  .c-btn-border-1x.c-btn-dark.disabled:hover,
  .c-btn-border-1x.c-btn-dark.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark.disabled:active,
  .c-btn-border-1x.c-btn-dark.disabled.active,
  .c-btn-border-1x.c-btn-dark[disabled],
  .c-btn-border-1x.c-btn-dark[disabled]:hover,
  .c-btn-border-1x.c-btn-dark[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark[disabled]:active,
  .c-btn-border-1x.c-btn-dark[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark.active {
    background: none;
    border-color: #2f353b; }
  .c-btn-border-1x.c-btn-dark .badge {
    color: none;
    background: #2f353b; }

.c-btn-border-1x.c-btn-dark-1 {
  border-color: #525e64;
  color: #525e64;
  background: none;
  border-color: #525e64; }
  .c-btn-border-1x.c-btn-dark-1.c-btn-border-opacity-04 {
    border-color: rgba(82, 94, 100, 0.4); }
  .c-btn-border-1x.c-btn-dark-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-dark-1.btn-no-focus.focus {
    color: #525e64;
    background: none;
    border-color: #525e64; }
  .c-btn-border-1x.c-btn-dark-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-dark-1:hover,
  .c-btn-border-1x.c-btn-dark-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-dark-1:active,
  .c-btn-border-1x.c-btn-dark-1.active,
  .open > .c-btn-border-1x.c-btn-dark-1.dropdown-toggle {
    color: #ffffff;
    background: #525e64;
    border-color: #525e64; }
  .c-btn-border-1x.c-btn-dark-1:active,
  .c-btn-border-1x.c-btn-dark-1.active,
  .open > .c-btn-border-1x.c-btn-dark-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-dark-1.disabled,
  .c-btn-border-1x.c-btn-dark-1.disabled:hover,
  .c-btn-border-1x.c-btn-dark-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-1.disabled:active,
  .c-btn-border-1x.c-btn-dark-1.disabled.active,
  .c-btn-border-1x.c-btn-dark-1[disabled],
  .c-btn-border-1x.c-btn-dark-1[disabled]:hover,
  .c-btn-border-1x.c-btn-dark-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-1[disabled]:active,
  .c-btn-border-1x.c-btn-dark-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-1.active {
    background: none;
    border-color: #525e64; }
  .c-btn-border-1x.c-btn-dark-1 .badge {
    color: none;
    background: #525e64; }

.c-btn-border-1x.c-btn-dark-2 {
  border-color: #31383c;
  color: #31383c;
  background: none;
  border-color: #31383c; }
  .c-btn-border-1x.c-btn-dark-2.c-btn-border-opacity-04 {
    border-color: rgba(49, 56, 60, 0.4); }
  .c-btn-border-1x.c-btn-dark-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-dark-2.btn-no-focus.focus {
    color: #31383c;
    background: none;
    border-color: #31383c; }
  .c-btn-border-1x.c-btn-dark-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-dark-2:hover,
  .c-btn-border-1x.c-btn-dark-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-dark-2:active,
  .c-btn-border-1x.c-btn-dark-2.active,
  .open > .c-btn-border-1x.c-btn-dark-2.dropdown-toggle {
    color: #ffffff;
    background: #31383c;
    border-color: #31383c; }
  .c-btn-border-1x.c-btn-dark-2:active,
  .c-btn-border-1x.c-btn-dark-2.active,
  .open > .c-btn-border-1x.c-btn-dark-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-dark-2.disabled,
  .c-btn-border-1x.c-btn-dark-2.disabled:hover,
  .c-btn-border-1x.c-btn-dark-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-2.disabled:active,
  .c-btn-border-1x.c-btn-dark-2.disabled.active,
  .c-btn-border-1x.c-btn-dark-2[disabled],
  .c-btn-border-1x.c-btn-dark-2[disabled]:hover,
  .c-btn-border-1x.c-btn-dark-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-2[disabled]:active,
  .c-btn-border-1x.c-btn-dark-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-2.active {
    background: none;
    border-color: #31383c; }
  .c-btn-border-1x.c-btn-dark-2 .badge {
    color: none;
    background: #31383c; }

.c-btn-border-1x.c-btn-dark-3 {
  border-color: #41515b;
  color: #41515b;
  background: none;
  border-color: #41515b; }
  .c-btn-border-1x.c-btn-dark-3.c-btn-border-opacity-04 {
    border-color: rgba(65, 81, 91, 0.4); }
  .c-btn-border-1x.c-btn-dark-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-dark-3.btn-no-focus.focus {
    color: #41515b;
    background: none;
    border-color: #41515b; }
  .c-btn-border-1x.c-btn-dark-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-dark-3:hover,
  .c-btn-border-1x.c-btn-dark-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-dark-3:active,
  .c-btn-border-1x.c-btn-dark-3.active,
  .open > .c-btn-border-1x.c-btn-dark-3.dropdown-toggle {
    color: #ffffff;
    background: #41515b;
    border-color: #41515b; }
  .c-btn-border-1x.c-btn-dark-3:active,
  .c-btn-border-1x.c-btn-dark-3.active,
  .open > .c-btn-border-1x.c-btn-dark-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-dark-3.disabled,
  .c-btn-border-1x.c-btn-dark-3.disabled:hover,
  .c-btn-border-1x.c-btn-dark-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-3.disabled:active,
  .c-btn-border-1x.c-btn-dark-3.disabled.active,
  .c-btn-border-1x.c-btn-dark-3[disabled],
  .c-btn-border-1x.c-btn-dark-3[disabled]:hover,
  .c-btn-border-1x.c-btn-dark-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-dark-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-dark-3[disabled]:active,
  .c-btn-border-1x.c-btn-dark-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-dark-3.active {
    background: none;
    border-color: #41515b; }
  .c-btn-border-1x.c-btn-dark-3 .badge {
    color: none;
    background: #41515b; }

.c-btn-border-1x.c-btn-grey {
  border-color: #eeeeee;
  color: #eeeeee;
  background: none;
  border-color: #eeeeee; }
  .c-btn-border-1x.c-btn-grey.c-btn-border-opacity-04 {
    border-color: rgba(238, 238, 238, 0.4); }
  .c-btn-border-1x.c-btn-grey.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-grey.btn-no-focus.focus {
    color: #eeeeee;
    background: none;
    border-color: #eeeeee; }
  .c-btn-border-1x.c-btn-grey.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-grey:hover,
  .c-btn-border-1x.c-btn-grey.btn-no-focus:active,
  .c-btn-border-1x.c-btn-grey:active,
  .c-btn-border-1x.c-btn-grey.active,
  .open > .c-btn-border-1x.c-btn-grey.dropdown-toggle {
    color: #6e6e6e;
    background: #eeeeee;
    border-color: #eeeeee; }
  .c-btn-border-1x.c-btn-grey:active,
  .c-btn-border-1x.c-btn-grey.active,
  .open > .c-btn-border-1x.c-btn-grey.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-grey.disabled,
  .c-btn-border-1x.c-btn-grey.disabled:hover,
  .c-btn-border-1x.c-btn-grey.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey.disabled:active,
  .c-btn-border-1x.c-btn-grey.disabled.active,
  .c-btn-border-1x.c-btn-grey[disabled],
  .c-btn-border-1x.c-btn-grey[disabled]:hover,
  .c-btn-border-1x.c-btn-grey[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey[disabled]:active,
  .c-btn-border-1x.c-btn-grey[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey.active {
    background: none;
    border-color: #eeeeee; }
  .c-btn-border-1x.c-btn-grey .badge {
    color: none;
    background: #eeeeee; }

.c-btn-border-1x.c-btn-grey-1 {
  border-color: #f7f7f7;
  color: #f7f7f7;
  background: none;
  border-color: #f7f7f7; }
  .c-btn-border-1x.c-btn-grey-1.c-btn-border-opacity-04 {
    border-color: rgba(247, 247, 247, 0.4); }
  .c-btn-border-1x.c-btn-grey-1.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-grey-1.btn-no-focus.focus {
    color: #f7f7f7;
    background: none;
    border-color: #f7f7f7; }
  .c-btn-border-1x.c-btn-grey-1.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-grey-1:hover,
  .c-btn-border-1x.c-btn-grey-1.btn-no-focus:active,
  .c-btn-border-1x.c-btn-grey-1:active,
  .c-btn-border-1x.c-btn-grey-1.active,
  .open > .c-btn-border-1x.c-btn-grey-1.dropdown-toggle {
    color: #777777;
    background: #f7f7f7;
    border-color: #f7f7f7; }
  .c-btn-border-1x.c-btn-grey-1:active,
  .c-btn-border-1x.c-btn-grey-1.active,
  .open > .c-btn-border-1x.c-btn-grey-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-grey-1.disabled,
  .c-btn-border-1x.c-btn-grey-1.disabled:hover,
  .c-btn-border-1x.c-btn-grey-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-1.disabled:active,
  .c-btn-border-1x.c-btn-grey-1.disabled.active,
  .c-btn-border-1x.c-btn-grey-1[disabled],
  .c-btn-border-1x.c-btn-grey-1[disabled]:hover,
  .c-btn-border-1x.c-btn-grey-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-1[disabled]:active,
  .c-btn-border-1x.c-btn-grey-1[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-1.active {
    background: none;
    border-color: #f7f7f7; }
  .c-btn-border-1x.c-btn-grey-1 .badge {
    color: none;
    background: #f7f7f7; }

.c-btn-border-1x.c-btn-grey-2 {
  border-color: #677083;
  color: #677083;
  background: none;
  border-color: #677083; }
  .c-btn-border-1x.c-btn-grey-2.c-btn-border-opacity-04 {
    border-color: rgba(103, 112, 131, 0.4); }
  .c-btn-border-1x.c-btn-grey-2.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-grey-2.btn-no-focus.focus {
    color: #677083;
    background: none;
    border-color: #677083; }
  .c-btn-border-1x.c-btn-grey-2.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-grey-2:hover,
  .c-btn-border-1x.c-btn-grey-2.btn-no-focus:active,
  .c-btn-border-1x.c-btn-grey-2:active,
  .c-btn-border-1x.c-btn-grey-2.active,
  .open > .c-btn-border-1x.c-btn-grey-2.dropdown-toggle {
    color: #ffffff;
    background: #677083;
    border-color: #677083; }
  .c-btn-border-1x.c-btn-grey-2:active,
  .c-btn-border-1x.c-btn-grey-2.active,
  .open > .c-btn-border-1x.c-btn-grey-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-grey-2.disabled,
  .c-btn-border-1x.c-btn-grey-2.disabled:hover,
  .c-btn-border-1x.c-btn-grey-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-2.disabled:active,
  .c-btn-border-1x.c-btn-grey-2.disabled.active,
  .c-btn-border-1x.c-btn-grey-2[disabled],
  .c-btn-border-1x.c-btn-grey-2[disabled]:hover,
  .c-btn-border-1x.c-btn-grey-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-2[disabled]:active,
  .c-btn-border-1x.c-btn-grey-2[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-2.active {
    background: none;
    border-color: #677083; }
  .c-btn-border-1x.c-btn-grey-2 .badge {
    color: none;
    background: #677083; }

.c-btn-border-1x.c-btn-grey-3 {
  border-color: #7f8c97;
  color: #7f8c97;
  background: none;
  border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-grey-3.c-btn-border-opacity-04 {
    border-color: rgba(127, 140, 151, 0.4); }
  .c-btn-border-1x.c-btn-grey-3.btn-no-focus:focus,
  .c-btn-border-1x.c-btn-grey-3.btn-no-focus.focus {
    color: #7f8c97;
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-grey-3.btn-no-focus:hover,
  .c-btn-border-1x.c-btn-grey-3:hover,
  .c-btn-border-1x.c-btn-grey-3.btn-no-focus:active,
  .c-btn-border-1x.c-btn-grey-3:active,
  .c-btn-border-1x.c-btn-grey-3.active,
  .open > .c-btn-border-1x.c-btn-grey-3.dropdown-toggle {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-grey-3:active,
  .c-btn-border-1x.c-btn-grey-3.active,
  .open > .c-btn-border-1x.c-btn-grey-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-1x.c-btn-grey-3.disabled,
  .c-btn-border-1x.c-btn-grey-3.disabled:hover,
  .c-btn-border-1x.c-btn-grey-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-3.disabled:active,
  .c-btn-border-1x.c-btn-grey-3.disabled.active,
  .c-btn-border-1x.c-btn-grey-3[disabled],
  .c-btn-border-1x.c-btn-grey-3[disabled]:hover,
  .c-btn-border-1x.c-btn-grey-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-1x.c-btn-grey-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-1x.c-btn-grey-3[disabled]:active,
  .c-btn-border-1x.c-btn-grey-3[disabled].active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3:hover,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3:active,
  fieldset[disabled] .c-btn-border-1x.c-btn-grey-3.active {
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-1x.c-btn-grey-3 .badge {
    color: none;
    background: #7f8c97; }

.c-btn-border-2x.c-btn-white {
  border-color: #FFFFFF;
  color: #FFFFFF;
  background: none;
  border-color: #FFFFFF; }
  .c-btn-border-2x.c-btn-white.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-white.btn-no-focus.focus {
    color: #FFFFFF;
    background: none;
    border-color: #FFFFFF; }
  .c-btn-border-2x.c-btn-white.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-white:hover,
  .c-btn-border-2x.c-btn-white.btn-no-focus:active,
  .c-btn-border-2x.c-btn-white:active,
  .c-btn-border-2x.c-btn-white.active,
  .open > .c-btn-border-2x.c-btn-white.dropdown-toggle {
    color: #2f353b;
    background: #FFFFFF;
    border-color: #FFFFFF; }
  .c-btn-border-2x.c-btn-white:active,
  .c-btn-border-2x.c-btn-white.active,
  .open > .c-btn-border-2x.c-btn-white.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-white.disabled,
  .c-btn-border-2x.c-btn-white.disabled:hover,
  .c-btn-border-2x.c-btn-white.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-white.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-white.disabled:active,
  .c-btn-border-2x.c-btn-white.disabled.active,
  .c-btn-border-2x.c-btn-white[disabled],
  .c-btn-border-2x.c-btn-white[disabled]:hover,
  .c-btn-border-2x.c-btn-white[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-white[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-white[disabled]:active,
  .c-btn-border-2x.c-btn-white[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-white,
  fieldset[disabled] .c-btn-border-2x.c-btn-white:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-white:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-white:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-white:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-white.active {
    background: none;
    border-color: #FFFFFF; }
  .c-btn-border-2x.c-btn-white .badge {
    color: none;
    background: #FFFFFF; }

.c-btn-border-2x.c-btn-black {
  border-color: #000000;
  color: #000000;
  background: none;
  border-color: #000000; }
  .c-btn-border-2x.c-btn-black.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-black.btn-no-focus.focus {
    color: #000000;
    background: none;
    border-color: #000000; }
  .c-btn-border-2x.c-btn-black.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-black:hover,
  .c-btn-border-2x.c-btn-black.btn-no-focus:active,
  .c-btn-border-2x.c-btn-black:active,
  .c-btn-border-2x.c-btn-black.active,
  .open > .c-btn-border-2x.c-btn-black.dropdown-toggle {
    color: #ffffff;
    background: #000000;
    border-color: #000000; }
  .c-btn-border-2x.c-btn-black:active,
  .c-btn-border-2x.c-btn-black.active,
  .open > .c-btn-border-2x.c-btn-black.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-black.disabled,
  .c-btn-border-2x.c-btn-black.disabled:hover,
  .c-btn-border-2x.c-btn-black.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-black.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-black.disabled:active,
  .c-btn-border-2x.c-btn-black.disabled.active,
  .c-btn-border-2x.c-btn-black[disabled],
  .c-btn-border-2x.c-btn-black[disabled]:hover,
  .c-btn-border-2x.c-btn-black[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-black[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-black[disabled]:active,
  .c-btn-border-2x.c-btn-black[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-black,
  fieldset[disabled] .c-btn-border-2x.c-btn-black:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-black:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-black:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-black:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-black.active {
    background: none;
    border-color: #000000; }
  .c-btn-border-2x.c-btn-black .badge {
    color: none;
    background: #000000; }

.c-btn-border-2x.c-btn-regular {
  border-color: #7f8c97;
  color: #7f8c97;
  background: none;
  border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-regular.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-regular.btn-no-focus.focus {
    color: #7f8c97;
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-regular.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-regular:hover,
  .c-btn-border-2x.c-btn-regular.btn-no-focus:active,
  .c-btn-border-2x.c-btn-regular:active,
  .c-btn-border-2x.c-btn-regular.active,
  .open > .c-btn-border-2x.c-btn-regular.dropdown-toggle {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-regular:active,
  .c-btn-border-2x.c-btn-regular.active,
  .open > .c-btn-border-2x.c-btn-regular.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-regular.disabled,
  .c-btn-border-2x.c-btn-regular.disabled:hover,
  .c-btn-border-2x.c-btn-regular.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-regular.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-regular.disabled:active,
  .c-btn-border-2x.c-btn-regular.disabled.active,
  .c-btn-border-2x.c-btn-regular[disabled],
  .c-btn-border-2x.c-btn-regular[disabled]:hover,
  .c-btn-border-2x.c-btn-regular[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-regular[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-regular[disabled]:active,
  .c-btn-border-2x.c-btn-regular[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-regular.active {
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-regular .badge {
    color: none;
    background: #7f8c97; }

.c-btn-border-2x.c-btn-green {
  border-color: #32c5d2;
  color: #32c5d2;
  background: none;
  border-color: #32c5d2; }
  .c-btn-border-2x.c-btn-green.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-green.btn-no-focus.focus {
    color: #32c5d2;
    background: none;
    border-color: #32c5d2; }
  .c-btn-border-2x.c-btn-green.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-green:hover,
  .c-btn-border-2x.c-btn-green.btn-no-focus:active,
  .c-btn-border-2x.c-btn-green:active,
  .c-btn-border-2x.c-btn-green.active,
  .open > .c-btn-border-2x.c-btn-green.dropdown-toggle {
    color: #ffffff;
    background: #32c5d2;
    border-color: #32c5d2; }
  .c-btn-border-2x.c-btn-green:active,
  .c-btn-border-2x.c-btn-green.active,
  .open > .c-btn-border-2x.c-btn-green.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-green.disabled,
  .c-btn-border-2x.c-btn-green.disabled:hover,
  .c-btn-border-2x.c-btn-green.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green.disabled:active,
  .c-btn-border-2x.c-btn-green.disabled.active,
  .c-btn-border-2x.c-btn-green[disabled],
  .c-btn-border-2x.c-btn-green[disabled]:hover,
  .c-btn-border-2x.c-btn-green[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green[disabled]:active,
  .c-btn-border-2x.c-btn-green[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green,
  fieldset[disabled] .c-btn-border-2x.c-btn-green:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-green:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green.active {
    background: none;
    border-color: #32c5d2; }
  .c-btn-border-2x.c-btn-green .badge {
    color: none;
    background: #32c5d2; }

.c-btn-border-2x.c-btn-green-1 {
  border-color: #52c9ba;
  color: #52c9ba;
  background: none;
  border-color: #52c9ba; }
  .c-btn-border-2x.c-btn-green-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-green-1.btn-no-focus.focus {
    color: #52c9ba;
    background: none;
    border-color: #52c9ba; }
  .c-btn-border-2x.c-btn-green-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-green-1:hover,
  .c-btn-border-2x.c-btn-green-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-green-1:active,
  .c-btn-border-2x.c-btn-green-1.active,
  .open > .c-btn-border-2x.c-btn-green-1.dropdown-toggle {
    color: #ffffff;
    background: #52c9ba;
    border-color: #52c9ba; }
  .c-btn-border-2x.c-btn-green-1:active,
  .c-btn-border-2x.c-btn-green-1.active,
  .open > .c-btn-border-2x.c-btn-green-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-green-1.disabled,
  .c-btn-border-2x.c-btn-green-1.disabled:hover,
  .c-btn-border-2x.c-btn-green-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-1.disabled:active,
  .c-btn-border-2x.c-btn-green-1.disabled.active,
  .c-btn-border-2x.c-btn-green-1[disabled],
  .c-btn-border-2x.c-btn-green-1[disabled]:hover,
  .c-btn-border-2x.c-btn-green-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-1[disabled]:active,
  .c-btn-border-2x.c-btn-green-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-1.active {
    background: none;
    border-color: #52c9ba; }
  .c-btn-border-2x.c-btn-green-1 .badge {
    color: none;
    background: #52c9ba; }

.c-btn-border-2x.c-btn-green-2 {
  border-color: #5dc09c;
  color: #5dc09c;
  background: none;
  border-color: #5dc09c; }
  .c-btn-border-2x.c-btn-green-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-green-2.btn-no-focus.focus {
    color: #5dc09c;
    background: none;
    border-color: #5dc09c; }
  .c-btn-border-2x.c-btn-green-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-green-2:hover,
  .c-btn-border-2x.c-btn-green-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-green-2:active,
  .c-btn-border-2x.c-btn-green-2.active,
  .open > .c-btn-border-2x.c-btn-green-2.dropdown-toggle {
    color: #ffffff;
    background: #5dc09c;
    border-color: #5dc09c; }
  .c-btn-border-2x.c-btn-green-2:active,
  .c-btn-border-2x.c-btn-green-2.active,
  .open > .c-btn-border-2x.c-btn-green-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-green-2.disabled,
  .c-btn-border-2x.c-btn-green-2.disabled:hover,
  .c-btn-border-2x.c-btn-green-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-2.disabled:active,
  .c-btn-border-2x.c-btn-green-2.disabled.active,
  .c-btn-border-2x.c-btn-green-2[disabled],
  .c-btn-border-2x.c-btn-green-2[disabled]:hover,
  .c-btn-border-2x.c-btn-green-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-2[disabled]:active,
  .c-btn-border-2x.c-btn-green-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-2.active {
    background: none;
    border-color: #5dc09c; }
  .c-btn-border-2x.c-btn-green-2 .badge {
    color: none;
    background: #5dc09c; }

.c-btn-border-2x.c-btn-green-3 {
  border-color: #84c68f;
  color: #84c68f;
  background: none;
  border-color: #84c68f; }
  .c-btn-border-2x.c-btn-green-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-green-3.btn-no-focus.focus {
    color: #84c68f;
    background: none;
    border-color: #84c68f; }
  .c-btn-border-2x.c-btn-green-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-green-3:hover,
  .c-btn-border-2x.c-btn-green-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-green-3:active,
  .c-btn-border-2x.c-btn-green-3.active,
  .open > .c-btn-border-2x.c-btn-green-3.dropdown-toggle {
    color: #ffffff;
    background: #84c68f;
    border-color: #84c68f; }
  .c-btn-border-2x.c-btn-green-3:active,
  .c-btn-border-2x.c-btn-green-3.active,
  .open > .c-btn-border-2x.c-btn-green-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-green-3.disabled,
  .c-btn-border-2x.c-btn-green-3.disabled:hover,
  .c-btn-border-2x.c-btn-green-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-3.disabled:active,
  .c-btn-border-2x.c-btn-green-3.disabled.active,
  .c-btn-border-2x.c-btn-green-3[disabled],
  .c-btn-border-2x.c-btn-green-3[disabled]:hover,
  .c-btn-border-2x.c-btn-green-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-green-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-green-3[disabled]:active,
  .c-btn-border-2x.c-btn-green-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-green-3.active {
    background: none;
    border-color: #84c68f; }
  .c-btn-border-2x.c-btn-green-3 .badge {
    color: none;
    background: #84c68f; }

.c-btn-border-2x.c-btn-red {
  border-color: #eb5d68;
  color: #eb5d68;
  background: none;
  border-color: #eb5d68; }
  .c-btn-border-2x.c-btn-red.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-red.btn-no-focus.focus {
    color: #eb5d68;
    background: none;
    border-color: #eb5d68; }
  .c-btn-border-2x.c-btn-red.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-red:hover,
  .c-btn-border-2x.c-btn-red.btn-no-focus:active,
  .c-btn-border-2x.c-btn-red:active,
  .c-btn-border-2x.c-btn-red.active,
  .open > .c-btn-border-2x.c-btn-red.dropdown-toggle {
    color: #ffffff;
    background: #eb5d68;
    border-color: #eb5d68; }
  .c-btn-border-2x.c-btn-red:active,
  .c-btn-border-2x.c-btn-red.active,
  .open > .c-btn-border-2x.c-btn-red.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-red.disabled,
  .c-btn-border-2x.c-btn-red.disabled:hover,
  .c-btn-border-2x.c-btn-red.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red.disabled:active,
  .c-btn-border-2x.c-btn-red.disabled.active,
  .c-btn-border-2x.c-btn-red[disabled],
  .c-btn-border-2x.c-btn-red[disabled]:hover,
  .c-btn-border-2x.c-btn-red[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red[disabled]:active,
  .c-btn-border-2x.c-btn-red[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red,
  fieldset[disabled] .c-btn-border-2x.c-btn-red:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-red:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red.active {
    background: none;
    border-color: #eb5d68; }
  .c-btn-border-2x.c-btn-red .badge {
    color: none;
    background: #eb5d68; }

.c-btn-border-2x.c-btn-red-1 {
  border-color: #cd6262;
  color: #cd6262;
  background: none;
  border-color: #cd6262; }
  .c-btn-border-2x.c-btn-red-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-red-1.btn-no-focus.focus {
    color: #cd6262;
    background: none;
    border-color: #cd6262; }
  .c-btn-border-2x.c-btn-red-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-red-1:hover,
  .c-btn-border-2x.c-btn-red-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-red-1:active,
  .c-btn-border-2x.c-btn-red-1.active,
  .open > .c-btn-border-2x.c-btn-red-1.dropdown-toggle {
    color: #ffffff;
    background: #cd6262;
    border-color: #cd6262; }
  .c-btn-border-2x.c-btn-red-1:active,
  .c-btn-border-2x.c-btn-red-1.active,
  .open > .c-btn-border-2x.c-btn-red-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-red-1.disabled,
  .c-btn-border-2x.c-btn-red-1.disabled:hover,
  .c-btn-border-2x.c-btn-red-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-1.disabled:active,
  .c-btn-border-2x.c-btn-red-1.disabled.active,
  .c-btn-border-2x.c-btn-red-1[disabled],
  .c-btn-border-2x.c-btn-red-1[disabled]:hover,
  .c-btn-border-2x.c-btn-red-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-1[disabled]:active,
  .c-btn-border-2x.c-btn-red-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-1.active {
    background: none;
    border-color: #cd6262; }
  .c-btn-border-2x.c-btn-red-1 .badge {
    color: none;
    background: #cd6262; }

.c-btn-border-2x.c-btn-red-2 {
  border-color: #e7505a;
  color: #e7505a;
  background: none;
  border-color: #e7505a; }
  .c-btn-border-2x.c-btn-red-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-red-2.btn-no-focus.focus {
    color: #e7505a;
    background: none;
    border-color: #e7505a; }
  .c-btn-border-2x.c-btn-red-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-red-2:hover,
  .c-btn-border-2x.c-btn-red-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-red-2:active,
  .c-btn-border-2x.c-btn-red-2.active,
  .open > .c-btn-border-2x.c-btn-red-2.dropdown-toggle {
    color: #ffffff;
    background: #e7505a;
    border-color: #e7505a; }
  .c-btn-border-2x.c-btn-red-2:active,
  .c-btn-border-2x.c-btn-red-2.active,
  .open > .c-btn-border-2x.c-btn-red-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-red-2.disabled,
  .c-btn-border-2x.c-btn-red-2.disabled:hover,
  .c-btn-border-2x.c-btn-red-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-2.disabled:active,
  .c-btn-border-2x.c-btn-red-2.disabled.active,
  .c-btn-border-2x.c-btn-red-2[disabled],
  .c-btn-border-2x.c-btn-red-2[disabled]:hover,
  .c-btn-border-2x.c-btn-red-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-2[disabled]:active,
  .c-btn-border-2x.c-btn-red-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-2.active {
    background: none;
    border-color: #e7505a; }
  .c-btn-border-2x.c-btn-red-2 .badge {
    color: none;
    background: #e7505a; }

.c-btn-border-2x.c-btn-red-3 {
  border-color: #d05163;
  color: #d05163;
  background: none;
  border-color: #d05163; }
  .c-btn-border-2x.c-btn-red-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-red-3.btn-no-focus.focus {
    color: #d05163;
    background: none;
    border-color: #d05163; }
  .c-btn-border-2x.c-btn-red-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-red-3:hover,
  .c-btn-border-2x.c-btn-red-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-red-3:active,
  .c-btn-border-2x.c-btn-red-3.active,
  .open > .c-btn-border-2x.c-btn-red-3.dropdown-toggle {
    color: #ffffff;
    background: #d05163;
    border-color: #d05163; }
  .c-btn-border-2x.c-btn-red-3:active,
  .c-btn-border-2x.c-btn-red-3.active,
  .open > .c-btn-border-2x.c-btn-red-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-red-3.disabled,
  .c-btn-border-2x.c-btn-red-3.disabled:hover,
  .c-btn-border-2x.c-btn-red-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-3.disabled:active,
  .c-btn-border-2x.c-btn-red-3.disabled.active,
  .c-btn-border-2x.c-btn-red-3[disabled],
  .c-btn-border-2x.c-btn-red-3[disabled]:hover,
  .c-btn-border-2x.c-btn-red-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-red-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-red-3[disabled]:active,
  .c-btn-border-2x.c-btn-red-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-red-3.active {
    background: none;
    border-color: #d05163; }
  .c-btn-border-2x.c-btn-red-3 .badge {
    color: none;
    background: #d05163; }

.c-btn-border-2x.c-btn-yellow {
  border-color: #FF6B57;
  color: #FF6B57;
  background: none;
  border-color: #FF6B57; }
  .c-btn-border-2x.c-btn-yellow.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-yellow.btn-no-focus.focus {
    color: #FF6B57;
    background: none;
    border-color: #FF6B57; }
  .c-btn-border-2x.c-btn-yellow.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-yellow:hover,
  .c-btn-border-2x.c-btn-yellow.btn-no-focus:active,
  .c-btn-border-2x.c-btn-yellow:active,
  .c-btn-border-2x.c-btn-yellow.active,
  .open > .c-btn-border-2x.c-btn-yellow.dropdown-toggle {
    color: #ffffff;
    background: #FF6B57;
    border-color: #FF6B57; }
  .c-btn-border-2x.c-btn-yellow:active,
  .c-btn-border-2x.c-btn-yellow.active,
  .open > .c-btn-border-2x.c-btn-yellow.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-yellow.disabled,
  .c-btn-border-2x.c-btn-yellow.disabled:hover,
  .c-btn-border-2x.c-btn-yellow.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow.disabled:active,
  .c-btn-border-2x.c-btn-yellow.disabled.active,
  .c-btn-border-2x.c-btn-yellow[disabled],
  .c-btn-border-2x.c-btn-yellow[disabled]:hover,
  .c-btn-border-2x.c-btn-yellow[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow[disabled]:active,
  .c-btn-border-2x.c-btn-yellow[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow.active {
    background: none;
    border-color: #FF6B57; }
  .c-btn-border-2x.c-btn-yellow .badge {
    color: none;
    background: #FF6B57; }

.c-btn-border-2x.c-btn-yellow-1 {
  border-color: #c8d046;
  color: #c8d046;
  background: none;
  border-color: #c8d046; }
  .c-btn-border-2x.c-btn-yellow-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-yellow-1.btn-no-focus.focus {
    color: #c8d046;
    background: none;
    border-color: #c8d046; }
  .c-btn-border-2x.c-btn-yellow-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-yellow-1:hover,
  .c-btn-border-2x.c-btn-yellow-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-yellow-1:active,
  .c-btn-border-2x.c-btn-yellow-1.active,
  .open > .c-btn-border-2x.c-btn-yellow-1.dropdown-toggle {
    color: #ffffff;
    background: #c8d046;
    border-color: #c8d046; }
  .c-btn-border-2x.c-btn-yellow-1:active,
  .c-btn-border-2x.c-btn-yellow-1.active,
  .open > .c-btn-border-2x.c-btn-yellow-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-yellow-1.disabled,
  .c-btn-border-2x.c-btn-yellow-1.disabled:hover,
  .c-btn-border-2x.c-btn-yellow-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-1.disabled:active,
  .c-btn-border-2x.c-btn-yellow-1.disabled.active,
  .c-btn-border-2x.c-btn-yellow-1[disabled],
  .c-btn-border-2x.c-btn-yellow-1[disabled]:hover,
  .c-btn-border-2x.c-btn-yellow-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-1[disabled]:active,
  .c-btn-border-2x.c-btn-yellow-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-1.active {
    background: none;
    border-color: #c8d046; }
  .c-btn-border-2x.c-btn-yellow-1 .badge {
    color: none;
    background: #c8d046; }

.c-btn-border-2x.c-btn-yellow-2 {
  border-color: #c5bf66;
  color: #c5bf66;
  background: none;
  border-color: #c5bf66; }
  .c-btn-border-2x.c-btn-yellow-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-yellow-2.btn-no-focus.focus {
    color: #c5bf66;
    background: none;
    border-color: #c5bf66; }
  .c-btn-border-2x.c-btn-yellow-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-yellow-2:hover,
  .c-btn-border-2x.c-btn-yellow-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-yellow-2:active,
  .c-btn-border-2x.c-btn-yellow-2.active,
  .open > .c-btn-border-2x.c-btn-yellow-2.dropdown-toggle {
    color: #ffffff;
    background: #c5bf66;
    border-color: #c5bf66; }
  .c-btn-border-2x.c-btn-yellow-2:active,
  .c-btn-border-2x.c-btn-yellow-2.active,
  .open > .c-btn-border-2x.c-btn-yellow-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-yellow-2.disabled,
  .c-btn-border-2x.c-btn-yellow-2.disabled:hover,
  .c-btn-border-2x.c-btn-yellow-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-2.disabled:active,
  .c-btn-border-2x.c-btn-yellow-2.disabled.active,
  .c-btn-border-2x.c-btn-yellow-2[disabled],
  .c-btn-border-2x.c-btn-yellow-2[disabled]:hover,
  .c-btn-border-2x.c-btn-yellow-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-2[disabled]:active,
  .c-btn-border-2x.c-btn-yellow-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-2.active {
    background: none;
    border-color: #c5bf66; }
  .c-btn-border-2x.c-btn-yellow-2 .badge {
    color: none;
    background: #c5bf66; }

.c-btn-border-2x.c-btn-yellow-3 {
  border-color: #c5b96b;
  color: #c5b96b;
  background: none;
  border-color: #c5b96b; }
  .c-btn-border-2x.c-btn-yellow-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-yellow-3.btn-no-focus.focus {
    color: #c5b96b;
    background: none;
    border-color: #c5b96b; }
  .c-btn-border-2x.c-btn-yellow-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-yellow-3:hover,
  .c-btn-border-2x.c-btn-yellow-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-yellow-3:active,
  .c-btn-border-2x.c-btn-yellow-3.active,
  .open > .c-btn-border-2x.c-btn-yellow-3.dropdown-toggle {
    color: #ffffff;
    background: #c5b96b;
    border-color: #c5b96b; }
  .c-btn-border-2x.c-btn-yellow-3:active,
  .c-btn-border-2x.c-btn-yellow-3.active,
  .open > .c-btn-border-2x.c-btn-yellow-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-yellow-3.disabled,
  .c-btn-border-2x.c-btn-yellow-3.disabled:hover,
  .c-btn-border-2x.c-btn-yellow-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-3.disabled:active,
  .c-btn-border-2x.c-btn-yellow-3.disabled.active,
  .c-btn-border-2x.c-btn-yellow-3[disabled],
  .c-btn-border-2x.c-btn-yellow-3[disabled]:hover,
  .c-btn-border-2x.c-btn-yellow-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-yellow-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-yellow-3[disabled]:active,
  .c-btn-border-2x.c-btn-yellow-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-yellow-3.active {
    background: none;
    border-color: #c5b96b; }
  .c-btn-border-2x.c-btn-yellow-3 .badge {
    color: none;
    background: #c5b96b; }

.c-btn-border-2x.c-btn-blue {
  border-color: #3498DB;
  color: #3498DB;
  background: none;
  border-color: #3498DB; }
  .c-btn-border-2x.c-btn-blue.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-blue.btn-no-focus.focus {
    color: #3498DB;
    background: none;
    border-color: #3498DB; }
  .c-btn-border-2x.c-btn-blue.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-blue:hover,
  .c-btn-border-2x.c-btn-blue.btn-no-focus:active,
  .c-btn-border-2x.c-btn-blue:active,
  .c-btn-border-2x.c-btn-blue.active,
  .open > .c-btn-border-2x.c-btn-blue.dropdown-toggle {
    color: #ffffff;
    background: #3498DB;
    border-color: #3498DB; }
  .c-btn-border-2x.c-btn-blue:active,
  .c-btn-border-2x.c-btn-blue.active,
  .open > .c-btn-border-2x.c-btn-blue.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-blue.disabled,
  .c-btn-border-2x.c-btn-blue.disabled:hover,
  .c-btn-border-2x.c-btn-blue.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue.disabled:active,
  .c-btn-border-2x.c-btn-blue.disabled.active,
  .c-btn-border-2x.c-btn-blue[disabled],
  .c-btn-border-2x.c-btn-blue[disabled]:hover,
  .c-btn-border-2x.c-btn-blue[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue[disabled]:active,
  .c-btn-border-2x.c-btn-blue[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue.active {
    background: none;
    border-color: #3498DB; }
  .c-btn-border-2x.c-btn-blue .badge {
    color: none;
    background: #3498DB; }

.c-btn-border-2x.c-btn-blue-1 {
  border-color: #5e9cd1;
  color: #5e9cd1;
  background: none;
  border-color: #5e9cd1; }
  .c-btn-border-2x.c-btn-blue-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-blue-1.btn-no-focus.focus {
    color: #5e9cd1;
    background: none;
    border-color: #5e9cd1; }
  .c-btn-border-2x.c-btn-blue-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-blue-1:hover,
  .c-btn-border-2x.c-btn-blue-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-blue-1:active,
  .c-btn-border-2x.c-btn-blue-1.active,
  .open > .c-btn-border-2x.c-btn-blue-1.dropdown-toggle {
    color: #ffffff;
    background: #5e9cd1;
    border-color: #5e9cd1; }
  .c-btn-border-2x.c-btn-blue-1:active,
  .c-btn-border-2x.c-btn-blue-1.active,
  .open > .c-btn-border-2x.c-btn-blue-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-blue-1.disabled,
  .c-btn-border-2x.c-btn-blue-1.disabled:hover,
  .c-btn-border-2x.c-btn-blue-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-1.disabled:active,
  .c-btn-border-2x.c-btn-blue-1.disabled.active,
  .c-btn-border-2x.c-btn-blue-1[disabled],
  .c-btn-border-2x.c-btn-blue-1[disabled]:hover,
  .c-btn-border-2x.c-btn-blue-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-1[disabled]:active,
  .c-btn-border-2x.c-btn-blue-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-1.active {
    background: none;
    border-color: #5e9cd1; }
  .c-btn-border-2x.c-btn-blue-1 .badge {
    color: none;
    background: #5e9cd1; }

.c-btn-border-2x.c-btn-blue-2 {
  border-color: #5893dd;
  color: #5893dd;
  background: none;
  border-color: #5893dd; }
  .c-btn-border-2x.c-btn-blue-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-blue-2.btn-no-focus.focus {
    color: #5893dd;
    background: none;
    border-color: #5893dd; }
  .c-btn-border-2x.c-btn-blue-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-blue-2:hover,
  .c-btn-border-2x.c-btn-blue-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-blue-2:active,
  .c-btn-border-2x.c-btn-blue-2.active,
  .open > .c-btn-border-2x.c-btn-blue-2.dropdown-toggle {
    color: #ffffff;
    background: #5893dd;
    border-color: #5893dd; }
  .c-btn-border-2x.c-btn-blue-2:active,
  .c-btn-border-2x.c-btn-blue-2.active,
  .open > .c-btn-border-2x.c-btn-blue-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-blue-2.disabled,
  .c-btn-border-2x.c-btn-blue-2.disabled:hover,
  .c-btn-border-2x.c-btn-blue-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-2.disabled:active,
  .c-btn-border-2x.c-btn-blue-2.disabled.active,
  .c-btn-border-2x.c-btn-blue-2[disabled],
  .c-btn-border-2x.c-btn-blue-2[disabled]:hover,
  .c-btn-border-2x.c-btn-blue-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-2[disabled]:active,
  .c-btn-border-2x.c-btn-blue-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-2.active {
    background: none;
    border-color: #5893dd; }
  .c-btn-border-2x.c-btn-blue-2 .badge {
    color: none;
    background: #5893dd; }

.c-btn-border-2x.c-btn-blue-3 {
  border-color: #57bfe1;
  color: #57bfe1;
  background: none;
  border-color: #57bfe1; }
  .c-btn-border-2x.c-btn-blue-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-blue-3.btn-no-focus.focus {
    color: #57bfe1;
    background: none;
    border-color: #57bfe1; }
  .c-btn-border-2x.c-btn-blue-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-blue-3:hover,
  .c-btn-border-2x.c-btn-blue-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-blue-3:active,
  .c-btn-border-2x.c-btn-blue-3.active,
  .open > .c-btn-border-2x.c-btn-blue-3.dropdown-toggle {
    color: #ffffff;
    background: #57bfe1;
    border-color: #57bfe1; }
  .c-btn-border-2x.c-btn-blue-3:active,
  .c-btn-border-2x.c-btn-blue-3.active,
  .open > .c-btn-border-2x.c-btn-blue-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-blue-3.disabled,
  .c-btn-border-2x.c-btn-blue-3.disabled:hover,
  .c-btn-border-2x.c-btn-blue-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-3.disabled:active,
  .c-btn-border-2x.c-btn-blue-3.disabled.active,
  .c-btn-border-2x.c-btn-blue-3[disabled],
  .c-btn-border-2x.c-btn-blue-3[disabled]:hover,
  .c-btn-border-2x.c-btn-blue-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-blue-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-blue-3[disabled]:active,
  .c-btn-border-2x.c-btn-blue-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-blue-3.active {
    background: none;
    border-color: #57bfe1; }
  .c-btn-border-2x.c-btn-blue-3 .badge {
    color: none;
    background: #57bfe1; }

.c-btn-border-2x.c-btn-purple {
  border-color: #b771b0;
  color: #b771b0;
  background: none;
  border-color: #b771b0; }
  .c-btn-border-2x.c-btn-purple.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-purple.btn-no-focus.focus {
    color: #b771b0;
    background: none;
    border-color: #b771b0; }
  .c-btn-border-2x.c-btn-purple.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-purple:hover,
  .c-btn-border-2x.c-btn-purple.btn-no-focus:active,
  .c-btn-border-2x.c-btn-purple:active,
  .c-btn-border-2x.c-btn-purple.active,
  .open > .c-btn-border-2x.c-btn-purple.dropdown-toggle {
    color: #ffffff;
    background: #b771b0;
    border-color: #b771b0; }
  .c-btn-border-2x.c-btn-purple:active,
  .c-btn-border-2x.c-btn-purple.active,
  .open > .c-btn-border-2x.c-btn-purple.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-purple.disabled,
  .c-btn-border-2x.c-btn-purple.disabled:hover,
  .c-btn-border-2x.c-btn-purple.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple.disabled:active,
  .c-btn-border-2x.c-btn-purple.disabled.active,
  .c-btn-border-2x.c-btn-purple[disabled],
  .c-btn-border-2x.c-btn-purple[disabled]:hover,
  .c-btn-border-2x.c-btn-purple[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple[disabled]:active,
  .c-btn-border-2x.c-btn-purple[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple.active {
    background: none;
    border-color: #b771b0; }
  .c-btn-border-2x.c-btn-purple .badge {
    color: none;
    background: #b771b0; }

.c-btn-border-2x.c-btn-purple-1 {
  border-color: #a962bb;
  color: #a962bb;
  background: none;
  border-color: #a962bb; }
  .c-btn-border-2x.c-btn-purple-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-purple-1.btn-no-focus.focus {
    color: #a962bb;
    background: none;
    border-color: #a962bb; }
  .c-btn-border-2x.c-btn-purple-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-purple-1:hover,
  .c-btn-border-2x.c-btn-purple-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-purple-1:active,
  .c-btn-border-2x.c-btn-purple-1.active,
  .open > .c-btn-border-2x.c-btn-purple-1.dropdown-toggle {
    color: #ffffff;
    background: #a962bb;
    border-color: #a962bb; }
  .c-btn-border-2x.c-btn-purple-1:active,
  .c-btn-border-2x.c-btn-purple-1.active,
  .open > .c-btn-border-2x.c-btn-purple-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-purple-1.disabled,
  .c-btn-border-2x.c-btn-purple-1.disabled:hover,
  .c-btn-border-2x.c-btn-purple-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-1.disabled:active,
  .c-btn-border-2x.c-btn-purple-1.disabled.active,
  .c-btn-border-2x.c-btn-purple-1[disabled],
  .c-btn-border-2x.c-btn-purple-1[disabled]:hover,
  .c-btn-border-2x.c-btn-purple-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-1[disabled]:active,
  .c-btn-border-2x.c-btn-purple-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-1.active {
    background: none;
    border-color: #a962bb; }
  .c-btn-border-2x.c-btn-purple-1 .badge {
    color: none;
    background: #a962bb; }

.c-btn-border-2x.c-btn-purple-2 {
  border-color: #aa67a3;
  color: #aa67a3;
  background: none;
  border-color: #aa67a3; }
  .c-btn-border-2x.c-btn-purple-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-purple-2.btn-no-focus.focus {
    color: #aa67a3;
    background: none;
    border-color: #aa67a3; }
  .c-btn-border-2x.c-btn-purple-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-purple-2:hover,
  .c-btn-border-2x.c-btn-purple-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-purple-2:active,
  .c-btn-border-2x.c-btn-purple-2.active,
  .open > .c-btn-border-2x.c-btn-purple-2.dropdown-toggle {
    color: #ffffff;
    background: #aa67a3;
    border-color: #aa67a3; }
  .c-btn-border-2x.c-btn-purple-2:active,
  .c-btn-border-2x.c-btn-purple-2.active,
  .open > .c-btn-border-2x.c-btn-purple-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-purple-2.disabled,
  .c-btn-border-2x.c-btn-purple-2.disabled:hover,
  .c-btn-border-2x.c-btn-purple-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-2.disabled:active,
  .c-btn-border-2x.c-btn-purple-2.disabled.active,
  .c-btn-border-2x.c-btn-purple-2[disabled],
  .c-btn-border-2x.c-btn-purple-2[disabled]:hover,
  .c-btn-border-2x.c-btn-purple-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-2[disabled]:active,
  .c-btn-border-2x.c-btn-purple-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-2.active {
    background: none;
    border-color: #aa67a3; }
  .c-btn-border-2x.c-btn-purple-2 .badge {
    color: none;
    background: #aa67a3; }

.c-btn-border-2x.c-btn-purple-3 {
  border-color: #ac3773;
  color: #ac3773;
  background: none;
  border-color: #ac3773; }
  .c-btn-border-2x.c-btn-purple-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-purple-3.btn-no-focus.focus {
    color: #ac3773;
    background: none;
    border-color: #ac3773; }
  .c-btn-border-2x.c-btn-purple-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-purple-3:hover,
  .c-btn-border-2x.c-btn-purple-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-purple-3:active,
  .c-btn-border-2x.c-btn-purple-3.active,
  .open > .c-btn-border-2x.c-btn-purple-3.dropdown-toggle {
    color: #ffffff;
    background: #ac3773;
    border-color: #ac3773; }
  .c-btn-border-2x.c-btn-purple-3:active,
  .c-btn-border-2x.c-btn-purple-3.active,
  .open > .c-btn-border-2x.c-btn-purple-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-purple-3.disabled,
  .c-btn-border-2x.c-btn-purple-3.disabled:hover,
  .c-btn-border-2x.c-btn-purple-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-3.disabled:active,
  .c-btn-border-2x.c-btn-purple-3.disabled.active,
  .c-btn-border-2x.c-btn-purple-3[disabled],
  .c-btn-border-2x.c-btn-purple-3[disabled]:hover,
  .c-btn-border-2x.c-btn-purple-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-purple-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-purple-3[disabled]:active,
  .c-btn-border-2x.c-btn-purple-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-purple-3.active {
    background: none;
    border-color: #ac3773; }
  .c-btn-border-2x.c-btn-purple-3 .badge {
    color: none;
    background: #ac3773; }

.c-btn-border-2x.c-btn-brown {
  border-color: #8a7f68;
  color: #8a7f68;
  background: none;
  border-color: #8a7f68; }
  .c-btn-border-2x.c-btn-brown.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-brown.btn-no-focus.focus {
    color: #8a7f68;
    background: none;
    border-color: #8a7f68; }
  .c-btn-border-2x.c-btn-brown.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-brown:hover,
  .c-btn-border-2x.c-btn-brown.btn-no-focus:active,
  .c-btn-border-2x.c-btn-brown:active,
  .c-btn-border-2x.c-btn-brown.active,
  .open > .c-btn-border-2x.c-btn-brown.dropdown-toggle {
    color: #ffffff;
    background: #8a7f68;
    border-color: #8a7f68; }
  .c-btn-border-2x.c-btn-brown:active,
  .c-btn-border-2x.c-btn-brown.active,
  .open > .c-btn-border-2x.c-btn-brown.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-brown.disabled,
  .c-btn-border-2x.c-btn-brown.disabled:hover,
  .c-btn-border-2x.c-btn-brown.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown.disabled:active,
  .c-btn-border-2x.c-btn-brown.disabled.active,
  .c-btn-border-2x.c-btn-brown[disabled],
  .c-btn-border-2x.c-btn-brown[disabled]:hover,
  .c-btn-border-2x.c-btn-brown[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown[disabled]:active,
  .c-btn-border-2x.c-btn-brown[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown.active {
    background: none;
    border-color: #8a7f68; }
  .c-btn-border-2x.c-btn-brown .badge {
    color: none;
    background: #8a7f68; }

.c-btn-border-2x.c-btn-brown-1 {
  border-color: #685e47;
  color: #685e47;
  background: none;
  border-color: #685e47; }
  .c-btn-border-2x.c-btn-brown-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-brown-1.btn-no-focus.focus {
    color: #685e47;
    background: none;
    border-color: #685e47; }
  .c-btn-border-2x.c-btn-brown-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-brown-1:hover,
  .c-btn-border-2x.c-btn-brown-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-brown-1:active,
  .c-btn-border-2x.c-btn-brown-1.active,
  .open > .c-btn-border-2x.c-btn-brown-1.dropdown-toggle {
    color: #ffffff;
    background: #685e47;
    border-color: #685e47; }
  .c-btn-border-2x.c-btn-brown-1:active,
  .c-btn-border-2x.c-btn-brown-1.active,
  .open > .c-btn-border-2x.c-btn-brown-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-brown-1.disabled,
  .c-btn-border-2x.c-btn-brown-1.disabled:hover,
  .c-btn-border-2x.c-btn-brown-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-1.disabled:active,
  .c-btn-border-2x.c-btn-brown-1.disabled.active,
  .c-btn-border-2x.c-btn-brown-1[disabled],
  .c-btn-border-2x.c-btn-brown-1[disabled]:hover,
  .c-btn-border-2x.c-btn-brown-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-1[disabled]:active,
  .c-btn-border-2x.c-btn-brown-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-1.active {
    background: none;
    border-color: #685e47; }
  .c-btn-border-2x.c-btn-brown-1 .badge {
    color: none;
    background: #685e47; }

.c-btn-border-2x.c-btn-brown-2 {
  border-color: #7a6a61;
  color: #7a6a61;
  background: none;
  border-color: #7a6a61; }
  .c-btn-border-2x.c-btn-brown-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-brown-2.btn-no-focus.focus {
    color: #7a6a61;
    background: none;
    border-color: #7a6a61; }
  .c-btn-border-2x.c-btn-brown-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-brown-2:hover,
  .c-btn-border-2x.c-btn-brown-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-brown-2:active,
  .c-btn-border-2x.c-btn-brown-2.active,
  .open > .c-btn-border-2x.c-btn-brown-2.dropdown-toggle {
    color: #ffffff;
    background: #7a6a61;
    border-color: #7a6a61; }
  .c-btn-border-2x.c-btn-brown-2:active,
  .c-btn-border-2x.c-btn-brown-2.active,
  .open > .c-btn-border-2x.c-btn-brown-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-brown-2.disabled,
  .c-btn-border-2x.c-btn-brown-2.disabled:hover,
  .c-btn-border-2x.c-btn-brown-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-2.disabled:active,
  .c-btn-border-2x.c-btn-brown-2.disabled.active,
  .c-btn-border-2x.c-btn-brown-2[disabled],
  .c-btn-border-2x.c-btn-brown-2[disabled]:hover,
  .c-btn-border-2x.c-btn-brown-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-2[disabled]:active,
  .c-btn-border-2x.c-btn-brown-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-2.active {
    background: none;
    border-color: #7a6a61; }
  .c-btn-border-2x.c-btn-brown-2 .badge {
    color: none;
    background: #7a6a61; }

.c-btn-border-2x.c-btn-brown-3 {
  border-color: #9d8b81;
  color: #9d8b81;
  background: none;
  border-color: #9d8b81; }
  .c-btn-border-2x.c-btn-brown-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-brown-3.btn-no-focus.focus {
    color: #9d8b81;
    background: none;
    border-color: #9d8b81; }
  .c-btn-border-2x.c-btn-brown-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-brown-3:hover,
  .c-btn-border-2x.c-btn-brown-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-brown-3:active,
  .c-btn-border-2x.c-btn-brown-3.active,
  .open > .c-btn-border-2x.c-btn-brown-3.dropdown-toggle {
    color: #ffffff;
    background: #9d8b81;
    border-color: #9d8b81; }
  .c-btn-border-2x.c-btn-brown-3:active,
  .c-btn-border-2x.c-btn-brown-3.active,
  .open > .c-btn-border-2x.c-btn-brown-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-brown-3.disabled,
  .c-btn-border-2x.c-btn-brown-3.disabled:hover,
  .c-btn-border-2x.c-btn-brown-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-3.disabled:active,
  .c-btn-border-2x.c-btn-brown-3.disabled.active,
  .c-btn-border-2x.c-btn-brown-3[disabled],
  .c-btn-border-2x.c-btn-brown-3[disabled]:hover,
  .c-btn-border-2x.c-btn-brown-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-brown-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-brown-3[disabled]:active,
  .c-btn-border-2x.c-btn-brown-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-brown-3.active {
    background: none;
    border-color: #9d8b81; }
  .c-btn-border-2x.c-btn-brown-3 .badge {
    color: none;
    background: #9d8b81; }

.c-btn-border-2x.c-btn-dark {
  border-color: #2f353b;
  color: #2f353b;
  background: none;
  border-color: #2f353b; }
  .c-btn-border-2x.c-btn-dark.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-dark.btn-no-focus.focus {
    color: #2f353b;
    background: none;
    border-color: #2f353b; }
  .c-btn-border-2x.c-btn-dark.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-dark:hover,
  .c-btn-border-2x.c-btn-dark.btn-no-focus:active,
  .c-btn-border-2x.c-btn-dark:active,
  .c-btn-border-2x.c-btn-dark.active,
  .open > .c-btn-border-2x.c-btn-dark.dropdown-toggle {
    color: #ffffff;
    background: #2f353b;
    border-color: #2f353b; }
  .c-btn-border-2x.c-btn-dark:active,
  .c-btn-border-2x.c-btn-dark.active,
  .open > .c-btn-border-2x.c-btn-dark.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-dark.disabled,
  .c-btn-border-2x.c-btn-dark.disabled:hover,
  .c-btn-border-2x.c-btn-dark.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark.disabled:active,
  .c-btn-border-2x.c-btn-dark.disabled.active,
  .c-btn-border-2x.c-btn-dark[disabled],
  .c-btn-border-2x.c-btn-dark[disabled]:hover,
  .c-btn-border-2x.c-btn-dark[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark[disabled]:active,
  .c-btn-border-2x.c-btn-dark[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark.active {
    background: none;
    border-color: #2f353b; }
  .c-btn-border-2x.c-btn-dark .badge {
    color: none;
    background: #2f353b; }

.c-btn-border-2x.c-btn-dark-1 {
  border-color: #525e64;
  color: #525e64;
  background: none;
  border-color: #525e64; }
  .c-btn-border-2x.c-btn-dark-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-dark-1.btn-no-focus.focus {
    color: #525e64;
    background: none;
    border-color: #525e64; }
  .c-btn-border-2x.c-btn-dark-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-dark-1:hover,
  .c-btn-border-2x.c-btn-dark-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-dark-1:active,
  .c-btn-border-2x.c-btn-dark-1.active,
  .open > .c-btn-border-2x.c-btn-dark-1.dropdown-toggle {
    color: #ffffff;
    background: #525e64;
    border-color: #525e64; }
  .c-btn-border-2x.c-btn-dark-1:active,
  .c-btn-border-2x.c-btn-dark-1.active,
  .open > .c-btn-border-2x.c-btn-dark-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-dark-1.disabled,
  .c-btn-border-2x.c-btn-dark-1.disabled:hover,
  .c-btn-border-2x.c-btn-dark-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-1.disabled:active,
  .c-btn-border-2x.c-btn-dark-1.disabled.active,
  .c-btn-border-2x.c-btn-dark-1[disabled],
  .c-btn-border-2x.c-btn-dark-1[disabled]:hover,
  .c-btn-border-2x.c-btn-dark-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-1[disabled]:active,
  .c-btn-border-2x.c-btn-dark-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-1.active {
    background: none;
    border-color: #525e64; }
  .c-btn-border-2x.c-btn-dark-1 .badge {
    color: none;
    background: #525e64; }

.c-btn-border-2x.c-btn-dark-2 {
  border-color: #31383c;
  color: #31383c;
  background: none;
  border-color: #31383c; }
  .c-btn-border-2x.c-btn-dark-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-dark-2.btn-no-focus.focus {
    color: #31383c;
    background: none;
    border-color: #31383c; }
  .c-btn-border-2x.c-btn-dark-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-dark-2:hover,
  .c-btn-border-2x.c-btn-dark-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-dark-2:active,
  .c-btn-border-2x.c-btn-dark-2.active,
  .open > .c-btn-border-2x.c-btn-dark-2.dropdown-toggle {
    color: #ffffff;
    background: #31383c;
    border-color: #31383c; }
  .c-btn-border-2x.c-btn-dark-2:active,
  .c-btn-border-2x.c-btn-dark-2.active,
  .open > .c-btn-border-2x.c-btn-dark-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-dark-2.disabled,
  .c-btn-border-2x.c-btn-dark-2.disabled:hover,
  .c-btn-border-2x.c-btn-dark-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-2.disabled:active,
  .c-btn-border-2x.c-btn-dark-2.disabled.active,
  .c-btn-border-2x.c-btn-dark-2[disabled],
  .c-btn-border-2x.c-btn-dark-2[disabled]:hover,
  .c-btn-border-2x.c-btn-dark-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-2[disabled]:active,
  .c-btn-border-2x.c-btn-dark-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-2.active {
    background: none;
    border-color: #31383c; }
  .c-btn-border-2x.c-btn-dark-2 .badge {
    color: none;
    background: #31383c; }

.c-btn-border-2x.c-btn-dark-3 {
  border-color: #41515b;
  color: #41515b;
  background: none;
  border-color: #41515b; }
  .c-btn-border-2x.c-btn-dark-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-dark-3.btn-no-focus.focus {
    color: #41515b;
    background: none;
    border-color: #41515b; }
  .c-btn-border-2x.c-btn-dark-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-dark-3:hover,
  .c-btn-border-2x.c-btn-dark-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-dark-3:active,
  .c-btn-border-2x.c-btn-dark-3.active,
  .open > .c-btn-border-2x.c-btn-dark-3.dropdown-toggle {
    color: #ffffff;
    background: #41515b;
    border-color: #41515b; }
  .c-btn-border-2x.c-btn-dark-3:active,
  .c-btn-border-2x.c-btn-dark-3.active,
  .open > .c-btn-border-2x.c-btn-dark-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-dark-3.disabled,
  .c-btn-border-2x.c-btn-dark-3.disabled:hover,
  .c-btn-border-2x.c-btn-dark-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-3.disabled:active,
  .c-btn-border-2x.c-btn-dark-3.disabled.active,
  .c-btn-border-2x.c-btn-dark-3[disabled],
  .c-btn-border-2x.c-btn-dark-3[disabled]:hover,
  .c-btn-border-2x.c-btn-dark-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-dark-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-dark-3[disabled]:active,
  .c-btn-border-2x.c-btn-dark-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-dark-3.active {
    background: none;
    border-color: #41515b; }
  .c-btn-border-2x.c-btn-dark-3 .badge {
    color: none;
    background: #41515b; }

.c-btn-border-2x.c-btn-grey {
  border-color: #eeeeee;
  color: #eeeeee;
  background: none;
  border-color: #eeeeee; }
  .c-btn-border-2x.c-btn-grey.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-grey.btn-no-focus.focus {
    color: #eeeeee;
    background: none;
    border-color: #eeeeee; }
  .c-btn-border-2x.c-btn-grey.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-grey:hover,
  .c-btn-border-2x.c-btn-grey.btn-no-focus:active,
  .c-btn-border-2x.c-btn-grey:active,
  .c-btn-border-2x.c-btn-grey.active,
  .open > .c-btn-border-2x.c-btn-grey.dropdown-toggle {
    color: #6e6e6e;
    background: #eeeeee;
    border-color: #eeeeee; }
  .c-btn-border-2x.c-btn-grey:active,
  .c-btn-border-2x.c-btn-grey.active,
  .open > .c-btn-border-2x.c-btn-grey.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-grey.disabled,
  .c-btn-border-2x.c-btn-grey.disabled:hover,
  .c-btn-border-2x.c-btn-grey.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey.disabled:active,
  .c-btn-border-2x.c-btn-grey.disabled.active,
  .c-btn-border-2x.c-btn-grey[disabled],
  .c-btn-border-2x.c-btn-grey[disabled]:hover,
  .c-btn-border-2x.c-btn-grey[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey[disabled]:active,
  .c-btn-border-2x.c-btn-grey[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey.active {
    background: none;
    border-color: #eeeeee; }
  .c-btn-border-2x.c-btn-grey .badge {
    color: none;
    background: #eeeeee; }

.c-btn-border-2x.c-btn-grey-1 {
  border-color: #f7f7f7;
  color: #f7f7f7;
  background: none;
  border-color: #f7f7f7; }
  .c-btn-border-2x.c-btn-grey-1.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-grey-1.btn-no-focus.focus {
    color: #f7f7f7;
    background: none;
    border-color: #f7f7f7; }
  .c-btn-border-2x.c-btn-grey-1.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-grey-1:hover,
  .c-btn-border-2x.c-btn-grey-1.btn-no-focus:active,
  .c-btn-border-2x.c-btn-grey-1:active,
  .c-btn-border-2x.c-btn-grey-1.active,
  .open > .c-btn-border-2x.c-btn-grey-1.dropdown-toggle {
    color: #777777;
    background: #f7f7f7;
    border-color: #f7f7f7; }
  .c-btn-border-2x.c-btn-grey-1:active,
  .c-btn-border-2x.c-btn-grey-1.active,
  .open > .c-btn-border-2x.c-btn-grey-1.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-grey-1.disabled,
  .c-btn-border-2x.c-btn-grey-1.disabled:hover,
  .c-btn-border-2x.c-btn-grey-1.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-1.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-1.disabled:active,
  .c-btn-border-2x.c-btn-grey-1.disabled.active,
  .c-btn-border-2x.c-btn-grey-1[disabled],
  .c-btn-border-2x.c-btn-grey-1[disabled]:hover,
  .c-btn-border-2x.c-btn-grey-1[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-1[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-1[disabled]:active,
  .c-btn-border-2x.c-btn-grey-1[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-1.active {
    background: none;
    border-color: #f7f7f7; }
  .c-btn-border-2x.c-btn-grey-1 .badge {
    color: none;
    background: #f7f7f7; }

.c-btn-border-2x.c-btn-grey-2 {
  border-color: #677083;
  color: #677083;
  background: none;
  border-color: #677083; }
  .c-btn-border-2x.c-btn-grey-2.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-grey-2.btn-no-focus.focus {
    color: #677083;
    background: none;
    border-color: #677083; }
  .c-btn-border-2x.c-btn-grey-2.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-grey-2:hover,
  .c-btn-border-2x.c-btn-grey-2.btn-no-focus:active,
  .c-btn-border-2x.c-btn-grey-2:active,
  .c-btn-border-2x.c-btn-grey-2.active,
  .open > .c-btn-border-2x.c-btn-grey-2.dropdown-toggle {
    color: #ffffff;
    background: #677083;
    border-color: #677083; }
  .c-btn-border-2x.c-btn-grey-2:active,
  .c-btn-border-2x.c-btn-grey-2.active,
  .open > .c-btn-border-2x.c-btn-grey-2.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-grey-2.disabled,
  .c-btn-border-2x.c-btn-grey-2.disabled:hover,
  .c-btn-border-2x.c-btn-grey-2.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-2.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-2.disabled:active,
  .c-btn-border-2x.c-btn-grey-2.disabled.active,
  .c-btn-border-2x.c-btn-grey-2[disabled],
  .c-btn-border-2x.c-btn-grey-2[disabled]:hover,
  .c-btn-border-2x.c-btn-grey-2[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-2[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-2[disabled]:active,
  .c-btn-border-2x.c-btn-grey-2[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-2.active {
    background: none;
    border-color: #677083; }
  .c-btn-border-2x.c-btn-grey-2 .badge {
    color: none;
    background: #677083; }

.c-btn-border-2x.c-btn-grey-3 {
  border-color: #7f8c97;
  color: #7f8c97;
  background: none;
  border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-grey-3.btn-no-focus:focus,
  .c-btn-border-2x.c-btn-grey-3.btn-no-focus.focus {
    color: #7f8c97;
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-grey-3.btn-no-focus:hover,
  .c-btn-border-2x.c-btn-grey-3:hover,
  .c-btn-border-2x.c-btn-grey-3.btn-no-focus:active,
  .c-btn-border-2x.c-btn-grey-3:active,
  .c-btn-border-2x.c-btn-grey-3.active,
  .open > .c-btn-border-2x.c-btn-grey-3.dropdown-toggle {
    color: #ffffff;
    background: #7f8c97;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-grey-3:active,
  .c-btn-border-2x.c-btn-grey-3.active,
  .open > .c-btn-border-2x.c-btn-grey-3.dropdown-toggle {
    background-image: none; }
  .c-btn-border-2x.c-btn-grey-3.disabled,
  .c-btn-border-2x.c-btn-grey-3.disabled:hover,
  .c-btn-border-2x.c-btn-grey-3.disabled:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-3.disabled:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-3.disabled:active,
  .c-btn-border-2x.c-btn-grey-3.disabled.active,
  .c-btn-border-2x.c-btn-grey-3[disabled],
  .c-btn-border-2x.c-btn-grey-3[disabled]:hover,
  .c-btn-border-2x.c-btn-grey-3[disabled]:not(.btn-no-focus):focus,
  .c-btn-border-2x.c-btn-grey-3[disabled]:not(.btn-no-focus).focus,
  .c-btn-border-2x.c-btn-grey-3[disabled]:active,
  .c-btn-border-2x.c-btn-grey-3[disabled].active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3:hover,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3:not(.btn-no-focus):focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3:not(.btn-no-focus).focus,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3:active,
  fieldset[disabled] .c-btn-border-2x.c-btn-grey-3.active {
    background: none;
    border-color: #7f8c97; }
  .c-btn-border-2x.c-btn-grey-3 .badge {
    color: none;
    background: #7f8c97; }

.btn.c-btn-icon.c-btn-white {
  background: #FFFFFF;
  border-color: #FFFFFF;
  color: #2f353b; }
  .btn.c-btn-icon.c-btn-white > i {
    background: #f2f2f2;
    color: #2f353b; }
  .btn.c-btn-icon.c-btn-white > .c-helper {
    color: #2f353b; }
  .btn.c-btn-icon.c-btn-white:hover,
  .btn.c-btn-icon.c-btn-white:focus,
  .btn.c-btn-icon.c-btn-white.focus,
  .btn.c-btn-icon.c-btn-white:active,
  .btn.c-btn-icon.c-btn-white.active {
    color: #2f353b;
    background: #f2f2f2;
    border-color: #f2f2f2; }
    .btn.c-btn-icon.c-btn-white:hover > i,
    .btn.c-btn-icon.c-btn-white:focus > i,
    .btn.c-btn-icon.c-btn-white.focus > i,
    .btn.c-btn-icon.c-btn-white:active > i,
    .btn.c-btn-icon.c-btn-white.active > i {
      background: #ebebeb; }

.btn.c-btn-icon.c-btn-black {
  background: #000000;
  border-color: #000000;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-black > i {
    background: black;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-black > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-black:hover,
  .btn.c-btn-icon.c-btn-black:focus,
  .btn.c-btn-icon.c-btn-black.focus,
  .btn.c-btn-icon.c-btn-black:active,
  .btn.c-btn-icon.c-btn-black.active {
    color: #ffffff;
    background: black;
    border-color: black; }
    .btn.c-btn-icon.c-btn-black:hover > i,
    .btn.c-btn-icon.c-btn-black:focus > i,
    .btn.c-btn-icon.c-btn-black.focus > i,
    .btn.c-btn-icon.c-btn-black:active > i,
    .btn.c-btn-icon.c-btn-black.active > i {
      background: black; }

.btn.c-btn-icon.c-btn-regular {
  background: #7f8c97;
  border-color: #7f8c97;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-regular > i {
    background: #717f8b;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-regular > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-regular:hover,
  .btn.c-btn-icon.c-btn-regular:focus,
  .btn.c-btn-icon.c-btn-regular.focus,
  .btn.c-btn-icon.c-btn-regular:active,
  .btn.c-btn-icon.c-btn-regular.active {
    color: #ffffff;
    background: #717f8b;
    border-color: #717f8b; }
    .btn.c-btn-icon.c-btn-regular:hover > i,
    .btn.c-btn-icon.c-btn-regular:focus > i,
    .btn.c-btn-icon.c-btn-regular.focus > i,
    .btn.c-btn-icon.c-btn-regular:active > i,
    .btn.c-btn-icon.c-btn-regular.active > i {
      background: #6a7883; }

.btn.c-btn-icon.c-btn-green {
  background: #32c5d2;
  border-color: #32c5d2;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-green > i {
    background: #2ab4c0;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green:hover,
  .btn.c-btn-icon.c-btn-green:focus,
  .btn.c-btn-icon.c-btn-green.focus,
  .btn.c-btn-icon.c-btn-green:active,
  .btn.c-btn-icon.c-btn-green.active {
    color: #ffffff;
    background: #2ab4c0;
    border-color: #2ab4c0; }
    .btn.c-btn-icon.c-btn-green:hover > i,
    .btn.c-btn-icon.c-btn-green:focus > i,
    .btn.c-btn-icon.c-btn-green.focus > i,
    .btn.c-btn-icon.c-btn-green:active > i,
    .btn.c-btn-icon.c-btn-green.active > i {
      background: #27a8b4; }

.btn.c-btn-icon.c-btn-green-1 {
  background: #52c9ba;
  border-color: #52c9ba;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-1 > i {
    background: #3fc3b2;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-1:hover,
  .btn.c-btn-icon.c-btn-green-1:focus,
  .btn.c-btn-icon.c-btn-green-1.focus,
  .btn.c-btn-icon.c-btn-green-1:active,
  .btn.c-btn-icon.c-btn-green-1.active {
    color: #ffffff;
    background: #3fc3b2;
    border-color: #3fc3b2; }
    .btn.c-btn-icon.c-btn-green-1:hover > i,
    .btn.c-btn-icon.c-btn-green-1:focus > i,
    .btn.c-btn-icon.c-btn-green-1.focus > i,
    .btn.c-btn-icon.c-btn-green-1:active > i,
    .btn.c-btn-icon.c-btn-green-1.active > i {
      background: #3ab9a9; }

.btn.c-btn-icon.c-btn-green-2 {
  background: #5dc09c;
  border-color: #5dc09c;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-2 > i {
    background: #4bb991;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-2:hover,
  .btn.c-btn-icon.c-btn-green-2:focus,
  .btn.c-btn-icon.c-btn-green-2.focus,
  .btn.c-btn-icon.c-btn-green-2:active,
  .btn.c-btn-icon.c-btn-green-2.active {
    color: #ffffff;
    background: #4bb991;
    border-color: #4bb991; }
    .btn.c-btn-icon.c-btn-green-2:hover > i,
    .btn.c-btn-icon.c-btn-green-2:focus > i,
    .btn.c-btn-icon.c-btn-green-2.focus > i,
    .btn.c-btn-icon.c-btn-green-2:active > i,
    .btn.c-btn-icon.c-btn-green-2.active > i {
      background: #44b089; }

.btn.c-btn-icon.c-btn-green-3 {
  background: #84c68f;
  border-color: #84c68f;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-3 > i {
    background: #73be7f;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-green-3:hover,
  .btn.c-btn-icon.c-btn-green-3:focus,
  .btn.c-btn-icon.c-btn-green-3.focus,
  .btn.c-btn-icon.c-btn-green-3:active,
  .btn.c-btn-icon.c-btn-green-3.active {
    color: #ffffff;
    background: #73be7f;
    border-color: #73be7f; }
    .btn.c-btn-icon.c-btn-green-3:hover > i,
    .btn.c-btn-icon.c-btn-green-3:focus > i,
    .btn.c-btn-icon.c-btn-green-3.focus > i,
    .btn.c-btn-icon.c-btn-green-3:active > i,
    .btn.c-btn-icon.c-btn-green-3.active > i {
      background: #68b976; }

.btn.c-btn-icon.c-btn-red {
  background: #eb5d68;
  border-color: #eb5d68;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-red > i {
    background: #e84653;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red:hover,
  .btn.c-btn-icon.c-btn-red:focus,
  .btn.c-btn-icon.c-btn-red.focus,
  .btn.c-btn-icon.c-btn-red:active,
  .btn.c-btn-icon.c-btn-red.active {
    color: #ffffff;
    background: #e84653;
    border-color: #e84653; }
    .btn.c-btn-icon.c-btn-red:hover > i,
    .btn.c-btn-icon.c-btn-red:focus > i,
    .btn.c-btn-icon.c-btn-red.focus > i,
    .btn.c-btn-icon.c-btn-red:active > i,
    .btn.c-btn-icon.c-btn-red.active > i {
      background: #e73946; }

.btn.c-btn-icon.c-btn-red-1 {
  background: #cd6262;
  border-color: #cd6262;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-1 > i {
    background: #c74f4f;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-1:hover,
  .btn.c-btn-icon.c-btn-red-1:focus,
  .btn.c-btn-icon.c-btn-red-1.focus,
  .btn.c-btn-icon.c-btn-red-1:active,
  .btn.c-btn-icon.c-btn-red-1.active {
    color: #ffffff;
    background: #c74f4f;
    border-color: #c74f4f; }
    .btn.c-btn-icon.c-btn-red-1:hover > i,
    .btn.c-btn-icon.c-btn-red-1:focus > i,
    .btn.c-btn-icon.c-btn-red-1.focus > i,
    .btn.c-btn-icon.c-btn-red-1:active > i,
    .btn.c-btn-icon.c-btn-red-1.active > i {
      background: #c34343; }

.btn.c-btn-icon.c-btn-red-2 {
  background: #e7505a;
  border-color: #e7505a;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-2 > i {
    background: #e43a45;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-2:hover,
  .btn.c-btn-icon.c-btn-red-2:focus,
  .btn.c-btn-icon.c-btn-red-2.focus,
  .btn.c-btn-icon.c-btn-red-2:active,
  .btn.c-btn-icon.c-btn-red-2.active {
    color: #ffffff;
    background: #e43a45;
    border-color: #e43a45; }
    .btn.c-btn-icon.c-btn-red-2:hover > i,
    .btn.c-btn-icon.c-btn-red-2:focus > i,
    .btn.c-btn-icon.c-btn-red-2.focus > i,
    .btn.c-btn-icon.c-btn-red-2:active > i,
    .btn.c-btn-icon.c-btn-red-2.active > i {
      background: #e22c38; }

.btn.c-btn-icon.c-btn-red-3 {
  background: #d05163;
  border-color: #d05163;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-3 > i {
    background: #cb3d51;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-red-3:hover,
  .btn.c-btn-icon.c-btn-red-3:focus,
  .btn.c-btn-icon.c-btn-red-3.focus,
  .btn.c-btn-icon.c-btn-red-3:active,
  .btn.c-btn-icon.c-btn-red-3.active {
    color: #ffffff;
    background: #cb3d51;
    border-color: #cb3d51; }
    .btn.c-btn-icon.c-btn-red-3:hover > i,
    .btn.c-btn-icon.c-btn-red-3:focus > i,
    .btn.c-btn-icon.c-btn-red-3.focus > i,
    .btn.c-btn-icon.c-btn-red-3:active > i,
    .btn.c-btn-icon.c-btn-red-3.active > i {
      background: #c33549; }

.btn.c-btn-icon.c-btn-yellow {
  background: #FF6B57;
  border-color: #FF6B57;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow > i {
    background: #ff553e;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow:hover,
  .btn.c-btn-icon.c-btn-yellow:focus,
  .btn.c-btn-icon.c-btn-yellow.focus,
  .btn.c-btn-icon.c-btn-yellow:active,
  .btn.c-btn-icon.c-btn-yellow.active {
    color: #ffffff;
    background: #ff553e;
    border-color: #ff553e; }
    .btn.c-btn-icon.c-btn-yellow:hover > i,
    .btn.c-btn-icon.c-btn-yellow:focus > i,
    .btn.c-btn-icon.c-btn-yellow.focus > i,
    .btn.c-btn-icon.c-btn-yellow:active > i,
    .btn.c-btn-icon.c-btn-yellow.active > i {
      background: #ff472e; }

.btn.c-btn-icon.c-btn-yellow-1 {
  background: #c8d046;
  border-color: #c8d046;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-1 > i {
    background: #c1c933;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-1:hover,
  .btn.c-btn-icon.c-btn-yellow-1:focus,
  .btn.c-btn-icon.c-btn-yellow-1.focus,
  .btn.c-btn-icon.c-btn-yellow-1:active,
  .btn.c-btn-icon.c-btn-yellow-1.active {
    color: #ffffff;
    background: #c1c933;
    border-color: #c1c933; }
    .btn.c-btn-icon.c-btn-yellow-1:hover > i,
    .btn.c-btn-icon.c-btn-yellow-1:focus > i,
    .btn.c-btn-icon.c-btn-yellow-1.focus > i,
    .btn.c-btn-icon.c-btn-yellow-1:active > i,
    .btn.c-btn-icon.c-btn-yellow-1.active > i {
      background: #b5bd30; }

.btn.c-btn-icon.c-btn-yellow-2 {
  background: #c5bf66;
  border-color: #c5bf66;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-2 > i {
    background: #beb754;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-2:hover,
  .btn.c-btn-icon.c-btn-yellow-2:focus,
  .btn.c-btn-icon.c-btn-yellow-2.focus,
  .btn.c-btn-icon.c-btn-yellow-2:active,
  .btn.c-btn-icon.c-btn-yellow-2.active {
    color: #ffffff;
    background: #beb754;
    border-color: #beb754; }
    .btn.c-btn-icon.c-btn-yellow-2:hover > i,
    .btn.c-btn-icon.c-btn-yellow-2:focus > i,
    .btn.c-btn-icon.c-btn-yellow-2.focus > i,
    .btn.c-btn-icon.c-btn-yellow-2:active > i,
    .btn.c-btn-icon.c-btn-yellow-2.active > i {
      background: #bab348; }

.btn.c-btn-icon.c-btn-yellow-3 {
  background: #c5b96b;
  border-color: #c5b96b;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-3 > i {
    background: #beb059;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-yellow-3:hover,
  .btn.c-btn-icon.c-btn-yellow-3:focus,
  .btn.c-btn-icon.c-btn-yellow-3.focus,
  .btn.c-btn-icon.c-btn-yellow-3:active,
  .btn.c-btn-icon.c-btn-yellow-3.active {
    color: #ffffff;
    background: #beb059;
    border-color: #beb059; }
    .btn.c-btn-icon.c-btn-yellow-3:hover > i,
    .btn.c-btn-icon.c-btn-yellow-3:focus > i,
    .btn.c-btn-icon.c-btn-yellow-3.focus > i,
    .btn.c-btn-icon.c-btn-yellow-3:active > i,
    .btn.c-btn-icon.c-btn-yellow-3.active > i {
      background: #baab4e; }

.btn.c-btn-icon.c-btn-blue {
  background: #3498DB;
  border-color: #3498DB;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue > i {
    background: #258cd1;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue:hover,
  .btn.c-btn-icon.c-btn-blue:focus,
  .btn.c-btn-icon.c-btn-blue.focus,
  .btn.c-btn-icon.c-btn-blue:active,
  .btn.c-btn-icon.c-btn-blue.active {
    color: #ffffff;
    background: #258cd1;
    border-color: #258cd1; }
    .btn.c-btn-icon.c-btn-blue:hover > i,
    .btn.c-btn-icon.c-btn-blue:focus > i,
    .btn.c-btn-icon.c-btn-blue.focus > i,
    .btn.c-btn-icon.c-btn-blue:active > i,
    .btn.c-btn-icon.c-btn-blue.active > i {
      background: #2383c4; }

.btn.c-btn-icon.c-btn-blue-1 {
  background: #5e9cd1;
  border-color: #5e9cd1;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-1 > i {
    background: #4a90cb;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-1:hover,
  .btn.c-btn-icon.c-btn-blue-1:focus,
  .btn.c-btn-icon.c-btn-blue-1.focus,
  .btn.c-btn-icon.c-btn-blue-1:active,
  .btn.c-btn-icon.c-btn-blue-1.active {
    color: #ffffff;
    background: #4a90cb;
    border-color: #4a90cb; }
    .btn.c-btn-icon.c-btn-blue-1:hover > i,
    .btn.c-btn-icon.c-btn-blue-1:focus > i,
    .btn.c-btn-icon.c-btn-blue-1.focus > i,
    .btn.c-btn-icon.c-btn-blue-1:active > i,
    .btn.c-btn-icon.c-btn-blue-1.active > i {
      background: #3e88c8; }

.btn.c-btn-icon.c-btn-blue-2 {
  background: #5893dd;
  border-color: #5893dd;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-2 > i {
    background: #4385d9;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-2:hover,
  .btn.c-btn-icon.c-btn-blue-2:focus,
  .btn.c-btn-icon.c-btn-blue-2.focus,
  .btn.c-btn-icon.c-btn-blue-2:active,
  .btn.c-btn-icon.c-btn-blue-2.active {
    color: #ffffff;
    background: #4385d9;
    border-color: #4385d9; }
    .btn.c-btn-icon.c-btn-blue-2:hover > i,
    .btn.c-btn-icon.c-btn-blue-2:focus > i,
    .btn.c-btn-icon.c-btn-blue-2.focus > i,
    .btn.c-btn-icon.c-btn-blue-2:active > i,
    .btn.c-btn-icon.c-btn-blue-2.active > i {
      background: #367dd6; }

.btn.c-btn-icon.c-btn-blue-3 {
  background: #57bfe1;
  border-color: #57bfe1;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-3 > i {
    background: #41b7dd;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-blue-3:hover,
  .btn.c-btn-icon.c-btn-blue-3:focus,
  .btn.c-btn-icon.c-btn-blue-3.focus,
  .btn.c-btn-icon.c-btn-blue-3:active,
  .btn.c-btn-icon.c-btn-blue-3.active {
    color: #ffffff;
    background: #41b7dd;
    border-color: #41b7dd; }
    .btn.c-btn-icon.c-btn-blue-3:hover > i,
    .btn.c-btn-icon.c-btn-blue-3:focus > i,
    .btn.c-btn-icon.c-btn-blue-3.focus > i,
    .btn.c-btn-icon.c-btn-blue-3:active > i,
    .btn.c-btn-icon.c-btn-blue-3.active > i {
      background: #34b2db; }

.btn.c-btn-icon.c-btn-purple {
  background: #b771b0;
  border-color: #b771b0;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple > i {
    background: #ae60a7;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple:hover,
  .btn.c-btn-icon.c-btn-purple:focus,
  .btn.c-btn-icon.c-btn-purple.focus,
  .btn.c-btn-icon.c-btn-purple:active,
  .btn.c-btn-icon.c-btn-purple.active {
    color: #ffffff;
    background: #ae60a7;
    border-color: #ae60a7; }
    .btn.c-btn-icon.c-btn-purple:hover > i,
    .btn.c-btn-icon.c-btn-purple:focus > i,
    .btn.c-btn-icon.c-btn-purple.focus > i,
    .btn.c-btn-icon.c-btn-purple:active > i,
    .btn.c-btn-icon.c-btn-purple.active > i {
      background: #a956a1; }

.btn.c-btn-icon.c-btn-purple-1 {
  background: #a962bb;
  border-color: #a962bb;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-1 > i {
    background: #9f50b3;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-1:hover,
  .btn.c-btn-icon.c-btn-purple-1:focus,
  .btn.c-btn-icon.c-btn-purple-1.focus,
  .btn.c-btn-icon.c-btn-purple-1:active,
  .btn.c-btn-icon.c-btn-purple-1.active {
    color: #ffffff;
    background: #9f50b3;
    border-color: #9f50b3; }
    .btn.c-btn-icon.c-btn-purple-1:hover > i,
    .btn.c-btn-icon.c-btn-purple-1:focus > i,
    .btn.c-btn-icon.c-btn-purple-1.focus > i,
    .btn.c-btn-icon.c-btn-purple-1:active > i,
    .btn.c-btn-icon.c-btn-purple-1.active > i {
      background: #974aaa; }

.btn.c-btn-icon.c-btn-purple-2 {
  background: #aa67a3;
  border-color: #aa67a3;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-2 > i {
    background: #9f5997;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-2:hover,
  .btn.c-btn-icon.c-btn-purple-2:focus,
  .btn.c-btn-icon.c-btn-purple-2.focus,
  .btn.c-btn-icon.c-btn-purple-2:active,
  .btn.c-btn-icon.c-btn-purple-2.active {
    color: #ffffff;
    background: #9f5997;
    border-color: #9f5997; }
    .btn.c-btn-icon.c-btn-purple-2:hover > i,
    .btn.c-btn-icon.c-btn-purple-2:focus > i,
    .btn.c-btn-icon.c-btn-purple-2.focus > i,
    .btn.c-btn-icon.c-btn-purple-2:active > i,
    .btn.c-btn-icon.c-btn-purple-2.active > i {
      background: #95538e; }

.btn.c-btn-icon.c-btn-purple-3 {
  background: #ac3773;
  border-color: #ac3773;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-3 > i {
    background: #993166;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-purple-3:hover,
  .btn.c-btn-icon.c-btn-purple-3:focus,
  .btn.c-btn-icon.c-btn-purple-3.focus,
  .btn.c-btn-icon.c-btn-purple-3:active,
  .btn.c-btn-icon.c-btn-purple-3.active {
    color: #ffffff;
    background: #993166;
    border-color: #993166; }
    .btn.c-btn-icon.c-btn-purple-3:hover > i,
    .btn.c-btn-icon.c-btn-purple-3:focus > i,
    .btn.c-btn-icon.c-btn-purple-3.focus > i,
    .btn.c-btn-icon.c-btn-purple-3:active > i,
    .btn.c-btn-icon.c-btn-purple-3.active > i {
      background: #8d2d5e; }

.btn.c-btn-icon.c-btn-brown {
  background: #8a7f68;
  border-color: #8a7f68;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown > i {
    background: #7b725d;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown:hover,
  .btn.c-btn-icon.c-btn-brown:focus,
  .btn.c-btn-icon.c-btn-brown.focus,
  .btn.c-btn-icon.c-btn-brown:active,
  .btn.c-btn-icon.c-btn-brown.active {
    color: #ffffff;
    background: #7b725d;
    border-color: #7b725d; }
    .btn.c-btn-icon.c-btn-brown:hover > i,
    .btn.c-btn-icon.c-btn-brown:focus > i,
    .btn.c-btn-icon.c-btn-brown.focus > i,
    .btn.c-btn-icon.c-btn-brown:active > i,
    .btn.c-btn-icon.c-btn-brown.active > i {
      background: #736a56; }

.btn.c-btn-icon.c-btn-brown-1 {
  background: #685e47;
  border-color: #685e47;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-1 > i {
    background: #59503d;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-1:hover,
  .btn.c-btn-icon.c-btn-brown-1:focus,
  .btn.c-btn-icon.c-btn-brown-1.focus,
  .btn.c-btn-icon.c-btn-brown-1:active,
  .btn.c-btn-icon.c-btn-brown-1.active {
    color: #ffffff;
    background: #59503d;
    border-color: #59503d; }
    .btn.c-btn-icon.c-btn-brown-1:hover > i,
    .btn.c-btn-icon.c-btn-brown-1:focus > i,
    .btn.c-btn-icon.c-btn-brown-1.focus > i,
    .btn.c-btn-icon.c-btn-brown-1:active > i,
    .btn.c-btn-icon.c-btn-brown-1.active > i {
      background: #504836; }

.btn.c-btn-icon.c-btn-brown-2 {
  background: #7a6a61;
  border-color: #7a6a61;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-2 > i {
    background: #6c5e56;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-2:hover,
  .btn.c-btn-icon.c-btn-brown-2:focus,
  .btn.c-btn-icon.c-btn-brown-2.focus,
  .btn.c-btn-icon.c-btn-brown-2:active,
  .btn.c-btn-icon.c-btn-brown-2.active {
    color: #ffffff;
    background: #6c5e56;
    border-color: #6c5e56; }
    .btn.c-btn-icon.c-btn-brown-2:hover > i,
    .btn.c-btn-icon.c-btn-brown-2:focus > i,
    .btn.c-btn-icon.c-btn-brown-2.focus > i,
    .btn.c-btn-icon.c-btn-brown-2:active > i,
    .btn.c-btn-icon.c-btn-brown-2.active > i {
      background: #63564f; }

.btn.c-btn-icon.c-btn-brown-3 {
  background: #9d8b81;
  border-color: #9d8b81;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-3 > i {
    background: #927e73;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-brown-3:hover,
  .btn.c-btn-icon.c-btn-brown-3:focus,
  .btn.c-btn-icon.c-btn-brown-3.focus,
  .btn.c-btn-icon.c-btn-brown-3:active,
  .btn.c-btn-icon.c-btn-brown-3.active {
    color: #ffffff;
    background: #927e73;
    border-color: #927e73; }
    .btn.c-btn-icon.c-btn-brown-3:hover > i,
    .btn.c-btn-icon.c-btn-brown-3:focus > i,
    .btn.c-btn-icon.c-btn-brown-3.focus > i,
    .btn.c-btn-icon.c-btn-brown-3:active > i,
    .btn.c-btn-icon.c-btn-brown-3.active > i {
      background: #8a766b; }

.btn.c-btn-icon.c-btn-dark {
  background: #2f353b;
  border-color: #2f353b;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark > i {
    background: #24282d;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark:hover,
  .btn.c-btn-icon.c-btn-dark:focus,
  .btn.c-btn-icon.c-btn-dark.focus,
  .btn.c-btn-icon.c-btn-dark:active,
  .btn.c-btn-icon.c-btn-dark.active {
    color: #ffffff;
    background: #24282d;
    border-color: #24282d; }
    .btn.c-btn-icon.c-btn-dark:hover > i,
    .btn.c-btn-icon.c-btn-dark:focus > i,
    .btn.c-btn-icon.c-btn-dark.focus > i,
    .btn.c-btn-icon.c-btn-dark:active > i,
    .btn.c-btn-icon.c-btn-dark.active > i {
      background: #1d2124; }

.btn.c-btn-icon.c-btn-dark-1 {
  background: #525e64;
  border-color: #525e64;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-1 > i {
    background: #475156;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-1 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-1:hover,
  .btn.c-btn-icon.c-btn-dark-1:focus,
  .btn.c-btn-icon.c-btn-dark-1.focus,
  .btn.c-btn-icon.c-btn-dark-1:active,
  .btn.c-btn-icon.c-btn-dark-1.active {
    color: #ffffff;
    background: #475156;
    border-color: #475156; }
    .btn.c-btn-icon.c-btn-dark-1:hover > i,
    .btn.c-btn-icon.c-btn-dark-1:focus > i,
    .btn.c-btn-icon.c-btn-dark-1.focus > i,
    .btn.c-btn-icon.c-btn-dark-1:active > i,
    .btn.c-btn-icon.c-btn-dark-1.active > i {
      background: #40494e; }

.btn.c-btn-icon.c-btn-dark-2 {
  background: #31383c;
  border-color: #31383c;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-2 > i {
    background: #262b2e;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-2:hover,
  .btn.c-btn-icon.c-btn-dark-2:focus,
  .btn.c-btn-icon.c-btn-dark-2.focus,
  .btn.c-btn-icon.c-btn-dark-2:active,
  .btn.c-btn-icon.c-btn-dark-2.active {
    color: #ffffff;
    background: #262b2e;
    border-color: #262b2e; }
    .btn.c-btn-icon.c-btn-dark-2:hover > i,
    .btn.c-btn-icon.c-btn-dark-2:focus > i,
    .btn.c-btn-icon.c-btn-dark-2.focus > i,
    .btn.c-btn-icon.c-btn-dark-2:active > i,
    .btn.c-btn-icon.c-btn-dark-2.active > i {
      background: #1f2326; }

.btn.c-btn-icon.c-btn-dark-3 {
  background: #41515b;
  border-color: #41515b;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-3 > i {
    background: #36444c;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-dark-3:hover,
  .btn.c-btn-icon.c-btn-dark-3:focus,
  .btn.c-btn-icon.c-btn-dark-3.focus,
  .btn.c-btn-icon.c-btn-dark-3:active,
  .btn.c-btn-icon.c-btn-dark-3.active {
    color: #ffffff;
    background: #36444c;
    border-color: #36444c; }
    .btn.c-btn-icon.c-btn-dark-3:hover > i,
    .btn.c-btn-icon.c-btn-dark-3:focus > i,
    .btn.c-btn-icon.c-btn-dark-3.focus > i,
    .btn.c-btn-icon.c-btn-dark-3:active > i,
    .btn.c-btn-icon.c-btn-dark-3.active > i {
      background: #303c43; }

.btn.c-btn-icon.c-btn-grey {
  background: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e; }
  .btn.c-btn-icon.c-btn-grey > i {
    background: #e1e1e1;
    color: #6e6e6e; }
  .btn.c-btn-icon.c-btn-grey > .c-helper {
    color: #6e6e6e; }
  .btn.c-btn-icon.c-btn-grey:hover,
  .btn.c-btn-icon.c-btn-grey:focus,
  .btn.c-btn-icon.c-btn-grey.focus,
  .btn.c-btn-icon.c-btn-grey:active,
  .btn.c-btn-icon.c-btn-grey.active {
    color: #6e6e6e;
    background: #e1e1e1;
    border-color: #e1e1e1; }
    .btn.c-btn-icon.c-btn-grey:hover > i,
    .btn.c-btn-icon.c-btn-grey:focus > i,
    .btn.c-btn-icon.c-btn-grey.focus > i,
    .btn.c-btn-icon.c-btn-grey:active > i,
    .btn.c-btn-icon.c-btn-grey.active > i {
      background: #dadada; }

.btn.c-btn-icon.c-btn-grey-1 {
  background: #f7f7f7;
  border-color: #f7f7f7;
  color: #777777; }
  .btn.c-btn-icon.c-btn-grey-1 > i {
    background: #eaeaea;
    color: #777777; }
  .btn.c-btn-icon.c-btn-grey-1 > .c-helper {
    color: #777777; }
  .btn.c-btn-icon.c-btn-grey-1:hover,
  .btn.c-btn-icon.c-btn-grey-1:focus,
  .btn.c-btn-icon.c-btn-grey-1.focus,
  .btn.c-btn-icon.c-btn-grey-1:active,
  .btn.c-btn-icon.c-btn-grey-1.active {
    color: #777777;
    background: #eaeaea;
    border-color: #eaeaea; }
    .btn.c-btn-icon.c-btn-grey-1:hover > i,
    .btn.c-btn-icon.c-btn-grey-1:focus > i,
    .btn.c-btn-icon.c-btn-grey-1.focus > i,
    .btn.c-btn-icon.c-btn-grey-1:active > i,
    .btn.c-btn-icon.c-btn-grey-1.active > i {
      background: #e3e3e3; }

.btn.c-btn-icon.c-btn-grey-2 {
  background: #677083;
  border-color: #677083;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-2 > i {
    background: #5c6475;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-2 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-2:hover,
  .btn.c-btn-icon.c-btn-grey-2:focus,
  .btn.c-btn-icon.c-btn-grey-2.focus,
  .btn.c-btn-icon.c-btn-grey-2:active,
  .btn.c-btn-icon.c-btn-grey-2.active {
    color: #ffffff;
    background: #5c6475;
    border-color: #5c6475; }
    .btn.c-btn-icon.c-btn-grey-2:hover > i,
    .btn.c-btn-icon.c-btn-grey-2:focus > i,
    .btn.c-btn-icon.c-btn-grey-2.focus > i,
    .btn.c-btn-icon.c-btn-grey-2:active > i,
    .btn.c-btn-icon.c-btn-grey-2.active > i {
      background: #555c6c; }

.btn.c-btn-icon.c-btn-grey-3 {
  background: #7f8c97;
  border-color: #7f8c97;
  color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-3 > i {
    background: #717f8b;
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-3 > .c-helper {
    color: #ffffff; }
  .btn.c-btn-icon.c-btn-grey-3:hover,
  .btn.c-btn-icon.c-btn-grey-3:focus,
  .btn.c-btn-icon.c-btn-grey-3.focus,
  .btn.c-btn-icon.c-btn-grey-3:active,
  .btn.c-btn-icon.c-btn-grey-3.active {
    color: #ffffff;
    background: #717f8b;
    border-color: #717f8b; }
    .btn.c-btn-icon.c-btn-grey-3:hover > i,
    .btn.c-btn-icon.c-btn-grey-3:focus > i,
    .btn.c-btn-icon.c-btn-grey-3.focus > i,
    .btn.c-btn-icon.c-btn-grey-3:active > i,
    .btn.c-btn-icon.c-btn-grey-3.active > i {
      background: #6a7883; }

.btn.c-btn-border-1x.c-btn-white,
.btn.c-btn-border-2x.c-btn-white {
  border-color: rba(#ffffff, 0.9); }

.dropdown-menu {
  border: 0;
  box-shadow: 0px 3px 12px -5px rgba(0, 0, 0, 0.35); }
  .dropdown-menu > li > a {
    font-size: 16px;
    color: #5c6873; }

/* Dropdown submenu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative; }
  .dropdown-submenu > .dropdown-menu {
    top: 5px;
    left: 100%;
    margin-top: -6px;
    margin-left: 1px; }
    .dropdown-submenu > .dropdown-menu.c-pull-left {
      left: -100%;
      margin-left: -1px; }
  .dropdown-submenu:hover > .dropdown-menu {
    display: block; }
  .dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px; }

.modal {
  z-index: 10050;
  overflow-y: auto; }

.modal-open {
  overflow: auto; }

.modal-header {
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid #eeeeee; }
  .modal-header > .close {
    margin-top: -4px; }

.modal-body {
  padding: 20px; }

.modal-footer {
  padding: 20px;
  border-top: 1px solid #eeeeee; }

.modal-title {
  font-weight: 500;
  font-size: 18px;
  color: #3f444a; }

.table > tfooter > tr > td,
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > thead > tr > th {
  border-color: #eeeeee; }

.table > thead > tr > th {
  font-weight: 500;
  font-size: 17px;
  color: #3f444a; }

.table tr.success > th,
.table tr.success > td,
.table tr.info > th,
.table tr.info > td,
.table tr.danger > th,
.table tr.danger > td,
.table tr.warning > th,
.table tr.warning > td {
  color: #fff; }

.table-responsive {
  border-color: #eeeeee; }

/* Left and right tabs */
.tabs-right.nav-tabs,
.tabs-left.nav-tabs {
  border-bottom: 0; }
  .tabs-right.nav-tabs > li,
  .tabs-left.nav-tabs > li {
    float: none; }
    .tabs-right.nav-tabs > li > a,
    .tabs-left.nav-tabs > li > a {
      margin-right: 0;
      margin-bottom: 3px; }

/* Left tabs */
.tabs-left.nav-tabs {
  border-right: 1px solid #ddd; }
  .tabs-left.nav-tabs > li > a {
    display: block;
    margin-right: -1px; }
    .tabs-left.nav-tabs > li > a:hover,
    .tabs-left.nav-tabs > li > a:focus {
      border-color: #eeeeee #dddddd #eeeeee #eeeeee; }
  .tabs-left.nav-tabs > li.active > a,
  .tabs-left.nav-tabs > li.active > a:hover
> li.active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff; }

/* Right tabs */
.tabs-right.nav-tabs {
  border-left: 1px solid #ddd; }
  .tabs-right.nav-tabs > li > a {
    display: block;
    margin-left: -1px; }
    .tabs-right.nav-tabs > li > a:hover,
    .tabs-right.nav-tabs > li > a:focus {
      border-color: #eeeeee #eeeeee #eeeeee #dddddd; }
  .tabs-right.nav-tabs > li.active > a,
  .tabs-right.nav-tabs > li.active > a:hover
> li.active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: #ffffff; }

.tp-banner-container {
  position: relative; }
  .tp-banner-container.tp-fullscreen {
    height: 1100px; }

.tp-banner-container .fulllscreenvideo.tp-videolayer {
  left: 0px !important; }

.tp-banner {
  display: none;
  width: 100%;
  position: relative; }
  .tp-banner iframe {
    border: 0 !important; }

.tp-bullets.round > .tp-bullet {
  background: #e3e7ee;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 12px;
  height: 12px; }
  .tp-bullets.round > .tp-bullet.selected {
    background: #32c5d2; }
  .tp-bullets.round > .tp-bullet:hover {
    background: #c4ccd8; }

.tparrows.c-tparrows-hide {
  display: none; }

.tparrows.tp-leftarrow,
.tparrows.tp-rightarrow {
  background: none;
  font-family: FontAwesome;
  font-weight: 100; }

.tparrows.tp-leftarrow {
  background-position: 0px 3600%; }
  .tparrows.tp-leftarrow:before {
    content: ''; }

.tparrows.tp-rightarrow {
  background-position: 0px 3700%; }
  .tparrows.tp-rightarrow:before {
    content: ''; }

.tparrows.circle {
  background-image: url(../img/content/line-icons/white.png);
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: cover;
  transition: all 0.3s;
  color: #ffffff;
  opacity: 0.3;
  filter: alphaopacity=30; }
  .tparrows.circle.hidearrows {
    opacity: 0;
    filter: alphaopacity=0; }
  .tparrows.circle:hover {
    transition: all 0.3s;
    opacity: 1;
    filter: alphaopacity=100; }

.tp-banner-container.c-arrow-dark .tparrows.circle {
  background-image: url(../img/content/line-icons/dark1.png); }

.tp-banner-container.c-arrow-darken .tparrows.circle {
  background-image: url(../img/content/line-icons/dark3.png); }

.tp-loader.spinner2 {
  background-color: #999DA7; }

@media (max-width: 991px) {
  /* 991px */
  .tp-banner-container.tp-fullscreen {
    height: auto; }
  .tp-banner-container.tp-fullscreen-mobile {
    height: 800px; } }

.cbp-panel {
  width: 100%;
  max-width: auto;
  margin: 0 auto;
  font-family: "Roboto Condensed", sans-serif !important; }

.cbp-popup-wrap {
  z-index: 10005; }

.cbp-l-caption-title {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 500;
  margin: 0; }

.cbp-l-caption-desc {
  font-size: 15px;
  font-family: "Roboto Condensed", sans-serif; }

.cbp-caption-activeWrap {
  background: #000000; }

.cbp-l-inline-desc {
  font-size: 17px;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 10px;
  font-weight: 300;
  color: #3f444a; }

.cbp-l-filters-dropdownWrap {
  background: none;
  border: 1px solid #3f444a; }
  .cbp-l-filters-dropdownWrap .cbp-l-filters-dropdownHeader {
    color: #3f444a;
    font-size: 16px; }
  .cbp-l-filters-dropdownWrap .cbp-l-filters-dropdownList {
    background: #ffffff; }
    .cbp-l-filters-dropdownWrap .cbp-l-filters-dropdownList .cbp-filter-item {
      font-size: 16px;
      color: #3f444a;
      border: 0; }
      .cbp-l-filters-dropdownWrap .cbp-l-filters-dropdownList .cbp-filter-item:hover {
        background: white; }
      .cbp-l-filters-dropdownWrap .cbp-l-filters-dropdownList .cbp-filter-item.cbp-filter-item-active {
        background: #f6f7f8; }

.c-content-box > .cbp {
  margin-right: -2px; }

.cbp-l-inline-view {
  margin-top: 20px; }

.cbp-l-filters-work .cbp-filter-item,
.cbp-l-filters-button .cbp-filter-item,
.cbp-l-filters-buttonCenter .cbp-filter-item {
  border-radius: 0;
  font-family: "Roboto Condensed", sans-serif;
  background-color: #FFFFFF;
  border: 1px solid #3f444a;
  color: #3f444a;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 12px; }
  .cbp-l-filters-work .cbp-filter-item:hover,
  .cbp-l-filters-button .cbp-filter-item:hover,
  .cbp-l-filters-buttonCenter .cbp-filter-item:hover {
    background-color: #3f444a;
    color: #fff; }
  .cbp-l-filters-work .cbp-filter-item.cbp-filter-item-active,
  .cbp-l-filters-button .cbp-filter-item.cbp-filter-item-active,
  .cbp-l-filters-buttonCenter .cbp-filter-item.cbp-filter-item-active {
    background-color: #3f444a;
    color: #fff;
    border: 1px solid #3f444a; }

.cbp-l-filters-work .cbp-filter-counter {
  background: none;
  display: inline-block;
  top: -1px;
  position: relative; }
  .cbp-l-filters-work .cbp-filter-counter:before {
    border: 0; }

.cbp-l-inline-title {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: #3f444a; }

.cbp-l-inline-subtitle {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #3f444a;
  margin-top: 0px; }

.cbp-l-grid-masonry-projects-desc {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 2px;
  font-size: 13px;
  color: #7a838e; }

.cbp-l-caption-buttonLeft,
.cbp-l-caption-buttonRight {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 90px; }

.cbp-l-grid-masonry-projects .cbp-caption-activeWrap {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.6); }

.cbp-popup-singlePage .cbp-popup-navigation-wrap {
  z-index: 10006;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.8); }

.cbp-l-grid-masonry-projects-title {
  color: #3f444a;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600; }

.cbp-l-grid-masonry-projects-desc {
  color: #5c6873;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.cbp-l-project-title {
  color: #3f444a;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 1px; }

.cbp-l-project-subtitle {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 2px;
  font-size: 13px;
  color: #626a73; }

.cbp-l-project-related-link {
  color: #3f444a;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 17px; }

.cbp-l-project-desc-title span,
.cbp-l-project-details-title span {
  font-family: "Roboto Condensed", sans-serif;
  display: inline-block;
  color: #3f444a;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 0;
  margin: 5px 0 -1px 0;
  padding: 0 5px 0 0; }

.cbp-l-project-desc-title,
.cbp-l-project-details-title {
  margin-bottom: 40px;
  border-bottom: 0;
  display: block;
  position: relative;
  overflow: visible; }
  .cbp-l-project-desc-title:before,
  .cbp-l-project-details-title:before {
    position: absolute;
    top: 50px;
    content: '';
    width: 20px;
    height: 3px;
    display: block; }

.cbp-l-project-desc-text {
  font-family: "Roboto Condensed", sans-serif;
  color: #626a73;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 10px; }

.cbp-l-project-details-title {
  margin-bottom: 10px; }

.cbp-l-project-details-list {
  list-style: none;
  margin: 35px 0 20px 0;
  padding: 0; }
  .cbp-l-project-details-list > li {
    font-family: "Roboto Condensed", sans-serif;
    padding: 5px 0;
    margin: 0;
    color: #6e7781;
    font-size: 16px;
    font-weight: 300; }
    .cbp-l-project-details-list > li:first-child {
      padding-top: 0; }
    .cbp-l-project-details-list > li > strong {
      color: #4b5158;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 16px;
      font-weight: 500; }

.cbp-l-project-container {
  margin: 40px 0; }

.cbp-l-project-related-wrap {
  padding: 0;
  margin: 0; }

.cbp-l-project-related-title {
  font-family: "Roboto Condensed", sans-serif;
  color: #626a73;
  font-size: 16px;
  font-weight: 600; }

.cbp-l-grid-work-title,
.cbp-l-grid-projects-title,
.cbp-l-grid-agency-title {
  font-family: "Roboto Condensed", sans-serif;
  color: #3f444a;
  font-size: 18px;
  font-weight: 600; }

.cbp-l-grid-work-desc,
.cbp-l-grid-projects-desc,
.cbp-l-grid-agency-desc {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  color: #7a838e; }

.cbp-l-filters-alignCenter,
.cbp-l-filters-text {
  font-family: "Roboto Condensed", sans-serif; }
  .cbp-l-filters-alignCenter .cbp-l-filters-text-sort,
  .cbp-l-filters-text .cbp-l-filters-text-sort {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #626a73; }
  .cbp-l-filters-alignCenter .cbp-filter-item,
  .cbp-l-filters-text .cbp-filter-item {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #565d66; }

.cbp-popup-lightbox-title {
  color: #eee;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.cbp-l-filters-underline .cbp-filter-item.cbp-filter-item-active {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 600;
  cursor: default; }

.cbp-l-filters-underline .cbp-filter-item {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  color: #3f444a; }

.cbp-caption-expand .cbp-caption-defaultWrap {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #3f444a; }
  .cbp-caption-expand .cbp-caption-defaultWrap .fa {
    margin-right: 6px;
    width: 20px;
    text-align: center;
    font-size: 20px;
    color: #cad5e0; }

.cbp-caption-expand .cbp-l-caption-body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #3f444a; }

.cbp-l-grid-masonry-projects .cbp-l-caption-body a {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 16px;
  font-size: 12px;
  font-weight: 4600; }

.cbp-caption-activeWrap .c-masonry-border {
  border: 1px solid rgba(255, 255, 255, 0.2);
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  position: absolute; }

.owl-carousel .owl-controls {
  margin-top: 60px; }
  .owl-carousel .owl-controls .owl-dot {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%; }

.owl-carousel.c-owl-nav-center .owl-controls {
  text-align: center; }

.owl-carousel.owl-bordered {
  margin-top: 30px; }
  .owl-carousel.owl-bordered .owl-controls {
    margin-top: 20px; }
  .owl-carousel.owl-bordered .owl-wrapper-outer {
    border: 1px solid #eeeeee; }
  .owl-carousel.owl-bordered .owl-item {
    border-right: 1px solid #eeeeee; }
    .owl-carousel.owl-bordered .owl-item:last-child {
      border-right: 0; }

.owl-carousel.owl-reset-space:not(.owl-single):not(.owl-bordered) {
  margin: 0;
  width: 100%; }
  .owl-carousel.owl-reset-space:not(.owl-single):not(.owl-bordered) .owl-wrapper .owl-item {
    padding: 0; }

.owl-carousel.owl-small-space:not(.owl-single):not(.owl-bordered) {
  margin: 0;
  width: 100%; }
  .owl-carousel.owl-small-space:not(.owl-single):not(.owl-bordered) .owl-wrapper .owl-item {
    padding: 5px; }

.owl-theme:not(.owl-single):not(.owl-bordered) {
  margin-left: -30px;
  width: calc(100% + 60px); }
  .owl-theme:not(.owl-single):not(.owl-bordered) .owl-wrapper .owl-item, .owl-theme:not(.owl-single):not(.owl-bordered) .owl-stage-outer .owl-item {
    padding: 0 30px; }

.owl-theme.owl-theme-white .owl-pagination .owl-page span {
  background: #ffffff;
  opacity: 0.5;
  filter: alphaopacity=50; }

.owl-theme.owl-theme-white .owl-pagination .owl-page.active span {
  background: #ffffff;
  opacity: 1;
  filter: alphaopacity=100; }

@media (max-width: 767px) {
  /* 767px */
  .owl-theme:not(.owl-single):not(.owl-bordered) {
    margin-left: -10px;
    width: calc(100% + 20px); }
    .owl-theme:not(.owl-single):not(.owl-bordered) .owl-wrapper .owl-item {
      padding: 0 10px; } }

.fancybox-overlay {
  z-index: 10200; }

.fancybox-margin {
  margin: 0; }

/*** 
Fontawesome Demo
***/
.fontawesome-icon-list {
  margin-top: 20px;
  margin-bottom: 50px; }
  .fontawesome-icon-list .fa-hover > a {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    padding: 5px 10px; }
    .fontawesome-icon-list .fa-hover > a > i {
      width: 20px;
      font-size: 18px;
      margin-right: 5px; }

/***
Simple Line Icons Demo
***/
.simplelineicons-demo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%; }

.simplelineicons-demo .item-box .item {
  border: 1px solid #eaeaea;
  background-color: #fff;
  color: #33383e;
  display: inline-block;
  padding: 10px;
  width: 100%;
  vertical-align: center; }

.simplelineicons-demo .item-box .item span {
  font-size: 22px; }

.customline-demo.simplelineicons-demo .item-box a {
  display: inline-block; }

.customline-demo.simplelineicons-demo .c-content-line-icon {
  float: left;
  margin-top: -4px;
  margin-right: 15px; }

@media only screen and (min-width: 768px) {
  .simplelineicons-demo .item-box {
    width: 24.333%; } }

/*** 
Glyphicons Demo
***/
.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden; }

.bs-glyphicon-class {
  text-align: center; }

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden; }

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd; }

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px; }

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%; } }

.socicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.socicon-btn:empty
.socicon:empty {
  width: 1em; }

.socicon-btn {
  position: relative;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  width: 44px;
  height: 44px;
  padding-top: 11px;
  font-size: 24px;
  border: 1px solid #eeeeee;
  color: #3f444a;
  transition: background, color 0.3s; }
  .socicon-btn.socicon-solid {
    border: 0;
    width: 46px;
    height: 46px;
    padding-top: 12px; }
  .socicon-btn.socicon-btn-circle {
    border-radius: 44px; }
  .socicon-btn:hover {
    transition: background, color 0.3s; }
  .socicon-btn.socicon-lg {
    width: 54px;
    height: 54px;
    padding-top: 13px;
    font-size: 28px; }
    .socicon-btn.socicon-lg.socicon-solid {
      border: 0;
      width: 55px;
      height: 55px;
      padding-top: 13px; }
  .socicon-btn.socicon-sm {
    width: 30px;
    height: 30px;
    padding-top: 8px;
    font-size: 16px; }
    .socicon-btn.socicon-sm.socicon-solid {
      border: 0;
      width: 30px;
      height: 30px;
      padding-top: 8px; }

.socicons .socicon-btn {
  margin-bottom: 5px; }

.socicon-twitter:before {
  content: "a"; }

.socicon-facebook:before {
  content: "b"; }

.socicon-google:before {
  content: "c"; }

.socicon-pinterest:before {
  content: "d"; }

.socicon-foursquare:before {
  content: "e"; }

.socicon-yahoo:before {
  content: "f"; }

.socicon-skype:before {
  content: "g"; }

.socicon-yelp:before {
  content: "h"; }

.socicon-feedburner:before {
  content: "i"; }

.socicon-linkedin:before {
  content: "j"; }

.socicon-viadeo:before {
  content: "k"; }

.socicon-xing:before {
  content: "l"; }

.socicon-myspace:before {
  content: "m"; }

.socicon-soundcloud:before {
  content: "n"; }

.socicon-spotify:before {
  content: "o"; }

.socicon-grooveshark:before {
  content: "p"; }

.socicon-lastfm:before {
  content: "q"; }

.socicon-youtube:before {
  content: "r"; }

.socicon-vimeo:before {
  content: "s"; }

.socicon-dailymotion:before {
  content: "t"; }

.socicon-vine:before {
  content: "u"; }

.socicon-flickr:before {
  content: "v"; }

.socicon-500px:before {
  content: "w"; }

.socicon-instagram:before {
  content: "x"; }

.socicon-wordpress:before {
  content: "y"; }

.socicon-tumblr:before {
  content: "z"; }

.socicon-blogger:before {
  content: "A"; }

.socicon-technorati:before {
  content: "B"; }

.socicon-reddit:before {
  content: "C"; }

.socicon-dribbble:before {
  content: "D"; }

.socicon-stumbleupon:before {
  content: "E"; }

.socicon-digg:before {
  content: "F"; }

.socicon-envato:before {
  content: "G"; }

.socicon-behance:before {
  content: "H"; }

.socicon-delicious:before {
  content: "I"; }

.socicon-deviantart:before {
  content: "J"; }

.socicon-forrst:before {
  content: "K"; }

.socicon-playstore:before {
  content: "L"; }

.socicon-zerply:before {
  content: "M"; }

.socicon-wikipedia:before {
  content: "N"; }

.socicon-apple:before {
  content: "O"; }

.socicon-flattr:before {
  content: "P"; }

.socicon-github:before {
  content: "Q"; }

.socicon-chimein:before {
  content: "R"; }

.socicon-friendfeed:before {
  content: "S"; }

.socicon-newsvine:before {
  content: "T"; }

.socicon-identica:before {
  content: "U"; }

.socicon-bebo:before {
  content: "V"; }

.socicon-zynga:before {
  content: "W"; }

.socicon-steam:before {
  content: "X"; }

.socicon-xbox:before {
  content: "Y"; }

.socicon-windows:before {
  content: "Z"; }

.socicon-outlook:before {
  content: "1"; }

.socicon-coderwall:before {
  content: "2"; }

.socicon-tripadvisor:before {
  content: "3"; }

.socicon-appnet:before {
  content: "4"; }

.socicon-goodreads:before {
  content: "5"; }

.socicon-tripit:before {
  content: "6"; }

.socicon-lanyrd:before {
  content: "7"; }

.socicon-slideshare:before {
  content: "8"; }

.socicon-buffer:before {
  content: "9"; }

.socicon-rss:before {
  content: ","; }

.socicon-vkontakte:before {
  content: ";"; }

.socicon-disqus:before {
  content: ":"; }

.socicon-houzz:before {
  content: "+"; }

.socicon-mail:before {
  content: "@"; }

.socicon-patreon:before {
  content: "="; }

.socicon-paypal:before {
  content: "-"; }

.socicon-playstation:before {
  content: "^"; }

.socicon-smugmug:before {
  content: "¨"; }

.socicon-swarm:before {
  content: "$"; }

.socicon-triplej:before {
  content: "*"; }

.socicon-yammer:before {
  content: "&"; }

.socicon-stackoverflow:before {
  content: "("; }

.socicon-drupal:before {
  content: "#"; }

.socicon-odnoklassniki:before {
  content: "."; }

.socicon-android:before {
  content: "_"; }

.socicon-meetup:before {
  content: "]"; }

.socicon-persona:before {
  content: ")"; }

.btn-social {
  padding-left: 40px; }
  .btn-social > :first-child {
    font-size: 18px; }
  .btn-social:hover,
  .btn-social:active,
  .btn-social:focus {
    color: #fff; }
  .btn-social.btn-md > :first-child {
    padding-top: 4px;
    width: 40px;
    font-size: 20px; }
  .btn-social.btn-lg > :first-child {
    width: 44px;
    font-size: 22px; }

.c-spinner {
  width: 100%; }
  .c-spinner input {
    float: left;
    width: 40px;
    padding: 6px;
    border-radius: 0;
    border-color: #d0d7de; }

.c-input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: inline-block; }
  .c-input-group-btn-vertical i {
    position: absolute;
    top: 0;
    left: 4px; }
  .c-input-group-btn-vertical .btn {
    display: block;
    float: none;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;
    color: #d0d7de;
    border-color: #d0d7de; }
    .c-input-group-btn-vertical .btn:first-child {
      border-top-right-radius: 0; }
    .c-input-group-btn-vertical .btn:last-child {
      margin-top: -2px;
      border-bottom-right-radius: 0; }

.simplegallery {
  margin: 0 auto;
  width: 417px;
  height: 380px;
  overflow: hidden; }
  .simplegallery .content {
    background: #fff;
    position: relative;
    width: 418px;
    height: 270px;
    overflow: hidden; }
    .simplegallery .content img {
      width: 415px;
      border: 1px solid #9c9c9c; }
  .simplegallery .thumbnail {
    margin-top: 5px; }
    .simplegallery .thumbnail .thumb {
      float: left;
      width: 100px;
      height: 100px;
      margin: 0 5px 0 0;
      cursor: pointer; }
      .simplegallery .thumbnail .thumb img {
        border: 1px solid #9c9c9c;
        width: 100px; }
    .simplegallery .thumbnail .last {
      margin: 0; }

.ilightbox-caption > h4 {
  font-size: 18px;
  text-shadow: none; }

.ilightbox-caption > p {
  font-size: 14px;
  text-shadow: none; }

.ilightbox-holder.dark .ilightbox-caption > h1, .ilightbox-holder.dark .ilightbox-caption h2, .ilightbox-holder.dark .ilightbox-caption h3, .ilightbox-holder.dark .ilightbox-caption h4 {
  color: #fff; }

.ilightbox-holder.light .ilightbox-caption > h1, .ilightbox-holder.light .ilightbox-caption h2, .ilightbox-holder.light .ilightbox-caption h3, .ilightbox-holder.light .ilightbox-caption h4 {
  color: #000; }

.wow {
  opacity: 0; }
